import { useEffect } from "preact/hooks";
import { useQuery, useSubscription } from "urql";

import { usePatientContext } from "@app/components/Contexts";
import { Header } from "@app/components/Header";
import { Main } from "@app/components/Main";
import { CONTENT_MAX_WIDTH } from "@app/components/Main/Main";
import type { HubPageProps } from "@app/components/PatientHub";
import { Button } from "@shared/components/Button";
import { Highlight } from "@shared/components/Highlight";
import { Stack } from "@shared/components/Stack";
import { Text } from "@shared/components/Text";
import { useIntakeNavigation } from "@shared/hooks/useIntakeNavigation";
import { useTheme } from "@shared/hooks/useTheme";
import { HiddenEncounterStatuses } from "@shared/types/EncounterStatus";
import {
	GetPractitionerImagesForServiceIntentsDocument,
	WidgetGetServiceIntentsDocument,
} from "@shared/types/graphql";

import { PatientLinks } from "./PatientLinks";
import { useServiceIntents } from "./Visits.hooks";
import styles from "./Visits.module.css";
import { VisitsList } from "./VisitsList";

export function Visits({ onNextPage }: HubPageProps) {
	const { patientId } = usePatientContext();
	const { primaryBackground, primaryAccent200, spacing } = useTheme();
	const { navigateToIntakeStart } = useIntakeNavigation();

	const [{ data, error }] = useSubscription({
		query: WidgetGetServiceIntentsDocument,
		variables: {
			hiddenStatuses: HiddenEncounterStatuses,
		},
		pause: !patientId,
	});
	const {
		serviceIntentsList,
		currentServiceIntentsList,
		pastServiceIntentsList,
	} = useServiceIntents(data);
	const [{ data: practitionerImagesData }, refetchImagesData] = useQuery({
		query: GetPractitionerImagesForServiceIntentsDocument,
		pause: !serviceIntentsList.length,
		variables: {
			serviceIntentIds: serviceIntentsList.map((intent) => intent.id),
		},
	});
	const practitionerImages =
		practitionerImagesData?.getPractitionerImagesForServiceIntents ?? [];

	// Refetch practitioner images every hour
	useEffect(() => {
		const interval = setInterval(() => {
			refetchImagesData();
		}, 1000 * 60 * 60);
		return () => clearInterval(interval);
	}, [refetchImagesData]);

	const handleStartNewVisit = () => {
		navigateToIntakeStart();
	};

	const currentUnarchivedServiceIntentsList = currentServiceIntentsList.filter(
		(serviceIntent) => {
			//if updated in the last 2 days always show
			const updatedAt = new Date(
				serviceIntent!.encounter!.updated_at
			).getTime();
			if (updatedAt > Date.now() - 1000 * 60 * 60 * 24 * 2) {
				return true;
			}
			/**
			 * Otherwise show only if not the following statuses:
			 * Based on the funnel_status I'd like these statuses to auto-archive:
			Capture Assigned
			Capture Started
			Capture Completed
			Registration Started
			Evaluation Assigned
			Evaluation Started
			Evaluation Completed
			Payment Started
			Canceled - Restarted
			Assuming all DQ & "Canceled" statuses show up under Past Visits not Ongoing Visits already in the patient widget.
			 */
			return ![
				"capture_assigned",
				"capture_started",
				"capture_completed",
				"registration_started",
				"evaluation_assigned",
				"evaluation_started",
				"evaluation_completed",
				"payment_started",
				"canceled_restarted",
			].includes(serviceIntent!.encounter!.funnel_status!);
		}
	);

	return (
		<>
			<Header />
			<Main loading={!data} error={error?.message}>
				<Stack direction="vertical" gap={spacing(6)} className={styles.root}>
					<Stack
						className={styles.currentVisits}
						direction="vertical"
						gap={spacing(2)}
						style={{
							borderColor: primaryAccent200?.rgba,
						}}
					>
						<div className={styles.currentVisitsTitle}>
							<Text variant="h2">Ongoing Visits</Text>
						</div>
						{currentUnarchivedServiceIntentsList.length > 0 && (
							<VisitsList
								serviceIntents={currentUnarchivedServiceIntentsList}
								practitionerImages={practitionerImages}
								onNextPage={onNextPage}
							/>
						)}
						{currentUnarchivedServiceIntentsList.length === 0 && (
							<Highlight
								variant="primary"
								caption="No active visits"
								text="You don't currently have any active visits. Start a new visit to get connected to a specialist who can help."
								padding={spacing(4)}
							/>
						)}
					</Stack>
					{pastServiceIntentsList.length > 0 && (
						<Stack direction="vertical" gap={spacing(2)}>
							<Text variant="h2">Past Visits</Text>
							<VisitsList
								serviceIntents={pastServiceIntentsList}
								practitionerImages={practitionerImages}
								onNextPage={onNextPage}
							/>
						</Stack>
					)}
					<Stack direction="vertical" gap={spacing(2)}>
						<Text variant="h2">Settings</Text>
						<PatientLinks onNextPage={onNextPage} />
					</Stack>
				</Stack>
				<Stack
					className={styles.newVisitOverlay}
					direction="vertical"
					style={{
						background: `linear-gradient(180deg, ${
							primaryBackground.withAlpha(0).rgba
						} 0%, ${primaryBackground.withAlpha(0.8).rgba} 20%, ${
							primaryBackground.rgba
						} 100%)`,
					}}
				>
					<Button
						text="+ Start a new visit"
						onClick={handleStartNewVisit}
						style={{
							margin: "0 auto",
							maxWidth: CONTENT_MAX_WIDTH,
						}}
					/>
				</Stack>
			</Main>
		</>
	);
}
