import type { KeyboardEvent } from "react";

import type { TextProps } from "../InputGroup";
import { InputGroup } from "../InputGroup";

const validateZipCode = (input: HTMLInputElement): void => {
	const minLength = 5;
	if (input.value.length < minLength) {
		input.setCustomValidity(`ZIP code must be at least ${minLength} numbers`);
		return;
	}

	const maxLength = 5;
	if (input.value.length > maxLength) {
		input.setCustomValidity(`ZIP code must be at most ${maxLength} numbers`);
		return;
	}

	if (input.value.match(/[^0-9]/)) {
		input.setCustomValidity("ZIP code must be numeric");
		return;
	}

	// Everything is fine.
	input.setCustomValidity("");
	input.reportValidity();
};

const preventNonZipCodeInput = (e: KeyboardEvent<HTMLInputElement>) => {
	const validNonNumericKeys = [
		"Backspace",
		"Delete",
		"Tab",
		"ArrowLeft",
		"ArrowRight",
		"Home",
		"End",
		"Meta",
		"Control",
		"Alt",
		"Shift",
		"Enter",
	];
	if (e.metaKey || validNonNumericKeys.includes(e.key)) return;
	if ((e.key ?? "").match(/[^0-9]/)) e.preventDefault();
};

export function InputZipCode(
	props: Omit<TextProps, "type" | "validate" | "placeholder">
) {
	return (
		<InputGroup
			type="text"
			validate={validateZipCode}
			placeholder="12345"
			autoComplete="postal-code"
			onKeyDown={preventNonZipCodeInput}
			{...props}
		/>
	);
}
