import { useEffect, useRef } from "react";

export function useInterval<T>(
	callback: () => T,
	{ delay, skip = false }: { delay: number; skip?: boolean }
) {
	const savedCallback = useRef<() => void>();
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	const lastInterval = useRef<ReturnType<typeof window.setTimeout>>();
	useEffect(() => {
		if (skip)
			return () => {
				if (!lastInterval.current) return;
				clearInterval(lastInterval.current);
			};

		lastInterval.current = setInterval(() => {
			if (!savedCallback.current) return;
			savedCallback.current();
		}, delay);
		return () => {
			if (!lastInterval.current) return;
			clearInterval(lastInterval.current);
		};
	}, [delay, skip]);

	return lastInterval;
}
