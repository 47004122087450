import { chatboxOutline, chevronForward, calendarClear } from "ionicons/icons";
import { useCallback, useMemo } from "preact/hooks";

import { Badge } from "@shared/components/Badge";
import { List, type Item } from "@shared/components/ButtonList";
import { Icon } from "@shared/components/Icon";
import { Stack } from "@shared/components/Stack";
import { Text } from "@shared/components/Text";
import { useIconStyles } from "@shared/hooks/useIconStyles";
import { useIntakeNavigation } from "@shared/hooks/useIntakeNavigation";
import { useTheme } from "@shared/hooks/useTheme";
import { EncounterStatus } from "@shared/types/EncounterStatus";
import { IntakeStage } from "@shared/types/IntakeStages";
import { ServiceIntentStatus } from "@shared/types/ServiceIntentStatus";
import { formatDate } from "@shared/utilities/formatDate";
import { isDarkMode } from "@shared/utilities/isDarkMode";

import { UserAvatar } from "../../VisitChannel/UserAvatar";
import { getPractitionerAvatarData } from "../getPractitionerAvatarData";
import { useVisitUnreadCounts } from "../useVisitUnreadCounts";
import styles from "./VisitsList.module.css";
import type { Props } from "./types";
import { type ServiceIntentItem } from "./types";

const INERT_STATUSES = [
	ServiceIntentStatus.CAPTURE_QUESTIONS_COMPLETED_DISQUALIFIED,
	ServiceIntentStatus.QUALIFICATION_QUESTIONS_COMPLETED_DISQUALIFIED,
	ServiceIntentStatus.PAYMENT_SUBMITTED,
];

export function VisitsList({ serviceIntents, practitionerImages }: Props) {
	const { primaryForeground, primaryAccent700 } = useTheme();
	const chatIconStroke = isDarkMode() ? primaryForeground : primaryAccent700;

	const chevronIcon = useIconStyles({
		icon: chevronForward,
		stroke: primaryForeground,
	});
	const calendarIcon = useIconStyles({
		icon: calendarClear,
		stroke: primaryForeground,
	});

	const iconProps = useIconStyles({
		icon: chatboxOutline,
		stroke: chatIconStroke,
	});
	const channelUnreads = useVisitUnreadCounts(serviceIntents);
	const { navigateToIntake } = useIntakeNavigation();
	const practitionerImagesByUserId = useMemo(
		() =>
			practitionerImages.reduce(
				(acc, image) => ({
					...acc,
					[image?.practitionerId ?? ""]: image?.url,
				}),
				{} as Record<string, string | undefined>
			),
		[practitionerImages]
	);
	const onClickService = useCallback(
		({ service, status }: ServiceIntentItem) => {
			const serviceId = service?.id ?? "";

			return () => {
				const isInert = INERT_STATUSES.includes(status as ServiceIntentStatus);
				if (isInert) return;

				navigateToIntake(IntakeStage.Questions, {
					serviceId,
					isSecondaryQuestionStage: false,
				});
			};
		},
		[navigateToIntake]
	);

	const composeItemFromIntent = useCallback(
		(data: ServiceIntentItem) => {
			const { id, status, created_at: createdAt, service, encounter } = data;
			const isInert = INERT_STATUSES.includes(status as ServiceIntentStatus);
			const practitionerInteraction = encounter?.practitioner_interaction;
			const practitioner = practitionerInteraction?.practitioner;
			const { userName, userInitials } =
				getPractitionerAvatarData(practitioner);
			const avatarUrl = practitionerImagesByUserId[practitioner?.id ?? ""];
			const channel = encounter?.portal_message_channel;
			const hasUnread =
				[
					EncounterStatus.WAITING,
					EncounterStatus.SUPPORT_WAITING_FOR_CONSENT,
				].includes(encounter?.status as EncounterStatus) ||
				(channel && (channelUnreads[channel.id] ?? 0) > 0);
			const channelIcon = (() => {
				if (isInert || service?.status === "draft") return undefined;
				return chevronIcon;
			})();
			const channelImage = (
				<UserAvatar
					image={avatarUrl}
					userName={userName}
					userInitials={userInitials}
					iconProps={iconProps}
					size={48}
					showTooltipOnHover={false}
				/>
			);

			const endIcon = (() => {
				if (isInert) return null;
				return (
					<>
						{hasUnread ? (
							<div className={styles.badge}>
								<Badge circle backgroundColor="#7DD3FC" noBorder />
							</div>
						) : null}
						<Icon {...chevronIcon} />
					</>
				);
			})();

			return {
				key: id,
				title: service?.name ?? "",
				description: (
					<Stack direction="horizontal" gap={7} align="center">
						<Icon size={16} {...calendarIcon} />
						<Text>Started {formatDate(new Date(createdAt))}</Text>
					</Stack>
				),
				iconPosition: "after",
				iconProps: channelIcon,
				startIcon: channelImage,
				endIcon,
				onClick: onClickService(data),
				disabled: isInert || service?.status === "draft",
				align: "start",
			} as Item;
		},
		[
			calendarIcon,
			channelUnreads,
			chevronIcon,
			iconProps,
			onClickService,
			practitionerImagesByUserId,
		]
	);

	return <List items={serviceIntents.map(composeItemFromIntent)} />;
}
