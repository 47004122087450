import { useCallback, useMemo } from "react";

import { useMutation, useQuery } from "urql";

import type { WidgetGetConsentFormsQuery } from "@shared/types/graphql";
import {
	WidgetConsentToFormDocument,
	WidgetGetConsentFormsDocument,
} from "@shared/types/graphql";

import type { ConsentFormPayload } from "../Message";

const emptyConsentForms: WidgetGetConsentFormsQuery["consent_form"] = [];

export enum ConsentFormType {
	Telehealth = "telehealth",
	PrivacyPolicy = "notice_of_privacy_practices",
}

export function useConsentFormData({
	consentForms,
}: {
	consentForms: ConsentFormPayload[];
}) {
	const [, consentToForm] = useMutation(WidgetConsentToFormDocument);
	const consentFormIds = useMemo(
		() =>
			Array.from(
				new Set(consentForms.map(({ consentFormIds }) => consentFormIds).flat())
			),
		[consentForms]
	);
	const [{ data, fetching }] = useQuery({
		query: WidgetGetConsentFormsDocument,
		variables: { consentFormIds },
	});
	const consents = data?.consent_form ?? emptyConsentForms;
	const telehealthConsentFormId = useMemo(() => {
		return (
			consents.find(({ type }) => type === ConsentFormType.Telehealth)?.id ?? ""
		);
	}, [consents]);
	const privacyPolicyConsentFormId = useMemo(() => {
		return (
			consents.find(({ type }) => type === ConsentFormType.PrivacyPolicy)?.id ??
			""
		);
	}, [consents]);
	const consentToFormTemplateIds = useMemo(
		() =>
			consents.reduce((acc, { id, form_template_versions }) => {
				acc[id] = form_template_versions[0].id;
				return acc;
			}, {} as Record<string, string>),
		[consents]
	);
	const practitionerIds = useMemo(() => {
		return consentForms.map(({ practitionerId }) => practitionerId);
	}, [consentForms]);
	const consentToForms = useCallback(() => {
		const practitionersConsentForms = consentForms.map(
			({ practitionerId, consentFormIds }) => {
				return {
					practitionerId,
					consentForms: consentFormIds.map((consentFormId) => {
						return {
							consentFormId,
							consentFormTemplateId: consentToFormTemplateIds[consentFormId],
						};
					}),
				};
			}
		);

		consentToForm({
			practitionersConsentForms,
		});
	}, [consentForms, consentToForm, consentToFormTemplateIds]);

	return {
		fetching: fetching,
		consentToForms,
		practitionerIds,
		telehealthConsentFormId,
		privacyPolicyConsentFormId,
	};
}
