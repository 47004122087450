import { useEffect, useState } from "preact/hooks";

import { SESSION_STORAGE_KEYS } from "@shared/constants/storage";
import { safeSessionStorage } from "@shared/utilities/safeLocalStorage";

import { useIntakeStages } from "../IntakeStageContext";

export function useCustomMenuNavigation(currentMenuId?: string) {
	const intakeStageContext = useIntakeStages();
	const [showBackButton, setShowBackButton] = useState(false);
	const rootMenuId = safeSessionStorage.getItem(
		SESSION_STORAGE_KEYS.rootMenuId
	);

	useEffect(() => {
		if (!currentMenuId) return;

		if (!rootMenuId) {
			safeSessionStorage.setItem(
				SESSION_STORAGE_KEYS.rootMenuId,
				currentMenuId
			);
		}

		setShowBackButton(
			rootMenuId !== currentMenuId &&
				intakeStageContext.currentStage === intakeStageContext.stages[0]
		);
	}, [rootMenuId, currentMenuId, intakeStageContext]);

	return { rootMenuId, showBackButton };
}
