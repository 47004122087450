import {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "preact/hooks";
import { useQuery, useSubscription } from "urql";

import { usePatientContext } from "@app/components/Contexts";
import { usePractitionerImages } from "@app/hooks/usePractitionerImages";
import { usePrevious } from "@shared/hooks/usePrevious";
import type { MessageFragment } from "@shared/types/graphql";
import {
	WidgetGetHistoricalMessagesForChannelDocument,
	WidgetGetNewMessagesForChannelDocument,
} from "@shared/types/graphql";

const BATCH_SIZE = 20;

export function useMessageList(
	channelId: string,
	localMessages: MessageFragment[],
	setLocalMessages: (
		message:
			| MessageFragment[]
			| ((oldValue: MessageFragment[]) => MessageFragment[])
	) => void
) {
	const { patientId } = usePatientContext();
	const hasFetchedAllMessages = useRef(false);
	const [offset, setOffset] = useState(0);

	// Subscribe to new messages.
	const [newMessagesResponse] = useSubscription({
		query: WidgetGetNewMessagesForChannelDocument,
		variables: {
			channelId,
			limit: BATCH_SIZE,
		},
		pause: !channelId,
	});
	const newMessages = newMessagesResponse.data?.portal_message;

	// Fetch older messages on demand.
	const [historicalMessagesResponse] = useQuery({
		query: WidgetGetHistoricalMessagesForChannelDocument,
		variables: {
			channelId,
			offset,
			limit: BATCH_SIZE,
		},
		pause: !channelId || offset === 0,
	});
	const historicalMessages = historicalMessagesResponse.data?.portal_message;

	// Keep optimistic messages in local state.
	useEffect(() => {
		// Cleanup local messages that have come in through conventional means.
		setLocalMessages((oldValue) =>
			oldValue.filter(
				(localMessage) =>
					!newMessages?.some(
						(newMessage) => newMessage.id === localMessage.id
					) &&
					!historicalMessages?.some(
						(historicalMessage) => historicalMessage.id === localMessage.id
					)
			)
		);
	}, [setLocalMessages, newMessages, historicalMessages]);

	// Merge old messages, new messages, and local messages.
	const messages = useMemo(
		() =>
			[...localMessages, ...(newMessages ?? []), ...(historicalMessages ?? [])]
				.filter((message, index, array) => {
					// Remove duplicates.
					return array.findIndex((m) => m.id === message.id) === index;
				})
				.sort((a, b) => a.created_at - b.created_at)
				.reverse(),
		[historicalMessages, newMessages, localMessages]
	);

	const imageUrlsByUserId = usePractitionerImages({
		channelId,
		practitionerIds: messages
			.map((message) => message.sender_user_id)
			.filter((userId, index, array): userId is string => {
				const isString = typeof userId === "string";
				const isNotPatient = userId !== patientId;
				const isUnique = array.indexOf(userId) === index;
				return isString && isNotPatient && isUnique;
			})
			.sort(),
	});

	// We already had messages, then we fetched more, and now
	// we're done fetching but have no additional messages.
	// That means we can stop fetching more.
	const previousMessageCount = usePrevious(historicalMessages?.length ?? 0);
	const wasFetching = usePrevious(historicalMessagesResponse.fetching);
	const didStopFetching = wasFetching && !historicalMessagesResponse.fetching;
	const hadMessages = (previousMessageCount.current ?? 0) > 0;
	const messageCountUnchanged =
		previousMessageCount.current === (historicalMessages?.length ?? 0);
	if (
		!hasFetchedAllMessages.current &&
		didStopFetching &&
		hadMessages &&
		messageCountUnchanged
	) {
		hasFetchedAllMessages.current = true;
	}
	const fetchOlderMessages = useCallback(() => {
		if (hasFetchedAllMessages.current) return;

		setOffset(messages.length - localMessages.length);
	}, [messages.length, localMessages.length]);

	return {
		fetchOlderMessages,
		fetchingOlder: historicalMessagesResponse.fetching,
		fetching:
			newMessagesResponse.fetching &&
			!(newMessagesResponse.data || newMessagesResponse.error),
		error: newMessagesResponse.error,
		messages,
		imageUrlsByUserId,
	};
}
