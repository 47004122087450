import { useEffect, useRef } from "react";

export function useEffectOnce(
	callback: (markDidRun: () => void) => void | (() => void),
	dependencies: unknown[]
) {
	const didRun = useRef(false);
	useEffect(() => {
		if (didRun.current) return;
		const markDidRun = () => (didRun.current = true);
		return callback(markDidRun);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, dependencies);
}
