import type { ComponentChildren } from "preact";

import { useCallback, useState } from "preact/hooks";

import {
	closeReactNative,
	isReactNative,
	postMessageToReactNative,
} from "@app/utilities/reactNative";
import {
	PubSubMessage,
	usePublisher,
	useSubscription,
} from "@shared/hooks/usePubSub";
import { debug } from "@shared/utilities/debug";
import { getHeaders, setHeader } from "@shared/utilities/gql/headers";

import { WidgetContext } from "./WidgetContext";

interface Props {
	widgetId: string;
	children: ComponentChildren;
}

const WIDGET_ID_HEADER = "x-hasura-widget-id";

export function WidgetContextProvider({ widgetId, children }: Props) {
	const [isWidgetOpen, setIsWidgetOpen] = useState(true);
	const onRendered = usePublisher({
		type: PubSubMessage.Complete,
		recipient: window.top,
	});
	const exitPanel = usePublisher({
		type: PubSubMessage.ExitWidget,
		recipient: window.top,
	});

	if (widgetId && !getHeaders()[WIDGET_ID_HEADER]) {
		setHeader(WIDGET_ID_HEADER, widgetId);
	}

	const closeWidget = useCallback(() => {
		if (isReactNative()) {
			closeReactNative();
		} else {
			// We're only doing this for web, for now [CAT-1173]
			setIsWidgetOpen(false);
			exitPanel();
		}
	}, [exitPanel]);
	const openWidget = useCallback(() => setIsWidgetOpen(true), []);
	useSubscription(PubSubMessage.OpenWidget, openWidget);

	const handleRendered = useCallback(() => {
		onRendered();

		// Let the native app know that the widget has finished loading the essentials
		if (isReactNative()) {
			postMessageToReactNative("loadEnd");
		}
	}, [onRendered]);

	return (
		<WidgetContext.Provider
			ref={() =>
				debug("info", {
					context: "WidgetContextProvider",
					message: "Widget context loaded.",
					info: { widgetId },
				})
			}
			value={{
				widgetId,
				isWidgetOpen,
				closeWidget,
				openWidget,
				onRendered: handleRendered,
			}}
		>
			{children}
		</WidgetContext.Provider>
	);
}
