import { useRef } from "react";

import type { RouteMap } from "@app/constants/RouteMap";
import { useNavigation } from "@shared/hooks/useNavigation";
import { debug } from "@shared/utilities/debug";
import type { PathVariables } from "@shared/utilities/path";
import { path } from "@shared/utilities/path";

interface Props<Route extends ValueOf<typeof RouteMap>> {
	to?: Route;
	toPath?: string;
	variables?: PathVariables<Route>;
	queryParams?: Record<string, string>;
	replaceQueryParams?: boolean;
	replaceHistory?: boolean;
}

export function Redirect<Route extends ValueOf<typeof RouteMap>>({
	to,
	toPath,
	variables,
	queryParams,
	replaceQueryParams = true,
	replaceHistory = false,
}: Props<Route>) {
	debug("info", {
		context: "Redirect",
		message: "Redirecting",
		info: {
			to,
			toPath,
			variables,
			queryParams,
			replaceQueryParams,
			replaceHistory,
		},
	});
	const { navigateTo } = useNavigation();
	const hasRedirected = useRef(false);
	if (!hasRedirected.current) {
		hasRedirected.current = true;

		if (toPath) {
			navigateTo(toPath, replaceHistory);
		} else if (to && variables) {
			navigateTo(
				path(to, variables, queryParams, replaceQueryParams),
				replaceHistory
			);
		}
	}

	return null;
}
