import type { ReactNode, CSSProperties } from "react";

import classNames from "classnames";

import { Button } from "@shared/components/Button";
import { Stack } from "@shared/components/Stack";
import { Text } from "@shared/components/Text";
import { useTheme } from "@shared/hooks/useTheme";

import styles from "./List.module.css";

export interface Item {
	key?: string | number;
	title?: ReactNode | null;
	caption?: ReactNode | null;
	description?: ReactNode | null;
	accessoryView?: ReactNode | null;
	onClick?: (item: Item) => void;
	disabled?: boolean;
	startIcon?: ReactNode;
	endIcon?: ReactNode;
	align?:
		| "start"
		| "center"
		| "end"
		| "space-between"
		| "space-evenly"
		| "stretch";
	wrapperClassName?: string;
	style?: CSSProperties;
}

interface Props {
	items: Item[];
	gap?: number;
	compact?: boolean;
	className?: string;
}

const Wrapper = ({
	item,
	children,
	compactButtons,
	className,
}: {
	item: Item;
	children: ReactNode;
	compactButtons?: boolean;
	className?: string;
}) =>
	item.onClick ? (
		<Button
			className={classNames(className, {
				[styles.compactButton]: compactButtons,
			})}
			variant="plain"
			onClick={() => item.onClick?.(item)}
			disabled={item.disabled}
		>
			{children}
		</Button>
	) : (
		<>{children}</>
	);

function getItemTemplate(item: Item) {
	let template = "";

	if (item.startIcon) {
		template += "auto ";
	}

	if (item.endIcon) {
		template += "1fr min-content";
	} else {
		template += "auto";
	}

	return template;
}

export function List({ items = [], gap, compact, className }: Props) {
	const { spacing } = useTheme();

	return (
		<Stack
			element="ul"
			role="list"
			direction="vertical"
			align="stretch"
			gap={gap}
			className={className}
		>
			{items.map((item) => {
				return (
					<li className={styles.item} key={item.key} data-item-id={item.key}>
						<Stack direction="vertical" align="stretch" gap={spacing(3)}>
							<Wrapper
								item={item}
								compactButtons={compact}
								className={item.wrapperClassName}
							>
								<Stack
									direction="horizontal"
									template={getItemTemplate(item)}
									align={item.align ?? "center"}
									justify={item.endIcon ? "space-between" : "start"}
									gap={spacing(3)}
									padding={compact ? 0 : { vertical: spacing(2) }}
									style={item.style}
								>
									{item.startIcon}
									<Stack
										direction="vertical"
										align="stretch"
										justify="center"
										gap={spacing(1)}
									>
										{(item.title || item.caption) && (
											<Stack
												direction="vertical"
												align="stretch"
												gap={spacing(1)}
											>
												{typeof item.caption === "string" ? (
													<Text variant="caption">{item.caption}</Text>
												) : (
													item.caption
												)}
												{typeof item.title === "string" ? (
													<Text variant="h3">{item.title}</Text>
												) : (
													item.title
												)}
											</Stack>
										)}
										{typeof item.description === "string" ? (
											<Text>{item.description}</Text>
										) : (
											item.description
										)}
									</Stack>
									{item.endIcon}
								</Stack>
							</Wrapper>
							{item.accessoryView}
						</Stack>
					</li>
				);
			})}
		</Stack>
	);
}
