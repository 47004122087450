import { createContext } from "preact";

import type { CombinedError, UseQueryExecute } from "urql";

interface PatientProviderType {
	patientId: string | null;
	hasOngoingVisits: boolean;
	patientFirstName: string | null;
	patientLastName: string | null;
	patientFullName: string | null;
	patientExternalId: string | null;
	hasAcceptedTerms: boolean;
	fetching: boolean;
	error: CombinedError | undefined;
	refetchPatientContext: UseQueryExecute;
}

export const PatientContext = createContext<PatientProviderType>({
	patientId: null,
	hasOngoingVisits: false,
	patientFirstName: null,
	patientLastName: null,
	patientFullName: null,
	patientExternalId: null,
	hasAcceptedTerms: false,
	fetching: true,
	error: undefined,
	refetchPatientContext: () => null,
});
