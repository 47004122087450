import { IntakeStage } from "@shared/types/IntakeStages";
import { ServiceIntentStatus } from "@shared/types/ServiceIntentStatus";
import type { Scalars } from "@shared/types/graphql";
import { path } from "@shared/utilities/path";

import { CheckoutStep } from "../components/Intake/Checkout/CheckoutStep";
import { RouteMap } from "../constants/RouteMap";
import { hasStartedQuestionnaire } from "./hasStartedQuestionnaire";

type EncounterParam = {
	id: string;
	status: string;
	service_id: string;
	questionnaire_response?: {
		evaluation?: { state: Scalars["jsonb"] } | null;
	} | null;
	portal_message_channel?: {
		id: string;
	} | null;
};

export function getEncounterStepRoute(
	widgetId: string,
	encounter: EncounterParam
): string {
	const channelId = encounter?.portal_message_channel?.id;

	if (!channelId) {
		// Show an error if this happens.
		throw new Error("Encounter does not have a channel ID");
	}

	return path(RouteMap.VisitChannel, {
		widgetId,
		channelId,
	});
}

type ServiceIntentParam = {
	id: string;
	status: string;
	encounter?: EncounterParam | null;
};

export function getServiceIntentStepRoute({
	widgetId,
	serviceId,
	serviceIntent,
	forceStartOver,
	fromLogin = false,
}: {
	widgetId: string;
	serviceId: string;
	serviceIntent?: ServiceIntentParam;
	forceStartOver?: boolean;
	fromLogin?: boolean;
}): string {
	const startOver =
		forceStartOver !== undefined ? forceStartOver : !serviceIntent?.id;

	// When there's not a service intent, we're starting the intake flow.
	if (!serviceIntent || startOver) {
		return path(
			RouteMap.IntakeStages,
			{ widgetId, stageId: IntakeStage.Questions },
			{
				serviceId,
				isSecondaryQuestionStage: false,
				startOver,
			}
		);
	}

	const serviceStatus = serviceIntent.status as ServiceIntentStatus;
	const serviceIntentId = serviceIntent.id;
	const encounter = serviceIntent.encounter;

	// Note: Do not use `default`, so that we get a compile error if we forget to handle a case.
	switch (serviceStatus) {
		case ServiceIntentStatus.STARTED: {
			// Go to the capture questionnaire
			return path(
				RouteMap.IntakeStages,
				{
					widgetId,
					stageId: IntakeStage.Questions,
				},
				{
					serviceId,
					serviceIntentId,
					isSecondaryQuestionStage: false,
					startOver,
				}
			);
		}
		case ServiceIntentStatus.CAPTURE_QUESTIONS_COMPLETED_QUALIFIED: {
			// Coming from the login page, we need to check if they've started the questionnaire.
			if (
				fromLogin &&
				!hasStartedQuestionnaire(
					// TODO: Questionnaire response should come from the service intent instead of the encounter (in preparation for cases, too)
					serviceIntent.encounter?.questionnaire_response?.evaluation
				)
			) {
				return path(
					RouteMap.IntakeStages,
					{
						widgetId,
						stageId: IntakeStage.Treatment,
					},
					{
						serviceIntentId,
					}
				);
			}

			// They've completed the capture questionnaire. Go to the evaluation questionnaire.
			return path(
				RouteMap.IntakeStages,
				{
					widgetId,
					stageId: IntakeStage.Questions,
				},
				{
					serviceId,
					serviceIntentId,
					isSecondaryQuestionStage: true,
				}
			);
		}

		case ServiceIntentStatus.QUALIFICATION_QUESTIONS_COMPLETED_DISQUALIFIED:
		case ServiceIntentStatus.CAPTURE_QUESTIONS_COMPLETED_DISQUALIFIED: {
			// They've been disqualified. Redirect them to the disqualified page.
			return path(
				RouteMap.IntakeStages,
				{
					widgetId,
					stageId: IntakeStage.Disqualified,
				},
				{
					serviceIntentId,
				}
			);
		}

		// From here on out, we're dealing with the checkout flow (Should this be part of the service intent?)
		case ServiceIntentStatus.QUALIFICATION_QUESTIONS_COMPLETED_QUALIFIED: {
			return path(
				RouteMap.IntakeStages,
				{
					widgetId,
					stageId: IntakeStage.Checkout,
				},
				{
					serviceIntentId,
				}
			);
		}
		case ServiceIntentStatus.PAYMENT_STARTED: {
			// They've started the payment form. Redirect them back to it.
			return path(
				RouteMap.IntakeStages,
				{
					widgetId,
					stageId: IntakeStage.Checkout,
				},
				{
					step: CheckoutStep.Payment,
					serviceIntentId,
				}
			);
		}

		// From here on out, we're dealing with encounters.
		case ServiceIntentStatus.PAYMENT_SUBMITTED:
		case ServiceIntentStatus.PAYMENT_COLLECTED:
		case ServiceIntentStatus.PAYMENT_HOLD_AUTHORIZED:
		case ServiceIntentStatus.SUPPORT_WAITING_FOR_CONSENT:
		case ServiceIntentStatus.SUPPORT_WAITING_FOR_AV:
		case ServiceIntentStatus.ASSIGNED:
		case ServiceIntentStatus.WAITING:
		case ServiceIntentStatus.SUPPORT:
		case ServiceIntentStatus.PROCESSING:
		case ServiceIntentStatus.COMPLETED:
		case ServiceIntentStatus.CANCELED:
		case ServiceIntentStatus.CANCELLED:
		case ServiceIntentStatus.CANCELED_RESTARTED: {
			if (!encounter) {
				throw new Error("Encounter is missing");
			}

			return getEncounterStepRoute(widgetId, encounter);
		}
	}
}
