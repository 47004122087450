import { useCallback, useEffect } from "react";

import type { PubSubEventData, PubSubPayload } from "@shared/utilities/pubSub";
import {
	PubSubMessage,
	publish,
	subscribe,
	unsubscribe,
} from "@shared/utilities/pubSub";

export { PubSubMessage };

export function usePublisher<MessageType extends PubSubMessage>({
	type,
	recipient,
}: {
	type: MessageType;
	recipient: Window | null | undefined;
}) {
	return useCallback(
		(payload?: PubSubPayload[MessageType]) => {
			publish(recipient, type, payload);
		},
		[recipient, type]
	);
}

export function useSubscription<MessageType extends PubSubMessage>(
	type: MessageType | null,
	callback: (data: PubSubEventData<MessageType>) => void
) {
	useEffect(() => {
		if (!type) {
			return;
		}
		const cb = (data: PubSubEventData<MessageType>) => callback(data);
		subscribe(type, cb);
		return () => {
			if (!type) {
				return;
			}
			unsubscribe(type, cb);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);
}
