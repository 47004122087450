import { close as closeIcon, cloudDownload } from "ionicons/icons";

import { Alert } from "@shared/components/Alert";
import { Button } from "@shared/components/Button";
import { Loader } from "@shared/components/Loader";
import { ModalBackdrop } from "@shared/components/ModalBackdrop";
import { useImageCache } from "@shared/hooks/useImageCache";
import { useTheme } from "@shared/hooks/useTheme";

import styles from "./ImageModal.module.css";

interface Props {
	url: string;
	close(): void;
}

export function ImageModal({ url, close }: Props) {
	const { primaryBackground } = useTheme();
	const [data, { isLoading, isError }] = useImageCache(url);

	return (
		<ModalBackdrop close={close}>
			{isLoading && <Loader />}
			{isError && (
				<div className={styles.errorContainer}>
					<Alert
						variant="danger"
						caption="Error"
						text="Unable to load image."
					/>
				</div>
			)}
			{!isLoading && !isError && data && (
				<div className={styles.imageContainer}>
					<img className={styles.image} src={data} alt="file preview" />
					<div className={styles.buttons}>
						<Button
							variant="icon"
							className={styles.iconButton}
							href={data}
							download
							iconProps={{
								icon: cloudDownload,
								size: 24,
								fill: primaryBackground,
							}}
						/>
						<Button
							variant="icon"
							className={styles.iconButton}
							onClick={close}
							iconProps={{ icon: closeIcon, size: 24, fill: primaryBackground }}
						/>
					</div>
				</div>
			)}
			{(isLoading || isError) && !data && (
				<div className={styles.buttons}>
					<Button
						variant="icon"
						className={styles.iconButton}
						onClick={close}
						iconProps={{
							icon: closeIcon,
							size: 24,
							fill: primaryBackground,
						}}
					/>
				</div>
			)}
		</ModalBackdrop>
	);
}
