import { useState, useMemo } from "preact/hooks";

import { useAuthContext } from "@app/components/Contexts";
import { useOrganizationContext } from "@app/components/Contexts";
import TermsButton from "@app/components/Forms/EditInfo/TermsButton";
import { Form } from "@shared/components/Form";
import { InputBirthdate } from "@shared/components/InputBirthdate";
import { InputPhoneNumber } from "@shared/components/InputPhoneNumber";
import { formatDate } from "@shared/utilities/formatDate";
import { formatPhoneNumber } from "@shared/utilities/formatPhoneNumber";
import { isValidDate } from "@shared/utilities/isValidDate";

interface Props {
	title?: string;
	buttonText?: string;
	prevButtonText?: string;
	onSuccess?: (data: { phoneNumber: string; birthDate: string }) => void;
	onBack?(): void;
	showContent?: (content: { title: string; text: string }) => void;
	serviceId?: string;
}

export function StartLogin({
	title,
	buttonText = "Continue",
	prevButtonText = "Go back",
	onSuccess,
	onBack,
	showContent,
	serviceId,
}: Props) {
	const { startPatientLogin } = useAuthContext();
	const [phoneNumber, setPhoneNumber] = useState("");
	const [birthDate, setBirthDate] = useState<Date | null>(null);
	const onStartLogin = async () => {
		if (!phoneNumber || !birthDate || !isValidDate(birthDate)) {
			throw new Error("Missing required fields");
		}

		const formattedPhoneNumber = formatPhoneNumber(
			phoneNumber,
			"numeric-no-country-code"
		);
		const formattedBirthDate = formatDate(birthDate, "YYYY-MM-DD");

		const response = await startPatientLogin(
			formattedPhoneNumber,
			formattedBirthDate,
			serviceId
		);
		if (!response?.data?.startPatientLogin.success) {
			throw new Error(response?.error?.message ?? "Failed to send login code.");
		}

		onSuccess?.({
			phoneNumber: formattedPhoneNumber,
			birthDate: formattedBirthDate,
		});
	};
	const validDate = birthDate && isValidDate(birthDate);
	const validPhoneNumber = phoneNumber && phoneNumber.length === 11;
	const { termsOfUse, legalForm, organizationName } = useOrganizationContext();
	const termsLink = useMemo(() => {
		return (
			<TermsButton
				label="Terms of Use"
				title={`${organizationName} Terms of Use`}
				text={legalForm.termsOfUse ?? ""}
				link={termsOfUse}
				onClick={showContent}
			/>
		);
	}, [legalForm.termsOfUse, organizationName, showContent, termsOfUse]);

	return (
		<Form
			title={title}
			formSubtextDescription={
				<>
					{`We’ll text you to confirm your number. Standard message and data rates apply. `}
					{termsLink}
				</>
			}
			submitButtonProps={{
				text: buttonText,
				disabled: !validPhoneNumber || !validDate,
			}}
			additionalButtonProps={{
				text: prevButtonText,
				variant: "secondary",
				onClick: onBack,
			}}
			onSubmit={onStartLogin}
		>
			{({ isSubmitting }) => (
				<>
					<InputPhoneNumber
						label="Mobile number"
						value={phoneNumber}
						onInput={setPhoneNumber}
						disabled={isSubmitting}
						required
					/>
					<InputBirthdate
						label="Birthdate"
						onInput={setBirthDate}
						autocomplete="bday"
						disabled={isSubmitting}
						required
					/>
				</>
			)}
		</Form>
	);
}
