import { useCallback } from "preact/hooks";
import { useMutation } from "urql";

import { usePatientContext } from "@app/components/Contexts";
import type { ChatFile } from "@shared/components/InputFiles/InputFiles";
import { PortalMessageSource } from "@shared/types/PortalMessage";
import type { MessageFragment } from "@shared/types/graphql";
import { WidgetSendMessageToChannelDocument } from "@shared/types/graphql";
import { type User_Type_Enum } from "@shared/types/graphql";
import { createTemporaryMessageId } from "@shared/utilities/createTemporaryMessageId";
import { debug } from "@shared/utilities/debug";

export function useMessageComposer(
	channelId: string,
	setLocalMessages: (
		message:
			| MessageFragment[]
			| ((oldValue: MessageFragment[]) => MessageFragment[])
	) => void
) {
	// Patient info for local messages.
	const { patientId, patientFirstName, patientLastName } = usePatientContext();

	// Manage local messages.
	const addLocalMessage = useCallback(
		(text: string, file: ChatFile | null) => {
			const temporaryId = createTemporaryMessageId();
			setLocalMessages((oldValue) => [
				{
					id: temporaryId,
					text,
					type: "default",
					file: file
						? {
								id: file.id,
								display_name: "Download File",
								type: file.type,
						  }
						: null,
					sender_user_id: patientId ?? "",
					sender_expanded: {
						id: patientId ?? "",
						first_name: "",
						last_name: "",
						practitioner: {
							first_name: "",
							last_name: "",
							clinician_suffixes: null,
						},
						patient: {
							id: patientId ?? "",
							first_name: patientFirstName ?? "",
							last_name: patientLastName ?? "",
						},
						type: "patient" as User_Type_Enum,
					},
					created_at: Date.now(),
					payload: null,
					source: PortalMessageSource.CATCH,
				},
				...oldValue,
			]);

			return temporaryId;
		},
		[patientId, patientFirstName, patientLastName, setLocalMessages]
	);
	const removeLocalMessage = useCallback(
		(temporaryId: string) => {
			setLocalMessages((oldValue) =>
				oldValue.filter((message) => message.id !== temporaryId)
			);
		},
		[setLocalMessages]
	);
	const updateLocalMessageId = useCallback(
		(temporaryId: string, messageId: string) => {
			setLocalMessages((oldValue) =>
				oldValue.map((message) =>
					message.id === temporaryId ? { ...message, id: messageId } : message
				)
			);
		},
		[setLocalMessages]
	);

	// Send new messages, with optimistic updating.
	const [{ fetching: isSending }, sendMessage] = useMutation(
		WidgetSendMessageToChannelDocument
	);
	const doSendMessage = useCallback(
		async (text: string, file: ChatFile | null) => {
			// Optimistically add message to local state.
			const temporaryId = addLocalMessage(text, file);

			// Send message to server.
			try {
				const { data, error } = await sendMessage({
					channelId,
					text,
					fileId: file?.id ?? null,
				});

				// Error sending the message, remove the temporary message from local state.
				const messageId = data?.sendMessage?.messageId;
				if (error || !messageId) {
					debug("error", {
						context: "useMessaging",
						message: error?.message ?? "No message ID returned",
						info: error,
					});

					// TODO: maybe add an error state instead.
					removeLocalMessage(temporaryId);
					return;
				}

				// Update the message id to the real one.
				updateLocalMessageId(temporaryId, messageId);
			} catch {
				//
			}
		},
		[
			sendMessage,
			channelId,
			addLocalMessage,
			removeLocalMessage,
			updateLocalMessageId,
		]
	);

	return {
		isSending,
		sendMessage: useCallback(
			async (value: string, [file, ...files]: ChatFile[]) => {
				// Validate.
				const text = value.trim();
				if (!text && !file?.id) return;

				// Send message(s).
				if (text || file) await doSendMessage(text, file);
				files.forEach((file) => doSendMessage("", file));
			},
			[doSendMessage]
		),
	};
}
