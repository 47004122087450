import { useMemo } from "preact/hooks";

import {
	useOrganizationContext,
	usePatientContext,
} from "@app/components/Contexts";
import type { MessageFragment } from "@shared/types/graphql";
import { formatName } from "@shared/utilities/formatName";
import { formatProviderName } from "@shared/utilities/formatProviderName";

export function getSenderInfo(
	sender: MessageFragment["sender_expanded"],
	patientId: string | null,
	organizationName: string
) {
	const { id, practitioner, patient } = sender ?? {};
	const isPatient = patientId === id;
	const { first_name: firstName, last_name: lastName } = isPatient
		? patient ?? {}
		: practitioner ?? {};
	const isPractitioner = !isPatient && !!practitioner;
	const suffixes = isPatient ? null : practitioner?.clinician_suffixes;
	const userInitials =
		[firstName, lastName].map((name) => name?.[0]).join("") || undefined;
	const userName =
		formatName(firstName, lastName) || `${organizationName} Support Staff`;

	return {
		userInitials,
		userName: isPractitioner
			? formatProviderName(firstName, lastName, suffixes)
			: userName,
		isPatient,
	};
}

export function useSenderInfo(sender: MessageFragment["sender_expanded"]) {
	const { organizationName } = useOrganizationContext();
	const { patientId } = usePatientContext();
	return useMemo(
		() => getSenderInfo(sender, patientId, organizationName),
		[sender, patientId, organizationName]
	);
}
