import { type ReactNode, type Ref, useCallback } from "react";

import type { Theme } from "@shared/hooks/useTheme/Theme";

import { ThemeContext } from "./ThemeContext";
import { useThemeProvider } from "./ThemeProvider.hooks";

export interface ThemeProviderProps {
	light?: Theme;
	dark?: Theme;
	overrideIsDark?: boolean;
	children: ReactNode | ((ref: Ref<HTMLDivElement>) => ReactNode);
}

export const ThemeProvider = ({
	children,
	...restProps
}: ThemeProviderProps) => {
	const { theme, darkTheme, lightTheme, rootElementRef, hasFunctionChildren } =
		useThemeProvider({
			children,
			...restProps,
		});

	return (
		<ThemeContext.Provider
			value={{
				...theme,
				spacing: useCallback(
					(value: number) => (theme.spacingMultiplier ?? 0) * value,
					[theme.spacingMultiplier]
				),
				themes: {
					dark: darkTheme,
					light: lightTheme,
				},
			}}
		>
			{hasFunctionChildren ? (
				<>
					{(children as (ref: Ref<HTMLDivElement>) => ReactNode)(
						rootElementRef
					)}
				</>
			) : (
				<>{children}</>
			)}
		</ThemeContext.Provider>
	);
};
