import type { ChangeEvent, MouseEvent, ReactNode, RefObject } from "react";

import type { Props as InputProps } from "@shared/components/Input";
import { Input } from "@shared/components/Input";
import { Stack } from "@shared/components/Stack";
import { Text } from "@shared/components/Text";
import type { Props as TextAreaProps } from "@shared/components/TextArea";
import { TextArea } from "@shared/components/TextArea";
import { useTheme } from "@shared/hooks/useTheme";
import { dateToDateString } from "@shared/utilities/dateToDateString";
import { dateToTimeString } from "@shared/utilities/dateToTimeString";

import type { Address } from "../Input/Input";
import styles from "./InputGroup.module.css";
import { LabelWrapper } from "./LabelWrapper";

type SharedProps<P> = Omit<P, "value" | "onInput" | "onChange">;

export interface RadioProps extends SharedProps<InputProps> {
	type: "radio";
	value?: string;
	options: { label: string; value: string }[];
	validate?: (input: HTMLInputElement) => void;
	onInput?: (value: string, e: ChangeEvent<HTMLInputElement>) => void;
	name: string;
}

export interface CheckboxProps extends SharedProps<InputProps> {
	type: "checkbox";
	value: string[];
	options: {
		label: string;
		value: string;
		clearAll?: boolean;
		selectAll?: boolean;
	}[];
	validate?: (input: HTMLInputElement) => void;
	onInput?: (value: string[], e: ChangeEvent<HTMLInputElement>) => void;
	name: string;
}

export interface RangeProps extends SharedProps<InputProps> {
	type: "range";
	value: number;
	min: number;
	max: number;
	validate?: (input: HTMLInputElement) => void;
	onInput?: (value: number, e: ChangeEvent<HTMLInputElement>) => void;
}

export interface DateProps extends SharedProps<InputProps> {
	type: "date" | "time" | "datetime";
	value: Date | undefined;
	validate?: (input: HTMLInputElement) => void;
	onInput?: (value: Date | null, e: ChangeEvent<HTMLInputElement>) => void;
}

export interface TextAreaTypeProps extends SharedProps<TextAreaProps> {
	type: "textarea";
	value: string;
	validate?: (input: HTMLTextAreaElement) => void;
	onInput?: (value: string, e: ChangeEvent<HTMLTextAreaElement>) => void;
}

export interface NumberProps extends SharedProps<InputProps> {
	type: "number";
	value: number | undefined;
	placeholder?: string;
	validate?: (input: HTMLInputElement) => void;
	onInput?: (value: number, e: ChangeEvent<HTMLInputElement>) => void;
}

export interface TextProps extends SharedProps<InputProps> {
	type: "text" | "password" | "tel" | "email";
	value?: string;
	placeholder?: string;
	className?: string;
	validate?: (input: HTMLInputElement) => void;
	onInput?: (value: string, e: ChangeEvent<HTMLInputElement>) => void;
	mask?: string;
	onPreprocessValue?: (value: string) => string;
}

export interface AddressProps extends SharedProps<InputProps> {
	type: "address";
	value?: string | null;
	placeholder?: string;
	validate?: (input: HTMLInputElement) => void;
	onInput?: (value: Address, e: ChangeEvent<HTMLInputElement>) => void;
}

export type Props = (
	| RadioProps
	| CheckboxProps
	| RangeProps
	| DateProps
	| TextProps
	| NumberProps
	| TextAreaTypeProps
	| AddressProps
) & {
	title?: ReactNode;
	refInput?: RefObject<HTMLInputElement>;
	className?: string;
};

export const InputGroup = ({ title, refInput, ...props }: Props) => {
	const { spacing } = useTheme();
	const inputGroupBody = (() => {
		switch (props.type) {
			case "checkbox": {
				const { options, required, ...rest } = props;
				const isRequired = required && props.value.length === 0;
				return options.map((option) => (
					<Input
						key={option.value}
						ref={refInput}
						{...rest}
						label={option.label}
						renderInputWrapper={LabelWrapper}
						checked={props.value.includes(option.value)}
						onInput={(event: ChangeEvent<HTMLInputElement>) => {
							const input = event.target as HTMLInputElement;
							props.validate?.(input);

							const nextValue = (() => {
								const wasChecked = props.value.includes(option.value);

								// Special option type to clear all (other) options.
								if (option.clearAll) {
									if (!wasChecked) {
										return [option.value];
									}

									return [];
								}

								// Special option type to select all options.
								if (option.selectAll) {
									if (!wasChecked) {
										return options.map((o) => o.value);
									}

									return [];
								}

								// The rest of this logic concerns normal options.
								// Which also sometimes have to work around selectAll/clearAll.
								const clearAllOption = props.options.find(
									({ clearAll }) => clearAll
								);
								const hasClearAllChecked =
									clearAllOption && props.value.includes(clearAllOption.value);
								const selectAllOption = props.options.find(
									({ selectAll }) => selectAll
								);
								const hasSelectAllChecked =
									selectAllOption &&
									props.value.includes(selectAllOption.value);

								if (wasChecked) {
									return props.value
										.filter((v) => v !== option.value)
										.filter(
											(v) => !hasSelectAllChecked || v !== selectAllOption.value
										);
								}

								return hasClearAllChecked
									? [option.value]
									: [...props.value, option.value];
							})();
							props.onInput?.(
								nextValue,
								event as unknown as ChangeEvent<HTMLInputElement>
							);
						}}
						required={isRequired}
					/>
				));
			}
			case "radio": {
				const { options, value, onInput, validate, onClick, ...rest } = props;
				return options.map((option) => (
					<Input
						key={option.value}
						ref={refInput}
						{...rest}
						className={styles.reset}
						label={option.label}
						renderInputWrapper={LabelWrapper}
						checked={value === option.value}
						onClick={(event: MouseEvent<HTMLInputElement>) => {
							onClick?.(event);

							// If already selected, the onChange handler
							// won't fire by default.
							// So we add this in for better ergonomics.
							if (value === option.value) {
								onInput?.(
									option.value,
									event as unknown as ChangeEvent<HTMLInputElement>
								);
							}
						}}
						onInput={(event: ChangeEvent<HTMLInputElement>) => {
							const input = event.target as HTMLInputElement;
							validate?.(input);

							onInput?.(
								option.value,
								event as unknown as ChangeEvent<HTMLInputElement>
							);
						}}
					/>
				));
			}
			case "textarea": {
				const { onInput, validate, ...etc } = props;
				return (
					<TextArea
						{...etc}
						onInput={(event: ChangeEvent<HTMLTextAreaElement>) => {
							const input = event.target as HTMLTextAreaElement;
							validate?.(input);

							const nextValue = input.value;
							onInput?.(
								nextValue,
								event as unknown as ChangeEvent<HTMLTextAreaElement>
							);
						}}
					/>
				);
			}
			default: {
				const { type, value, validate, ...etc } = props;
				return (
					<Input
						ref={refInput}
						{...etc}
						type={type}
						value={(() => {
							if (type === "date" && value instanceof Date) {
								return dateToDateString(value);
							} else if (type === "time" && value instanceof Date) {
								return dateToTimeString(value);
							} else if (
								typeof value === "string" ||
								typeof value === "number"
							) {
								return value;
							}

							return "";
						})()}
						onInput={(event: ChangeEvent<HTMLInputElement>) => {
							const input = event.target as HTMLInputElement;
							validate?.(input);

							if (type === "date" || type === "time" || type === "datetime") {
								const nextValue = input.valueAsDate;
								props.onInput?.(
									nextValue,
									event as unknown as ChangeEvent<HTMLInputElement>
								);
							} else if (type === "number" || type === "range") {
								const nextValue = input.valueAsNumber;
								props.onInput?.(
									nextValue,
									event as unknown as ChangeEvent<HTMLInputElement>
								);
							} else {
								const nextValue = input.value;
								(
									props.onInput as (
										value: string,
										e: ChangeEvent<HTMLInputElement>
									) => void
								)?.(nextValue, event as ChangeEvent<HTMLInputElement>);
							}
						}}
					/>
				);
			}
		}
	})();

	return (
		<Stack
			direction="vertical"
			align="stretch"
			justify="end"
			gap={spacing(0.5)}
		>
			{!!title && <Text variant="caption">{title}</Text>}
			<Stack direction="vertical" align="stretch" gap={spacing(1.5)}>
				{inputGroupBody}
			</Stack>
		</Stack>
	);
};
