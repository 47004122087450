import { useMemo } from "react";

import { useTheme } from "@shared/hooks/useTheme";

export function useSelectTheme() {
	const {
		primaryForeground,
		primaryBackground,
		primaryAccent600,
		dangerBackground,
		borderRadius,
	} = useTheme();
	const theme = useMemo(
		() => ({
			theme: {
				borderRadius: borderRadius ?? 0,
				colors: {
					primary: primaryAccent600?.withAlpha(1).color || "",
					primary75: primaryAccent600?.withAlpha(0.75).color || "",
					primary50: primaryAccent600?.withAlpha(0.5).color || "",
					primary25: primaryAccent600?.withAlpha(0.25).color || "",
					danger: dangerBackground.color,
					dangerLight: dangerBackground.color,
					neutral0: primaryBackground.withAlpha(1).color,
					neutral5: primaryForeground.withAlpha(0.05).color,
					neutral10: primaryForeground.withAlpha(0.1).color,
					neutral20: primaryForeground.withAlpha(1).color,
					neutral30: primaryForeground.withAlpha(1).color,
					neutral40: primaryForeground.withAlpha(0.4).color,
					neutral50: primaryForeground.withAlpha(0.5).color,
					neutral60: primaryForeground.withAlpha(0.6).color,
					neutral70: primaryForeground.withAlpha(0.7).color,
					neutral80: primaryForeground.withAlpha(1).color,
					neutral90: primaryForeground.withAlpha(0.9).color,
				},
				spacing: {
					baseUnit: 8,
					controlHeight: 36,
					menuGutter: 4,
				},
			},
			styles: {
				control: (baseStyles: $FixMe) => ({
					...baseStyles,
					borderWidth: 2,
					backgroundColor: "transparent",
				}),
			},
		}),
		[
			primaryForeground,
			primaryBackground,
			primaryAccent600,
			dangerBackground,
			borderRadius,
		]
	);

	return theme;
}
