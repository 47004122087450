import classNames from "classnames";
import { useQuery } from "urql";

import {
	useOrganizationContext,
	usePatientContext,
} from "@app/components/Contexts";
import { Header } from "@app/components/Header";
import { Main } from "@app/components/Main";
import { Badge } from "@shared/components/Badge";
import { Button } from "@shared/components/Button";
import { Stack } from "@shared/components/Stack";
import { useAuthRequired } from "@shared/hooks/useAuthRequired";
import { useTheme } from "@shared/hooks/useTheme";
import type { IntakeStageProps } from "@shared/types/IntakeStages";
import { IntakeStage } from "@shared/types/IntakeStages";
import { WidgetLoadServiceDetailsDocument } from "@shared/types/graphql";
import { trpc } from "@shared/utilities/trpc/trpc";

import styles from "./Treatment.module.css";

// Duplicated from service_intent.service.ts
export enum ServiceType {
	VideoOnDemand = "video_ondemand",
	VideoScheduled = "video_scheduled",
	AsyncOnDemand = "async_ondemand",
}
export function Treatment({
	props: { serviceIntentId },
	onNextStage,
}: IntakeStageProps<IntakeStage.Treatment>) {
	const { organizationName } = useOrganizationContext();
	const { patientId } = usePatientContext();
	const { spacing } = useTheme();
	// Get what type of service is this?
	const { data: serviceTypeData, isLoading } =
		trpc.getServiceTypesForPatient.useQuery({
			serviceIntentId,
		});
	const [{ data, fetching, error }] = useQuery({
		query: WidgetLoadServiceDetailsDocument,
		variables: {
			serviceIntentId,
		},
		pause: !patientId,
	});

	const service = data?.service_intent_by_pk?.service;

	const isScheduled = serviceTypeData?.includes(ServiceType.VideoScheduled);
	const needsAuth = useAuthRequired(true);
	const serviceName = service?.name ?? "healthcare";
	const imageUrl = service?.image_url ?? "";
	const STEPS = isScheduled
		? [
				{
					badge: "Book a Time",
					title: "Book your video call appointment",
					description:
						"Choose a time to book an video call appointment with a clinician.",
				},
				{
					badge: "Health Survey",
					title: "Tell us what's going on",
					description:
						"Answer our quick and personalized questions to help our clinicians perform an intial assessment.",
				},
				{
					badge: "Video Call Consult",
					title: "Video Call with a clinician",
					description:
						"Talk with a licensed medical professional over video call.",
				},
				{
					badge: "Treatment Plan",
					title: "Receive care",
					description:
						"Pick up a same-day prescription from a nearby pharmacy on your time.",
				},
		  ]
		: [
				{
					badge: "Health Survey",
					title: "Tell us what's going on",
					description:
						"Answer our quick and personalized questions to help our clinicians perform an assessment.",
				},
				{
					badge: "Consult",
					title: "Chat with a clinician",
					description:
						"Get linked up with one of our licensed medical professionals for an assessment, and answer additional questions about your health history.",
				},
				{
					badge: "Treatment Plan",
					title: "Receive care",
					description:
						"Pick up a same-day prescription from a nearby pharmacy on your time.",
				},
		  ];
	return (
		<>
			<Header />
			<Main loading={needsAuth || fetching || isLoading} error={error?.message}>
				<Stack direction="vertical" gap={spacing(6)} className={styles.root}>
					<div className="relative w-full overflow-y-auto flex flex-col px-0 pb-0 box-border items-center justify-start gap-[10px]">
						<div className="self-stretch flex flex-col items-center justify-start">
							<div className="self-stretch relative">
								<p
									className={classNames(
										styles.textPretty,
										"m-0 text-center leading-[32px] font-extrabold"
									)}
								>{`Welcome to ${organizationName}. `}</p>
								<p
									className={classNames(
										styles.textPretty,
										"m-0 text-center text-base leading-[24px]"
									)}
								>
									Let&apos;s get you started.
								</p>
							</div>
						</div>
						<div className="self-stretch flex flex-col items-center justify-start text-xl">
							<div className="self-stretch flex flex-col pt-5 px-4 pb-2.5 items-center justify-start">
								<div
									className={classNames(
										styles.textPretty,
										"self-stretch text-center flex flex-col items-start justify-start gap-[20px]"
									)}
								>
									<b className="self-stretch relative leading-[28px]">
										{`Your ${serviceName} health survey`}
									</b>
								</div>
							</div>
						</div>
						<div className="self-stretch flex flex-row py-0 pr-0 pl-[3px] items-start justify-start gap-[10px] text-left text-base text-grayscale-900">
							<div
								className={classNames(
									styles.textPretty,
									"flex-1 relative leading-[20px]"
								)}
							>
								Our team of licensed clinicians will use your responses from the
								health survey to navigate you to a treatment plan.
							</div>
							<div className="flex flex-row py-0 px-0.5 items-start justify-start">
								<div className="relative w-[51px] h-[68px] object-contain fill-primary-accent-400 text-primary-accent-400">
									{imageUrl ? (
										<img
											alt="Avatars of clinicians who designed your treatment"
											src="/assets/service_preview_image.svg"
										/>
									) : null}
								</div>
							</div>
						</div>
					</div>
					<div className="w-full self-stretch flex flex-col py-0 px-4 items-center justify-center">
						<div className="self-stretch relative bg-primary-accent-100 h-0.5" />
					</div>
					<div className="self-stretch rounded-lg flex flex-col py-0 px-6 md:px-10 items-center justify-start">
						<b className="self-stretch text-center relative">How it works</b>
						<div className="self-stretch flex flex-col py-4 px-0 items-start justify-start gap-[20px] text-sm text-primary-accent-800">
							{STEPS.map((step, index) => (
								<div
									key={index}
									className="self-stretch flex flex-col items-start justify-start gap-[8px]"
								>
									<div className={styles.badgeWrapper}>
										<Badge shimmer variant="accent">
											{step.badge}
										</Badge>
									</div>
									<div className="self-stretch flex flex-col items-start justify-start text-left text-grayscale-900">
										{step.title && (
											<div
												className={classNames(
													styles.textPretty,
													"self-stretch relative text-lg font-medium"
												)}
											>
												{step.title}
											</div>
										)}
										<div
											className={classNames(
												styles.textPretty,
												"self-stretch relative text-sm"
											)}
										>
											{step.description}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>

					<div className={styles.continueButtonWrapper}>
						<Button
							variant="primary"
							text="Continue"
							className={styles.continueButton}
							onClick={() => {
								if (isScheduled) {
									onNextStage(IntakeStage.Scheduling, {
										serviceId: service?.id as string,
										serviceIntentId,
									});
								} else {
									onNextStage(IntakeStage.Questions, {
										serviceId: service?.id as string,
										serviceIntentId,
										isSecondaryQuestionStage: true,
									});
								}
							}}
						/>
					</div>
				</Stack>
			</Main>
		</>
	);
}
