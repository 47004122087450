type SupportedFormat =
	| "MM/DD/YYYY"
	| "MM-DD-YYYY"
	| "MM/DD/YY"
	| "MM-DD-YY"
	| "YYYY-MM-DD"
	| "YYYY/MM/DD"
	| "MM-DD-YY HH:MM"
	| "MM/DD/YY HH:MM"
	| "MM-DD-YYYY HH:MM"
	| "MM/DD/YYYY HH:MM";

// Format date as MM/DD/YYYY
export const formatDate = (
	rawDate: Date | number,
	format: SupportedFormat = "MM/DD/YYYY"
) => {
	const date = !(rawDate instanceof Date) ? new Date(rawDate) : rawDate;

	switch (format) {
		case "MM-DD-YYYY":
		case "MM/DD/YYYY": {
			const separator = format === "MM-DD-YYYY" ? "-" : "/";
			const month = `${date.getMonth() + 1}`.padStart(2, "0");
			const day = `${date.getDate()}`.padStart(2, "0");
			const year = date.getFullYear();
			return `${month}${separator}${day}${separator}${year}`;
		}
		case "MM-DD-YY":
		case "MM/DD/YY": {
			const separator = format === "MM-DD-YY" ? "-" : "/";
			const month = `${date.getMonth() + 1}`.padStart(2, "0");
			const day = `${date.getDate()}`.padStart(2, "0");
			const year = `${date.getFullYear()}`.slice(2);
			return `${month}${separator}${day}${separator}${year}`;
		}
		case "YYYY-MM-DD":
		case "YYYY/MM/DD": {
			const separator = format === "YYYY-MM-DD" ? "-" : "/";
			const month = `${date.getMonth() + 1}`.padStart(2, "0");
			const day = `${date.getDate()}`.padStart(2, "0");
			const year = date.getFullYear();
			return `${year}${separator}${month}${separator}${day}`;
		}
		case "MM-DD-YY HH:MM":
		case "MM/DD/YY HH:MM": {
			const separator = format === "MM-DD-YY HH:MM" ? "-" : "/";
			const month = `${date.getMonth() + 1}`;
			const day = `${date.getDate()}`;
			const year = `${date.getFullYear()}`.slice(2);
			const hours = (() => {
				// Format hour as non-military time
				const hour = date.getHours();
				return hour > 12 ? hour - 12 : hour;
			})();
			const minutes = `${date.getMinutes()}`.padStart(2, "0");
			const ampm = date.getHours() >= 12 ? "pm" : "am";
			return `${month}${separator}${day}${separator}${year} ${hours}:${minutes}${ampm}`;
		}
		case "MM-DD-YYYY HH:MM":
		case "MM/DD/YYYY HH:MM": {
			const separator = format === "MM-DD-YYYY HH:MM" ? "-" : "/";
			const month = `${date.getMonth() + 1}`.padStart(2, "0");
			const day = `${date.getDate()}`.padStart(2, "0");
			const year = date.getFullYear();
			const hours = `${(() => {
				// Format hour as non-military time
				const hour = date.getHours();
				return hour > 12 ? hour - 12 : hour;
			})()}`.padStart(2, "0");
			const minutes = `${date.getMinutes()}`.padStart(2, "0");
			const ampm = date.getHours() >= 12 ? "PM" : "AM";
			return `${month}${separator}${day}${separator}${year} ${hours}:${minutes} ${ampm}`;
		}
		default: {
			return date.toLocaleDateString();
		}
	}
};
