import { createContext } from "preact";

import { useContext } from "preact/hooks";

import type {
	IntakeStage,
	IntakeStageTransitionProps,
} from "@shared/types/IntakeStages";
import { noop } from "@shared/utilities/noop";

interface IntakeStageContextValue<CurrentStage extends IntakeStage> {
	currentStage: CurrentStage | undefined;
	currentStageProps: IntakeStageTransitionProps[CurrentStage];
	onPreviousStage: () => void;
	onNextStage: <NextStage extends IntakeStage>(
		stage: NextStage,
		props: IntakeStageTransitionProps[NextStage]
	) => void;
	goToStage: <NextStage extends IntakeStage>(
		stage: NextStage,
		props: IntakeStageTransitionProps[NextStage]
	) => void;
	stages: IntakeStage[];
}

export const IntakeStageContext = createContext<
	IntakeStageContextValue<IntakeStage>
>({
	currentStage: undefined,
	currentStageProps: {},
	onPreviousStage: noop,
	onNextStage: noop,
	goToStage: noop,
	stages: [],
});

export function useIntakeStages() {
	return useContext(IntakeStageContext);
}
