import type { Props as IconProps } from "@shared/components/Icon";
import { Icon } from "@shared/components/Icon";
import { Stack } from "@shared/components/Stack";
import { Text } from "@shared/components/Text";
import { useTheme } from "@shared/hooks/useTheme";

interface Props {
	title?: string;
	subtitle?: JSX.Element | string;
	iconProps?: IconProps;
}

export function Hero({ title, subtitle, iconProps }: Props) {
	const { spacing } = useTheme();
	return (
		<Stack
			direction="vertical"
			align="center"
			gap={spacing(5)}
			padding={{ vertical: spacing(3), horizontal: 0 }}
		>
			{iconProps && <Icon {...iconProps} />}
			<Stack direction="vertical" align="stretch" gap={spacing(1)}>
				{title && (
					<Text variant="h2" align="center">
						{title}
					</Text>
				)}
				{subtitle && (
					<Text variant="heroSubtitle" align="center">
						{subtitle}
					</Text>
				)}
			</Stack>
		</Stack>
	);
}
