import { useQuery } from "urql";

import { useAuthContext, usePatientContext } from "@app/components/Contexts";
import { WidgetGetServiceIntentDocument } from "@shared/types/graphql";

export function useServiceIntent({
	serviceIntentId,
	pauseExecution,
}: {
	serviceIntentId?: string;
	pauseExecution?: boolean;
}) {
	const { patientId } = usePatientContext();
	const { temporaryPatientId } = useAuthContext();
	const pause =
		!serviceIntentId || !(patientId || temporaryPatientId) || pauseExecution;
	const [{ data, fetching: fetchingServiceIntent, error: serviceIntentError }] =
		useQuery({
			query: WidgetGetServiceIntentDocument,
			variables: {
				serviceIntentId: serviceIntentId as string,
				isAuthenticated: !!patientId,
			},
			pause,
			// We need to fetch the service intent every time, because it may have changed
			requestPolicy: "network-only",
		});

	return {
		done: pause || !!data,
		serviceIntent: data?.service_intent_by_pk,
		fetching: fetchingServiceIntent,
		error: serviceIntentError,
	};
}
