import { useState, useEffect } from "react";

export function useMouseInteraction(): boolean {
	const [isMouseInteraction, setIsMouseInteraction] = useState(true);

	useEffect(() => {
		const mouseDownHandler = () => {
			setIsMouseInteraction(true);
		};

		const keyDownHandler = () => {
			setIsMouseInteraction(false);
		};

		document.body.addEventListener("mousedown", mouseDownHandler);
		document.body.addEventListener("keydown", keyDownHandler);

		return () => {
			document.body.removeEventListener("mousedown", mouseDownHandler);
			document.body.removeEventListener("keydown", keyDownHandler);
		};
	}, []);

	return isMouseInteraction;
}
