import { matchPath } from "react-router-dom";

import { RouteMap } from "@app/constants/RouteMap";
import { IntakeStage } from "@shared/types/IntakeStages";

import type { ButtonLayout, RouteMapKeys } from "./Header.types";
import { UserState } from "./Header.types";
import { BackButton } from "./components/BackButton";
import { ChatButton } from "./components/ChatButton";
import { ExitButton } from "./components/ExitButton";
import { HomeButton } from "./components/HomeButton";
import { LogoImage } from "./components/LogoImage";
import { RefreshButton } from "./components/RefreshButton";

export function getButtonConfiguration(
	userState: UserState,
	route: RouteMapKeys,
	intakeStage?: IntakeStage
): ButtonLayout {
	if (userState === UserState.Unauthenticated) {
		return getUnauthConfig(route, intakeStage);
	}

	if (userState === UserState.Authenticated) {
		return getAuthConfig(route, intakeStage);
	}

	return getDefaultConfig();
}

function getUnauthConfig(
	route: RouteMapKeys,
	intakeStage?: IntakeStage
): ButtonLayout {
	if (
		matchPath(RouteMap.Default, route) ||
		(matchPath(RouteMap.IntakeStages, route) &&
			intakeStage === IntakeStage.Services)
	) {
		return {
			left: [],
			middle: [LogoImage],
			right: [ExitButton],
		};
	}

	if (
		matchPath(RouteMap.IntakeStages, route) &&
		intakeStage === IntakeStage.Menu
	) {
		return {
			left: [BackButton],
			middle: [LogoImage],
			right: [ExitButton],
		};
	}

	if (
		matchPath(RouteMap.IntakeStages, route) ||
		matchPath(RouteMap.AuthStages, route)
	) {
		return {
			left: [],
			middle: [LogoImage],
			right: [RefreshButton, ExitButton],
		};
	}

	return getDefaultConfig();
}

function getAuthConfig(
	route: RouteMapKeys,
	intakeStage?: IntakeStage
): ButtonLayout {
	if (matchPath(RouteMap.Visits, route)) {
		return {
			left: [],
			middle: [LogoImage],
			right: [ExitButton],
		};
	}

	if (matchPath(RouteMap.VisitChannel, route)) {
		return {
			left: [BackButton],
			middle: [LogoImage],
			right: [ExitButton],
		};
	}

	if (matchPath(RouteMap.VisitChannelDetails, route)) {
		return {
			left: [BackButton],
			middle: [LogoImage],
			right: [ExitButton],
		};
	}

	if (matchPath(RouteMap.AuthStages, route)) {
		return {
			left: [],
			middle: [LogoImage],
			right: [ExitButton],
		};
	}

	if (
		matchPath(RouteMap.IntakeStages, route) &&
		(intakeStage === IntakeStage.Treatment ||
			intakeStage === IntakeStage.Questions ||
			intakeStage === IntakeStage.Questionnaire ||
			intakeStage === IntakeStage.Checkout)
	) {
		return {
			left: [],
			middle: [LogoImage],
			right: [ChatButton, ExitButton],
		};
	}

	if (
		matchPath(RouteMap.IntakeStages, route) &&
		intakeStage === IntakeStage.Disqualified
	) {
		return {
			left: [],
			middle: [LogoImage],
			right: [ChatButton, HomeButton, ExitButton],
		};
	}

	return {
		left: [BackButton],
		middle: [LogoImage],
		right: [HomeButton, ExitButton],
	};
}

function getDefaultConfig(): ButtonLayout {
	return {
		left: [],
		middle: [LogoImage],
		right: [RefreshButton, ExitButton],
	};
}
