import { useEffect, useState } from "react";

import type { SpringValue } from "react-spring";
import { useSpring } from "react-spring";

interface Props {
	children: (value: SpringValue) => JSX.Element;
	onRest?: () => void;
	preserveState?: boolean;
	show: boolean;
}

export function AnimationToggle({
	children,
	onRest,
	show,
	preserveState = false,
}: Props) {
	const [shouldRender, setShouldRender] = useState(show);

	const animationProps = useSpring({
		from: { value: show ? 1 : 0 },
		to: { value: show ? 0 : 1 },
		onRest: () => {
			if (!show && !preserveState) setShouldRender(false);
			onRest?.();
		},
	});

	useEffect(() => {
		if (show) setShouldRender(true);
	}, [show]);

	if (!shouldRender) return null;

	return children(animationProps.value);
}
