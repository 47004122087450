import { useMemo, useRef, useState } from "preact/hooks";

import { useAuthContext } from "@app/components/Contexts";
import { Alert } from "@shared/components/Alert";
import { Button } from "@shared/components/Button";
import { CodeInput } from "@shared/components/CodeInput";
import { Form } from "@shared/components/Form";
import { Loader } from "@shared/components/Loader";
import { Stack } from "@shared/components/Stack";
import { Text } from "@shared/components/Text";
import { useIntakeNavigation } from "@shared/hooks/useIntakeNavigation";
import { useInterval } from "@shared/hooks/useInterval";
import { useTheme } from "@shared/hooks/useTheme";
import { formatPhoneNumber } from "@shared/utilities/formatPhoneNumber";

const RETRY_DELAY = 30;

interface Props {
	birthDate: string;
	phoneNumber?: string;
	code?: string;
	serviceId?: string;
	externalId?: string;
	onBack: () => void;
	onSuccess?: () => void;
}

export function ConfirmLogin({
	phoneNumber,
	code: autoLoginCode,
	birthDate,
	serviceId,
	externalId,
	onBack,
	onSuccess,
}: Props) {
	const { spacing } = useTheme();
	const { navigateToIntakeStart } = useIntakeNavigation();
	const { verifyLoginCode, startPatientLogin, loginWithCode } =
		useAuthContext();
	const [isRetrying, setIsRetrying] = useState(false);
	const [successText, setSuccessText] = useState<string | null>(null);
	const [errorText, setErrorText] = useState<string | null>(null);
	const codeRef = useRef("");
	const resendLoginCode = async () => {
		try {
			setIsRetrying(true);

			if (!externalId && !phoneNumber) {
				throw new Error("Missing phone number or external id.");
			}

			if (phoneNumber) {
				const formattedPhoneNumber = formatPhoneNumber(
					phoneNumber,
					"numeric-no-country-code"
				);
				const response = await startPatientLogin(
					formattedPhoneNumber,
					birthDate,
					serviceId
				);
				if (!response?.data?.startPatientLogin.success) {
					throw new Error(
						response?.error?.message ?? "Failed to send login code."
					);
				}
			}

			setIsRetrying(false);
			setSecondsUntilRetryAvailable(RETRY_DELAY);
		} catch (error) {
			setIsRetrying(false);
			setSecondsUntilRetryAvailable(RETRY_DELAY);
			throw new Error(
				(error as Error).message ??
					"An unknown error occurred. Please try again."
			);
		}
	};

	const handleResendCode = async () => {
		setErrorText(null);
		setSuccessText(null);
		try {
			await resendLoginCode();
			setSuccessText("Please check your phone for a new login code.");
		} catch (error) {
			setErrorText((error as Error).message);
		}
	};

	const onSubmit = async () => {
		const code = codeRef.current;
		if (!externalId && (!phoneNumber || !birthDate || !code)) {
			setErrorText("Missing phone number, login code, or date of birth.");
			return;
		}

		// Clear errors & start the loading state.
		setSuccessText("");
		try {
			const verifyLoginResponse = await verifyLoginCode(
				code,
				phoneNumber,
				externalId
			);
			if (!verifyLoginResponse?.data?.verifyLoginCode?.valid) {
				// Reset the auto login code if it's invalid.
				const shouldResend = autoLoginCode === code;
				if (shouldResend) {
					await resendLoginCode();
				}

				throw new Error(
					shouldResend
						? "Your login code was invalid. Please check your phone for a new login code."
						: "Invalid code. Please try again or request a new code."
				);
			}

			const loginWithCodeResponse = await loginWithCode(
				code,
				phoneNumber,
				birthDate,
				externalId
			);
			if (!loginWithCodeResponse?.data?.loginWithCode?.jwt) {
				throw new Error(
					loginWithCodeResponse?.error?.message ?? "No JWT returned"
				);
			}

			onSuccess?.();
		} catch (error) {
			setErrorText(
				(error as Error).message ??
					"An unknown error occurred. Please try again."
			);
			if (
				(error as Error).message ===
				"No user found with that phone number and dob"
			) {
				setTimeout(() => {
					navigateToIntakeStart();
				}, 30000);
			}
		}
	};

	const [secondsUntilRetryAvailable, setSecondsUntilRetryAvailable] =
		useState(RETRY_DELAY);
	useInterval(
		() => {
			setSecondsUntilRetryAvailable((oldValue) => Math.max(oldValue - 1, 0));
		},
		{ delay: 1000, skip: !secondsUntilRetryAvailable }
	);

	const errorMessageContent = useMemo(() => {
		if (!errorText) return null;
		if (
			errorText.includes(
				"No user found with that phone number and date of birth"
			)
		) {
			return (
				<span style={{ fontSize: "0.72em" }}>
					We couldn&#39;t find that user in our records. Please{" "}
					<button
						type="button"
						style={{ display: "inline-block", textDecoration: "underline" }}
						onClick={navigateToIntakeStart}
					>
						start a visit
					</button>{" "}
					to sign up.
				</span>
			);
		}

		return errorText;
	}, [errorText, navigateToIntakeStart]);

	return (
		<Form onSubmit={onSubmit}>
			{({ isSubmitting, submitForm }) => {
				// Handle login code being passed in as a prop.
				const needsCodeSubmission =
					autoLoginCode && codeRef.current !== autoLoginCode;
				if (submitForm && !isSubmitting && needsCodeSubmission) {
					codeRef.current = autoLoginCode;
					submitForm();
				}

				return (
					<Stack
						direction="vertical"
						gap={spacing(3)}
						align="center"
						justify="center"
						style={{ minHeight: 100 }}
					>
						{errorText && (
							<Alert
								variant="danger"
								caption="Uh-oh"
								text={errorMessageContent}
							/>
						)}
						{successText && (
							<Alert
								variant="success"
								caption="Login code sent"
								text={successText}
								data-testid="success-code-alert"
							/>
						)}
						{isSubmitting ? (
							<Loader />
						) : (
							<>
								<CodeInput
									length={6}
									onComplete={(code) => {
										codeRef.current = code;
										submitForm?.();
									}}
								/>
								<div className="flex flex-col gap-1 items-center">
									<Button
										type="button"
										variant="plain"
										data-testid="resend-code-button"
										onClick={handleResendCode}
										disabled={
											secondsUntilRetryAvailable > 0 ||
											isRetrying ||
											isSubmitting
										}
									>
										<Text variant="button">
											{(() => {
												if (isRetrying) {
													return "Sending...";
												}

												return secondsUntilRetryAvailable
													? `Resend Code (${secondsUntilRetryAvailable})`
													: "Resend Code";
											})()}
										</Text>
									</Button>
									<Button
										type="button"
										variant="plain"
										className="min-h-fit"
										onClick={onBack}
									>
										<Text
											variant="button"
											className="text-grayscale-700 dark:text-grayscale-600"
										>
											Go Back
										</Text>
									</Button>
								</div>
							</>
						)}
					</Stack>
				);
			}}
		</Form>
	);
}
