import { getAppVersion } from "@app/utilities/getAppVersion";

const AUTHORIZATION_HEADER = "Authorization";
const ROLE_HEADER = "x-hasura-role";
enum Role {
	PATIENT = "patient",
	ANONYMOUS = "anonymous",
}

const headers: Record<string, string> = {
	"content-type": "application/json",
	"x-hasura-app-version": getAppVersion(),
	[ROLE_HEADER]: "anonymous",
};

export function getHeaders() {
	return headers;
}

export function setHeader(name: string, value: string) {
	headers[name] = value;
}

export function clearHeader(name: string) {
	delete headers[name];
}

export function setAuthorizationHeaders(jwt: string) {
	setHeader(ROLE_HEADER, Role.PATIENT);
	setHeader(AUTHORIZATION_HEADER, `Bearer ${jwt}`);
}

export function clearAuthorizationHeaders() {
	setHeader(ROLE_HEADER, Role.ANONYMOUS);
	clearHeader(AUTHORIZATION_HEADER);
}

export function hasAuthorizationHeader() {
	return headers[AUTHORIZATION_HEADER] !== undefined;
}

export function getAuthorizationHeader() {
	return headers[AUTHORIZATION_HEADER];
}
