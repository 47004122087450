import classNames from "classnames";

import {
	faCircleStop,
	faRecordVinyl,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { ChatFile } from "@shared/components/InputFiles/InputFiles";

import {
	CameraPermissionStatus,
	useVideoRecorder,
} from "./VideoRecorder.hooks";
import { VideoRecorderSpinner } from "./VideoRecorder.loader";

type Props = {
	onChange: (file: ChatFile) => void;
};

export function VideoRecorder({ onChange }: Props) {
	const {
		videoURL,
		videoRef,
		duration,
		countdown,
		hasRecorded,
		isRecording,
		isUploading,
		isCountingDown,
		permissionStatus,
		handleSubmit,
		handleCountdownStart,
		handleRecordingStop,
	} = useVideoRecorder(onChange);

	return (
		<div className="relative">
			{permissionStatus === CameraPermissionStatus.Denied && (
				<div className="absolute w-full h-full bg-gray-600 opacity-80 z-20 flex items-center justify-center">
					<div className="text-white text-center">
						<p>You have denied camera access.</p>
						<p>Please enable the camera to proceed.</p>
					</div>
				</div>
			)}
			{isUploading && <VideoRecorderSpinner />}
			<div
				className={classNames("w-96 h-72", {
					["opacity-30"]: isUploading,
				})}
			>
				<video
					ref={videoRef}
					className="absolute w-fill h-full rounded-lg bg-primary-accent-500"
					autoPlay
				>
					<track kind="captions" />
				</video>
				{videoURL && (
					<video
						src={videoURL}
						className="absolute w-fill h-fill rounded-lg bg-primary-accent-500"
						controls
					>
						<track kind="captions" />
					</video>
				)}
			</div>

			{isRecording ? (
				<span className="absolute top-2 right-2 z-10">{duration}s</span>
			) : isCountingDown ? (
				<span className="absolute top-2 right-2 z-10">{countdown}</span>
			) : (
				<button
					type="button"
					className={classNames("absolute z-10 text-white rounded-full p-2", {
						"bottom-0  left-[50%] translate-x-[-50%]": !hasRecorded,
						"bottom--2 right-0": hasRecorded,
					})}
					onClick={handleCountdownStart}
					title={hasRecorded ? "Record Again" : "Start Recording"}
				>
					{hasRecorded ? (
						<span>Record again</span>
					) : (
						<FontAwesomeIcon
							size="2x"
							icon={faRecordVinyl}
							className="text-red-500 p-2"
						/>
					)}
				</button>
			)}

			{isRecording && (
				<button
					type="button"
					className="absolute bottom-0 left-[50%] translate-x-[-50%] z-10 text-white"
					onClick={handleRecordingStop}
					title="Stop Recording"
				>
					<FontAwesomeIcon
						size="2x"
						icon={faCircleStop}
						className="text-red-500 p-2"
					/>
				</button>
			)}

			{!isRecording && !isCountingDown && videoURL && (
				<button
					type="button"
					className="absolute bottom-[-2] left-0 z-10 text-white rounded-full p-2"
					onClick={handleSubmit}
				>
					Submit
				</button>
			)}
		</div>
	);
}
