import { BrowserStorage } from "@shared/utilities/BrowserStorage";
import { debug } from "@shared/utilities/debug";

const createSafeStorage = (storage: Storage): Storage => {
	return {
		getItem(key: string) {
			try {
				return storage.getItem(key);
			} catch (e) {
				return null;
			}
		},
		setItem(key: string, value: string) {
			try {
				storage.setItem(key, value);
			} catch (error) {
				debug("error", {
					context: "storage",
					message: "Error setting local storage",
					info: { error, key, value },
				});
			}
		},
		removeItem(key: string) {
			try {
				storage.removeItem(key);
			} catch (error) {
				debug("error", {
					context: "storage",
					message: "Error removing from local storage",
					info: { error, key },
				});
			}
		},
		clear() {
			try {
				storage.clear();
			} catch (error) {
				debug("error", {
					context: "storage",
					message: "Error getting clearing local storage",
					info: { error },
				});
			}
		},
		key(index: number) {
			try {
				return storage.key(index);
			} catch (error) {
				debug("error", {
					context: "storage",
					message: "Error getting local storage key",
					info: { error, index },
				});
				return null;
			}
		},
		get length() {
			try {
				return storage.length;
			} catch (error) {
				debug("error", {
					context: "storage",
					message: "Error getting local storage length",
					info: { error },
				});
				return 0;
			}
		},
	};
};

export const safeLocalStorage = createSafeStorage(
	BrowserStorage.localStorage ??
		BrowserStorage.sessionStorage ??
		BrowserStorage.memoryStorage
);

export const safeSessionStorage = createSafeStorage(
	BrowserStorage.sessionStorage ?? BrowserStorage.memoryStorage
);
