import { useEffect } from "preact/hooks";

import { useAuthContext } from "@app/components/Contexts";
import { useCreateServiceIntent } from "@app/hooks/serviceIntent/useCreateServiceIntent";
import { useServiceIntent } from "@app/hooks/serviceIntent/useServiceIntent";
import { useServiceIntentByService } from "@app/hooks/serviceIntent/useServiceIntentByService";
import { useEffectOnce } from "@shared/hooks/useEffectOnce";
import { useNavigation } from "@shared/hooks/useNavigation";
import { debug } from "@shared/utilities/debug";

type Params = {
	patientId: string | null;
	serviceIntentId?: string;
	serviceId: string;
	startOver?: boolean;
};

export function useInferServiceIntent({
	patientId,
	serviceIntentId,
	serviceId,
	startOver,
}: Params) {
	const { replaceQueryParams } = useNavigation();
	const { temporaryPatientId } = useAuthContext();
	const isPatientPresent = !!patientId || !!temporaryPatientId;

	debug("info", {
		context: "useInferServiceIntent",
		message: "id",
		info: { temporaryPatientId },
	});

	const {
		createdServiceIntentId,
		startService,
		fetching: creatingServiceIntent,
		error: createServiceIntentError,
	} = useCreateServiceIntent({
		serviceId,
		patientId: patientId || temporaryPatientId,
		startOver,
	});
	const {
		done: hasRequestedServiceIntent,
		serviceIntent,
		fetching: fetchingServiceIntent,
		error: serviceIntentError,
	} = useServiceIntent({
		serviceIntentId,
	});

	// This is an edge case. We only want to guess the service intent if the user got here manually
	const {
		done: hasRequestedServiceIntentService,
		serviceIntent: serviceIntentFromService,
		fetching: fetchingServiceIntentFromService,
		error,
	} = useServiceIntentByService({
		serviceId,
		skip: !!serviceIntentId || !isPatientPresent || !serviceId || !!startOver,
	});

	useEffect(() => {
		const newServiceIntentId =
			createdServiceIntentId || serviceIntentFromService?.id;

		// If the service intent ID hasn't changed, then don't update the URL
		if (!newServiceIntentId || serviceIntentId === newServiceIntentId) {
			return;
		}

		// Update the URL with the new service intent ID
		replaceQueryParams({
			serviceIntentId: newServiceIntentId,
			startOver: null,
		});
	}, [
		serviceIntentId,
		createdServiceIntentId,
		serviceIntentFromService,
		replaceQueryParams,
	]);

	const isServiceIntentMismatch =
		serviceIntentFromService &&
		serviceIntentId !== serviceIntentFromService?.id;

	const resolvedServiceId = serviceIntent?.service_id
		? serviceIntent.service_id
		: serviceId;

	const fetching =
		fetchingServiceIntent ||
		fetchingServiceIntentFromService ||
		creatingServiceIntent ||
		!hasRequestedServiceIntent ||
		!hasRequestedServiceIntentService ||
		!serviceIntent ||
		!resolvedServiceId ||
		!isPatientPresent;

	const shouldStartService =
		!isPatientPresent ||
		(hasRequestedServiceIntent && (!serviceIntent || startOver));

	useEffectOnce(
		(markDone) => {
			if (
				!startOver &&
				(!hasRequestedServiceIntentService || isServiceIntentMismatch)
			)
				return;
			if (shouldStartService) startService();
			if (!hasRequestedServiceIntent) return;
			markDone();
		},
		[
			isServiceIntentMismatch,
			serviceIntent,
			startService,
			startOver,
			hasRequestedServiceIntent,
			hasRequestedServiceIntentService,
			isPatientPresent,
		]
	);

	return {
		data: {
			resolvedServiceId,
			serviceIntent,
		},
		fetching,
		error: error ?? createServiceIntentError ?? serviceIntentError,
	};
}
