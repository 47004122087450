import { useMemo } from "react";

import { useQuery } from "urql";

import { DARK_THEME, LIGHT_THEME } from "@shared/constants/themes";
import { defaultWidgetSettings } from "@shared/constants/widget";
import type { Color, ThemeColors } from "@shared/hooks/useTheme/types";
import {
	transformToPaletteThemeColor,
	transformToThemeColor,
} from "@shared/hooks/useTheme/utils";
import { GetWidgetConfigDocument } from "@shared/types/graphql";

import { useFirst } from "./useFirst";
import type { Theme } from "./useTheme/Theme";

export type WidgetSettings = {
	lightThemeColors: Record<string, Color>;
	darkThemeColors: Record<string, Color>;
	palette: {
		lightThemeColors: ThemeColors;
		darkThemeColors: ThemeColors;
	};
};

export function useWidgetSettings(execute = true) {
	const [{ data, fetching }] = useQuery({
		query: GetWidgetConfigDocument,
		pause: !execute,
	});
	const widgetConfig = useFirst(data?.widget_config);
	const widgetSettings =
		(widgetConfig?.settings as WidgetSettings | undefined) ||
		defaultWidgetSettings;

	const lightThemeColors = transformToThemeColor(
		widgetSettings?.lightThemeColors
	);
	const darkThemeColors = transformToThemeColor(
		widgetSettings?.darkThemeColors
	);
	const paletteLightThemeColors = transformToPaletteThemeColor(
		widgetSettings?.palette?.lightThemeColors
	);
	const paletteDarkThemeColors = transformToPaletteThemeColor(
		widgetSettings?.palette?.darkThemeColors
	);

	const darkTheme = useMemo(
		() =>
			darkThemeColors || paletteDarkThemeColors
				? {
						...DARK_THEME,
						...(darkThemeColors || {}),
						...(paletteDarkThemeColors || {}),
				  }
				: DARK_THEME,
		[darkThemeColors, paletteDarkThemeColors]
	);
	const lightTheme = useMemo(
		() =>
			lightThemeColors || paletteLightThemeColors
				? {
						...LIGHT_THEME,
						...(lightThemeColors || {}),
						...(paletteLightThemeColors || {}),
				  }
				: LIGHT_THEME,
		[lightThemeColors, paletteLightThemeColors]
	);

	return {
		fetching,
		ctaMessage:
			widgetConfig?.cta_message ?? "Get care from the comfort of your home.",
		darkTheme,
		lightTheme,
	};
}

export function getDefaultThemes(): { lightTheme: Theme; darkTheme: Theme } {
	const lightThemeColors = transformToThemeColor(
		defaultWidgetSettings.lightThemeColors
	);
	const darkThemeColors = transformToThemeColor(
		defaultWidgetSettings.darkThemeColors
	);
	const paletteLightThemeColors = transformToPaletteThemeColor(
		defaultWidgetSettings.palette.lightThemeColors
	);
	const paletteDarkThemeColors = transformToPaletteThemeColor(
		defaultWidgetSettings.palette.darkThemeColors
	);

	const darkTheme = () =>
		darkThemeColors || paletteDarkThemeColors
			? {
					...DARK_THEME,
					...(darkThemeColors || {}),
					...(paletteDarkThemeColors || {}),
			  }
			: DARK_THEME;

	const lightTheme = () =>
		lightThemeColors || paletteLightThemeColors
			? {
					...LIGHT_THEME,
					...(lightThemeColors || {}),
					...(paletteLightThemeColors || {}),
			  }
			: LIGHT_THEME;

	return { lightTheme: lightTheme(), darkTheme: darkTheme() };
}
