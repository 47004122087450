import { useState } from "react";

import { cloudDownload, expand } from "ionicons/icons";

import { Button } from "@shared/components/Button";
import { useTheme } from "@shared/hooks/useTheme";
import type { MessageFragment } from "@shared/types/graphql";

import { FilePreview } from "./FilePreview";
import styles from "./Message.module.css";

interface Props {
	file: NonNullable<MessageFragment["file"]>;
	fileUrl: string | null | undefined;
	fetching: boolean;
	error: Error | null | undefined;
	showImagePreview?(url: string): void;
}

export function Image({
	file,
	fileUrl,
	fetching,
	error,
	showImagePreview,
}: Props) {
	const [isLoaded, setIsLoaded] = useState(false);
	const [isError, setIsError] = useState(false);
	const { primaryBackground } = useTheme();

	return (
		<FilePreview
			file={file}
			isLoaded={!fetching && isLoaded}
			isError={!!error || isError}
		>
			{fileUrl && (
				<>
					<img
						className={styles.image}
						src={fileUrl}
						alt={`Preview of ${file.display_name}`}
						onLoad={() => {
							setIsLoaded(true);
							setIsError(false);
						}}
						onError={() => {
							setIsLoaded(true);
							setIsError(true);
						}}
					/>
					<div className={styles.buttons}>
						<Button
							variant="icon"
							iconProps={{
								icon: cloudDownload,
								size: 20,
								fill: primaryBackground,
							}}
							href={fileUrl}
							download
						/>
						{showImagePreview && (
							<Button
								variant="icon"
								iconProps={{
									icon: expand,
									size: 20,
									fill: primaryBackground,
								}}
								onClick={() => {
									if (!fileUrl) return;
									showImagePreview(fileUrl);
								}}
							/>
						)}
					</div>
				</>
			)}
		</FilePreview>
	);
}
