import type { CheckoutStep } from "@app/components/Intake/Checkout/CheckoutStep";
import type { TransitionDirection } from "@shared/components/AnimationTransition";

export enum IntakeStage {
	Menu = "menu",
	MenuPreview = "menu_preview",
	Services = "services",
	Questions = "questions",
	Treatment = "treatment",
	Scheduling = "scheduling",
	Checkout = "checkout",
	Disqualified = "disqualified",
	Questionnaire = "lead",
}

export type IntakeStageTransitionProps = {
	[IntakeStage.Menu]: {
		menuId: string;
	};
	[IntakeStage.MenuPreview]: {
		rawMenuData: string;
	};
	[IntakeStage.Services]: Record<string, never>;
	[IntakeStage.Questions]: {
		serviceId: string;
		serviceIntentId?: string;
		isSecondaryQuestionStage: boolean;
		startOver?: boolean;
	};
	[IntakeStage.Treatment]: {
		serviceIntentId: string;
	};
	[IntakeStage.Scheduling]: {
		serviceId: string;
		serviceIntentId: string;
	};
	[IntakeStage.Checkout]: {
		serviceIntentId: string;
		step?: CheckoutStep;
	};
	[IntakeStage.Disqualified]: {
		serviceIntentId: string;
	};
	[IntakeStage.Questionnaire]: {
		questionnaireId: string;
	};
};

export interface IntakeStageProps<StageType extends IntakeStage> {
	props: IntakeStageTransitionProps[StageType];
	onPreviousStage: (count?: number) => void;
	onNextStage: <StageType extends IntakeStage>(
		nextStage: StageType,
		nextStageProps: IntakeStageTransitionProps[StageType]
	) => void;
	goToStage: <StageType extends IntakeStage>(
		nextStage: StageType,
		nextStageProps: IntakeStageTransitionProps[StageType]
	) => void;
	direction: TransitionDirection;
}

export const isValidIntakeStage = (stageId: string): stageId is IntakeStage =>
	Object.values(IntakeStage).includes(stageId as IntakeStage);
