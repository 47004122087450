import { useQuery } from "urql";

import { useAuthContext } from "@app/components/Contexts";
import { EditableEncounterStatuses } from "@shared/types/EncounterStatus";
import { WidgetGetServicesDocument } from "@shared/types/graphql";
import type { Service } from "@shared/types/graphql";

export function useServices(serviceIds: string[]) {
	const { patientId } = useAuthContext();

	// Filter out any empty strings to prevent invalid UUID errors
	const filteredServiceIds = serviceIds.filter((id) => id.trim() !== "");

	const [{ data, fetching }] = useQuery({
		query: WidgetGetServicesDocument,
		variables: {
			serviceIds: filteredServiceIds,
			editableStatuses: EditableEncounterStatuses,
			isAuthenticated: !!patientId,
		},
		pause: !filteredServiceIds.length,
	});

	return {
		services: (data?.service as Service[]) || [],
		fetching,
	};
}
