import { useCallback, useEffect, useMemo } from "preact/hooks";

import { useClient } from "@app/components/Contexts/ClientContext";
import { useWidgetContext } from "@app/components/Contexts/WidgetContext";
import { RouteMap } from "@app/constants/RouteMap";
import { StorageKeys } from "@app/types/StorageKeys";
import { configureSentryUser } from "@app/utilities/initSentry";
import {
	postMessageToReactNative,
	isReactNative,
} from "@app/utilities/reactNative";
import { useInterval } from "@shared/hooks/useInterval";
import { useIsMounted } from "@shared/hooks/useIsMounted";
import { useLocation } from "@shared/hooks/useLocation";
import { useNavigation } from "@shared/hooks/useNavigation";
import { PubSubMessage, useSubscription } from "@shared/hooks/usePubSub";
import { useStorage } from "@shared/hooks/useStorage";
import { BrowserStorage } from "@shared/utilities/BrowserStorage";
import { debug } from "@shared/utilities/debug";
import {
	clearAuthorizationHeaders,
	hasAuthorizationHeader,
	getAuthorizationHeader,
	setAuthorizationHeaders,
} from "@shared/utilities/gql/headers";
import { parseJWT } from "@shared/utilities/parseJWT";
import { getQueryString, parse } from "@shared/utilities/queryString";

import { useTokenSync } from "./useTokenSync";

export function useTokenManager() {
	const isMounted = useIsMounted();
	const { url } = useLocation();
	const { resetClient } = useClient();
	const { widgetId } = useWidgetContext();
	const { goTo, navigateTo } = useNavigation();

	const externalId = parse(url)?.externalId as string | undefined;
	const loginToken = parse(url)?.loginToken as string | undefined;

	// Cached information about the user's auth tokens.
	const [cachedJWT, setCachedJWT] = useStorage<string | null>(
		StorageKeys.AuthToken,
		null,
		{
			storage:
				BrowserStorage.localStorage ??
				BrowserStorage.sessionStorage ??
				BrowserStorage.memoryStorage,
		}
	);
	const [hasRunFirst, setHasRunFirst] = useStorage<string | null>(
		StorageKeys.HasRunFirst,
		null,
		{
			storage:
				BrowserStorage.localStorage ??
				BrowserStorage.sessionStorage ??
				BrowserStorage.memoryStorage,
		}
	);
	const handleCachedJWT = useCallback(
		(jwt: string | null) => {
			setCachedJWT(jwt);
			if (isReactNative()) {
				postMessageToReactNative(JSON.stringify({ token: jwt || "" }));
			}
		},
		[setCachedJWT]
	);

	useTokenSync(cachedJWT, handleCachedJWT);

	const logout = useCallback(
		({ redirect = true, query = {} } = {}) => {
			debug("info", {
				context: "useTokenManager",
				message: "Logging out.",
			});
			clearAuthorizationHeaders();
			resetClient();
			configureSentryUser(null);
			handleCachedJWT(null);

			// Broadcast a logout message to other tabs
			const channel = new BroadcastChannel(`${widgetId}-token-sync`);
			channel.postMessage({ type: "logout" });
			channel.close();

			if (!redirect) return;

			setTimeout(() => {
				goTo(
					RouteMap.Default,
					{
						widgetId,
					},
					{
						// Include the serviceId, as a hint for redirecting after login.
						serviceId: parse(window.location.search).serviceId,
						...query,
					},
					true
				);
			}, 0);
		},
		[handleCachedJWT, resetClient, goTo, widgetId]
	);

	useSubscription(PubSubMessage.CleanCache, () => logout());

	// Sync React Native's auth token
	useEffect(() => {
		if (!isReactNative()) return;

		const nativeToken = localStorage.getItem("NATIVE_ACCESS_TOKEN");

		if (!nativeToken) return;

		const parsedToken = parseJWT(nativeToken);
		const expirationDate = parsedToken
			? new Date(parsedToken.exp * 1000)
			: null;
		const expirationMargin = 1000 * 60 * 60; // 1 hour

		// If the token is still valid, use it
		if (
			expirationDate &&
			expirationDate.getTime() > Date.now() + expirationMargin
		) {
			setCachedJWT(nativeToken);
		}
	}, [externalId, loginToken, logout, setCachedJWT]);

	// Clear cache if requested by the url.
	useEffect(() => {
		const cleanCache = parse(url)?.cleanCache as string | undefined;

		if (!cleanCache) return;

		if (hasRunFirst) {
			logout({ redirect: false });
		} else {
			setHasRunFirst("true");
		}

		const redirectUrl = new URLSearchParams(getQueryString(url));

		redirectUrl.delete("cleanCache");

		// Fix location to not include the cleanCache param.
		const pathname = window.location.pathname;
		const newUrl = `${pathname}${
			redirectUrl.toString() ? `?${redirectUrl.toString()}` : ""
		}`;

		navigateTo(newUrl);
	}, [url, navigateTo, logout, hasRunFirst, setHasRunFirst]);

	const { patientId, expirationDate } = useMemo(() => {
		if (!cachedJWT) {
			return {
				patientId: null,
				expirationDate: null,
				fingerprintHash: null,
			};
		}

		const payload = parseJWT(cachedJWT);

		// If it's expired, clear the cache and return null.
		if (!payload || payload.exp * 1000 <= Date.now()) {
			debug("info", {
				context: "AuthContextProvider",
				message: "JWT token expired.",
				info: {
					jwt: cachedJWT,
				},
			});
			logout({ redirect: false });
			return {
				patientId: null,
				expirationDate: null,
				fingerprintHash: null,
			};
		}

		const patientId =
			payload?.["https://hasura.io/jwt/claims"]["x-hasura-user-id"] ?? null;
		const fingerprintHash =
			payload?.["https://hasura.io/jwt/claims"]["X-User-Fingerprint"] ?? null;
		const expirationDate = payload ? new Date(payload.exp * 1000) : null;
		debug("info", {
			context: "AuthContextProvider",
			message: "Parsed new JWT token.",
			info: {
				jwt: cachedJWT,
				patientId,
				expirationDate,
				fingerprintHash,
			},
		});
		return {
			patientId,
			expirationDate,
			fingerprintHash,
		};
	}, [cachedJWT, logout]);

	if (cachedJWT && expirationDate) {
		// Set authorization headers if out of sync.
		if (
			getAuthorizationHeader() !== cachedJWT &&
			expirationDate.getTime() > Date.now()
		) {
			setAuthorizationHeaders(cachedJWT);
		}

		// Logout if out of sync.
		if (hasAuthorizationHeader() && expirationDate.getTime() <= Date.now()) {
			logout();
		}
	}

	useInterval(
		useCallback(async () => {
			if (!expirationDate) return;
			if (!(expirationDate instanceof Date)) {
				debug("error", {
					context: "AuthContextProvider",
					message: "Invalid auth token expiration date.",
					info: {
						expirationDate,
					},
				});
				return;
			}

			const isExpired = expirationDate.getTime() - Date.now() <= 0;

			// Check if auth token is expired.
			if (isExpired && isMounted.current) {
				debug("info", {
					context: "AuthContextProvider",
					message: "Auth token expired.",
				});
				logout();
			} else if (isExpired && !isMounted.current) {
				debug("info", {
					context: "AuthContextProvider",
					message: "Auth token expired, but provider is unmounted.",
					info: {
						isExpired,
					},
				});
			}
		}, [expirationDate, isMounted, logout]),
		{ delay: 1000 * 60, skip: !expirationDate }
	);

	useEffect(() => {
		if (!patientId) return;

		configureSentryUser({ id: patientId });
	}, [patientId]);

	return {
		patientId,
		onLogin: useCallback(
			(authToken: string, refreshToken?: string) => {
				debug("info", {
					context: "AuthContextProvider",
					message: "Logged in.",
					info: {
						refreshToken,
						authToken,
					},
				});

				handleCachedJWT(authToken);
			},
			[handleCachedJWT]
		),
		onLogout: logout,
	};
}
