import type { ComponentChildren } from "preact";

import styles from "./ContractLink.module.css";

type Props = {
	children: ComponentChildren;
	link?: Maybe<string>;
};

export const ContractLink = ({ children, link }: Props) => {
	if (!link) return <>{children}</>;

	return (
		<a
			href={link}
			target="_blank"
			rel="noopener noreferrer"
			className={styles.contractLink}
		>
			{children}
		</a>
	);
};
