import { useState } from "preact/hooks";

import { useOrganizationContext } from "@app/components/Contexts";
import { Button } from "@shared/components/Button";
import { Highlight } from "@shared/components/Highlight";
import { HorizontalLine } from "@shared/components/HorizontalLine";
import { InputGroup } from "@shared/components/InputGroup";
import { Stack } from "@shared/components/Stack";
import { Text } from "@shared/components/Text";
import { useTheme } from "@shared/hooks/useTheme";

import { LineItem } from "./LineItem";

export type LineItems = LineItem[];

interface Props {
	formatter: Intl.NumberFormat;
	items: LineItems;
	appliedDiscountCode: string | null;
	discountAmount: number;
	onApplyDiscount: (discountCode: string) => void;
}

export function LineItems({
	formatter,
	items,
	appliedDiscountCode,
	discountAmount,
	onApplyDiscount,
}: Props) {
	const { spacing } = useTheme();
	const { bypassPrepayment } = useOrganizationContext();
	const [discountCode, setDiscountCode] = useState("");
	const subtotalPrice = items.reduce((acc, curr) => acc + (curr.price ?? 0), 0);
	const formattedSubtotalPrice = formatter.format(subtotalPrice);
	const formattedDiscountAmount = formatter.format(discountAmount);
	const totalPrice = subtotalPrice - discountAmount;
	const formattedTotalPrice = formatter.format(totalPrice);

	// Gets rid of information related to cost if the patient's visit fee is zero after subsidy
	const isPaymentFeeDetailsVisible: boolean = totalPrice >= 1;

	return (
		<Highlight>
			<Stack
				direction="vertical"
				gap={spacing(3)}
				padding={{ top: isPaymentFeeDetailsVisible ? 30 : 0 }}
			>
				{items.map((item) => (
					<>
						{isPaymentFeeDetailsVisible && (
							<LineItem {...item} formatter={formatter} />
						)}
						{isPaymentFeeDetailsVisible && <HorizontalLine />}
					</>
				))}

				<Stack direction="vertical" gap={spacing(3)}>
					{isPaymentFeeDetailsVisible && (
						<Stack
							direction="horizontal"
							align="center"
							justify="space-between"
						>
							<Text variant="body">Subtotal</Text>
							<Text variant="body">{formattedSubtotalPrice}</Text>
						</Stack>
					)}
					{discountAmount > 0 && (
						<Stack
							direction="horizontal"
							align="center"
							justify="space-between"
						>
							<Text variant="body">Discount</Text>
							<Text variant="body">-{formattedDiscountAmount}</Text>
						</Stack>
					)}

					{isPaymentFeeDetailsVisible && (
						<Stack
							direction="horizontal"
							align="center"
							justify="space-between"
							padding={{ top: 15 }}
						>
							<Text variant="h3">Total</Text>
							<Text variant="h3">{formattedTotalPrice}</Text>
						</Stack>
					)}

					{bypassPrepayment ||
						(isPaymentFeeDetailsVisible && (
							<Stack
								direction="horizontal"
								align="center"
								justify="stretch"
								gap={spacing(2)}
								padding={{ top: 50 }}
							>
								<InputGroup
									type="text"
									placeholder="Discount Code"
									value={discountCode.toUpperCase()}
									onInput={setDiscountCode}
									maxLength={5}
								/>

								<Button
									text="Apply"
									onClick={() => onApplyDiscount(discountCode)}
									width={100}
									disabled={
										appliedDiscountCode?.toUpperCase() ===
										discountCode.toUpperCase()
									}
								/>
							</Stack>
						))}
				</Stack>
			</Stack>
		</Highlight>
	);
}
