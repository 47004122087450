import type { ReactNode } from "react";
import { useEffect, useMemo, useState } from "react";

import { animated } from "react-spring";

import { AnimationToggle } from "../AnimationToggle";
import { Text } from "../Text";
import styles from "./Tooltip.module.css";

const ARROW_HEIGHT = 5;

const isMobile =
	/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
		navigator.userAgent
	);

export interface TooltipStyleProps {
	verticalAlignment: "top";
	verticalOffset?: number;
	horizontalAlignment: "right" | "left";
	horizontalOffset?: number;
	maxWidth?: number;
}

function getStylesForElement(
	element: HTMLElement | null,
	{
		horizontalAlignment,
		horizontalOffset = 0,
		verticalAlignment,
		verticalOffset = 0,
		maxWidth = 200,
	}: TooltipStyleProps
) {
	if (!element) return {};

	const position = element.getBoundingClientRect();
	if (verticalAlignment === "top" && horizontalAlignment === "right") {
		const { right: hAnchor, top: vAnchor, width } = position;
		return {
			tooltipStyle: {
				right: window.innerWidth - hAnchor + horizontalOffset,
				bottom: window.innerHeight - vAnchor + verticalOffset + ARROW_HEIGHT,
				maxWidth: isMobile ? maxWidth : 250,
				transformOrigin: "bottom right",
				boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
			},
			tooltipArrowStyle: {
				transform: `rotateX(180deg) translateX(-${width / 2}px)`,
				left: "calc(100% - 5px)",
			},
		};
	} else if (verticalAlignment === "top" && horizontalAlignment === "left") {
		const { left: hAnchor, top: vAnchor, width } = position;
		return {
			tooltipStyle: {
				left: hAnchor + horizontalOffset,
				bottom: window.innerHeight - vAnchor + verticalOffset + ARROW_HEIGHT,
				maxWidth: isMobile ? maxWidth : 250,
				transformOrigin: "bottom left",
				boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
			},
			tooltipArrowStyle: {
				transform: `rotateX(180deg) translateX(${width / 2}px)`,
				left: "-5px",
			},
		};
	}

	return {};
}

interface Props extends TooltipStyleProps {
	text: string;
	show: boolean;
	children: (setElement: (element: HTMLElement | null) => void) => ReactNode;
}

export function Tooltip({
	text,
	children,
	verticalAlignment,
	horizontalAlignment,
	verticalOffset,
	horizontalOffset,
	maxWidth,
	show,
}: Props) {
	const [element, setElement] = useState<HTMLElement | null>(null);
	const [position, setPosition] = useState<DOMRectReadOnly | null>(null);
	const { tooltipStyle, tooltipArrowStyle } = useMemo(
		() =>
			getStylesForElement(element, {
				verticalAlignment,
				verticalOffset,
				horizontalAlignment,
				horizontalOffset,
				maxWidth,
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			element,
			verticalAlignment,
			horizontalAlignment,
			horizontalOffset,
			verticalOffset,
			maxWidth,
			show,
			position,
		]
	);
	useEffect(() => {
		if (!element) return;

		const intersectionObserver = new IntersectionObserver(([entry]) => {
			setPosition(entry.boundingClientRect);
		});
		intersectionObserver.observe(element);
		return () => intersectionObserver.unobserve(element);
	}, [element]);

	return (
		<>
			<AnimationToggle show={show}>
				{(value) => (
					<animated.div
						className={styles.tooltip}
						style={{
							...tooltipStyle,
							opacity: value.to([1, 0], [0, 1]),
							scale: value.to([1, 0], [0, 1]),
						}}
					>
						<Text align="center" variant="medium">
							{text}
						</Text>
						<div className={styles.tooltipArrow} style={tooltipArrowStyle} />
					</animated.div>
				)}
			</AnimationToggle>
			{children(setElement)}
		</>
	);
}
