import type { FunctionalComponent } from "preact";

import type { RouteMap } from "@app/constants/RouteMap";

import type { BackButtonProps } from "./components/BackButton";
import type { LogoImageProps } from "./components/LogoImage";

export type RouteMapKeys = (typeof RouteMap)[keyof typeof RouteMap];

export enum UserState {
	Authenticated = "authenticated",
	Unauthenticated = "unauthenticated",
}

type ButtonPosition = "left" | "middle" | "right";

type NamedFunctionComponent<P = object> = FunctionalComponent<P> & {
	componentName?: string;
};

export type ButtonComponentType =
	| NamedFunctionComponent<BackButtonProps>
	| NamedFunctionComponent<LogoImageProps>;

export type ButtonLayout = {
	[key in ButtonPosition]: ButtonComponentType[];
};
