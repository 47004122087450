import { medical, search, time } from "ionicons/icons";
import { useState, useMemo } from "preact/hooks";
import { useMutation } from "urql";

import { useContextualRedirect } from "@app/components/Auth/useContextualRedirect";
import {
	useOrganizationContext,
	usePatientContext,
} from "@app/components/Contexts";
import TermsButton from "@app/components/Forms/EditInfo/TermsButton";
import { Header } from "@app/components/Header";
import { Main } from "@app/components/Main";
import { TermsModal } from "@app/components/PatientHub/Visits/TermsModal";
import type { ContentInfo } from "@app/components/PatientHub/Visits/types";
import { Button } from "@shared/components/Button";
import { Hero } from "@shared/components/Hero";
import { Icon } from "@shared/components/Icon";
import { Stack } from "@shared/components/Stack";
import { Text } from "@shared/components/Text";
import { useTheme } from "@shared/hooks/useTheme";
import type { AuthStage, AuthStageProps } from "@shared/types/AuthStages";
import { WidgetSetPatientConsentDocument } from "@shared/types/graphql";
import { debug } from "@shared/utilities/debug";

import styles from "./Consent.module.css";

export function Consent({
	props: { serviceIntentId },
}: AuthStageProps<AuthStage.Consent>) {
	const { primaryForeground, spacing } = useTheme();
	const [ready, setReady] = useState(false);
	const { organizationName } = useOrganizationContext();
	const { fetching, error } = useContextualRedirect(ready, { serviceIntentId });
	const { patientId } = usePatientContext();
	const [marketingConsentResponse, setMarketingConsent] = useMutation(
		WidgetSetPatientConsentDocument
	);
	const [contentInfo, showContent] = useState<ContentInfo>();
	const onSubmit = async (consent: boolean) => {
		if (!patientId) return;
		try {
			await setMarketingConsent({
				patientId,
				consent,
			});
			setReady(true);
		} catch (error) {
			debug("error", {
				context: "Consent",
				message: "Failed to update patient consent",
				info: error,
			});
		}
	};
	const terms = `By selecting “Opt-in & Continue”, you opt-in to receive text messages from ${organizationName} to the mobile number you provided that may be considered marketing. Message and data rates may apply. Message frequency varies. Reply HELP for help. Reply STOP to opt out.`;
	const { termsOfUse, legalForm } = useOrganizationContext();
	const termsLink = useMemo(() => {
		return (
			<TermsButton
				label={`Read ${organizationName} SMS Policy.`}
				title="Terms of Use"
				text={legalForm.termsOfUse ?? ""}
				link={termsOfUse}
				onClick={showContent}
			/>
		);
	}, [legalForm.termsOfUse, organizationName, showContent, termsOfUse]);

	return (
		<>
			<Header />
			<Main loading={fetching} error={error?.message}>
				<div className={styles.content}>
					<Hero
						title="Opt-in for SMS Notifications"
						subtitle={`${organizationName} can send you important updates via text message.`}
					/>
					<Stack
						direction="vertical"
						justify="space-between"
						style={{ flexGrow: 1 }}
					>
						<ul className={styles.list}>
							<li className={styles.item}>
								<Stack direction="horizontal" align="center" gap={spacing(4)}>
									<Icon icon={medical} size={24} fill={primaryForeground} />
									<Text variant="body">
										New treatments and offers from {organizationName}
									</Text>
								</Stack>
							</li>
							<li className={styles.item}>
								<Stack direction="horizontal" align="center" gap={spacing(4)}>
									<Icon icon={time} size={24} fill={primaryForeground} />
									<Text variant="body">Prescription expiration reminders</Text>
								</Stack>
							</li>
							<li className={styles.item}>
								<Stack direction="horizontal" align="center" gap={spacing(4)}>
									<Icon icon={search} size={24} fill={primaryForeground} />
									<Text variant="body">
										Helping you find doctors to treat your conditions
									</Text>
								</Stack>
							</li>
						</ul>

						<Stack
							direction="vertical"
							align="center"
							justify="center"
							gap={spacing(3)}
						>
							<Button
								variant="primary"
								text="Opt-in & Continue"
								onClick={() => onSubmit(true)}
								disabled={marketingConsentResponse.fetching}
							/>
							<Button
								variant="plain"
								text="No thanks"
								underline
								onClick={() => onSubmit(false)}
								disabled={marketingConsentResponse.fetching}
							/>
							<Stack
								direction="vertical"
								align="center"
								justify="center"
								gap={spacing(0)}
							>
								<Text variant="small">
									{terms} {termsLink}
								</Text>
							</Stack>
						</Stack>
					</Stack>
				</div>
			</Main>
			{contentInfo && (
				<TermsModal {...contentInfo} close={() => showContent(undefined)} />
			)}
		</>
	);
}
