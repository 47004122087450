import { useCallback, useEffect, useRef, useState } from "preact/hooks";

import {
	useAuthContext,
	usePatientContext,
	useWidgetContext,
} from "@app/components/Contexts";
import { Header } from "@app/components/Header";
import { Main } from "@app/components/Main";
import { RouteMap } from "@app/constants/RouteMap";
import { useIntakeNavigation } from "@shared/hooks/useIntakeNavigation";
import { useLocation } from "@shared/hooks/useLocation";
import { useNavigation } from "@shared/hooks/useNavigation";
import { IntakeStage } from "@shared/types/IntakeStages";
import { parse } from "@shared/utilities/queryString";

export function Home() {
	// This is just a stub for the Home page route.
	// All this will do is redirect to the correct route based off of whether the user is logged in or not.
	const [error, setError] = useState<string | null>(null);
	const { url } = useLocation();
	const { replace, navigateTo } = useNavigation();
	const { navigateToIntakeStart } = useIntakeNavigation();
	const {
		patientId,
		fetching: fetchingPatientContext,
		hasOngoingVisits,
		patientExternalId,
	} = usePatientContext();
	const { startExternalPatientLogin, logout } = useAuthContext();
	const { widgetId } = useWidgetContext();
	const fecthingStartExternalPatientLogin = useRef(false);
	const externalId = parse(url)?.externalId as string | undefined;
	const loginToken = parse(url)?.loginToken as string | undefined;
	const redirectUrl = parse(url)?.redirectUrl as string | undefined;
	const fetching =
		fetchingPatientContext || fecthingStartExternalPatientLogin.current;

	const loginRedirect = useCallback(() => {
		if (redirectUrl) {
			navigateTo(decodeURIComponent(redirectUrl));
			return;
		}

		if (hasOngoingVisits) {
			replace(RouteMap.Visits, {
				widgetId,
			});
			return;
		}

		navigateToIntakeStart();
	}, [
		replace,
		navigateTo,
		widgetId,
		navigateToIntakeStart,
		hasOngoingVisits,
		redirectUrl,
	]);

	const startLogin = useCallback(
		(externalId: string, loginToken: string) => {
			const [, , menuId] = loginToken.split(":");

			startExternalPatientLogin(externalId, loginToken.replaceAll(" ", "+"))
				.then(() => {
					if (menuId && !redirectUrl) return;

					fecthingStartExternalPatientLogin.current = false;
					loginRedirect();
				})
				.catch((error) => {
					setError((error as Error).message);
				});

			// If we have a menu id, we need to redirect to the menu, no need to wait for the login to finish.
			if (menuId !== undefined && !redirectUrl) {
				replace(
					RouteMap.IntakeStages,
					{ widgetId, stageId: IntakeStage.Menu },
					{ menuId }
				);
			}
		},
		[startExternalPatientLogin, replace, widgetId, loginRedirect, redirectUrl]
	);

	useEffect(() => {
		if (!fetching) {
			// Logged in, redirect to the patient hub.

			const isExternalLoginAttempt = !!externalId && !!loginToken;
			const isLoggedIn = !!patientId;
			const isDifferentPatient = patientExternalId !== externalId;

			if (isExternalLoginAttempt && !isLoggedIn) {
				fecthingStartExternalPatientLogin.current = true;

				// Not logged in, but we have an external id, so we can log in.
				startLogin(externalId, loginToken);
			} else if (isExternalLoginAttempt && isLoggedIn && isDifferentPatient) {
				logout({ redirect: false });
			} else {
				loginRedirect();
			}
		}
	}, [
		externalId,
		fetching,
		loginRedirect,
		loginToken,
		patientId,
		patientExternalId,
		startLogin,
		logout,
	]);

	return (
		<>
			<Header />
			<Main loading={fetching && !error} error={error} />
		</>
	);
}
