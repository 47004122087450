import type { WidgetGetServiceIntentsSubscription } from "@shared/types/graphql";

type Practitioner = NonNullable<
	NonNullable<
		WidgetGetServiceIntentsSubscription["service_intent"][0]["encounter"]
	>["practitioner_interaction"]
>["practitioner"];

export function getPractitionerAvatarData(practitioner?: Practitioner) {
	if (!practitioner) {
		return {
			userInitials: undefined,
			userName: undefined,
		};
	}

	const firstName = practitioner?.first_name;
	const lastName = practitioner?.last_name;
	const userName = [firstName, lastName].filter(Boolean).join(" ");
	const userInitials = [firstName, lastName].map((name) => name?.[0]).join("");

	return {
		userInitials,
		userName: `Dr. ${userName}`,
	};
}
