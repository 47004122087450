import { usePatientContext } from "@app/components/Contexts";
import { useIntakeStages } from "@app/components/Intake/IntakeStageContext";
import { useLocation } from "@shared/hooks/useLocation";

import type { RouteMapKeys } from "./Header.types";
import { UserState } from "./Header.types";
import { getButtonConfiguration } from "./Header.utils";

export function useHeaderConfig() {
	const { path } = useLocation();
	const { patientId } = usePatientContext();
	const { currentStage } = useIntakeStages();

	const userState = patientId
		? UserState.Authenticated
		: UserState.Unauthenticated;

	const buttonConfig = getButtonConfiguration(
		userState,
		path as RouteMapKeys,
		currentStage
	);

	return { buttonConfig };
}
