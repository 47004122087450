import { useCallback, useState } from "preact/hooks";
import { useMutation } from "urql";

import { useAuthContext } from "@app/components/Contexts";
import { WidgetCreateServiceIntentDocument } from "@shared/types/graphql";

export function useCreateServiceIntent({
	serviceId,
	patientId,
	startOver = false,
}: {
	serviceId: string;
	patientId: string | undefined | null;
	startOver?: boolean;
}) {
	const [createdServiceIntentId, setCreatedServiceIntentId] = useState<
		string | undefined | null
	>(undefined);
	const { getTemporaryPatient, temporaryPatientError } = useAuthContext();

	const [{ fetching, error }, createServiceIntent] = useMutation(
		WidgetCreateServiceIntentDocument
	);

	const startService = useCallback(async () => {
		let newServiceIntentId: string | undefined | null;

		// If there's no patient, then we need to get a temporary patient
		if (!patientId) {
			await getTemporaryPatient();
			const response = await createServiceIntent({
				serviceId,
				startOver,
			});
			newServiceIntentId =
				response?.data?.createEncounterAsPatient.serviceIntentId;
		} else {
			const response = await createServiceIntent({
				serviceId,
				startOver,
			});

			newServiceIntentId =
				response.data?.createEncounterAsPatient?.serviceIntentId;
		}

		if (!newServiceIntentId) {
			throw new Error("Service intent ID is missing.");
		}

		setCreatedServiceIntentId(newServiceIntentId);
	}, [
		patientId,
		getTemporaryPatient,
		serviceId,
		createServiceIntent,
		startOver,
	]);

	return {
		createdServiceIntentId,
		fetching,
		error: error ?? temporaryPatientError,
		startService,
	};
}
