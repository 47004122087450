import { useEffect, useState } from "react";

import { isDarkMode } from "@shared/utilities/isDarkMode";

export function useIsDarkMode(isDark?: boolean) {
	const [isDarkModeEnabled, setIsDarkModeEnabled] = useState(isDarkMode());
	useEffect(() => {
		window
			.matchMedia("(prefers-color-scheme: dark)")
			.addEventListener("change", () => {
				setIsDarkModeEnabled(isDark !== undefined ? isDark : isDarkMode());
			});
	});

	return isDarkModeEnabled;
}
