import type { Theme } from "@shared/hooks/useTheme";
import { ThemeColor } from "@shared/hooks/useTheme";
import { isDarkMode } from "@shared/utilities/isDarkMode";

const MAX = 255;

export const DARK_THEME: Theme = {
	primaryForeground: new ThemeColor({
		red: 1,
		green: 1,
		blue: 1,
	}),
	primaryPlaceholder: new ThemeColor({
		red: 0.6,
		green: 0.6,
		blue: 0.6,
	}),
	primaryBackground: new ThemeColor({
		red: 11 / MAX,
		green: 32 / MAX,
		blue: 39 / MAX,
	}),
	primaryShadow: new ThemeColor({
		red: 22 / MAX,
		green: 22 / MAX,
		blue: 22 / MAX,
		alpha: 0.4,
	}),
	warningForeground: new ThemeColor({
		red: 11 / MAX,
		green: 32 / MAX,
		blue: 39 / MAX,
	}),
	warningBackground: new ThemeColor({
		red: 252 / MAX,
		green: 236 / MAX,
		blue: 201 / MAX,
	}),
	dangerForeground: new ThemeColor({
		red: 1,
		green: 1,
		blue: 1,
	}),
	dangerBackground: new ThemeColor({
		red: 0.83,
		green: 0.15,
		blue: 0.15,
	}),
	successForeground: new ThemeColor({
		red: 11 / MAX,
		green: 32 / MAX,
		blue: 39 / MAX,
	}),
	successBackground: new ThemeColor({
		red: 210 / MAX,
		green: 255 / MAX,
		blue: 210 / MAX,
	}),
	primaryFont: "Inter",
	borderRadius: 8,
	spacingMultiplier: 4,
};

export const LIGHT_THEME: Theme = {
	primaryForeground: new ThemeColor({
		red: 11 / MAX,
		green: 32 / MAX,
		blue: 39 / MAX,
	}),
	primaryPlaceholder: new ThemeColor({
		red: 0.4,
		green: 0.4,
		blue: 0.4,
	}),
	primaryBackground: new ThemeColor({
		red: 1,
		green: 1,
		blue: 1,
	}),
	primaryShadow: new ThemeColor({
		red: 11 / MAX,
		green: 32 / MAX,
		blue: 39 / MAX,
		alpha: 0.05,
	}),
	warningForeground: new ThemeColor({
		red: 11 / MAX,
		green: 32 / MAX,
		blue: 39 / MAX,
	}),
	warningBackground: new ThemeColor({
		red: 252 / MAX,
		green: 236 / MAX,
		blue: 201 / MAX,
	}),
	dangerForeground: new ThemeColor({
		red: 1,
		green: 1,
		blue: 1,
	}),
	dangerBackground: new ThemeColor({
		red: 0.83,
		green: 0.15,
		blue: 0.15,
	}),
	successForeground: new ThemeColor({
		red: 11 / MAX,
		green: 32 / MAX,
		blue: 39 / MAX,
	}),
	successBackground: new ThemeColor({
		red: 177 / MAX,
		green: 251 / MAX,
		blue: 177 / MAX,
	}),
	primaryFont: "Inter",
	borderRadius: 8,
	spacingMultiplier: 4,
};

export const DEFAULT_THEME = isDarkMode() ? DARK_THEME : LIGHT_THEME;
