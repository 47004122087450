import type { ComponentChildren } from "preact";

import { useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect } from "preact/hooks";

import { LD_ANONYMOUS_USER_KEY } from "@shared/constants/environments";
import { debug } from "@shared/utilities/debug";

import { useAuthContext } from "../AuthContext";
import { useOrganizationContext } from "../OrganizationContext";
import { usePatientContext } from "../PatientContext";
import { useWidgetContext } from "../WidgetContext";

interface Props {
	children: ComponentChildren;
}

export function LaunchDarklyContext({ children }: Props) {
	const { widgetId } = useWidgetContext();
	const {
		organizationId,
		organizationName,
		fetching: fetchingOrganization,
	} = useOrganizationContext();
	const { temporaryPatientId } = useAuthContext();
	const { patientId, fetching: fetchingPatient } = usePatientContext();

	const ldClient = useLDClient();
	useEffect(() => {
		if (!ldClient || fetchingOrganization || fetchingPatient) return;

		const contextHash = undefined; // TODO: Get from server if we want secure mode.
		const context: Parameters<typeof ldClient.identify>[0] = {
			kind: "multi" as const,
			// TODO: Enable once we get access to the Contexts EAP.
			// Details: https://docs-stg.launchdarkly.com/contexts-eap/guides/flags/intro-contexts
			// widget: {
			// 	key: widgetId,
			// },
			// organization: {
			// 	key: organizationId,
			// 	name: organizationName,
			// },
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			user: patientId
				? {
						key: patientId,
						organizationId,
						widgetId,
				  }
				: {
						key: LD_ANONYMOUS_USER_KEY,
						anonymous: true,
						widgetId,
				  },
		};
		debug("debug", {
			context: "LaunchDarkly",
			message: "Will set LaunchDarkly context.",
			info: context,
		});
		ldClient.identify(context, contextHash).then((result) => {
			debug("debug", {
				context: "LaunchDarkly",
				message: "Identified LaunchDarkly user.",
				info: result,
			});
		});
	}, [
		ldClient,
		widgetId,
		organizationId,
		organizationName,
		patientId,
		temporaryPatientId,
		fetchingOrganization,
		fetchingPatient,
	]);

	return <>{children}</>;
}
