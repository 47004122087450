import { useWidgetContext } from "@app/components/Contexts";
import { ConfirmDrawer } from "@shared/components/ConfirmDrawer";
import { IconButton } from "@shared/components/IconButton";
import { useIconStyles } from "@shared/hooks/useIconStyles";
import { useIntakeFlow } from "@shared/hooks/useIntakeFlow";
import { cross } from "@shared/icons/cross";

export function ExitButton() {
	const { isIntakeFlow } = useIntakeFlow();
	const { closeWidget } = useWidgetContext();
	const iconProps = useIconStyles({
		icon: cross,
	});

	if (!isIntakeFlow) {
		return (
			<IconButton
				iconProps={iconProps}
				buttonProps={{ onClick: () => closeWidget(), variant: "plain" }}
			/>
		);
	}

	return (
		<ConfirmDrawer
			title="Exit confirmation"
			description="Are you sure you want to exit the Health Survey?"
			onAgree={() => closeWidget()}
			cancelText="Go back to Survey"
			confirmText="Exit"
		>
			{(toggleOpen) => (
				<IconButton
					iconProps={iconProps}
					buttonProps={{ onClick: toggleOpen, variant: "plain" }}
				/>
			)}
		</ConfirmDrawer>
	);
}
