import { useOrganizationContext } from "@app/components/Contexts";
import { AddressPicker } from "@app/components/Forms/AddressPicker";
import { Header } from "@app/components/Header";
import { Main } from "@app/components/Main";
import { Hero } from "@shared/components/Hero";
import { Stack } from "@shared/components/Stack";
import { useTheme } from "@shared/hooks/useTheme";

import type { CheckoutStepProps } from "../CheckoutStep";
import { CheckoutStep } from "../CheckoutStep";

export function Address({ onNextStep, onPreviousStep }: CheckoutStepProps) {
	const { spacing } = useTheme();
	const { showShippingAddress } = useOrganizationContext();

	return (
		<>
			<Header />
			<Main>
				<Stack direction="vertical" align="center" gap={spacing(5)}>
					<Hero
						title="Let's get some details"
						subtitle="We need some final information to get you started."
					/>
					<AddressPicker
						buttonText="Continue"
						prevButtonText="Go back"
						showShippingAddress={showShippingAddress}
						onBack={() => onPreviousStep()}
						onSuccess={() => onNextStep(CheckoutStep.Payment, {})}
					/>
				</Stack>
			</Main>
		</>
	);
}
