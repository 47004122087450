import { useCallback, useEffect, useRef } from "react";
import { lockScroll, unlockScroll } from "@shared/utilities/lockScroll";
import { useClickOutside } from "@shared/hooks/useClickOutside";

export function useDrawer({
	show,
	exceptionIds,
	onToggle,
}: {
	show: boolean;
	exceptionIds?: `#${string}`[];
	onToggle: () => void;
}) {
	const ref = useRef<HTMLDivElement | null>(null);

	const handleToggle = useCallback(() => {
		onToggle();
	}, [onToggle]);

	useEffect(() => {
		if (show) {
			lockScroll("fixed");
		} else {
			unlockScroll();
		}

		return () => {
			unlockScroll();
		};
	}, [show]);

	useEffect(() => {
		if (!show) {
			return;
		}

		function handleKeyUp(evt: KeyboardEvent): void {
			if (!ref.current) {
				return;
			}

			if (evt.key === "Escape") {
				handleToggle();
			}
		}

		document.addEventListener("keyup", handleKeyUp, true);

		return () => {
			document.removeEventListener("keyup", handleKeyUp, true);
		};
	}, [show, handleToggle]);

	useClickOutside(ref, () => show && handleToggle(), exceptionIds);

	return {
		ref,
	};
}
