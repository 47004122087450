import type { ComponentChildren } from "preact";

import { useMemo } from "preact/hooks";

import type {
	ConsentAgreementPayload,
	ConsentRequestPayload,
	PortalMessagePayload,
} from "@shared/components/Message/Message";
import { PortalMessagePayloadType } from "@shared/components/Message/Message";
import type { MessageFragment } from "@shared/types/graphql";
import { debug } from "@shared/utilities/debug";

import type { ShowConsentProps } from "./ConsentContext";
import { ConsentContext } from "./ConsentContext";

type MessageWithPayload = MessageFragment & {
	payload: PortalMessagePayload | undefined;
};

type Props = {
	children: ComponentChildren;
	encounterId: string;
	messages: MessageFragment[];
	showConsent: (props: ShowConsentProps) => void;
};

export function ConsentContextProvider({
	children,
	encounterId,
	messages,
	showConsent,
}: Props) {
	const { requiresConsent, approvedAtMap } = useMemo(() => {
		const consentRequiredEvent = (messages as MessageWithPayload[]).find(
			(message) =>
				message.type === "event" &&
				message.payload?.type === PortalMessagePayloadType.CONSENT_FORM_REQUEST
		);
		const consetRequiredEvents = (messages as MessageWithPayload[]).filter(
			(message) =>
				message.type === "event" &&
				message.payload?.type === PortalMessagePayloadType.CONSENT_FORM_REQUEST
		);
		const consentAcceptedEvents = (messages as MessageWithPayload[]).filter(
			(message) =>
				message.type === "event" &&
				message.payload?.type ===
					PortalMessagePayloadType.CONSENT_FORM_AGREEMENT
		);

		const requiresConsent = !!consentRequiredEvent?.payload;
		if (!requiresConsent || consentAcceptedEvents.length === 0)
			return { requiresConsent, approvedAtMap: null };

		const consetRequiredEventsMap: { [key: string]: MessageWithPayload } =
			consetRequiredEvents.reduce((acc, curr) => {
				return {
					...acc,
					[(curr.payload as ConsentRequestPayload).practitionerId]: curr,
				};
			}, {});

		const consentAcceptedAtMap: { [key: string]: number | null } = {};
		Object.entries(consetRequiredEventsMap).forEach(
			([practitionerId, value]) => {
				const filteredConsentAcceptedFormEvents = consentAcceptedEvents.filter(
					(event) => new Date(event.created_at) > new Date(value.created_at)
				);

				const consentFormIds = Array.from(
					new Set(
						(value.payload as ConsentRequestPayload).consentForms
							.map((form) => form.consentFormIds)
							.flat()
					)
				);
				const consentAcceptedFormIds = Array.from(
					new Set(
						filteredConsentAcceptedFormEvents
							.map((event) =>
								(event.payload as ConsentAgreementPayload).consentForms
									.map(({ consentForms }) =>
										consentForms.map(({ consentFormId }) => consentFormId)
									)
									.flat()
							)
							.flat()
					)
				);

				// If there are any consent forms that have not been accepted, then we don't have consent.
				if (
					!consentFormIds.every((id) => consentAcceptedFormIds.includes(id))
				) {
					consentAcceptedAtMap[practitionerId] = null;
				} else {
					// Otherwise, return the latest consent date.
					consentAcceptedAtMap[practitionerId] = Math.max(
						...filteredConsentAcceptedFormEvents.map(
							(event) => (event.payload as ConsentAgreementPayload).date
						)
					);
				}
			}
		);

		return {
			requiresConsent,
			approvedAtMap: consentAcceptedAtMap,
		};
	}, [messages]);

	return (
		<ConsentContext.Provider
			ref={() =>
				debug("info", {
					context: "ConsentContextProvider",
					message: "Consent context loaded.",
					info: {
						encounterId,
						approvedAtMap,
					},
				})
			}
			value={{
				encounterId,
				requiresConsent,
				approvedAtMap,
				showConsent,
			}}
		>
			{children}
		</ConsentContext.Provider>
	);
}
