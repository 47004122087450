import type { ComponentChildren, JSX } from "preact";

import classNames from "classnames";
import { alertCircle } from "ionicons/icons";
import { useCallback } from "preact/compat";

import { Alert } from "@shared/components/Alert";
import { ErrorBoundary } from "@shared/components/ErrorBoundary";
import { Loader } from "@shared/components/Loader";
import { useTheme } from "@shared/hooks/useTheme";
import { useWindowSize } from "@shared/hooks/useWindowSize";

import styles from "./Main.module.css";

interface Props {
	loading?: boolean;
	error?: string | null;
	children?: ComponentChildren | (() => ComponentChildren);
	style?: JSX.CSSProperties;
	className?: string;
}

interface WrapperProps {
	children?: ComponentChildren | (() => ComponentChildren);
	style?: JSX.CSSProperties;
	className?: string;
}

export const CONTENT_MAX_WIDTH = 520;

const MainWrapper = ({ children, style, className }: WrapperProps) => {
	const { width } = useWindowSize();
	return (
		<main
			className={classNames(styles.main, className, "flex flex-col p-4")}
			style={{ width: Math.min(CONTENT_MAX_WIDTH, width), ...style }}
		>
			{children}
		</main>
	);
};

export function Main({ children, loading, error, style, className }: Props) {
	const { dangerForeground } = useTheme();

	const renderError = useCallback(
		(errorMessage?: string | null) => {
			return (
				<div className={styles.center}>
					<Alert
						variant="danger"
						iconProps={{
							icon: alertCircle,
							fill: dangerForeground,
							size: 48,
						}}
						iconPosition="after"
						caption="Uh-oh!"
						text={errorMessage}
						buttonProps={{
							variant: "danger",
							text: "Reload",
							onClick: () => window.location.reload(),
						}}
					/>
				</div>
			);
		},
		[dangerForeground]
	);

	const renderContent = useCallback(() => {
		if (loading) {
			return (
				<div className={styles.center}>
					<Loader />
				</div>
			);
		} else if (error) {
			return renderError(error);
		} else if (children) {
			return typeof children === "function" ? children() : children;
		}
	}, [children, error, loading, renderError]);

	return (
		<ErrorBoundary
			fallback={({ errorMessage }) => (
				<MainWrapper style={style} className={className}>
					{renderError(errorMessage)}
				</MainWrapper>
			)}
		>
			<MainWrapper style={style} className={className}>
				{renderContent()}
			</MainWrapper>
		</ErrorBoundary>
	);
}
