import type { Props as IconProps } from "@shared/components/Icon";
import { IconButton } from "@shared/components/IconButton";
import { useTheme } from "@shared/hooks/useTheme";
import { chevronLeft } from "@shared/icons/chevronLeft";

export type BackButtonProps = {
	label?: string;
	iconProps?: IconProps;
	onBack?: () => void;
};

export function BackButton({
	label,
	iconProps: _iconProps,
	onBack,
}: BackButtonProps) {
	const { primaryForeground } = useTheme();
	const iconProps = _iconProps || {
		icon: chevronLeft,
		stroke: primaryForeground,
	};

	return (
		<IconButton
			buttonProps={{
				onClick: () => onBack?.(),
				variant: "plain",
				"data-testid": "back-button",
			}}
			iconProps={iconProps}
			textProps={{ variant: "new", children: label }}
			textPosition="after"
		/>
	);
}

BackButton.componentName = "BackButton";
