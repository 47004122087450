import type { Scalars } from "@shared/types/graphql";

function isObjectEmpty(obj: object): boolean {
	return Object.keys(obj).length === 0;
}

export function hasStartedQuestionnaire(
	response?: { state: Scalars["jsonb"] } | null
): boolean {
	if (!response) {
		return false;
	}

	return response && !!response.state && !isObjectEmpty(response.state);
}
