export class ThemeColor {
	red: number;
	green: number;
	blue: number;
	alpha: number;

	static fromHex(_hex: string) {
		if (!_hex.startsWith("#")) throw new Error("Invalid hex color!");
		if (_hex.length !== 7 && _hex.length !== 9) {
			throw new Error("Invalid hex color!");
		}

		const hex = _hex.toUpperCase();
		return new ThemeColor({
			red: parseInt(hex.slice(1, 3), 16) / 255,
			green: parseInt(hex.slice(3, 5), 16) / 255,
			blue: parseInt(hex.slice(5, 7), 16) / 255,
			alpha:
				hex.slice(7, 9).length > 0 ? parseInt(hex.slice(7, 9), 16) / 255 : 1,
		});
	}

	constructor(values: {
		red: number;
		green: number;
		blue: number;
		alpha?: number;
	}) {
		this.red = values.red <= 1 ? values.red : values.red / 255;
		this.green = values.green <= 1 ? values.green : values.green / 255;
		this.blue = values.blue <= 1 ? values.blue : values.blue / 255;
		this.alpha = values.alpha ?? 1;
	}

	get color() {
		return this.rgba;
	}

	get rgb() {
		return `rgb(${this.red * 255}, ${this.green * 255}, ${this.blue * 255})`;
	}

	get rgba() {
		return `rgba(${this.red * 255}, ${this.green * 255}, ${this.blue * 255}, ${
			this.alpha
		})`;
	}

	withAlpha(alpha: number) {
		return new ThemeColor({
			red: this.red,
			green: this.green,
			blue: this.blue,
			alpha,
		});
	}
}
