import { useMemo } from "preact/hooks";

import type { ServiceIntentStatus } from "@shared/types/ServiceIntentStatus";
import { SERVICE_INTENT_TERMINAL_STATUSES } from "@shared/types/ServiceIntentStatus";
import type { WidgetGetServiceIntentsSubscription } from "@shared/types/graphql";

export function useServiceIntents(
	data: WidgetGetServiceIntentsSubscription | undefined
) {
	return useMemo(() => {
		const serviceIntentsList = data?.service_intent ?? [];

		const { currentServiceIntentsList, pastServiceIntentsList } =
			serviceIntentsList.reduce(
				(
					acc: {
						currentServiceIntentsList: (typeof serviceIntent)[];
						pastServiceIntentsList: (typeof serviceIntent)[];
					},
					serviceIntent
				) => {
					const { status, encounter: encounter } = serviceIntent;

					/**
					 * If the service intent or its encounter has a 'complete' status, it's considered past.
					 * Otherwise, it's considered current.
					 */
					if (
						SERVICE_INTENT_TERMINAL_STATUSES.has(
							status as ServiceIntentStatus
						) ||
						(encounter &&
							SERVICE_INTENT_TERMINAL_STATUSES.has(
								encounter.status as ServiceIntentStatus
							))
					) {
						acc.pastServiceIntentsList.push(serviceIntent);
					} else {
						acc.currentServiceIntentsList.push(serviceIntent);
					}

					return acc;
				},
				{
					currentServiceIntentsList: [],
					pastServiceIntentsList: [],
				}
			);

		return {
			currentServiceIntentsList,
			pastServiceIntentsList,
			serviceIntentsList,
		};
	}, [data]);
}
