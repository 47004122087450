export enum EncounterStatus {
  CREATED = 'created',
  CAPTURE_QUESTIONS_COMPLETED_QUALIFIED = 'capture_questions_completed_qualified',
  CAPTURE_QUESTIONS_COMPLETED_DISQUALIFIED = 'capture_questions_completed_disqualified', //Terminal status
  QUALIFICATION_QUESTIONS_COMPLETED_QUALIFIED = 'qualification_questions_completed_qualified',
  QUALIFICATION_QUESTIONS_COMPLETED_DISQUALIFIED = 'qualification_questions_completed_disqualified', //Terminal status
  PAYMENT_STARTED = 'payment_started',
  PAYMENT_SUBMITTED = 'payment_submitted',
  PAYMENT_HOLD_AUTHORIZED = 'payment_hold_authorized',
  PAYMENT_COLLECTED = 'payment_collected',
  PAYMENT_COMPLETED = 'payment_completed', //Not used
  UNASSIGNED = 'unassigned',
  SUPPORT_WAITING_FOR_CONSENT = 'support_waiting_for_consent',
  SUPPORT_WAITING_FOR_AV = 'support_waiting_for_av',
  ASSIGNED = 'assigned',
  WAITING = 'waiting',
  SUPPORT = 'support',
  PROCESSING = 'processing',
  COMPLETED = 'completed', //Terminal status
  CANCELED = 'canceled', //Terminal  status
  CANCELLED = 'cancelled', // Terminal status - deprecated
  CANCELED_RESTARTED = 'canceled_restarted', //Terminal status
}

export const TerminalEncounterStatuses = [
  EncounterStatus.CANCELLED, // Deprecated
  EncounterStatus.CANCELED,
  EncounterStatus.QUALIFICATION_QUESTIONS_COMPLETED_DISQUALIFIED,
  EncounterStatus.CAPTURE_QUESTIONS_COMPLETED_DISQUALIFIED,
  EncounterStatus.COMPLETED,
  EncounterStatus.CANCELED_RESTARTED,
];

// Statuses at which the encounters' questionnaires can be edited by a patient
export const EditableEncounterStatuses = [
  EncounterStatus.CREATED,
  EncounterStatus.CAPTURE_QUESTIONS_COMPLETED_QUALIFIED,
  EncounterStatus.QUALIFICATION_QUESTIONS_COMPLETED_QUALIFIED,
  EncounterStatus.PAYMENT_STARTED,
];

export const StartedEncounterStatuses = Object.values(EncounterStatus).filter(
  (status) =>
    !EditableEncounterStatuses.includes(status) &&
    !TerminalEncounterStatuses.includes(status),
);

export const HiddenEncounterStatuses = [EncounterStatus.CANCELED_RESTARTED];
