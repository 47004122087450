import { useState } from "preact/hooks";

import { EditInfo } from "@app/components/Forms/EditInfo";
import { Header } from "@app/components/Header";
import { Main } from "@app/components/Main";
import { TermsModal } from "@app/components/PatientHub/Visits/TermsModal";
import type { ContentInfo } from "@app/components/PatientHub/Visits/types";
import { Hero } from "@shared/components/Hero";
import { Stack } from "@shared/components/Stack";
import { useTheme } from "@shared/hooks/useTheme";
import type { AuthStageProps } from "@shared/types/AuthStages";
import { AuthStage } from "@shared/types/AuthStages";

export function Registration({
	props: { serviceIntentId },
	onNextStage,
}: AuthStageProps<AuthStage.Registration>) {
	const { spacing } = useTheme();
	const [contentInfo, showContent] = useState<ContentInfo>();

	return (
		<>
			<Header />
			<Main>
				<Stack
					element="form"
					direction="vertical"
					align="center"
					gap={spacing(5)}
				>
					<Hero
						title="Let's get some details"
						subtitle="Please enter your legal name and contact info."
					/>
					<EditInfo
						onSuccess={() =>
							onNextStage(AuthStage.Consent, { serviceIntentId })
						}
						showContent={showContent}
						buttonText="Continue"
						buttonType="rounded"
						showPersonalInfo
						showTermsOfUse
					/>
				</Stack>
			</Main>
			{contentInfo && (
				<TermsModal {...contentInfo} close={() => showContent(undefined)} />
			)}
		</>
	);
}
