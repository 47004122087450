import type { Ref } from "react";

import classNames from "classnames";
import type { GroupBase, Props, SingleValue } from "react-select";
import Select from "react-select";
import type SelectType from "react-select/dist/declarations/src/Select";

import { Stack } from "@shared/components/Stack";
import { Text } from "@shared/components/Text";
import { useTheme } from "@shared/hooks/useTheme";

import styles from "./InputSelect.module.css";
import { useSelectTheme } from "./useSelectTheme";

export interface Option<Value extends string | number | boolean> {
	label: JSX.Element | string;
	value: Value;
	searchableLabel?: string;
}

type IsMulti = false;

export function InputSelect<
	Value extends string | number | boolean,
	Group extends GroupBase<Option<Value>> = GroupBase<Option<Value>>
>({
	options,
	value,
	onChange,
	label,
	...props
}: Omit<
	Props<Option<Value>, IsMulti, Group> & {
		ref?: Ref<SelectType<Option<Value>, IsMulti, Group>>;
	},
	"onChange" | "value" | "options"
> & {
	options: Option<Value>[];
	value: Value | undefined;
	onChange: (value: Value | undefined) => void;
	label?: string;
}) {
	const { spacing } = useTheme();
	const theme = useSelectTheme();
	const selectValue = value
		? options?.find((option) => option.value === value)
		: null;

	return (
		<Stack direction="vertical" align="stretch" gap={spacing(1)}>
			{label && <Text variant="caption">{label}</Text>}
			<Select
				{...props}
				{...theme}
				className={classNames(styles.inputSelect, props.className)}
				options={options}
				value={selectValue}
				onChange={(option: SingleValue<Option<Value>>) =>
					onChange?.(option?.value)
				}
			/>
		</Stack>
	);
}
