import { useEffect, useState } from "react";

import classNames from "classnames";

import { Icon } from "@shared/components/Icon";
import { Text } from "@shared/components/Text";
import { ThemeColor } from "@shared/hooks/useTheme";
import { checkmark } from "@shared/icons/checkmark";
import { formatDate } from "@shared/utilities/formatDate";

export function ApprovedMessage({ approvedAt }: { approvedAt: number }) {
	const [show, setShow] = useState(false);
	useEffect(() => {
		const timer = setTimeout(() => {
			setShow(true);
		}, 0);

		return () => clearTimeout(timer);
	}, []);

	return (
		<div
			className={classNames(
				"flex items-center h-0 opacity-0 transition duration-500 ease-in-out",
				{
					"h-auto": show,
					"opacity-100": show,
				}
			)}
		>
			<div className="rounded-3xl w-[38px] h-[38px] bg-[#D1FAE5] flex justify-center items-center">
				<Icon icon={checkmark} size={24} fill={ThemeColor.fromHex("#059669")} />
			</div>
			<Text faded className="ml-2">
				Completed {formatDate(approvedAt, "MM/DD/YY HH:MM")}
			</Text>
		</div>
	);
}
