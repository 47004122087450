import type {
	ReactNode,
	TextareaHTMLAttributes,
	PropsWithChildren,
} from "react";

import classNames from "classnames";

import styles from "./Text.module.css";

export type Props = PropsWithChildren<{
	align?: "start" | "end" | "center";
	bold?: boolean;
	className?: string;
	clip?: boolean;
	code?: boolean;
	elementName?: "span";
	faded?: boolean;
	formatted?: boolean;
	italic?: boolean;
	numeric?: boolean;
	parseLinks?: boolean;
	screenReaderOnly?: boolean;
	selectable?: boolean;
	strikethrough?: boolean;
	underline?: boolean;
	variant?:
		| "h1"
		| "h2"
		| "h3"
		| "body"
		| "heroSubtitle"
		| "caption"
		| "legend"
		| "button"
		| "link"
		| "small"
		| "smallBold"
		| "medium"
		| "xsmall"
		| "new";
}> &
	TextareaHTMLAttributes<HTMLElement>;

export function Text({
	align = "start",
	bold,
	children,
	className,
	clip,
	code,
	elementName: Element = "span",
	faded,
	formatted,
	italic,
	numeric,
	parseLinks,
	screenReaderOnly,
	selectable = true,
	strikethrough,
	underline,
	variant = "body",
	...restProps
}: Props) {
	let asdf: ReactNode[] | ReactNode = children;

	if (typeof children === "string" && parseLinks) {
		const linkRegex = /https?:\/\/[^\s]+/g;
		const linkMatches = children.match(linkRegex);

		if (linkMatches) {
			const linkElements = linkMatches.map((link) => (
				<a
					key={link}
					href={link}
					target="_blank"
					rel="noopener noreferrer"
					className={styles.inlineLink}
				>
					{link}
				</a>
			));

			asdf = children.split(linkRegex).reduce((acc, text, index) => {
				acc.push(text);

				if (linkElements[index]) {
					acc.push(linkElements[index]);
				}

				return acc;
			}, [] as ReactNode[]);
		}
	}

	return (
		<Element
			className={classNames(
				styles.text,
				styles[variant],
				styles[align],
				{
					[styles.italic]: italic,
					[styles.bold]: bold,
					[styles.faded]: faded,
					[styles.formatted]: formatted,
					[styles.strikethrough]: strikethrough,
					[styles.underline]: underline,
					[styles.clip]: clip,
					[styles.code]: code,
					[styles.numeric]: numeric,
					[styles.screenReaderOnly]: screenReaderOnly,
					[styles.unselectable]: !selectable,
				},
				className
			)}
			{...restProps}
		>
			{asdf}
		</Element>
	);
}
