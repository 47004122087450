import type { ComponentType } from "preact";

import { useState } from "preact/hooks";

import {
	useOrganizationContext,
	usePatientContext,
} from "@app/components/Contexts";
import { EditInfo } from "@app/components/Forms/EditInfo";
import { LogoImage } from "@app/components/Header/components/LogoImage";
import { Main } from "@app/components/Main";
import { TermsModal } from "@app/components/PatientHub/Visits/TermsModal";
import type { ContentInfo } from "@app/components/PatientHub/Visits/types";
import type { Props as ButtonProps } from "@shared/components/Button/Button";
import { Stack } from "@shared/components/Stack";
import { useTheme } from "@shared/hooks/useTheme";
import { paytientCare } from "@shared/icons/paytientCare";
import type { AuthStageProps } from "@shared/types/AuthStages";
import { AuthStage } from "@shared/types/AuthStages";

import { useContextualRedirect } from "../useContextualRedirect";
import styles from "./TermsOfService.module.css";

const PaytientCare = () => (
	// eslint-disable-next-line react/no-danger
	<div dangerouslySetInnerHTML={{ __html: paytientCare }} />
);

export function TermsOfService({
	props: { serviceIntentId, wrapperComponent },
	onNextStage,
}: AuthStageProps<AuthStage.TermsOfService>) {
	const { isPaytient } = useOrganizationContext();
	const { patientFirstName } = usePatientContext();
	const { spacing } = useTheme();
	const [ready, setReady] = useState(false);
	const { fetching, error } = useContextualRedirect(ready, {
		pauseExecution: !isPaytient,
		serviceIntentId,
	});
	const [contentInfo, showContent] = useState<ContentInfo>();

	const onSuccess = async () => {
		if (isPaytient) {
			setReady(true);
		} else {
			onNextStage(AuthStage.Consent, { serviceIntentId });
		}
	};

	return (
		<>
			<Main loading={fetching} error={error?.message}>
				<Stack
					element="form"
					direction="vertical"
					align="center"
					gap={spacing(5)}
					style={{ height: "100%" }}
				>
					<div className={styles.logo}>
						{isPaytient ? <PaytientCare /> : <LogoImage />}
					</div>
					<div className={styles.welcome}>Welcome, {patientFirstName}.</div>
					<div className={styles.terms}>
						<EditInfo
							buttonText="Explore Care"
							buttonType="rounded"
							onSuccess={onSuccess}
							showContent={showContent}
							wrapperComponent={
								wrapperComponent as ComponentType<{
									buttonProps?: ButtonProps | undefined;
								}>
							}
							showTermsOfUse
						/>
					</div>
				</Stack>
			</Main>
			{contentInfo && (
				<TermsModal {...contentInfo} close={() => showContent(undefined)} />
			)}
		</>
	);
}
