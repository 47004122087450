import { useState, useRef, useEffect } from "react";
import type { RefObject } from "react";

import * as mime from "mime-types";
import { useMutation } from "urql";

import type { ChatFile } from "@shared/components/InputFiles/InputFiles";
import { WidgetCreateFileUploadUrlDocument } from "@shared/types/graphql";
import { debug } from "@shared/utilities/debug";

export enum CameraPermissionStatus {
	Granted = "granted",
	Denied = "denied",
	Prompt = "prompt",
}

export function useVideoRecorder(onChange: (file: ChatFile) => void) {
	const [isUploading, setIsUploading] = useState(false);
	const recordedChunksRef = useRef<BlobPart[]>([]);
	const [videoURL, setVideoURL] = useState<string | null>(null);
	const videoRef = useRef<HTMLVideoElement>(null);
	const [isRecording, setIsRecording] = useState(false);
	const [hasRecorded, setHasRecorded] = useState(false);
	const [isCountingDown, setIsCountingDown] = useState(false);
	const [countdown, setCountdown] = useState(3);
	const [duration, setDuration] = useState(0);
	const [recordedBlob, setRecordedBlob] = useState<Blob | null>(null);
	const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
		null
	);
	const [permissionStatus, setPermissionStatus] = useState<
		`${CameraPermissionStatus}` | null
	>(null);
	const [, createFileUploadUrl] = useMutation(
		WidgetCreateFileUploadUrlDocument
	);

	useVideoRecorderInit(videoRef, setPermissionStatus);

	const handleCountdownStart = () => {
		setHasRecorded(false);
		setIsCountingDown(true);

		const countdownTimer = setInterval(() => {
			setCountdown((prev) => {
				if (prev <= 1) {
					clearInterval(countdownTimer);
					setIsCountingDown(false);
					handleRecordingStart();
					return 3;
				}
				return prev - 1;
			});
		}, 1000);
	};

	const handleRecordingStart = async () => {
		setVideoURL(null);
		setIsRecording(true);
		recordedChunksRef.current.length = 0;
		setDuration(0);

		try {
			let stream: MediaStream | null = videoRef.current
				?.srcObject as MediaStream;

			if (!stream) {
				stream = await navigator.mediaDevices.getUserMedia({
					video: true,
					audio: true,
				});

				setPermissionStatus(CameraPermissionStatus.Granted);

				if (videoRef.current) {
					videoRef.current.srcObject = stream;
					videoRef.current.muted = true; // Mute local playback
					videoRef.current.play();
				}
			}

			const recorder = new MediaRecorder(stream);

			recorder.ondataavailable = (e) => {
				if (e.data.size > 0) {
					recordedChunksRef.current.push(e.data);
				}
			};

			const durationTimer = setInterval(() => {
				setDuration((prev) => prev + 1);
			}, 1000);

			recorder.onstop = () => {
				clearInterval(durationTimer);

				if (videoRef.current) {
					videoRef.current.muted = false; // Unmute during playback
					const tracks = (
						videoRef.current.srcObject as MediaStream
					)?.getTracks();
					tracks?.forEach((track) => track.stop());
					videoRef.current.srcObject = null;
				}

				const blob = new Blob(recordedChunksRef.current, {
					type: "video/webm;codecs=vp8,opus",
				});
				const videoURL = URL.createObjectURL(blob);

				setVideoURL(videoURL);
				setRecordedBlob(blob);
			};

			recorder.start();
			setMediaRecorder(recorder);
		} catch (err) {
			const error = err as Error;

			if (error.name === "NotFoundError") {
				setPermissionStatus(CameraPermissionStatus.Denied);
				debug("error", {
					context: "mediaDevices.getUserMedia",
					message: "No media devices found.",
					info: { error },
				});

				return;
			}

			if (error.name === "NotAllowedError") {
				setPermissionStatus(CameraPermissionStatus.Denied);
				debug("error", {
					context: "mediaDevices.getUserMedia",
					message: "Permission to access media devices was denied.",
					info: { error },
				});

				return;
			}

			debug("error", {
				context: "mediaDevices.getUserMedia",
				message: "Failed to get user media.",
				info: { error },
			});
		}
	};

	const handleRecordingStop = () => {
		if (mediaRecorder) {
			mediaRecorder.stop();
			setMediaRecorder(null);
			if (videoRef.current) {
				const stream = videoRef.current.srcObject as MediaStream;
				const tracks = stream?.getTracks();
				tracks?.forEach((track) => track.stop());
				videoRef.current.srcObject = null;
			}
		}
		setDuration(0);
		setHasRecorded(true);
		setIsRecording(false);
	};

	const uploadBlob = async (blob: Blob): Promise<ChatFile | null> => {
		try {
			setIsUploading(true);

			const fileName = `rec-${new Date().toISOString()}-${crypto.randomUUID()}.webm`;

			const { data } = await createFileUploadUrl({
				fileName,
				contentType: blob.type,
			});

			const { fileId, signedURL } = data?.createFileUploadURL ?? {};

			if (!blob || !fileId || !signedURL) {
				throw new Error("Failed to create file upload URL");
			}

			const response = await fetch(signedURL, {
				method: "PUT",
				body: blob,
				headers: {
					"Content-Type": blob.type,
					"Content-Disposition": `attachment; filename=${fileId}.${mime.extension(
						blob.type
					)}`,
				},
			});

			if (!response.ok) {
				throw new Error("Failed to upload blob");
			}

			setIsUploading(false);

			return { id: fileId, type: blob.type } as ChatFile;
		} catch (error) {
			setIsUploading(false);
			debug("error", {
				context: "createFileUploadUrl",
				message: "Error creating file upload URL.",
				info: {
					error,
				},
			});
			return null;
		}
	};

	const handleSubmit = async () => {
		if (recordedBlob) {
			const chatFile = await uploadBlob(recordedBlob);
			if (chatFile) {
				onChange(chatFile);
			}
		}
	};

	return {
		videoRef,
		videoURL,
		duration,
		countdown,
		hasRecorded,
		isUploading,
		isRecording,
		isCountingDown,
		permissionStatus,
		handleSubmit,
		handleCountdownStart,
		handleRecordingStop,
	};
}

function useVideoRecorderInit(
	videoRef: RefObject<HTMLVideoElement>,
	onPermissionStatus: (status: CameraPermissionStatus) => void
) {
	useEffect(() => {
		let stream: MediaStream;

		const currentVideoRef = videoRef.current;

		const init = async () => {
			try {
				stream = await navigator.mediaDevices.getUserMedia({
					video: true,
					audio: true,
				});

				onPermissionStatus(CameraPermissionStatus.Granted);

				if (currentVideoRef) {
					currentVideoRef.srcObject = stream;
					currentVideoRef.muted = true; // Mute local playback
					currentVideoRef.play();
				}
			} catch (err) {
				const error = err as Error;

				if (error.name === "NotFoundError") {
					onPermissionStatus(CameraPermissionStatus.Denied);
					debug("error", {
						context: "mediaDevices.getUserMedia",
						message: "No media devices found.",
						info: { error },
					});

					return;
				}

				if (error.name === "NotAllowedError") {
					onPermissionStatus(CameraPermissionStatus.Denied);
					debug("error", {
						context: "mediaDevices.getUserMedia",
						message: "Permission to access media devices was denied.",
						info: { error },
					});

					return;
				}

				debug("error", {
					context: "mediaDevices.getUserMedia",
					message: "Failed to get user media.",
					info: { error },
				});
			}
		};

		init();

		return () => {
			if (stream) {
				const tracks = stream.getTracks();
				tracks.forEach((track) => track.stop());
			}
			if (currentVideoRef) {
				currentVideoRef.srcObject = null;
			}
		};
	}, [videoRef, onPermissionStatus]);
}
