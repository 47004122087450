import { useCallback } from "preact/hooks";
import { useMutation } from "urql";

import type {
	ChargeCardOutput,
	Mutation_RootChargeCardArgs,
} from "@shared/types/graphql";
import { ChargeCardDocument } from "@shared/types/graphql";

export function usePaytientPurchase(
	serviceId: string,
	onSuccess?: (data?: ChargeCardOutput) => void | Promise<void>
) {
	const [{ fetching, error }, chargeCard] = useMutation<
		ChargeCardOutput,
		Mutation_RootChargeCardArgs
	>(ChargeCardDocument);
	const paymentError = error
		? error.message ?? "Unknown payment error. Please try again later."
		: undefined;

	const purchase = useCallback(async () => {
		const result = await chargeCard({ serviceId });

		if (result.data) {
			await onSuccess?.(result.data);
		}
	}, [serviceId, chargeCard, onSuccess]);

	return [purchase, { loading: fetching, paymentError }] as const;
}
