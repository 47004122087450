import { useCallback, useEffect, useState } from "preact/hooks";
import { useMutation, useSubscription } from "urql";

import { Header } from "@app/components/Header";
import { Main } from "@app/components/Main";
import type { HubPageProps } from "@app/components/PatientHub";
import type { ChatFile } from "@shared/components/InputFiles/InputFiles";
import { useFirst } from "@shared/hooks/useFirst";
import { useLast } from "@shared/hooks/useLast";
import { useLocation } from "@shared/hooks/useLocation";
import { usePageVisibility } from "@shared/hooks/usePageVisibility";
import { EncounterStatus } from "@shared/types/EncounterStatus";
import type { Encounter, MessageFragment } from "@shared/types/graphql";
import {
	WidgetGetChannelInfoDocument,
	WidgetSetLastReadMessageDocument,
} from "@shared/types/graphql";
import { debug } from "@shared/utilities/debug";
import { isTemporaryMessageId } from "@shared/utilities/isTemporaryMessageId";

import { HubPage } from "../HubPage";
import { useGetVisitStatusInfo } from "../useGetVisitStatusInfo";
import { ConsentModal } from "./ConsentModal";
import { ConsentContextProvider } from "./Context";
import type { ShowConsentProps } from "./Context/ConsentContext";
import { ImageModal } from "./ImageModal";
import { MessageComposer } from "./MessageComposer";
import { MessageList } from "./MessageList";
import { VisitStatus } from "./VisitStatus";
import { useMessageComposer } from "./useMessageComposer";
import { useMessageList } from "./useMessageList";

export function VisitChannel({ onPreviousPage, onNextPage }: HubPageProps) {
	const { matches } = useLocation();
	debug("info", {
		context: "VisitChannel",
		message: "matches",
		info: {
			matches,
		},
	});
	const channelId = matches?.channelId ?? "";
	const [channelInfo] = useSubscription({
		query: WidgetGetChannelInfoDocument,
		variables: {
			channelId,
		},
		pause: !channelId,
	});
	const channel = useFirst(channelInfo.data?.portal_message_channel);
	const getStatusInfo = useGetVisitStatusInfo();
	const statusInfo = getStatusInfo(
		channel?.encounter?.status as Encounter["status"]
	);
	const [localMessages, setLocalMessages] = useState<MessageFragment[]>([]);
	const {
		messages,
		imageUrlsByUserId,
		fetchOlderMessages,

		fetching,
		fetchingOlder,
	} = useMessageList(channelId, localMessages, setLocalMessages);
	const isPageVisible = usePageVisibility();
	const [, setLastReadMessage] = useMutation(WidgetSetLastReadMessageDocument);
	const lastMessageId = useLast(messages)?.id;
	useEffect(() => {
		if (!channelId || !lastMessageId || !isPageVisible) return;
		if (isTemporaryMessageId(lastMessageId)) return;
		setLastReadMessage({
			channelId,
			messageId: lastMessageId,
		});
	}, [channelId, lastMessageId, setLastReadMessage, isPageVisible]);
	const { sendMessage, isSending } = useMessageComposer(
		channelId,
		setLocalMessages
	);
	const [previewUrl, showImagePreview] = useState<string | null>(null);
	const [consentInfo, showConsent] = useState<ShowConsentProps | null>(null);

	const handleUploadFile = useCallback(
		(file: ChatFile) => {
			sendMessage("", [file]);
		},
		[sendMessage]
	);

	const onDetailsClick = useCallback(
		() => onNextPage(HubPage.VisitChannelDetails, { channelId }),
		[onNextPage, channelId]
	);

	const hideCreatedAt =
		channel?.encounter?.status === EncounterStatus.CANCELED ||
		channel?.encounter?.status === EncounterStatus.CANCELLED ||
		channel?.encounter?.status === EncounterStatus.COMPLETED;

	return (
		<>
			<Header onBack={onPreviousPage} />
			<VisitStatus
				name={statusInfo.name as string}
				color={statusInfo.color as string}
				title={channel?.name}
				createdAt={channel?.created_at}
				onDetailsClick={onDetailsClick}
				hideCreatedAt={hideCreatedAt}
			/>
			<Main
				loading={fetching}
				style={{ display: "flex", flexDirection: "column", padding: 0 }}
			>
				<ConsentContextProvider
					encounterId={channel?.encounter?.id ?? ""}
					messages={messages}
					showConsent={showConsent}
				>
					<MessageList
						messages={messages}
						imageUrlsByUserId={imageUrlsByUserId}
						fetchingOlder={fetchingOlder}
						fetchOlderMessages={fetchOlderMessages}
						showImagePreview={showImagePreview}
						// TODO: Move to a provider
						onUploadFile={handleUploadFile}
					/>
					<MessageComposer sendMessage={sendMessage} isSending={isSending} />
				</ConsentContextProvider>
			</Main>
			{previewUrl && (
				<ImageModal url={previewUrl} close={() => showImagePreview(null)} />
			)}
			{consentInfo && (
				<ConsentModal {...consentInfo} close={() => showConsent(null)} />
			)}
		</>
	);
}
