import {
	STRIPE_SECRET_KEY,
	ORG_STRIPE_KEYS,
} from "@shared/constants/environments";
import { debug } from "@shared/utilities/debug";
import { loadStripe } from "@stripe/stripe-js";

const stripeKeysCache: Record<string, string> = {};
const TEMP_ORG_STRIPE_KEYS = new TextDecoder().decode(
	Uint8Array.from(atob(ORG_STRIPE_KEYS), (c) => c.charCodeAt(0))
);

const getStripeKeyForOrg = (orgId: string) => {
	if (!TEMP_ORG_STRIPE_KEYS) {
		debug("warn", {
			message: "ORG_STRIPE_KEYS env is not set",
			context: "getStripeKeyForOrg",
		});

		return STRIPE_SECRET_KEY;
	}

	if (stripeKeysCache[orgId]) {
		return stripeKeysCache[orgId];
	}

	let orgStripeKeys: {
		organizationId: string;
		stripePublishableKey: string;
	}[];

	try {
		orgStripeKeys = JSON.parse(TEMP_ORG_STRIPE_KEYS);
	} catch (err) {
		const error = err as Error;
		debug("error", {
			message: `Error parsing ORG_STRIPE_KEYS: ${error.message}`,
			context: "getStripeKeyForOrg",
		});

		return STRIPE_SECRET_KEY;
	}

	if (!Array.isArray(orgStripeKeys)) {
		debug("warn", {
			message: "ORG_STRIPE_KEYS env is not an array",
			context: "getStripeKeyForOrg",
			info: {
				format:
					"the format should be an array of objects with the following fields: { organizationId: string, stripePublishableKey: string }",
			},
		});

		return STRIPE_SECRET_KEY;
	}

	const orgStripeKey = orgStripeKeys.find(
		(key) => key.organizationId === orgId
	)?.stripePublishableKey;

	const defaultStripeKey = orgStripeKeys.find(
		(key) => key.organizationId === "default"
	)?.stripePublishableKey;

	if (orgStripeKey) {
		stripeKeysCache[orgId] = orgStripeKey;
		return orgStripeKey;
	}

	if (defaultStripeKey) {
		stripeKeysCache[orgId] = defaultStripeKey;
		return defaultStripeKey;
	}

	stripeKeysCache[orgId] = STRIPE_SECRET_KEY;
	return STRIPE_SECRET_KEY;
};

export const getStripe = (orgId: string) =>
	loadStripe(getStripeKeyForOrg(orgId), {
		betas: ["address_element_beta_1"],
	});
