import type { ComponentProps } from "preact";
import type { JSX } from "preact";

import classNames from "classnames";
import { chevronUp } from "ionicons/icons";
import { useCallback, useEffect, useMemo, useState } from "preact/hooks";
import type { CombinedError } from "urql";

import { usePatientContext, useWidgetContext } from "@app/components/Contexts";
import { Header } from "@app/components/Header";
import { Main } from "@app/components/Main";
import { RouteMap } from "@app/constants/RouteMap";
import { useActiveServiceIntents } from "@app/hooks/serviceIntent/useActiveServiceIntents";
import { Alert } from "@shared/components/Alert";
import { Badge } from "@shared/components/Badge";
import { List } from "@shared/components/ButtonList";
import { Hero } from "@shared/components/Hero";
import { Icon } from "@shared/components/Icon";
import { OverflowContainer } from "@shared/components/OverflowContainer";
import { Stack } from "@shared/components/Stack";
import { useNavigation } from "@shared/hooks/useNavigation";
import { PubSubMessage, usePublisher } from "@shared/hooks/usePubSub";
import { useTheme } from "@shared/hooks/useTheme";
import { useWindowSize } from "@shared/hooks/useWindowSize";
import { arrowLongRight } from "@shared/icons/arrowLongRight";
import { AuthStage } from "@shared/types/AuthStages";
import type { IntakeStageProps } from "@shared/types/IntakeStages";
import { IntakeStage } from "@shared/types/IntakeStages";
import type { ServiceIntentStatus } from "@shared/types/ServiceIntentStatus";
import { SERVICE_INTENT_EDITABLE_STATUSES } from "@shared/types/ServiceIntentStatus";

import { captureEvent } from "../../../utilities/eventTracker";
import { ClickableBanner } from "../ClickableBanner/ClickableBanner";
import { ConfirmStartOver } from "../Services/ConfirmStartOver";
import { useCustomMenuNavigation } from "./CustomMenu.hooks";
import { isMenuAction, matchesSearchTerm } from "./CustomMenu.utils";
import styles from "./Menu.module.css";
import { SearchBar } from "./SearchBar";
import type { MenuAction, MenuData, MenuItem } from "./types";
import { useServices } from "./useServices";

interface Props {
	menu: MenuData | undefined;
	fetching: boolean;
	error: CombinedError | undefined;
	onPreviousStage: IntakeStageProps<IntakeStage.Menu>["onPreviousStage"];
	onNextStage: IntakeStageProps<IntakeStage.Menu>["onNextStage"];
}

export function CustomMenu({
	menu,
	fetching,
	error,
	onNextStage,
	onPreviousStage,
}: Props) {
	const { spacing, primaryForeground } = useTheme();
	const { width } = useWindowSize();
	const { widgetId } = useWidgetContext();
	const { patientId } = usePatientContext();
	const { push } = useNavigation();
	const { showBackButton } = useCustomMenuNavigation(menu?.id);
	const [showConfirmStartOver, setShowConfirmStartOver] = useState(false);
	const [selectedServiceId, setSelectedServiceId] = useState<string | null>(
		null
	);
	const showLoginPrompt = menu?.show_login_prompt ?? false;
	const showSearchBar = menu?.show_search_bar ?? false;
	const onParentOpenUrl = usePublisher({
		type: PubSubMessage.OpenUrl,
		recipient: window.top,
	});
	const onOpenUrl = useCallback(
		(url: string) => {
			if (window.top) {
				onParentOpenUrl(url);
			}

			window.location.href = url;
		},
		[onParentOpenUrl]
	);
	const [collapsedSections, setCollapsedSections] = useState<string[] | null>(
		null
	);
	const [searchTerm, setSearchTerm] = useState("");
	const menuItems = useMemo(
		() => (menu?.menu_items ?? []) as MenuItem[],
		[menu?.menu_items]
	);
	const serviceIds = useMemo(() => {
		const serviceIds: string[] = [];

		function addServiceId(item: MenuAction) {
			if (item.action_type === "open_service") {
				serviceIds.push(item.action_payload);
			}
		}

		menuItems.forEach((item) => {
			if (isMenuAction(item)) {
				addServiceId(item);
			} else {
				item.children.forEach(addServiceId);
			}
		});

		return serviceIds;
	}, [menuItems]);
	const { services, fetching: isLoadingServices } = useServices(serviceIds);
	const {
		navigateToService,
		serviceIntentsByServiceId,
		fetching: fetchingServiceIntents,
		error: serviceIntentsError,
	} = useActiveServiceIntents();

	const handleConfirmation = useCallback(
		(startOver: boolean) => {
			setShowConfirmStartOver(false);

			if (!selectedServiceId) return;

			navigateToService(selectedServiceId, startOver);
		},
		[selectedServiceId, navigateToService]
	);

	const handleClickLogin = () => {
		push(RouteMap.AuthStages, {
			stageId: AuthStage.Start,
			widgetId,
		});
	};

	const items: ComponentProps<typeof List>["items"] = useMemo(() => {
		return menuItems
			.filter((item) => {
				return matchesSearchTerm(item, searchTerm, services);
			})
			.map((item, index) => {
				const createAction = (
					{ title, description, action_payload, action_type }: MenuAction,
					index: number,
					subitem = false
				) => {
					const service =
						action_type === "open_service"
							? services.find(({ id }) => id === action_payload)
							: undefined;

					return {
						key: index,
						title: service ? title || service.name : title,
						description: service
							? description || service.description
							: description,
						onClick: () => {
							switch (action_type) {
								case "open_service": {
									setSelectedServiceId(action_payload);
									const serviceIntent =
										serviceIntentsByServiceId[action_payload];
									const isEditable =
										serviceIntent &&
										SERVICE_INTENT_EDITABLE_STATUSES.has(
											serviceIntent.status as ServiceIntentStatus
										);

									// If it's editable, we want to ask the user if they want to start over.
									if (isEditable) {
										setShowConfirmStartOver(true);
									} else {
										navigateToService(action_payload);
									}
									break;
								}
								case "open_url":
									onOpenUrl(action_payload);
									break;
								case "open_menu":
									onNextStage(IntakeStage.Menu, {
										menuId: action_payload,
									});
									break;
								case "open_questionnaire":
									onNextStage(IntakeStage.Questionnaire, {
										questionnaireId: action_payload,
									});
									break;
							}
							captureEvent("menu_item_clicked", {
								menu_item_title: title,
								menu_item_description: description,
								menu_item_action_type: action_type,
								menu_item_action_payload: action_payload,
							});
						},
						endIcon: <Icon icon={arrowLongRight} stroke={primaryForeground} />,
						style: {
							padding: subitem
								? `${spacing(2)}px ${spacing(1)}px`
								: `${spacing(2)}px 0`,
						} as JSX.CSSProperties,
					};
				};

				if (isMenuAction(item)) {
					return createAction(item, index);
				}

				const key = `section-${index}`;
				const isCollapsed = collapsedSections
					? collapsedSections.includes(key)
					: item.autocollapse;

				return {
					key,
					onClick: () => {
						if (!collapsedSections) {
							return;
						}

						setCollapsedSections(
							collapsedSections.includes(key)
								? collapsedSections.filter((sectionKey) => sectionKey !== key)
								: [...collapsedSections, key]
						);
					},
					endIcon: (
						<Icon
							icon={chevronUp}
							stroke={primaryForeground}
							className={classNames(styles.sectionIcon, {
								[styles.sectionIconCollapsed]: isCollapsed,
							})}
						/>
					),
					title: isCollapsed ? (
						item.title
					) : (
						<div className={styles.sectionTitle} data-testid={key}>
							<Badge shimmer variant="accent" textVariant="smallBold">
								{item.title}
							</Badge>
						</div>
					),
					wrapperClassName: styles.sectionButton,
					accessoryView: (
						<List
							items={item.children
								.filter((child) =>
									matchesSearchTerm(child, searchTerm, services)
								)
								.map((item, index) => createAction(item, index, true))}
							className={classNames(styles.sectionContent, {
								[styles.sectionContentCollapsed]: isCollapsed,
							})}
						/>
					),
				};
			});
	}, [
		menuItems,
		onOpenUrl,
		onNextStage,
		primaryForeground,
		spacing,
		collapsedSections,
		searchTerm,
		services,
		serviceIntentsByServiceId,
		navigateToService,
	]);

	useEffect(() => {
		if (!menu) {
			return;
		}
		const menuItems = (menu?.menu_items ?? []) as MenuItem[];
		const collapsedItems: string[] = [];

		menuItems.forEach((item, index) => {
			if (!isMenuAction(item) && item.autocollapse && !searchTerm) {
				collapsedItems.push(`section-${index}`);
			}
		});

		setCollapsedSections(collapsedItems);
	}, [menu, searchTerm]);

	return (
		<>
			<Header {...(showBackButton ? { onBack: onPreviousStage } : {})} />
			<Main
				loading={fetching || isLoadingServices || fetchingServiceIntents}
				error={(error || serviceIntentsError)?.message}
			>
				<Stack
					direction="vertical"
					align="center"
					justify="space-between"
					style={{
						height: "100%",
						paddingBottom: !patientId && showLoginPrompt ? "80px" : undefined,
					}}
				>
					<OverflowContainer gap={spacing(2)}>
						<Stack direction="vertical" align="center" gap={spacing(3)}>
							{showSearchBar && (
								<SearchBar
									onSearch={setSearchTerm}
									data-testid="menu-search-input"
								/>
							)}
							<Hero
								title={menu?.title ?? "How can we help?"}
								subtitle={
									menu?.subtitle ??
									"Choose an option from the menu or use the search bar to find what you need."
								}
							/>
							{items.length > 0 && (
								<Stack
									direction="vertical"
									padding={{ start: width > 400 ? spacing(3) : spacing(1) }}
								>
									<List items={items} gap={spacing(2)} compact />
								</Stack>
							)}
							{items.length === 0 && (
								<Alert
									variant="warning"
									caption={
										searchTerm
											? "Oops! We couldn't find what you're looking for."
											: "Nothing to see here."
									}
									text={
										searchTerm
											? "We're sorry, but our search didn't return any results for your query. Please try a different search term or check your spelling."
											: "This menu is empty."
									}
								/>
							)}
						</Stack>
					</OverflowContainer>
					{showLoginPrompt && !patientId && (
						<ClickableBanner
							show={true}
							data-testid="sign-in-button"
							onClick={handleClickLogin}
						/>
					)}
				</Stack>
			</Main>
			<ConfirmStartOver
				show={!!showConfirmStartOver}
				onClose={() => setShowConfirmStartOver(false)}
				onConfirmation={handleConfirmation}
			/>
		</>
	);
}
