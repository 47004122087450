export const defaultWidgetSettings = {
	palette: {
		darkThemeColors: {
			grayscale: {
				"50": {
					red: 51,
					blue: 60,
					alpha: 1,
					green: 58,
				},
				"100": {
					red: 68,
					blue: 80,
					alpha: 1,
					green: 77,
				},
				"200": {
					red: 86,
					blue: 99,
					alpha: 1,
					green: 96,
				},
				"300": {
					red: 105,
					blue: 120,
					alpha: 1,
					green: 116,
				},
				"400": {
					red: 125,
					blue: 140,
					alpha: 1,
					green: 136,
				},
				"500": {
					red: 146,
					blue: 160,
					alpha: 1,
					green: 157,
				},
				"600": {
					red: 169,
					blue: 181,
					alpha: 1,
					green: 178,
				},
				"700": {
					red: 192,
					blue: 202,
					alpha: 1,
					green: 200,
				},
				"800": {
					red: 217,
					blue: 223,
					alpha: 1,
					green: 222,
				},
				"900": {
					red: 242,
					blue: 244,
					alpha: 1,
					green: 244,
				},
			},
			primaryAccent: {
				"50": {
					red: 31,
					blue: 104,
					alpha: 1,
					green: 56,
				},
				"100": {
					red: 41,
					blue: 136,
					alpha: 1,
					green: 74,
				},
				"200": {
					red: 56,
					blue: 165,
					alpha: 1,
					green: 92,
				},
				"300": {
					red: 76,
					blue: 191,
					alpha: 1,
					green: 112,
				},
				"400": {
					red: 101,
					blue: 212,
					alpha: 1,
					green: 132,
				},
				"500": {
					red: 127,
					blue: 228,
					alpha: 1,
					green: 153,
				},
				"600": {
					red: 156,
					blue: 240,
					alpha: 1,
					green: 174,
				},
				"700": {
					red: 185,
					blue: 248,
					alpha: 1,
					green: 196,
				},
				"800": {
					red: 213,
					blue: 252,
					alpha: 1,
					green: 219,
				},
				"900": {
					red: 241,
					blue: 254,
					alpha: 1,
					green: 243,
				},
			},
			secondaryAccent: {
				"50": {
					red: 52,
					blue: 95,
					alpha: 1,
					green: 74,
				},
				"100": {
					red: 73,
					blue: 134,
					alpha: 1,
					green: 104,
				},
				"200": {
					red: 90,
					blue: 164,
					alpha: 1,
					green: 128,
				},
				"300": {
					red: 104,
					blue: 190,
					alpha: 1,
					green: 148,
				},
				"400": {
					red: 117,
					blue: 213,
					alpha: 1,
					green: 166,
				},
				"500": {
					red: 154,
					blue: 222,
					alpha: 1,
					green: 187,
				},
				"600": {
					red: 184,
					blue: 230,
					alpha: 1,
					green: 206,
				},
				"700": {
					red: 210,
					blue: 219,
					alpha: 1,
					green: 223,
				},
				"800": {
					red: 234,
					blue: 247,
					alpha: 1,
					green: 239,
				},
				"900": {
					red: 244,
					blue: 251,
					alpha: 1,
					green: 247,
				},
			},
		},
		lightThemeColors: {
			grayscale: {
				"50": {
					red: 242,
					blue: 244,
					alpha: 1,
					green: 244,
				},
				"100": {
					red: 217,
					blue: 223,
					alpha: 1,
					green: 222,
				},
				"200": {
					red: 192,
					blue: 202,
					alpha: 1,
					green: 200,
				},
				"300": {
					red: 169,
					blue: 181,
					alpha: 1,
					green: 178,
				},
				"400": {
					red: 146,
					blue: 160,
					alpha: 1,
					green: 157,
				},
				"500": {
					red: 125,
					blue: 140,
					alpha: 1,
					green: 136,
				},
				"600": {
					red: 105,
					blue: 120,
					alpha: 1,
					green: 116,
				},
				"700": {
					red: 86,
					blue: 99,
					alpha: 1,
					green: 96,
				},
				"800": {
					red: 68,
					blue: 80,
					alpha: 1,
					green: 77,
				},
				"900": {
					red: 51,
					blue: 60,
					alpha: 1,
					green: 58,
				},
			},
			primaryAccent: {
				"50": {
					red: 241,
					blue: 254,
					alpha: 1,
					green: 243,
				},
				"100": {
					red: 213,
					blue: 252,
					alpha: 1,
					green: 219,
				},
				"200": {
					red: 185,
					blue: 248,
					alpha: 1,
					green: 196,
				},
				"300": {
					red: 156,
					blue: 240,
					alpha: 1,
					green: 174,
				},
				"400": {
					red: 127,
					blue: 228,
					alpha: 1,
					green: 153,
				},
				"500": {
					red: 101,
					blue: 212,
					alpha: 1,
					green: 132,
				},
				"600": {
					red: 76,
					blue: 191,
					alpha: 1,
					green: 112,
				},
				"700": {
					red: 56,
					blue: 165,
					alpha: 1,
					green: 92,
				},
				"800": {
					red: 41,
					blue: 136,
					alpha: 1,
					green: 74,
				},
				"900": {
					red: 31,
					blue: 104,
					alpha: 1,
					green: 56,
				},
			},
			secondaryAccent: {
				"50": {
					red: 244,
					blue: 251,
					alpha: 1,
					green: 247,
				},
				"100": {
					red: 234,
					blue: 247,
					alpha: 1,
					green: 239,
				},
				"200": {
					red: 210,
					blue: 219,
					alpha: 1,
					green: 223,
				},
				"300": {
					red: 184,
					blue: 230,
					alpha: 1,
					green: 206,
				},
				"400": {
					red: 154,
					blue: 222,
					alpha: 1,
					green: 187,
				},
				"500": {
					red: 117,
					blue: 213,
					alpha: 1,
					green: 166,
				},
				"600": {
					red: 104,
					blue: 190,
					alpha: 1,
					green: 148,
				},
				"700": {
					red: 90,
					blue: 164,
					alpha: 1,
					green: 128,
				},
				"800": {
					red: 73,
					blue: 134,
					alpha: 1,
					green: 104,
				},
				"900": {
					red: 52,
					blue: 95,
					alpha: 1,
					green: 74,
				},
			},
		},
	},
	darkThemeColors: {
		primaryShadow: {
			red: 0.08627450980392157,
			blue: 0.08627450980392157,
			alpha: 0.4,
			green: 0.08627450980392157,
		},
		accentBackground: {
			red: 0.4,
			blue: 1,
			alpha: 1,
			green: 0.5490196078431373,
		},
		accentForeground: {
			red: 0.9803921568627451,
			blue: 0.9803921568627451,
			alpha: 1,
			green: 0.9803921568627451,
		},
		dangerBackground: {
			red: 0.83,
			blue: 0.15,
			green: 0.15,
		},
		dangerForeground: {
			red: 1,
			blue: 1,
			green: 1,
		},
		primaryBackground: {
			red: 0.043137254901960784,
			blue: 0.15294117647058825,
			green: 0.12549019607843137,
		},
		primaryForeground: {
			red: 1,
			blue: 1,
			green: 1,
		},
		successBackground: {
			red: 0.8235294117647058,
			blue: 0.8235294117647058,
			green: 1,
		},
		successForeground: {
			red: 0.043137254901960784,
			blue: 0.15294117647058825,
			green: 0.12549019607843137,
		},
		warningBackground: {
			red: 0.9882352941176471,
			blue: 0.788235294117647,
			green: 0.9254901960784314,
		},
		warningForeground: {
			red: 0.043137254901960784,
			blue: 0.15294117647058825,
			green: 0.12549019607843137,
		},
		primaryPlaceholder: {
			red: 0.6,
			blue: 0.6,
			green: 0.6,
		},
	},
	lightThemeColors: {
		primaryShadow: {
			red: 0.043137254901960784,
			blue: 0.15294117647058825,
			alpha: 0.05,
			green: 0.12549019607843137,
		},
		accentBackground: {
			red: 0.5058823529411764,
			blue: 0.9490196078431372,
			alpha: 1,
			green: 0.615686274509804,
		},
		accentForeground: {
			red: 1,
			blue: 1,
			green: 1,
		},
		dangerBackground: {
			red: 0.83,
			blue: 0.15,
			green: 0.15,
		},
		dangerForeground: {
			red: 1,
			blue: 1,
			green: 1,
		},
		primaryBackground: {
			red: 1,
			blue: 1,
			green: 1,
		},
		primaryForeground: {
			red: 0.043137254901960784,
			blue: 0.15294117647058825,
			green: 0.12549019607843137,
		},
		successBackground: {
			red: 0.6941176470588235,
			blue: 0.6941176470588235,
			green: 0.984313725490196,
		},
		successForeground: {
			red: 0.043137254901960784,
			blue: 0.15294117647058825,
			green: 0.12549019607843137,
		},
		warningBackground: {
			red: 0.9882352941176471,
			blue: 0.788235294117647,
			green: 0.9254901960784314,
		},
		warningForeground: {
			red: 0.043137254901960784,
			blue: 0.15294117647058825,
			green: 0.12549019607843137,
		},
		primaryPlaceholder: {
			red: 0.4,
			blue: 0.4,
			green: 0.4,
		},
	},
};
