import { useMutation, useQuery } from "urql";

import {
	useOrganizationContext,
	usePatientContext,
	StripeProvider,
} from "@app/components/Contexts";
import { PaymentType } from "@app/components/Contexts/OrganizationContext/OrganizationContext";
import { Loader } from "@shared/components/Loader";
import {
	WidgetGetServiceIntentForPharmacyDocument,
	WidgetGetStripeSecretDocument,
	WidgetSetServiceIntentStatusToPaymentSubmittedDocument,
} from "@shared/types/graphql";

import { BypassForm } from "./BypassForm";
import { PaytientForm } from "./PaytientForm";
import { PurchaseForm } from "./PurchaseForm";

interface Props {
	serviceIntentId: string;
	buttonText?: string;
	prevButtonText?: string;
	buttonDisabled?: boolean;
	onBack?(): void;
	onSuccess?: () => void;
}

export function Purchase({
	serviceIntentId,
	buttonText,
	prevButtonText,
	buttonDisabled,
	onBack,
	onSuccess,
}: Props) {
	const { patientId } = usePatientContext();
	const { paymentType, bypassPrepayment } = useOrganizationContext();

	const [, markPaymentSubmitted] = useMutation(
		WidgetSetServiceIntentStatusToPaymentSubmittedDocument
	);

	const [{ data: serviceIntentData, fetching: serviceIntentDataFetching }] =
		useQuery({
			query: WidgetGetServiceIntentForPharmacyDocument,
			variables: { serviceIntentId },
			pause: !serviceIntentId,
		});

	const serviceIntent = serviceIntentData?.service_intent_by_pk;
	const serviceId = serviceIntent?.service_id as string;
	const [{ data }] = useQuery({
		// https://stripe.com/docs/payments/accept-a-payment?platform=web&ui=elements#web-collect-payment-details
		query: WidgetGetStripeSecretDocument,
		variables: {
			serviceId,
		},
		pause:
			paymentType === PaymentType.Paytient || bypassPrepayment || !serviceId,
	});

	const clientSecret = data?.getStripeSecret?.clientSecret;

	if (
		(!clientSecret &&
			paymentType !== PaymentType.Paytient &&
			!bypassPrepayment) ||
		!patientId ||
		serviceIntentDataFetching
	) {
		return <Loader />;
	}

	if (bypassPrepayment) {
		return (
			<BypassForm
				buttonText={buttonText}
				prevButtonText={prevButtonText}
				buttonDisabled={buttonDisabled}
				onBack={onBack}
				onSuccess={() => {
					markPaymentSubmitted({
						serviceIntentId,
					});

					onSuccess?.();
				}}
			/>
		);
	}
	const Form =
		paymentType === PaymentType.Paytient ? PaytientForm : PurchaseForm;
	return paymentType === PaymentType.Paytient ? (
		<Form
			serviceId={serviceId}
			buttonText={buttonText}
			prevButtonText={prevButtonText}
			buttonDisabled={buttonDisabled}
			onBack={onBack}
			onSuccess={() => {
				markPaymentSubmitted({
					serviceIntentId,
				});

				onSuccess?.();
			}}
		/>
	) : (
		<StripeProvider clientSecret={clientSecret}>
			<Form
				serviceId={serviceId}
				buttonText={buttonText}
				prevButtonText={prevButtonText}
				buttonDisabled={buttonDisabled}
				onBack={onBack}
				onSuccess={() => {
					markPaymentSubmitted({
						serviceIntentId,
					});

					onSuccess?.();
				}}
			/>
		</StripeProvider>
	);
}
