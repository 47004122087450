import type { ReactNode } from "react";
import { useLayoutEffect, useState } from "react";

import { useTheme } from "@shared/hooks/useTheme";

import styles from "./OverflowContainer.module.css";

const calculateScrollGradientOpacity = (childRef: HTMLDivElement) => {
	const distanceToStartHiding = 50;
	const distanceFromBottom =
		childRef.scrollHeight - childRef.scrollTop - childRef.clientHeight;
	return Math.min(1, distanceFromBottom / distanceToStartHiding);
};

interface Props {
	gap?: number;
	children: ReactNode;
}

export const OverflowContainer = ({ gap = 0, children }: Props) => {
	const { primaryBackground } = useTheme();
	const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
	const [childRef, setChildRef] = useState<HTMLDivElement | null>(null);
	const [scrollGradientOpacity, setScrollGradientOpacity] = useState(0);
	useLayoutEffect(() => {
		if (!containerRef || !childRef) return;

		// Initialize the scroll gradient opacity.
		setScrollGradientOpacity(calculateScrollGradientOpacity(childRef));
		const onResize = () => {
			setScrollGradientOpacity(calculateScrollGradientOpacity(childRef));
		};

		// Setup resize observer.
		const resizeObserver = new ResizeObserver(onResize);
		resizeObserver.observe(containerRef);

		// Setup window resize listener.
		window.addEventListener("resize", onResize);

		return () => {
			resizeObserver.disconnect();
			window.removeEventListener("resize", onResize);
		};
	}, [containerRef, childRef]);

	return (
		<div ref={setContainerRef} className={styles.overflowContainer}>
			<div
				ref={setChildRef}
				className={styles.overflowContainerInner}
				style={{ paddingBottom: gap }}
				onScroll={() => {
					if (!childRef) return;
					setScrollGradientOpacity(calculateScrollGradientOpacity(childRef));
				}}
			>
				{children}
			</div>
			<div
				className={styles.scrollGradient}
				style={{
					background: `linear-gradient(to bottom, ${
						primaryBackground.withAlpha(0).color
					} 0%, ${primaryBackground.withAlpha(0.5).color} 50%, ${
						primaryBackground.color
					} 100%)`,
					opacity: scrollGradientOpacity,
				}}
			/>
		</div>
	);
};
