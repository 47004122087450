import { forwardRef } from "react";

import classNames from "classnames";
import InputMask from "react-input-mask";

import type { Props as InputProps } from "@shared/components/Input/Input";

import styles from "./MaskedInput.module.css";

interface Props extends InputProps {
	mask?: string;
}

export const MaskedInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
	const { mask, className, ...rest } = props;
	const isEmpty = !props?.value;
	const propRef = ref;

	if (mask) {
		return (
			// @ts-ignore react-preact types mismatch
			<InputMask
				inputRef={ref as React.Ref<HTMLInputElement>}
				mask={mask}
				placeholder={props.placeholder as string}
				maskPlaceholder={props.placeholder as string}
				// @ts-ignore react-preact types mismatch
				className={classNames(className, {
					[styles.placeholder]: isEmpty,
				})}
				{...rest}
			/>
		);
	}

	return <input ref={ref || propRef} className={className} {...rest} />;
});
