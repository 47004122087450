import { debug } from "./debug";

export enum PubSubMessage {
	Storage = "Storage",
	ExitWidget = "ExitWidget",
	OpenWidget = "OpenWidget",
	Ready = "Ready",
	Intitialize = "Intitialize",
	Complete = "Complete",
	OpenUrl = "OpenUrl",
	MenuData = "MenuData",
	SetSessionStorage = "SetSessionStorage",
	CleanCache = "CleanCache",
}

export type PubSubPayload = {
	[PubSubMessage.Storage]: {
		key: string;
	};
	[PubSubMessage.ExitWidget]: never;
	[PubSubMessage.OpenWidget]: never;
	[PubSubMessage.Ready]: never;
	[PubSubMessage.Intitialize]: {
		lightTheme: string;
		darkTheme: string;
	};
	[PubSubMessage.Complete]: never;
	[PubSubMessage.OpenUrl]: string;
	[PubSubMessage.MenuData]: {
		__typename?: "menu";
		id: string;
		name: string;
		menu_items: unknown;
		show_search_bar: boolean;
		show_login_prompt: boolean;
		status: string;
	};
	[PubSubMessage.SetSessionStorage]: {
		key: string;
		value: string;
	};
	[PubSubMessage.CleanCache]: never;
};

export type PubSubEventData<MessageType extends PubSubMessage> = {
	type: MessageType;
	payload: PubSubPayload[MessageType];
};

const subscribers = new Map<
	PubSubMessage,
	((payload: PubSubEventData<PubSubMessage>) => void)[]
>();

export const publish = <MessageType extends PubSubMessage>(
	recipient: Window | null | undefined,
	type: MessageType,
	payload?: PubSubPayload[MessageType]
) => {
	debug("info", {
		context: "publish",
		message: "Publishing message",
		info: {
			type,
			recipient,
			payload,
		},
	});
	recipient?.postMessage({ type, payload }, "*");
};

export const subscribe = <MessageType extends PubSubMessage>(
	type: MessageType,
	callback: (data: PubSubEventData<MessageType>) => void
) => {
	subscribers.set(
		type,
		(subscribers.get(type) ?? []).concat(
			callback as (data: PubSubEventData<PubSubMessage>) => void
		)
	);
};

export const unsubscribe = <MessageType extends PubSubMessage>(
	type: MessageType,
	callback: (data: PubSubEventData<MessageType>) => void
) => {
	const callbacks = subscribers.get(type) ?? [];
	callbacks.filter((cb) => callback !== cb);
	subscribers.set(type, callbacks);
};

/**
 * This event listener should run once, and never again.
 */
let listener;
if (!listener && typeof window !== "undefined") {
	listener = window.addEventListener(
		"message",
		(event: MessageEvent<PubSubEventData<PubSubMessage>>) => {
			const isValidEvent = Object.values(PubSubMessage).includes(
				event.data.type
			);
			if (!isValidEvent) {
				return;
			}

			const data = event.data;
			subscribers.get(data.type)?.forEach((fn) => fn(data));
		}
	);
}
