import { useContextualRedirect } from "@app/components/Auth/useContextualRedirect";
import { useAuthContext } from "@app/components/Contexts";
import { ConfirmLogin } from "@app/components/Forms/ConfirmLogin";
import { Header } from "@app/components/Header";
import { Main } from "@app/components/Main";
import { Hero } from "@shared/components/Hero";
import { Stack } from "@shared/components/Stack";
import { useTheme } from "@shared/hooks/useTheme";
import type { AuthStage, AuthStageProps } from "@shared/types/AuthStages";
import { formatPhoneNumber } from "@shared/utilities/formatPhoneNumber";

import styles from "./AuthConfirm.module.css";

export function AuthConfirm({
	props: {
		serviceId,
		serviceIntentId,
		phoneNumber,
		birthDate,
		code,
		externalId,
	},
	onPreviousStage,
}: AuthStageProps<AuthStage.LoginCode>) {
	const { patientId } = useAuthContext();
	const { primaryBackground, spacing } = useTheme();
	const { fetching, error } = useContextualRedirect(!!patientId, {
		serviceIntentId,
	});

	return (
		<>
			<Header onBack={onPreviousStage} />
			<Main loading={fetching} error={error?.message}>
				<div
					className={styles.authConfirm}
					style={{ backgroundColor: primaryBackground.withAlpha(0.8).color }}
				>
					<Stack
						direction="vertical"
						align="center"
						gap={spacing(5)}
						padding={spacing(3)}
					>
						<Hero
							title="We just texted you."
							subtitle={`Please enter the verification code we just sent to ${
								phoneNumber
									? formatPhoneNumber(phoneNumber, "pretty")
									: "your registered phone number"
							}`}
						/>
						<ConfirmLogin
							phoneNumber={phoneNumber ? `${phoneNumber}` : undefined}
							code={code ? `${code}` : undefined}
							birthDate={birthDate}
							serviceId={serviceId}
							externalId={externalId}
							onBack={onPreviousStage}
						/>
					</Stack>
				</div>
			</Main>
		</>
	);
}
