import { useCallback, useEffect } from "preact/hooks";
import { Survey } from "survey-react-ui";
import { useQuery } from "urql";

import {
	useAuthContext,
	usePatientContext,
	useWidgetContext,
} from "@app/components/Contexts";
import { Header } from "@app/components/Header";
import { Main } from "@app/components/Main";
import { RouteMap } from "@app/constants/RouteMap";
import { useAuthRequired } from "@shared/hooks/useAuthRequired";
import { useNavigation } from "@shared/hooks/useNavigation";
import { AuthStage } from "@shared/types/AuthStages";
import type { IntakeStageProps } from "@shared/types/IntakeStages";
import { IntakeStage } from "@shared/types/IntakeStages";
import { WidgetGetLeadQuestionnaireDocument } from "@shared/types/graphql";

import { useSurvey } from "../Questions/useSurvey";
import { useSurveyCSS } from "../Questions/useSurveyCSS";

export function Questionnaire({
	props: { questionnaireId },
	onNextStage,
}: IntakeStageProps<IntakeStage.Questionnaire>) {
	const { widgetId, closeWidget } = useWidgetContext();
	const { patientId } = usePatientContext();
	const { push } = useNavigation();
	const { temporaryPatientId, getTemporaryPatient } = useAuthContext();

	// Load the questionnaire from the server.
	const [{ data, fetching: fetchingSurvey, error: errorFetchingSurvey }] =
		useQuery({
			query: WidgetGetLeadQuestionnaireDocument,
			variables: {
				questionnaireId,
			},
			pause: !questionnaireId,
		});
	const questionnaire = data?.questionnaire_by_pk;

	const { survey } = useSurvey({
		questionnaire,
		patientId,
		onComplete: useCallback(
			(isDisqualified, success) => {
				if (success) {
					success("Thanks for Submitting!");
					// Automatically close the widget after 5 seconds
					setTimeout(() => closeWidget(), 5000);
				}
				if (!patientId) {
					push(
						RouteMap.AuthStages,
						{
							stageId: AuthStage.Start,
							widgetId,
						},
						{
							questionnaireId,
							isDisqualified,
						}
					);
					return;
				}
			},
			[patientId, closeWidget, push, widgetId, questionnaireId]
		),
		onNavigate: useCallback(
			async (url: string) => {
				let queryParams = new URLSearchParams(url.split("?")[1]);
				const widgetUrl = decodeURIComponent(
					queryParams.get("widgetUrl") || url
				);
				queryParams = new URLSearchParams(widgetUrl.split("?")[1]);
				if (!widgetUrl) return;

				// Go to a questionnaire
				if (widgetUrl.includes("/intake/lead")) {
					const newQuestionnaireId = queryParams.get("questionnaireId");
					onNextStage(IntakeStage.Questionnaire, {
						questionnaireId: newQuestionnaireId || "",
					});
					return;
				}
				// Go to a service
				if (
					widgetUrl.includes("/intake/services") ||
					widgetUrl.includes("/intake/questions")
				) {
					const serviceId = queryParams.get("serviceId");
					onNextStage(IntakeStage.Questions, {
						serviceId: serviceId || "",
						isSecondaryQuestionStage: false,
					});
					return;
				}
				// Go to a menu
				if (widgetUrl.includes("/intake/menu")) {
					const menuId = queryParams.get("menuId");
					onNextStage(IntakeStage.Menu, {
						menuId: menuId || "",
					});
					return;
				}
				// Redirect to a new URL
				const newTab = queryParams.get("new_tab");
				const newWindow = queryParams.get("new_window");
				const stayInWidget = queryParams.get("stay_in_widget");
				if (newTab) {
					window.open(widgetUrl, "_blank");
					return;
				}
				if (newWindow) {
					window.open(
						widgetUrl,
						"_blank",
						`width=${window.screen.width},height=${window.screen.height}`
					);
					return;
				}
				if (stayInWidget) {
					window.open(widgetUrl, "_self");
					return;
				}

				window.parent.location.href = widgetUrl;
			},
			[onNextStage]
		),
	});

	const surveyCSSData = useSurveyCSS();
	const needsAuth = useAuthRequired(false);
	const isLoading = needsAuth || fetchingSurvey;
	const hasError = (!isLoading && !survey) || !!errorFetchingSurvey;

	useEffect(() => {
		const hasPatientId = Boolean(patientId || temporaryPatientId);
		const isLeadIntakePath = window.location.pathname.includes("/intake/lead");

		if (isLeadIntakePath && !hasPatientId) {
			getTemporaryPatient();
		}
	}, [patientId, temporaryPatientId, getTemporaryPatient]);

	return (
		<>
			<Header />
			<Main
				loading={isLoading}
				error={hasError ? "Failed to load questionnaire." : undefined}
			>
				{!hasError && <Survey model={survey} css={surveyCSSData} />}
			</Main>
		</>
	);
}
