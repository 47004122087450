import type { ComponentChildren } from "preact";
import { createContext } from "preact";

import { useContext, useState } from "preact/hooks";
import { Provider as UrqlProvider } from "urql";

import { makeClient } from "@shared/utilities/gql/getGraphqlClient";
import { noop } from "@shared/utilities/noop";

type Props = {
	children: ComponentChildren;
};

type ClientState = {
	resetClient: () => void;
};

const ClientContext = createContext<ClientState>({
	resetClient: noop,
});

export function ClientProvider({ children }: Props) {
	const [client, setClient] = useState(makeClient());

	return (
		<ClientContext.Provider
			value={{
				resetClient: () => setClient(makeClient()),
			}}
		>
			<UrqlProvider value={client}>{children}</UrqlProvider>
		</ClientContext.Provider>
	);
}

export const useClient = () => useContext(ClientContext);
