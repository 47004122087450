import { useEffect, useState } from "preact/hooks";
import { useMutation, useQuery } from "urql";

import { Form } from "@shared/components/Form";
import { InputGroup } from "@shared/components/InputGroup";
import { InputZipCode } from "@shared/components/InputZipCode";
import { Stack } from "@shared/components/Stack";
import { useFirst } from "@shared/hooks/useFirst";
import { useTheme } from "@shared/hooks/useTheme";
import type { State_Lookup_Enum } from "@shared/types/graphql";
import {
	WidgetGetPatientAddressDocument,
	WidgetAddPatientAddressDocument,
	WidgetUpdatePatientAddressDocument,
} from "@shared/types/graphql";

interface Props {
	title?: string;
	buttonText?: string;
	onSuccess?: () => void;
	showSuccessMessage?: boolean;
	shouldDisableIfUnchanged?: boolean;
}

export function EditAddress({
	title,
	buttonText = "Save",
	onSuccess,
	showSuccessMessage,
	shouldDisableIfUnchanged,
}: Props) {
	const { spacing } = useTheme();
	const [addressLine1, setAddressLine1] = useState("");
	const [addressLine2, setAddressLine2] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [zipCode, setZipCode] = useState("");
	const isMissingRequiredInfo = !addressLine1 || !city || !state || !zipCode;

	const [hasModifiedAddress, setHasModifiedAddress] = useState(false);
	const [successMessage, setSuccessMessage] = useState<string | null>(null);

	// Fetch existing data from the server.
	const [{ data, fetching, error }] = useQuery({
		query: WidgetGetPatientAddressDocument,
		requestPolicy: "network-only",
	});
	const address = useFirst(data?.patient_address);
	const [addressId, setAddressId] = useState<string | null>(null);
	useEffect(() => {
		if (!address) return;

		setAddressId(address.id);
		setAddressLine1(address.line_1 ?? "");
		setAddressLine2(address.line_2 ?? "");
		setCity(address.city ?? "");
		setState(address.state ?? "");
		setZipCode(`${address.zip}` ?? "");
		setHasModifiedAddress(false);
	}, [address]);
	useEffect(() => {
		setHasModifiedAddress(
			addressLine1 !== (address?.line_1 ?? "") ||
				addressLine2 !== (address?.line_2 ?? "") ||
				city !== (address?.city ?? "") ||
				state !== (address?.state ?? "") ||
				zipCode !== `${address?.zip ?? ""}`
		);
	}, [addressLine1, addressLine2, city, state, zipCode, address]);

	const [, addPatientAddress] = useMutation(WidgetAddPatientAddressDocument);
	const [, updatePatientAddress] = useMutation(
		WidgetUpdatePatientAddressDocument
	);
	const onSubmitAddress = async () => {
		setSuccessMessage(null);
		let newAddress;
		let error;
		const payload = {
			line_1: addressLine1,
			line_2: addressLine2,
			city,
			state: state as State_Lookup_Enum,
			country: "USA",
			zipCode,
		};
		if (addressId) {
			const result = await updatePatientAddress({
				id: addressId,
				...payload,
			});
			newAddress = result.data?.update_patient_address?.returning[0];
			error = result.error;
			if (result.error || !newAddress) {
				throw new Error(
					result.error?.message ??
						"An unknown error occurred while updating your address."
				);
			}
		} else {
			const result = await addPatientAddress(payload);
			newAddress = result.data?.insert_patient_address_one;
			error = result.error;
			setAddressId(newAddress?.id ?? null);
		}

		if (error || !newAddress) {
			throw new Error(
				error?.message ??
					"An unknown error occurred while updating your address."
			);
		}

		onSuccess?.();
		if (showSuccessMessage) {
			setSuccessMessage("We've successfully updated your address.");
		}
	};

	return (
		<Form
			title={title}
			errorMessage={error?.message}
			successMessage={successMessage}
			submitButtonProps={{
				text: buttonText,
				disabled:
					fetching ||
					isMissingRequiredInfo ||
					(shouldDisableIfUnchanged && !hasModifiedAddress),
			}}
			onSubmit={onSubmitAddress}
		>
			{({ isSubmitting }) => (
				<>
					<InputGroup
						type="text"
						placeholder="123 Jubily Way"
						label="Address Line 1"
						value={addressLine1}
						onInput={setAddressLine1}
						autocomplete="address-line1"
						disabled={fetching || isSubmitting}
						required
					/>
					<InputGroup
						type="text"
						placeholder="Apartment 2B"
						label="Address Line 2"
						value={addressLine2}
						onInput={setAddressLine2}
						autocomplete="address-line2"
						disabled={fetching || isSubmitting}
					/>
					<Stack
						direction="horizontal"
						align="center"
						template="65% 1fr"
						gap={spacing(2)}
					>
						<InputGroup
							type="text"
							placeholder="Los Angeles"
							label="City"
							value={city}
							onInput={setCity}
							autocomplete="address-level2"
							disabled={fetching || isSubmitting}
						/>
						<InputGroup
							type="text"
							placeholder="CA"
							label="State"
							value={state}
							onInput={(newValue) => setState(newValue.toUpperCase().trim())}
							autocomplete="address-level1"
							disabled={fetching || isSubmitting}
							maxLength={2}
							required
						/>
					</Stack>
					<InputZipCode
						label="ZIP Code"
						value={zipCode}
						onInput={setZipCode}
						disabled={fetching || isSubmitting}
						maxLength={5}
						required
					/>
				</>
			)}
		</Form>
	);
}
