import { useCallback } from "react";

import {
	useOrganizationContext,
	useWidgetContext,
} from "@app/components/Contexts";
import { RouteMap } from "@app/constants/RouteMap";
import { useNavigation } from "@shared/hooks/useNavigation";
import type { IntakeStageTransitionProps } from "@shared/types/IntakeStages";
import { IntakeStage } from "@shared/types/IntakeStages";

export function useIntakeNavigation() {
	const { widgetId } = useWidgetContext();
	const { push, replace } = useNavigation();
	const { defaultMenuId } = useOrganizationContext();

	const navigateToIntake = useCallback(
		<StageId extends IntakeStage>(
			stageId: StageId,
			props: IntakeStageTransitionProps[StageId],
			replaceHistory?: boolean
		) => {
			if (!widgetId) return;
			const navigate = replaceHistory ? replace : push;

			navigate(
				RouteMap.IntakeStages,
				{
					widgetId,
					stageId,
				},
				props,
				true
			);
		},
		[push, replace, widgetId]
	);

	const navigateToIntakeStart = useCallback(() => {
		if (defaultMenuId) {
			navigateToIntake(IntakeStage.Menu, { menuId: defaultMenuId });
		} else {
			navigateToIntake(IntakeStage.Services, {});
		}
	}, [defaultMenuId, navigateToIntake]);

	return {
		navigateToIntake,
		navigateToIntakeStart,
	};
}
