import { useEffect } from "react";

import { matchPath } from "react-router-dom";

import { useIntakeStages } from "@app/components/Intake/IntakeStageContext";
import { RouteMap } from "@app/constants/RouteMap";
import { SESSION_STORAGE_KEYS } from "@shared/constants/storage";
import { useLocation } from "@shared/hooks/useLocation";
import { PubSubMessage, usePublisher } from "@shared/hooks/usePubSub";
import { IntakeStage } from "@shared/types/IntakeStages";
import { BrowserStorage } from "@shared/utilities/BrowserStorage";
import { safeSessionStorage } from "@shared/utilities/safeLocalStorage";

const determineIntakeFlow = (currentStage?: IntakeStage, path?: string) => {
	if (!currentStage || !path) return false;

	if (
		matchPath(RouteMap.IntakeStages, path)?.pattern.path ===
			RouteMap.IntakeStages &&
		(currentStage === IntakeStage.Services || currentStage === IntakeStage.Menu)
	) {
		return false;
	}

	if (
		matchPath(RouteMap.AuthStages, path) ||
		matchPath(RouteMap.VisitChannel, path) ||
		matchPath(RouteMap.VisitChannelDetails, path) ||
		matchPath(RouteMap.Visits, path)
	) {
		return false;
	}

	return true;
};

export function useIntakeFlow() {
	const { currentStage } = useIntakeStages();
	const { path } = useLocation();
	const setSessionStorage = usePublisher({
		type: PubSubMessage.SetSessionStorage,
		recipient: window.top,
	});

	useEffect(() => {
		if (!BrowserStorage?.sessionStorage) return;

		const isIntakeFlow = determineIntakeFlow(currentStage, path);

		safeSessionStorage.setItem(
			SESSION_STORAGE_KEYS.isIntakeFlow,
			isIntakeFlow.toString()
		);

		// Send the updated value to container
		setSessionStorage({
			key: SESSION_STORAGE_KEYS.isIntakeFlow,
			value: isIntakeFlow.toString(),
		});
	}, [currentStage, path, setSessionStorage]);

	return { isIntakeFlow: determineIntakeFlow(currentStage, path) };
}
