import type { PropsWithChildren } from "react";

import classNames from "classnames";

import { useDrawer } from "./Drawer.hooks";

export type DrawerProps = PropsWithChildren<{
	show: boolean;
	direction?: "left" | "right" | "bottom";
	width?: string;
	exceptionIds?: `#${string}`[];
	containerClassname?: string;
	onToggle(): void;
}>;

const getPositionClass = (width?: string) => ({
	left: `top-0 bottom-0 left-0 ${width ?? "w-4/5"}`,
	right: `top-0 bottom-0 right-0 ${width ?? "w-4/5"}`,
	bottom: "left-0 right-0 bottom-0 w-full min-h-fit",
});

const getTransformClass = (isVisible: boolean) => ({
	left: isVisible ? "translate-x-0" : "-translate-x-full",
	right: isVisible ? "translate-x-0" : "translate-x-full",
	bottom: isVisible ? "-translate-y-0" : "translate-y-full",
});

export function Drawer({
	show,
	width,
	children,
	exceptionIds,
	containerClassname,
	direction = "bottom",
	onToggle,
}: DrawerProps) {
	const { ref } = useDrawer({ show, exceptionIds, onToggle });
	const transformClass = getTransformClass(show);
	const positionClass = getPositionClass(width);

	return (
		<>
			<div
				className={`fixed left-0 top-0 w-full h-full transition-opacity duration-300 ease-in-out z-[150] ${
					show ? "opacity-50 bg-gray-600" : "opacity-0 pointer-events-none"
				}`}
			/>
			<div
				ref={ref}
				className={classNames(
					`fixed w-full max-w-[520px] m-auto bg-[var(--primary-background)] text-[var(--primary-foreground)] shadow-lg overflow-auto transition-all duration-300 ease-in-out z-[200] ${positionClass[direction]} ${transformClass[direction]}`,
					{
						["rounded-tl-2xl rounded-tr-2xl shadow-2xl"]:
							direction === "bottom",
					},
					containerClassname
				)}
			>
				{children}
			</div>
		</>
	);
}
