import { createContext } from "react";

import { noop } from "@shared/utilities/noop";

import type { Theme } from "./Theme";

type ThemeContextType = Theme & {
	spacing(value: number): number;
	themes: {
		light: Theme;
		dark: Theme;
	};
};

export const ThemeContext = createContext<ThemeContextType>({
	spacing: noop,
} as unknown as ThemeContextType);
