import type { Service } from "@shared/types/graphql";

import type { MenuAction, MenuItem } from "./types";

export const isMenuAction = (item: MenuItem): item is MenuAction =>
	"action_type" in item;

export function matchesSearchTerm(
	item: MenuItem,
	searchTerm: string,
	services: Service[]
) {
	const lowerCaseSearchTerm = searchTerm.toLowerCase();

	let matches = checkMatch(item.title, lowerCaseSearchTerm);

	if (isMenuAction(item)) {
		matches = matches || checkMatch(item.description, lowerCaseSearchTerm);

		const linkedService = services.find(
			(service) => service.id === item.action_payload
		);

		if (linkedService) {
			matches =
				matches ||
				checkMatch(linkedService.name, lowerCaseSearchTerm) ||
				checkMatch(linkedService.description, lowerCaseSearchTerm);
		}
	} else {
		matches =
			matches ||
			item.children.some((subItem) =>
				matchesSearchTerm(subItem, searchTerm, services)
			);
	}

	return matches;
}

function checkMatch(text: string | undefined, lowerCaseSearchTerm: string) {
	return text?.toLowerCase().includes(lowerCaseSearchTerm);
}
