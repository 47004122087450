import type { Props as IconProps } from "@shared/components/Icon";
import { useTheme } from "@shared/hooks/useTheme";

export function useIconStyles(props: IconProps, fill?: boolean) {
	const { primaryForeground } = useTheme();
	return fill
		? {
				fill: primaryForeground,
				...props,
		  }
		: {
				stroke: primaryForeground,
				strokeWidth: 40,
				...props,
		  };
}
