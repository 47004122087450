import classNames from "classnames";

import { CONTENT_MAX_WIDTH } from "@app/components/Main/Main";
import type { Props as IconProps } from "@shared/components/Icon";
import { Stack } from "@shared/components/Stack";
import { useWindowSize } from "@shared/hooks/useWindowSize";

import { useHeaderConfig } from "./Header.hooks";
import styles from "./Header.module.css";

interface Props {
	className?: string;
	hideMiddleButton?: boolean;
	backButtonIconProps?: IconProps;
	onBack?: () => void;
}

export function Header({
	className,
	hideMiddleButton,
	backButtonIconProps,
	onBack,
}: Props) {
	const { width } = useWindowSize();
	const { buttonConfig } = useHeaderConfig();
	const showMiddleButton = !hideMiddleButton && buttonConfig.middle.length > 0;

	return (
		<header className={classNames(styles.header, className)}>
			<Stack
				direction="horizontal"
				template="1fr auto 1fr"
				align="center"
				justify="center"
				style={{
					width: Math.min(CONTENT_MAX_WIDTH, width),
					margin: "auto",
					padding:
						"calc(4 * var(--spacing-multiplier)) calc(4 * var(--spacing-multiplier)) calc(3 * var(--spacing-multiplier))",
				}}
			>
				<div className={styles.headerStart}>
					{buttonConfig.left.map((ButtonComponent) => {
						if (ButtonComponent.componentName === "BackButton" && onBack) {
							return (
								<ButtonComponent
									key={ButtonComponent.name}
									onBack={onBack}
									iconProps={backButtonIconProps}
								/>
							);
						}

						return (
							ButtonComponent.componentName !== "BackButton" && (
								<ButtonComponent key={ButtonComponent.name} />
							)
						);
					})}
				</div>
				{showMiddleButton ? (
					buttonConfig.middle.map((ButtonComponent) => (
						<ButtonComponent key={ButtonComponent.name} />
					))
				) : (
					<div />
				)}
				<div className={styles.headerEnd}>
					{buttonConfig.right.map((ButtonComponent) => (
						<ButtonComponent key={ButtonComponent.name} />
					))}
				</div>
			</Stack>
		</header>
	);
}
