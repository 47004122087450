import * as Sentry from "@sentry/browser";
import { type User } from "@sentry/browser";
import { type Integration } from "@sentry/types";
import {
	GQL_ENDPOINT,
	isDev,
	SENTRY_DSN,
} from "@shared/constants/environments";

// Environment based on domain
const domainPerEnvironment: Record<string, string> = {
	"app.catchhealth.com": "production",
	"app-dev.catchhealth.com": "development",
	"app-demo.catchhealth.com": "staging",
};

export function initSentry() {
	if (!SENTRY_DSN) {
		return;
	}

	const integrations: Integration[] = [new Sentry.BrowserTracing()];

	const isProd =
		!isDev && domainPerEnvironment[window.location.hostname] === "production";

	integrations.push(
		new Sentry.Replay({
			networkDetailAllowUrls: [GQL_ENDPOINT],
			networkRequestHeaders: [
				"x-hasura-user-id",
				"x-hasura-app-version",
				"x-hasura-role",
				"x-hasura-widget-id",
				"x-hasura-temp-user-id",
				"x-hasura-org-id",
				"x-hasura-suborg-id",
			],
		})
	);

	Sentry.init({
		dsn: SENTRY_DSN,
		release: __APP_VERSION__,
		replaysSessionSampleRate: isProd ? 1.0 : 0.33,
		replaysOnErrorSampleRate: 1.0,
		environment: isDev
			? "local"
			: domainPerEnvironment[window.location.hostname] ?? "preview",
		integrations,
		tracesSampleRate: 1.0,
	});
}

export function configureSentryUser(user: User | null) {
	Sentry.configureScope((scope) => {
		scope.setUser(user);
	});
}
