import { createContext } from "preact";

import { noop } from "@shared/utilities/noop";

export type ShowConsentProps = {
	consentFormId: string;
	practitionerIds: string[];
	title: string;
};

interface ConsentProviderType {
	encounterId: string | null;
	requiresConsent: boolean;
	approvedAtMap: { [key: string]: number | null } | null;
	showConsent: (props: ShowConsentProps) => void;
}

export const ConsentContext = createContext<ConsentProviderType>({
	encounterId: null,
	requiresConsent: false,
	approvedAtMap: null,
	showConsent: noop,
});
