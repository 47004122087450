import { useEffect, useState } from "preact/hooks";
import { useMutation, useQuery } from "urql";

import { useFeatureFlag } from "@app/components/Contexts/LaunchDarklyContext";
import type { Address } from "@shared/components/Input/Input";
import type { AddressInput } from "@shared/types/graphql";
import {
	WidgetGetPatientAddressesDocument,
	WidgetUpdateAddressesDocument,
	Address_Types,
} from "@shared/types/graphql";

import {
	addressToAddressInput,
	isValidUSState,
	validateAddress,
} from "./AddressPicker.utils";

type Params = {
	showAsBilling?: boolean;
	showShippingAddress?: boolean;
	onSuccess?: () => void;
	onChange?(address: AddressInput | null): void;
};

export const useAddressPicker = ({
	showAsBilling,
	showShippingAddress,
	onChange,
	onSuccess,
}: Params) => {
	const [homeAddress, setHomeAddress] = useState<Address | null>(null);
	const [billingAddress, setBillingAddress] = useState<Address | null>(null);
	const [shippingAddress, setShippingAddress] = useState<Address | null>(null);
	const isUSStateValidationDisabled = useFeatureFlag(
		"usStateValidationDisabled"
	);
	const [shouldUseSameAddress, setShouldUseSameAddress] =
		useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);
	const [{ data, fetching }] = useQuery({
		query: WidgetGetPatientAddressesDocument,
		requestPolicy: "network-only",
	});
	const [, updateAddresses] = useMutation(WidgetUpdateAddressesDocument);
	const isMissingRequiredInfo = showAsBilling
		? !homeAddress
		: !homeAddress || (!shippingAddress && !shouldUseSameAddress);
	const isInvalidUSState =
		Boolean(shippingAddress && !isValidUSState(shippingAddress.state)) ||
		Boolean(homeAddress && !isValidUSState(homeAddress.state));

	const onSubmitInfo = async () => {
		if (isMissingRequiredInfo) {
			throw new Error("Error: Missing required address information.");
		}

		validateAddress(homeAddress as Address);
		if (!shouldUseSameAddress) {
			validateAddress(shippingAddress as Address);
		}

		if (!isUSStateValidationDisabled && isInvalidUSState) {
			throw new Error(
				"Error: Invalid state selected. Please select a valid US state (e.g., 'CA' for California)."
			);
		}

		const homeAddressInput = addressToAddressInput(
			homeAddress as Address,
			Address_Types.Home
		);
		const shippingAddressInput = shouldUseSameAddress
			? { ...homeAddressInput, type: Address_Types.Shipping }
			: addressToAddressInput(
					shippingAddress as Address,
					Address_Types.Shipping
			  );

		try {
			const result = await updateAddresses({
				addresses: [homeAddressInput, shippingAddressInput],
			});

			if (result.error) {
				throw new Error(result.error.message);
			}

			onChange?.(homeAddressInput);
			onSuccess?.();
		} catch (error) {
			setError((error as Error).message ?? "Error: Unknown error.");
		}
	};

	useEffect(() => {
		if (fetching || !data?.patient_address) {
			return;
		}

		const homeAddress = data?.patient_address.find(
			(address) =>
				address.type.toLowerCase() === Address_Types.Home.toLowerCase()
		);

		const shippingAddress = data?.patient_address.find(
			(address) =>
				address.type.toLowerCase() === Address_Types.Shipping.toLowerCase()
		);

		if (homeAddress) {
			setHomeAddress({
				line1: homeAddress.line_1,
				line2: homeAddress.line_2,
				city: homeAddress.city,
				state: homeAddress.state,
				zip: homeAddress.zip,
			});
		}

		if (shippingAddress && showShippingAddress && !showAsBilling) {
			setShippingAddress({
				line1: shippingAddress.line_1,
				line2: shippingAddress.line_2,
				city: shippingAddress.city,
				state: shippingAddress.state,
				zip: shippingAddress.zip,
			});
			setShouldUseSameAddress(false);
		}
	}, [data?.patient_address, fetching, showAsBilling, showShippingAddress]);

	useEffect(() => {
		if (!showAsBilling) return;

		setBillingAddress(shouldUseSameAddress ? homeAddress : null);
	}, [homeAddress, shouldUseSameAddress, showAsBilling]);

	useEffect(() => {
		if (!showAsBilling) {
			return;
		}

		if (!billingAddress) {
			onChange?.(null);

			return;
		}

		onChange?.(addressToAddressInput(billingAddress, Address_Types.Billing));
	}, [billingAddress, showAsBilling, onChange]);

	return {
		error,
		homeAddress,
		billingAddress,
		shippingAddress,
		shouldUseSameAddress,
		isMissingRequiredInfo,
		setHomeAddress,
		setBillingAddress,
		setShippingAddress,
		setShouldUseSameAddress,
		onSubmitInfo,
	};
};
