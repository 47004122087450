import { Tool } from "./PatientLinks.types";

const TOOLS = [
	{
		id: Tool.UpdateAccount,
		title: "Update my account",
		description: "Update your personal details",
	},
	{
		id: Tool.Logout,
		title: "Sign out",
	},
];

export const getLinksData = (showSignout: boolean) => {
	return showSignout ? TOOLS : TOOLS.filter((tool) => tool.id !== Tool.Logout);
};
