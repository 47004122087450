export const isDev = process.env.NODE_ENV === "development";
export const isPlaywright =
	typeof window !== "undefined" && !!window?.isPlaywright;
export const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;
export const PAYTIENT_ORG_ID = import.meta.env.VITE_PAYTIENT_ORG_ID;
export const LD_CLIENT_KEY = import.meta.env.VITE_LD_CLIENT_KEY;
export const STRIPE_SECRET_KEY = import.meta.env.VITE_STRIPE_SECRET_KEY;
export const ORG_STRIPE_KEYS = import.meta.env.VITE_ORG_STRIPE_KEYS;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const GQL_ENDPOINT = import.meta.env.VITE_GRAPHQL_URL;
export const WS_ENDPOINT = import.meta.env.VITE_WS_URL;
export const APP_URL = import.meta.env.VITE_APP_URL;
export const LD_ANONYMOUS_USER_KEY = import.meta.env.VITE_LD_ANONYMOUS_USER_KEY;
export const FONTAWESOME_AUTH_TOKEN = import.meta.env
	.VITE_FONTAWESOME_AUTH_TOKEN;
export const EVENT_URL = import.meta.env.VITE_EVENT_URL;
export const EVENT_AUTH = import.meta.env.VITE_EVENT_AUTH;
export const TRPC_ENDPOINT = import.meta.env.VITE_TRPC_URL;
