export enum ServiceIntentStatus {
	CREATED = "created",
	QUALIFICATION_QUESTIONS_COMPLETED_DISQUALIFIED = "qualification_questions_completed_disqualified",
	QUALIFICATION_QUESTIONS_COMPLETED_QUALIFIED = "qualification_questions_completed_qualified",
	CAPTURE_QUESTIONS_COMPLETED_DISQUALIFIED = "capture_questions_completed_disqualified",
	CAPTURE_QUESTIONS_COMPLETED_QUALIFIED = "capture_questions_completed_qualified",
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	STARTED = "created",
	PAYMENT_STARTED = "payment_started",
	PAYMENT_SUBMITTED = "payment_submitted",
	PAYMENT_HOLD_AUTHORIZED = "payment_hold_authorized",
	PAYMENT_COLLECTED = "payment_collected",

	// Encounter related status
	SUPPORT_WAITING_FOR_CONSENT = "support_waiting_for_consent",
	SUPPORT_WAITING_FOR_AV = "support_waiting_for_av",
	ASSIGNED = "assigned",
	WAITING = "waiting",
	SUPPORT = "support",
	PROCESSING = "processing",
	COMPLETED = "completed", //Terminal status
	CANCELED = "canceled", //Terminal  status
	CANCELLED = "cancelled", //Terminal  status - deprecated
	CANCELED_RESTARTED = "canceled_restarted", //Terminal status
}

export const SERVICE_INTENT_TERMINAL_STATUSES = new Set([
	ServiceIntentStatus.CAPTURE_QUESTIONS_COMPLETED_DISQUALIFIED,
	ServiceIntentStatus.QUALIFICATION_QUESTIONS_COMPLETED_DISQUALIFIED,
	ServiceIntentStatus.COMPLETED,
	ServiceIntentStatus.CANCELED,
	ServiceIntentStatus.CANCELLED,
	ServiceIntentStatus.CANCELED_RESTARTED,
]);

export const SERVICE_INTENT_EDITABLE_STATUSES = new Set([
	ServiceIntentStatus.CREATED,
	ServiceIntentStatus.CAPTURE_QUESTIONS_COMPLETED_QUALIFIED,
	ServiceIntentStatus.QUALIFICATION_QUESTIONS_COMPLETED_QUALIFIED,
	ServiceIntentStatus.PAYMENT_STARTED,
]);

export const SERVICE_INTENT_INPROGRESS_STATUSES = new Set(
	Object.values(ServiceIntentStatus).filter(
		(status) =>
			!SERVICE_INTENT_EDITABLE_STATUSES.has(status) &&
			!SERVICE_INTENT_TERMINAL_STATUSES.has(status)
	)
);

export const SERVICE_INTENT_HIDDEN_STATUSES = new Set([
	ServiceIntentStatus.CANCELED_RESTARTED,
]);
