import type { TextareaHTMLAttributes } from "react";

import classNames from "classnames";

import { useResizable } from "./TextArea.hooks";
import styles from "./TextArea.module.css";

export interface Props
	extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "class"> {
	resizeable?: boolean;
}

export function TextArea({
	resizeable = false,
	className,
	...restProps
}: Props) {
	const { textareaRef } = useResizable(resizeable, restProps.value as string);

	return (
		<textarea
			ref={textareaRef}
			className={classNames(styles.textarea, className)}
			{...restProps}
		/>
	);
}
