import { useState } from "react";

import classNames from "classnames";

import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@shared/components/Button";
import type { ChatFile } from "@shared/components/InputFiles/InputFiles";

import { VideoRecorder } from "../VideoRecorder";

type Props = {
	title?: string;
	onChange: (file: ChatFile) => void;
};

export function VideoModal({ title = "Record Video", onChange }: Props) {
	const [isOpen, setIsOpen] = useState(false);

	const handleOpenModal = () => {
		setIsOpen(true);
	};

	const handleCloseModal = () => {
		setIsOpen(false);
	};

	const handleVideoChange = (file: ChatFile) => {
		onChange(file);
		handleCloseModal();
	};

	return (
		<>
			<Button variant="primary" text={title} onClick={handleOpenModal} />
			{isOpen && (
				<div
					className={classNames(
						"fixed inset-0",
						"bg-gray-800 bg-opacity-40 backdrop-blur-lg",
						"transition-opacity duration-500 ease-in-out",
						"z-[250] flex flex-col items-center justify-center"
					)}
				>
					<div className="flex flex-col">
						<button
							type="button"
							onClick={handleCloseModal}
							className="text-right pb-2"
							title="Close"
						>
							<FontAwesomeIcon size="lg" icon={faXmark} />
						</button>
						<VideoRecorder onChange={handleVideoChange} />
					</div>
				</div>
			)}
		</>
	);
}
