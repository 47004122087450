import type { IntakeStage, IntakeStageProps } from "@shared/types/IntakeStages";

import { CustomMenu } from "./CustomMenu";

export function MenuPreview({
	props,
	onNextStage,
	onPreviousStage,
}: IntakeStageProps<IntakeStage.MenuPreview>) {
	const { rawMenuData } = props;
	const menu = rawMenuData && JSON.parse(rawMenuData);
	return (
		<CustomMenu
			menu={menu}
			fetching={!menu}
			error={undefined}
			onNextStage={onNextStage}
			onPreviousStage={onPreviousStage}
		/>
	);
}
