import type { ChangeEvent } from "react";

import { phonePortrait } from "ionicons/icons";

import {
	InputGroup,
	type TextProps as InputGroupProps,
} from "@shared/components/InputGroup";
import { useTheme } from "@shared/hooks/useTheme";
import { isValidPhoneNumber } from "@shared/utilities/isValidPhoneNumber";

const validatePhoneNumber = (input: HTMLInputElement): void => {
	if (!isValidPhoneNumber(input.value)) {
		input.setCustomValidity(`Phone number appears invalid.`);
		return;
	}

	// Everything is fine.
	input.setCustomValidity("");
	input.reportValidity();
};

export function InputPhoneNumber({
	onInput,
	...props
}: Omit<InputGroupProps, "type" | "onKeyDown">) {
	const { primaryPlaceholder } = useTheme();

	const handleInput = (value: string, e: ChangeEvent<HTMLInputElement>) => {
		let numericValue = value.replace(/\D/g, "");

		if (numericValue === "1") {
			numericValue = "";
		} else if (!numericValue.startsWith("1")) {
			numericValue = `1${numericValue}`;
		}

		onInput?.(value ? numericValue : "", e);
	};

	return (
		<InputGroup
			{...props}
			type="tel"
			iconProps={{
				icon: phonePortrait,
				fill: primaryPlaceholder,
				size: 16,
			}}
			onPreprocessValue={(value) => {
				const numericValue = value.replace(/\D/g, "");

				if (numericValue.startsWith("11")) {
					return numericValue.slice(2);
				}

				return value;
			}}
			onInput={handleInput}
			validate={validatePhoneNumber}
			autoComplete="tel"
			placeholder="+1 (xxx) xxx - xxxx"
			mask="+1 (999) 999 - 9999"
		/>
	);
}
