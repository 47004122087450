import type {
	ReactNode,
	DetailedHTMLProps,
	AnchorHTMLAttributes,
	ButtonHTMLAttributes,
} from "react";

import classNames from "classnames";

import { useMouseInteraction } from "@shared/hooks/useMouseInteraction";
import { useTheme } from "@shared/hooks/useTheme";

import type { Props as IconProps } from "../Icon";
import { Icon } from "../Icon";
import { Stack } from "../Stack";
import { Text } from "../Text";
import styles from "./Button.module.css";

type DataAttributes = {
	[K in `data-${string}`]?: string;
};

type ButtonProps = Omit<
	DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
	"ref"
> &
	DataAttributes;
type LinkProps = Omit<
	DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
	"ref"
>;
type ElementProps = ButtonProps | LinkProps;
export type Props = ElementProps & {
	iconProps?: IconProps;
	text?: string;
	children?: ReactNode;
	variant?: "primary" | "secondary" | "outline" | "danger" | "plain" | "icon";
	compact?: boolean;
	underline?: boolean;
	textClassName?: string;
};

const isLinkProps = (props: ElementProps): props is LinkProps =>
	"href" in props;
const isButtonProps = (props: ElementProps): props is ButtonProps =>
	!isLinkProps(props);

export function Button({
	variant = "primary",
	iconProps,
	text,
	children,
	compact,
	underline,
	hidden = false,
	textClassName,
	...props
}: Props) {
	const { spacing } = useTheme();
	const isMouseIteraction = useMouseInteraction();

	if (hidden) {
		return null;
	}

	if (isLinkProps(props)) {
		return (
			<a
				{...props}
				className={classNames(
					styles.button,
					styles[variant],
					compact ? styles.compact : undefined,
					props.className
				)}
			>
				<Stack
					direction="horizontal"
					align="center"
					justify="center"
					gap={spacing(2)}
				>
					{iconProps && <Icon {...iconProps} />}
					{text && (
						<Text
							className={classNames(textClassName)}
							variant="button"
							align="center"
						>
							{text}
						</Text>
					)}
				</Stack>
				{children}
			</a>
		);
	} else if (isButtonProps(props)) {
		return (
			<button
				type="button"
				{...props}
				className={classNames(
					styles.button,
					styles[variant],
					{
						[styles.noFocus]: isMouseIteraction,
						[styles.compact]: compact,
					},
					props.className
				)}
			>
				{!children && (
					<Stack
						direction="horizontal"
						align="center"
						justify="center"
						gap={spacing(2)}
					>
						{iconProps && <Icon {...iconProps} />}
						{text && (
							<Text
								className={classNames(textClassName)}
								variant="button"
								align="center"
								underline={underline}
							>
								{text}
							</Text>
						)}
					</Stack>
				)}
				{children}
			</button>
		);
	}

	return null;
}
