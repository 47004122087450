import { Button } from "@shared/components/Button";

type Props = Omit<JSX.HTMLAttributes<HTMLButtonElement>, "ref">;

export function MessageButton({ ...restProps }: Props) {
	return (
		<Button
			{...restProps}
			variant="primary"
			className="bg-primary-accent-50 text-primary-foreground h-[30px] min-h-[30px] rounded-[4px] py-[8px] text-[12px] leading-[12px]"
		>
			Send
		</Button>
	);
}
