import { alertCircleOutline, chatboxOutline } from "ionicons/icons";

import { useContextualRedirect } from "@app/components/Auth/useContextualRedirect";
import { useAuthContext } from "@app/components/Contexts";
import { BirthdayLogin } from "@app/components/Forms/BirthdayLogin";
import { Header } from "@app/components/Header";
import { Main } from "@app/components/Main";
import { Hero } from "@shared/components/Hero";
import { Stack } from "@shared/components/Stack";
import { useEffectOnce } from "@shared/hooks/useEffectOnce";
import { useIconStyles } from "@shared/hooks/useIconStyles";
import { useTheme } from "@shared/hooks/useTheme";
import type { AuthStageProps } from "@shared/types/AuthStages";
import { AuthStage } from "@shared/types/AuthStages";

export function AuthQuick({
	props: { phoneNumber, code, serviceIntentId },
	goToStage,
}: AuthStageProps<AuthStage.QuickLogin>) {
	const { spacing } = useTheme();
	const { patientId, authError } = useAuthContext();
	const { fetching, error } = useContextualRedirect(!!patientId, {
		serviceIntentId,
	});

	const chatIconProps = useIconStyles({
		icon: chatboxOutline,
		size: 48,
	});
	const exclimationIconProps = useIconStyles({
		icon: alertCircleOutline,
		size: 48,
	});

	const handleLoginRedirect = () => {
		goToStage(AuthStage.Start, { serviceIntentId });
	};

	useEffectOnce(
		(markDidRun) => {
			if (!phoneNumber || !code) {
				handleLoginRedirect();
				markDidRun();
			}
		},
		[phoneNumber, code]
	);

	const isInvalidCodeError = authError?.message
		.toLowerCase()
		.includes("code is not valid");

	return (
		<>
			<Header />
			<Main loading={fetching} error={error?.message}>
				<Stack
					direction="vertical"
					align="center"
					gap={spacing(5)}
					padding={spacing(3)}
				>
					<Hero
						title={
							isInvalidCodeError
								? "Oops! The link has expired"
								: "Just one more step..."
						}
						subtitle={
							isInvalidCodeError ? (
								<span>
									Please proceed to&nbsp;
									<button
										type="button"
										className="inline-block underline"
										onClick={handleLoginRedirect}
									>
										sign in
									</button>
								</span>
							) : (
								"Please confirm your date of birth to login."
							)
						}
						iconProps={
							isInvalidCodeError ? exclimationIconProps : chatIconProps
						}
					/>
					<BirthdayLogin
						phoneNumber={`${phoneNumber}`}
						code={`${code}`}
						buttonText="Login"
					/>
				</Stack>
			</Main>
		</>
	);
}
