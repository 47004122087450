import type { MessageFragment } from "@shared/types/graphql";

import { FilePreview } from "./FilePreview";
import styles from "./Message.module.css";

interface Props {
	file: NonNullable<MessageFragment["file"]>;
	fileUrl: string | null | undefined;
	fetching: boolean;
	error: Error | null | undefined;
}

export function Video({ file, fileUrl, fetching, error }: Props) {
	return (
		<FilePreview file={file} isLoaded={!fetching} isError={!!error}>
			{fileUrl && (
				<>
					{/* eslint-disable-next-line jsx-a11y/media-has-caption */}
					<video
						className={styles.video}
						src={`${fileUrl}#t=0.1`}
						aria-label={file.display_name ?? ""}
						controls
						preload="metadata"
					/>
				</>
			)}
		</FilePreview>
	);
}
