import type { ReactNode } from "react";

import classNames from "classnames";

import { Text } from "@shared/components/Text";

import styles from "./MessageBubble.module.css";

export enum MessageBubbleStyle {
	Incoming = "incoming",
	Outgoing = "outgoing",
	Event = "event",
}

interface Props {
	timestamp: string;
	messageDate?: string | null;
	type: MessageBubbleStyle;
	children: ReactNode;
	senderName?: string;
	showBeforeArrow?: boolean;
}

export function MessageBubble({
	senderName,
	timestamp,
	type,
	children,
	messageDate,
	showBeforeArrow = false,
}: Props) {
	const isIncoming = type === MessageBubbleStyle.Incoming;
	const isEvent = type === MessageBubbleStyle.Event;

	return (
		<div
			title={timestamp}
			className={classNames(styles.root, styles[type], {
				[styles.showBeforeArrow]: showBeforeArrow,
			})}
		>
			{isIncoming && senderName && (
				<Text align="start" className={styles.sender}>
					{senderName}
				</Text>
			)}
			{typeof children === "string" ? (
				<Text
					align={isEvent ? "center" : isIncoming ? "start" : "end"}
					parseLinks
					className={styles.text}
					dangerouslySetInnerHTML={{
						__html: children,
					}}
				/>
			) : (
				children
			)}
			{messageDate && (
				<Text align={isEvent ? "center" : "end"} className={styles.date}>
					{messageDate}
				</Text>
			)}
		</div>
	);
}
