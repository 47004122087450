import { useFormatter } from "@shared/hooks/useFormatter";

import { LineItems } from "./LineItems";

interface Props {
	items: LineItems;
	appliedDiscountCode: string | null;
	discountAmount: number;
	onApplyDiscount: (discountCode: string) => void;
}

export function CostSummary({
	items,
	appliedDiscountCode,
	discountAmount,
	onApplyDiscount,
}: Props) {
	const formatter = useFormatter({
		style: "currency",
		currency: "USD",
	});
	return (
		<LineItems
			formatter={formatter}
			items={items}
			appliedDiscountCode={appliedDiscountCode}
			discountAmount={discountAmount}
			onApplyDiscount={onApplyDiscount}
		/>
	);
}
