import { useCallback } from "preact/hooks";

import { useTheme } from "@shared/hooks/useTheme";
import { EncounterStatus } from "@shared/types/EncounterStatus";
import type { Encounter } from "@shared/types/graphql";

export function useGetVisitStatusInfo() {
	const { primaryForeground, successBackground, dangerBackground } = useTheme();
	return useCallback(
		(status: Encounter["status"]) => {
			if (!status) {
				return {
					name: "",
					color: "",
				};
			}
			const name = (() => {
				switch (status) {
					case EncounterStatus.CREATED:
					case EncounterStatus.CAPTURE_QUESTIONS_COMPLETED_QUALIFIED:
					case EncounterStatus.QUALIFICATION_QUESTIONS_COMPLETED_QUALIFIED:
					case EncounterStatus.PAYMENT_STARTED:
						return "Started";
					case EncounterStatus.CAPTURE_QUESTIONS_COMPLETED_DISQUALIFIED:
					case EncounterStatus.QUALIFICATION_QUESTIONS_COMPLETED_DISQUALIFIED:
						return "Offline Consultation Recommended";
					case EncounterStatus.PAYMENT_SUBMITTED:
					case EncounterStatus.PAYMENT_HOLD_AUTHORIZED:
					case EncounterStatus.UNASSIGNED:
					case EncounterStatus.ASSIGNED:
					case EncounterStatus.WAITING:
					case EncounterStatus.SUPPORT:
					case EncounterStatus.PROCESSING:
						return "In Progress";
					case EncounterStatus.SUPPORT_WAITING_FOR_CONSENT:
						return "Consent Required";
					case EncounterStatus.SUPPORT_WAITING_FOR_AV:
						return "Waiting for Intro Video";
					case EncounterStatus.COMPLETED:
						return "Completed";
					case EncounterStatus.CANCELED:
					case "cancelled":
						return "Canceled";
				}
			})();
			const color = (() => {
				switch (status) {
					case EncounterStatus.CREATED:
					case EncounterStatus.CAPTURE_QUESTIONS_COMPLETED_QUALIFIED:
					case EncounterStatus.QUALIFICATION_QUESTIONS_COMPLETED_QUALIFIED:
					case EncounterStatus.PAYMENT_STARTED:
						return successBackground.rgba;
					case EncounterStatus.CAPTURE_QUESTIONS_COMPLETED_DISQUALIFIED:
					case EncounterStatus.QUALIFICATION_QUESTIONS_COMPLETED_DISQUALIFIED:
						return primaryForeground.rgba;
					case EncounterStatus.PAYMENT_SUBMITTED:
					case EncounterStatus.PAYMENT_HOLD_AUTHORIZED:
					case EncounterStatus.UNASSIGNED:
					case EncounterStatus.ASSIGNED:
					case EncounterStatus.WAITING:
					case EncounterStatus.SUPPORT:
					case EncounterStatus.PROCESSING:
						return successBackground.rgba;
					case EncounterStatus.SUPPORT_WAITING_FOR_CONSENT:
						return primaryForeground.rgba;
					case EncounterStatus.COMPLETED:
						return successBackground.rgba;
					case EncounterStatus.CANCELED:
					case "cancelled":
						return dangerBackground.color;
				}
			})();

			return {
				name,
				color,
			};
		},
		[successBackground, primaryForeground, dangerBackground]
	);
}
