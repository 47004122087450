import type { AppRouter } from "@backend/trpc/trpc.router";
import * as Sentry from "@sentry/browser";
import { TRPC_ENDPOINT } from "@shared/constants/environments";
import { getHeaders } from "@shared/utilities/gql/headers";
import { httpBatchLink, loggerLink } from "@trpc/client";
import {
	type inferReactQueryProcedureOptions,
	createTRPCReact,
} from "@trpc/react-query";
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server";

export const trpc = createTRPCReact<AppRouter>();
export type ReactQueryOptions = inferReactQueryProcedureOptions<AppRouter>;
export type RouterInputs = inferRouterInputs<AppRouter>;
export type RouterOutputs = inferRouterOutputs<AppRouter>;

export const links = [
	loggerLink({
		enabled: (opts) => {
			if (opts.direction === "down" && opts.result instanceof Error) {
				Sentry.captureException(opts.result, {
					extra: {
						cause: opts.result.cause,
						shape: opts.result.shape,
						data: opts.result.data,
					},
				});
			}
			// TODO: add is not prod to this to only log in non prod
			return opts.direction === "down" && opts.result instanceof Error;
		},
	}),
	// Need to create links for retrying and maybe caching
	//
	// retryLink(),
	// export const retryExchange = _retryExchange({
	// 	initialDelayMs: 1000,
	// 	maxDelayMs: 15000,
	// 	randomDelay: true,
	// 	maxNumberAttempts: 3,
	// 	retryIf: (err) => err && !!err.networkError,
	// });

	// devtoolsLink(),
	httpBatchLink({
		url: TRPC_ENDPOINT ?? "http://localhost:3002/trpc",
		fetch(url, options) {
			return fetch(url, {
				...options,
				credentials: "include",
			});
		},
		async headers() {
			return { ...getHeaders(), "x-trpc-source": "react" };
		},
	}),
];
export const trpcClient = trpc.createClient({
	links,
});
