export class MemoryStorage implements Storage {
	private store: { [key: string]: string } = {};

	public getItem(key: string): string | null {
		return this.store[key] || null;
	}

	public setItem(key: string, value: string): void {
		this.store[key] = value;
	}

	public removeItem(key: string): void {
		delete this.store[key];
	}

	public clear(): void {
		this.store = {};
	}

	public key(index: number): string | null {
		return Object.keys(this.store)[index] || null;
	}

	public get length(): number {
		return Object.keys(this.store).length;
	}
}
