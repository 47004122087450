import { useRef } from "react";

import { useAuthContext, useWidgetContext } from "@app/components/Contexts";
import { RouteMap } from "@app/constants/RouteMap";
import { useNavigation } from "@shared/hooks/useNavigation";
import { AuthStage } from "@shared/types/AuthStages";

export function useAuthRequired(isRequired: boolean) {
	const hasRedirected = useRef(false);
	const { push } = useNavigation();
	const { patientId } = useAuthContext();
	const { widgetId } = useWidgetContext();

	if (isRequired && !patientId && !hasRedirected.current) {
		push(RouteMap.AuthStages, {
			stageId: AuthStage.Start,
			widgetId,
		});
	}

	return isRequired && !patientId;
}
