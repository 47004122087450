import { useEffect, useRef } from "preact/hooks";

import { useClient } from "@app/components/Contexts/ClientContext";
import { useWidgetContext } from "@app/components/Contexts/WidgetContext";
import { RouteMap } from "@app/constants/RouteMap";
import { configureSentryUser } from "@app/utilities/initSentry";
import { useNavigation } from "@shared/hooks/useNavigation";
import { debug } from "@shared/utilities/debug";
import { clearAuthorizationHeaders } from "@shared/utilities/gql/headers";

enum TokenChannelMessageType {
	BroadcastToken = "broadcast-token",
	RequestToken = "request-token",
}

// Use a BroadcastChannel to sync auth tokens across tabs.
// This is necessary because the auth token is stored in sessionStorage,
// which is not shared across tabs.
export function useTokenSync(
	cachedJWT: string | null | undefined,
	setCachedJWT: (jwt: string | null) => void
) {
	const { widgetId } = useWidgetContext();
	const tokenSyncChannel = useRef(
		new BroadcastChannel(`${widgetId}-token-sync`)
	);
	const lastReceivedToken = useRef<string | null | undefined>(cachedJWT);
	const { resetClient } = useClient();
	const { goTo } = useNavigation();

	// Subscribe to messages from other tabs.
	useEffect(() => {
		const channel = tokenSyncChannel.current;
		channel.onmessage = (event) => {
			debug("info", {
				context: "useTokenSync",
				message: "Received message from other tab.",
				info: event.data,
			});

			// When a new token is received, update the cached token.
			if (event.data.type === TokenChannelMessageType.BroadcastToken) {
				const receivedJWT = event.data.token;
				if (receivedJWT === cachedJWT) return;

				lastReceivedToken.current = receivedJWT;
				setCachedJWT(receivedJWT);
			}

			// When a token is requested, send the current token to the requesting tab.
			else if (event.data.type === TokenChannelMessageType.RequestToken) {
				if (!cachedJWT) return;
				channel.postMessage({
					type: TokenChannelMessageType.BroadcastToken,
					token: cachedJWT,
				});
			}

			// Handle logout message
			if (event.data.type === "logout") {
				clearAuthorizationHeaders();
				resetClient();
				configureSentryUser(null);
				setCachedJWT(null);

				setTimeout(() => {
					goTo(
						RouteMap.Default,
						{
							widgetId,
						},
						{},
						true
					);
				}, 0);
			}
		};
	}, [cachedJWT, goTo, resetClient, setCachedJWT, widgetId]);

	// On first mount, request the token from other tabs.
	// On unmount, close the channel.
	useEffect(() => {
		const channel = tokenSyncChannel.current;
		channel.postMessage({
			type: TokenChannelMessageType.RequestToken,
		});
		debug("info", {
			context: "useTokenSync",
			message: "Requested token from other tabs.",
		});
		return () => {
			debug("info", {
				context: "useTokenSync",
				message: "Will close channel.",
			});
			channel.close();
		};
	}, []);

	// When the cached token changes, send the new token to other tabs.
	useEffect(() => {
		if (cachedJWT === lastReceivedToken.current) {
			return;
		}

		const channel = tokenSyncChannel.current;
		channel.postMessage({
			type: TokenChannelMessageType.BroadcastToken,
			token: cachedJWT,
		});
		debug("info", {
			context: "useTokenSync",
			message: "Broadcasted new token to other tabs.",
			info: cachedJWT,
		});
	}, [cachedJWT]);
}
