import type { ReactNode } from "react";

import styles from "./Tray.module.css";

interface Props {
	children: ReactNode;
}

export function Tray({ children }: Props) {
	return <div className={styles.tray}>{children}</div>;
}
