import type { ComponentChildren } from "preact";

import { useEffect, useMemo } from "preact/hooks";
import { useQuery } from "urql";

import { CONTRACT_AGREEMENTS } from "@app/components/Auth/TermsOfService/constants";
import { Main } from "@app/components/Main";
import { useFirst } from "@shared/hooks/useFirst";
import { TerminalEncounterStatuses } from "@shared/types/EncounterStatus";
import { WidgetLoadPatientContextDocument } from "@shared/types/graphql";
import { debug } from "@shared/utilities/debug";

import { useAuthContext } from "../AuthContext";
import { PatientContext } from "./PatientContext";

interface Props {
	children: ComponentChildren;
}

export function PatientContextProvider({ children }: Props) {
	const { patientId } = useAuthContext();

	const [{ data, fetching, error }, executeQuery] = useQuery({
		query: WidgetLoadPatientContextDocument,
		variables: {
			patientId: patientId as string,
			terminalStatuses: TerminalEncounterStatuses,
		},
		pause: !patientId,
	});

	const refetchPatientContext = () => {
		executeQuery({
			query: WidgetLoadPatientContextDocument,
			variables: {
				patientId: patientId as string,
				terminalStatuses: TerminalEncounterStatuses,
			},
			requestPolicy: "network-only",
		});
	};

	const patient = useFirst(data?.patient);
	const encountersCount = patient?.encounters?.length ?? 0;

	useEffect(() => {
		if (!error) return;
		debug("error", {
			context: "PatientContext",
			message: "Error loading patient context",
			info: error,
		});
	}, [error]);

	const contractAgreements = useMemo(() => {
		return (
			patient?.contract_agreements?.map(({ contract_name }) => contract_name) ??
			[]
		);
	}, [patient?.contract_agreements]);

	if (patientId && error?.message) {
		return <Main error={error.message} />;
	}

	return (
		<PatientContext.Provider
			ref={() =>
				debug("info", {
					context: "PatientContextProvider",
					message: "Patient context loaded.",
					info: data,
				})
			}
			value={{
				patientId,
				hasOngoingVisits: encountersCount > 0,
				hasAcceptedTerms: contractAgreements.includes(
					CONTRACT_AGREEMENTS.TermsOfService
				),
				// For now, we don't ask for consent to the privacy policy
				// && contractAgreements.includes(CONTRACT_AGREEMENTS.PrivacyPolicy),
				patientFirstName: patient?.first_name ?? null,
				patientLastName: patient?.last_name ?? null,
				patientFullName: [patient?.first_name, patient?.last_name]
					.filter(Boolean)
					.join(" "),
				patientExternalId: patient?.external_id ?? null,
				fetching,
				error,
				refetchPatientContext,
			}}
		>
			{children}
		</PatientContext.Provider>
	);
}
