export const isReactNative = () => {
	return window.ReactNativeWebView !== undefined;
};

export function postMessageToReactNative(message: string) {
	if (isReactNative()) {
		window.ReactNativeWebView.postMessage(message);
	}
}

export function closeReactNative() {
	postMessageToReactNative("close");
}
