import classNames from "classnames";
import { useImageCache } from "@shared/hooks/useImageCache";
import styles from "./UserImage.module.css";

interface Props {
	url: string;
	alt?: string;
	className?: string;
}

export function UserImage({ url, alt, className }: Props) {
	const [data] = useImageCache(url);
	return (
		<img
			className={classNames(styles.userImage, className)}
			src={data ?? url}
			alt={alt ?? "User"}
		/>
	);
}
