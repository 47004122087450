export function lockScroll(type?: "fixed") {
	if (type === "fixed") {
		document.body.style.cssText =
			"overflow: hidden; position: fixed; width: 100%; height: 100%";
		return;
	}
	document.body.style.setProperty("overflow", "hidden");
}

export function unlockScroll() {
	document.body.removeAttribute("style");
}
