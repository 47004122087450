import { debug } from '@shared/utilities/debug';
export function processContent(inputString?: string | null): string | null {
  if (inputString == null) return null;

  let processed = inputString;

  try {
    processed = processUrls(processed);
  } catch (error) {
    debug('error', {
      context: 'processUrls',
      message: 'Error processing URLs:',
      info: { error },
    });
  }

  try {
    processed = processEmails(processed);
  } catch (error) {
    debug('error', {
      context: 'processEmails',
      message: 'Error processing Emails:',
      info: { error },
    });
  }

  try {
    processed = processPhoneNumbers(processed);
  } catch (error) {
    debug('error', {
      context: 'processPhoneNumbers',
      message: 'Error processing Phone Numbers:',
      info: { error },
    });
  }

  try {
    processed = processBold(processed);
  } catch (error) {
    debug('error', {
      context: 'processBold',
      message: 'Error processing Bold text:',
      info: { error },
    });
  }

  try {
    processed = processItalicsWithAsterisks(processed);
  } catch (error) {
    debug('error', {
      context: 'processItalicsWithAsterisks',
      message: 'Error processing Italics with Asterisks:',
      info: { error },
    });
  }

  try {
    processed = processItalicsWithUnderscores(processed);
  } catch (error) {
    debug('error', {
      context: 'processItalicsWithUnderscores',
      message: 'Error processing Italics with Underscores:',
      info: { error },
    });
  }

  try {
    processed = processDividers(processed);
  } catch (error) {
    debug('error', {
      context: 'processDividers',
      message: 'Error processing Dividers:',
      info: { error },
    });
  }

  try {
    processed = processUnderline(processed);
  } catch (error) {
    debug('error', {
      context: 'processUnderline',
      message: 'Error processing Underline:',
      info: { error },
    });
  }

  try {
    processed = processNewlines(processed);
  } catch (error) {
    debug('error', {
      context: 'processNewlines',
      message: 'Error processing Newlines:',
      info: { error },
    });
  }

  return processed;
}

function processUrls(input: string): string {
  const urlRegex = /(?<!href=")(https:\/\/[^\s<"'!?]*)(?=[.!?]?(\s|$|<))/g;

  return input.replace(
    urlRegex,
    (url) =>
      `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`,
  );
}

function processEmails(input: string): string {
  const emailRegex =
    /(?<!href="mailto:)\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;

  return input.replace(
    emailRegex,
    (email) => `<a href="mailto:${email}">${email}</a>`,
  );
}

function processPhoneNumbers(input: string): string {
  const phoneRegex =
    /(^|\s|\W)(\+1\s?)?(\(\d{3}\)\s?|\d{3}[\s.-]?)\d{3}[\s.-]?\d{4}(\W|$)/g;

  return input.replace(
    phoneRegex,
    (phone) => `<a href="tel:${phone.replace(/\D/g, '')}">${phone}</a>`,
  );
}

function processBold(input: string): string {
  return input.replace(/\*\*([^\n]+?)\*\*/g, '<strong>$1</strong>');
}

function processItalicsWithAsterisks(input: string): string {
  return input.replace(/(?<!\*)\*([^\n*]+?)\*(?!\*)/g, '<em>$1</em>');
}

function processItalicsWithUnderscores(input: string): string {
  return input.replace(
    /(^|[^a-zA-Z0-9])_(?!_)([^\n_]+?)_(?!_)(?=[^a-zA-Z0-9]|$)/g,
    '$1<em>$2</em>',
  );
}

function processDividers(input: string): string {
  const dividerRegex = /_{4,}/g;

  return input.replace(dividerRegex, '<hr>');
}

function processUnderline(input: string): string {
  return input.replace(/__(.+?)__/g, '<u>$1</u>');
}

function processNewlines(input: string): string {
  const processed = input.replace(/(?!<a[^>]*>)\\n(?!<\/a>)/g, '<br />');

  return processed.replace(/(?!<a[^>]*>)\n(?!<\/a>)/g, '<br />');
}
