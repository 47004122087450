import { useMemo, useRef } from "preact/hooks";
import { useQuery } from "urql";

import { useAuthStages } from "@app/components/Auth/AuthStageContext";
import { usePatientContext, useWidgetContext } from "@app/components/Contexts";
import { RouteMap } from "@app/constants/RouteMap";
import { useServiceIntent } from "@app/hooks/serviceIntent/useServiceIntent";
import { useServiceIntentByService } from "@app/hooks/serviceIntent/useServiceIntentByService";
import { getServiceIntentStepRoute } from "@app/utilities/getServiceIntentStepRoute";
import { useIntakeNavigation } from "@shared/hooks/useIntakeNavigation";
import { useLocation } from "@shared/hooks/useLocation";
import { useNavigation } from "@shared/hooks/useNavigation";
import { AuthStage } from "@shared/types/AuthStages";
import type { ServiceIntentStatus } from "@shared/types/ServiceIntentStatus";
import {
	SERVICE_INTENT_EDITABLE_STATUSES,
	SERVICE_INTENT_INPROGRESS_STATUSES,
	SERVICE_INTENT_TERMINAL_STATUSES,
} from "@shared/types/ServiceIntentStatus";
import { WidgetGetRedirectInfoDocument } from "@shared/types/graphql";

import { CONTRACT_AGREEMENTS } from "../TermsOfService/constants";

export function useContextualRedirect(
	ready: boolean,
	{
		serviceIntentId,
		pauseExecution = false,
	}: { serviceIntentId?: string; pauseExecution?: boolean } = {}
) {
	const { replace, navigateTo } = useNavigation();
	const { matches } = useLocation();

	const channelId =
		matches.channelId !== "undefined" ? matches.channelId : null;
	const serviceId =
		matches.serviceId !== "undefined" ? matches.serviceId : null;

	const { widgetId } = useWidgetContext();
	const { patientId } = usePatientContext();
	const auth = useAuthStages();
	const { navigateToIntakeStart } = useIntakeNavigation();
	const [{ data, fetching, error }] = useQuery({
		query: WidgetGetRedirectInfoDocument,
		requestPolicy: "network-only",
		pause: !patientId || pauseExecution,
	});

	const patientEncounters = useMemo(() => {
		return data?.patient[0].encounters ?? [];
	}, [data]);

	const hasOngoingVisits = useMemo(() => {
		const ongoingVisits = patientEncounters.filter(
			(encounter) =>
				SERVICE_INTENT_EDITABLE_STATUSES.has(
					encounter.service_intent?.status as ServiceIntentStatus
				) ||
				SERVICE_INTENT_INPROGRESS_STATUSES.has(
					encounter.service_intent?.status as ServiceIntentStatus
				)
		);

		return ongoingVisits.length > 0;
	}, [patientEncounters]);

	const {
		done: doneFetchingServiceIntentFromQuery,
		serviceIntent: serviceIntentFromQuery,
		fetching: serviceIntentFetching,
		error: serviceIntentError,
	} = useServiceIntent({
		pauseExecution,
		serviceIntentId,
	});
	const {
		done: doneFetchingServiceIntentFromService,
		serviceIntent: serviceIntentFromService,
		fetching: fetchingServiceIntentFromService,
		error: fetchingServiceIntentFromServiceError,
	} = useServiceIntentByService({
		serviceId: serviceId as string,
		skip:
			(serviceIntentFromQuery !== null &&
				serviceIntentFromQuery?.status &&
				!SERVICE_INTENT_TERMINAL_STATUSES.has(
					serviceIntentFromQuery.status as ServiceIntentStatus
				)) ||
			!serviceId ||
			!patientId,
	});

	const serviceIntent = serviceIntentFromService ?? serviceIntentFromQuery;
	const done =
		doneFetchingServiceIntentFromQuery && doneFetchingServiceIntentFromService;

	// At this point, we're logged in and have fetched data.
	// Redirect the user as appropriate.
	const didSetupRedirect = useRef(false);
	if (ready && data && done && !didSetupRedirect.current) {
		didSetupRedirect.current = true;

		const [patient] = data?.patient ?? [];
		const [patientContact] = data?.patient_contact ?? [];
		const needsRegistration = !(
			patient?.first_name &&
			patient?.last_name &&
			patientContact?.email
		);
		const needsConsent = false; // !patient.marketing_consent; // TODO: Need Zack to update this.
		const contactAgreements =
			patient?.contract_agreements?.map(({ contract_name }) => contract_name) ??
			[];
		const needsToAcceptTerms = !contactAgreements.includes(
			CONTRACT_AGREEMENTS.TermsOfService
		);
		const isInEhr =
			patient?.integration_status &&
			["new", "established"].includes(patient?.integration_status);
		// For now, we don't ask for consent to the privacy policy
		// || !contactAgreements.includes(CONTRACT_AGREEMENTS.PrivacyPolicy);

		if (needsRegistration || (isInEhr && needsToAcceptTerms)) {
			auth.goToStage(AuthStage.Registration, {
				serviceIntentId,
			});
		} else if (needsConsent) {
			auth.goToStage(AuthStage.Consent, {
				serviceIntentId,
			});
		} else if (needsToAcceptTerms) {
			auth.goToStage(AuthStage.TermsOfService, {
				serviceIntentId,
			});
		} else if (serviceIntent) {
			const serviceId = serviceIntent.service.id;
			const stepRoute = getServiceIntentStepRoute({
				widgetId,
				serviceId,
				serviceIntent,
				fromLogin: true,
			});

			navigateTo(stepRoute);
			// If query has channelId, go to visit channel.
		} else if (channelId) {
			replace(RouteMap.VisitChannel, {
				widgetId,
				channelId,
			});
			// If the user has ongoing visits, go to the visits page
		} else if (hasOngoingVisits) {
			replace(RouteMap.Visits, { widgetId });
			// If the user has no ongoing visits, go to the start of intake selection
		} else {
			navigateToIntakeStart();
		}
	}

	return {
		fetching:
			fetching || serviceIntentFetching || fetchingServiceIntentFromService,
		error: error || serviceIntentError || fetchingServiceIntentFromServiceError,
	};
}
