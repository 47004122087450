import { usePatientContext, useWidgetContext } from "@app/components/Contexts";
import { RouteMap } from "@app/constants/RouteMap";
import { faHouseUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@shared/components/Button";
import { useNavigation } from "@shared/hooks/useNavigation";

export function HomeButton() {
	const { push } = useNavigation();
	const { widgetId } = useWidgetContext();
	const { patientId } = usePatientContext();

	return (
		<Button
			variant="plain"
			onClick={() =>
				push(patientId ? RouteMap.Visits : RouteMap.Default, { widgetId })
			}
			data-testid="home-button"
		>
			<FontAwesomeIcon icon={faHouseUser} />
		</Button>
	);
}
