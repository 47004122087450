import type { ComponentType } from "preact/compat";
import { useEffect } from "preact/hooks";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	useParams,
} from "react-router-dom";

import { Auth } from "@app/components/Auth";
import {
	Providers,
	useOrganizationContext,
	usePatientContext,
	useWidgetContext,
} from "@app/components/Contexts";
import { useFeatureFlag } from "@app/components/Contexts/LaunchDarklyContext";
import { Home } from "@app/components/Home";
import { Intake } from "@app/components/Intake";
import { NotFound } from "@app/components/NotFound";
import { PatientHub } from "@app/components/PatientHub";
import { RouteMap } from "@app/constants/RouteMap";
import { initSentry } from "@app/utilities/initSentry";
import {
	isReactNative,
	postMessageToReactNative,
} from "@app/utilities/reactNative";
import { Theme as RadixThemeProvider } from "@radix-ui/themes";
import { ConfirmDrawer } from "@shared/components/ConfirmDrawer";
import { useQuickLoginRedirect } from "@shared/hooks/useQuickLoginRedirect";
import type { Theme } from "@shared/hooks/useTheme";
import { ThemeProvider } from "@shared/hooks/useTheme";
import { getDefaultThemes } from "@shared/hooks/useWidgetSettings";

import { debug } from "../../../shared/utilities/debug";
import { initEventTracker } from "../utilities/eventTracker";
import "./App.css";
import { useAppVersionSync } from "./App.hooks";
import styles from "./App.module.css";
import { PaytientTerms } from "./PatientHub/Visits/PaytientTerms";

/* 
RMC - 2024-04-06 
**IMPORTANT** 
	Widget (App) Initializaation Notes:
		- /apps/widget-app/index.tsx is the entry point of the application that calls App component
			- index loads several providers before rendering the App component
		- App is the main component that renders the application
			- App renders the Router and all Routes.
			- Each route is passed a ProvidersWrapperComponent to render.
		- ProvidersWrapperComponent receives a Component from App based on the Route.
			- Within ProvidersWrapperComponent, it wraps the AppContent with several providers from 
				./Contexts/Providers.tsx.
		- AppContent is a functional component that renders the application content.
			- It is required for PaytientTerms and ConfirmDrawer components to work properly.
*/

export function App({
	lightTheme,
	darkTheme,
}: {
	lightTheme?: Theme;
	darkTheme?: Theme;
}) {
	useEffect(() => {
		initSentry();

		// Let the native app know that the widget has started loading
		if (isReactNative()) {
			postMessageToReactNative("loadStart");
		}
	}, []);

	// Initialize event tracker if feature flag is enabled
	const useTracker = useFeatureFlag("useTracker");
	debug("info", {
		context: "EVENT_TRACKER_STATUS",
		message: "useTracker",
		info: { useTracker },
	});
	initEventTracker();

	return (
		<div className={styles.app}>
			<Router>
				<Routes>
					<Route
						path={RouteMap.Default}
						element={
							<ProvidersWrapperComponent
								AppContentComponent={Home}
								lightTheme={lightTheme}
								darkTheme={darkTheme}
							/>
						}
					/>
					<Route
						path={RouteMap.AuthStages}
						element={
							<ProvidersWrapperComponent
								AppContentComponent={Auth}
								lightTheme={lightTheme}
								darkTheme={darkTheme}
							/>
						}
					/>
					<Route
						path={RouteMap.IntakeStages}
						element={
							<ProvidersWrapperComponent
								AppContentComponent={Intake}
								lightTheme={lightTheme}
								darkTheme={darkTheme}
							/>
						}
					/>
					<Route
						path={RouteMap.Visits}
						element={
							<ProvidersWrapperComponent
								AppContentComponent={PatientHub}
								lightTheme={lightTheme}
								darkTheme={darkTheme}
							/>
						}
					/>
					<Route
						path={RouteMap.VisitChannel}
						element={
							<ProvidersWrapperComponent
								AppContentComponent={PatientHub}
								lightTheme={lightTheme}
								darkTheme={darkTheme}
							/>
						}
					/>
					<Route
						path={RouteMap.VisitChannelDetails}
						element={
							<ProvidersWrapperComponent
								AppContentComponent={PatientHub}
								lightTheme={lightTheme}
								darkTheme={darkTheme}
							/>
						}
					/>
					<Route
						path={RouteMap.AccountDetails}
						element={
							<ProvidersWrapperComponent
								AppContentComponent={PatientHub}
								lightTheme={lightTheme}
								darkTheme={darkTheme}
							/>
						}
					/>
					<Route
						path={`${RouteMap.Default}/*`}
						element={
							<ProvidersWrapperComponent
								AppContentComponent={NotFound}
								lightTheme={lightTheme}
								darkTheme={darkTheme}
							/>
						}
					/>
					<Route
						path="*"
						element={
							<ProvidersWrapperComponent AppContentComponent={NotFound} />
						}
					/>
				</Routes>
			</Router>
		</div>
	);
}

const ProvidersWrapperComponent = ({
	AppContentComponent,
	lightTheme,
	darkTheme,
}: {
	AppContentComponent: ComponentType;
	lightTheme?: Theme;
	darkTheme?: Theme;
}) => {
	const { widgetId } = useParams<{ widgetId: string }>();
	const { lightTheme: defaultLightTheme, darkTheme: defaultDarkTheme } =
		getDefaultThemes();

	return widgetId ? (
		<Providers
			widgetId={widgetId}
			lightTheme={lightTheme}
			darkTheme={darkTheme}
		>
			<AppContent Component={AppContentComponent} />
		</Providers>
	) : (
		<RadixThemeProvider>
			<ThemeProvider light={defaultLightTheme} dark={defaultDarkTheme}>
				<AppContent Component={AppContentComponent} />
			</ThemeProvider>
		</RadixThemeProvider>
	);
};

function AppContent({ Component }: { Component: ComponentType }) {
	// Added matches to reload the page when a new version is available
	const matches = useParams();
	const { isPaytient } = useOrganizationContext();
	const { patientId, hasAcceptedTerms } = usePatientContext();
	const { isWidgetOpen, onRendered } = useWidgetContext();
	useQuickLoginRedirect();
	const { showConfirmReload, reloadPage, hideConfirmReloadDrawer } =
		useAppVersionSync(!isWidgetOpen);

	useEffect(() => {
		if (!isWidgetOpen) return;

		onRendered();
	}, [isWidgetOpen, onRendered]);

	if (!isWidgetOpen) {
		return null;
	}

	return (
		<>
			{/* key is to ensure component re-render when you switch between routes */}
			<Component key={window.location.pathname} matches={matches} />
			{patientId && isPaytient && !hasAcceptedTerms && <PaytientTerms />}
			<ConfirmDrawer
				show={showConfirmReload}
				title="New version available"
				description="A new version of the app is available. Please confirm to reload and get the latest version."
				onAgree={reloadPage}
				onToggle={hideConfirmReloadDrawer}
				onCancel={hideConfirmReloadDrawer}
			/>
		</>
	);
}
