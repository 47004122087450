export const RouteMap = {
	Default: "/w/:widgetId",
	IntakeStage: "/w/:widgetId/intake",
	IntakeStages: "/w/:widgetId/intake/:stageId",
	AuthStage: "/w/:widgetId/auth",
	AuthStages: "/w/:widgetId/auth/:stageId",
	Visits: "/w/:widgetId/visits",
	VisitChannel: "/w/:widgetId/visits/:channelId",
	VisitChannelDetails: "/w/:widgetId/visits/:channelId/details",
	AccountDetails: "/w/:widgetId/details",
} as const;
