import { ConfirmDrawer } from "@shared/components/ConfirmDrawer";

type Props = {
	show: boolean;
	onClose: () => void;
	onConfirmation: (startOver: boolean) => void;
};

export function ConfirmStartOver({ show, onClose, onConfirmation }: Props) {
	if (!show) {
		return null;
	}

	return (
		<ConfirmDrawer
			title="Existing survey in-progress"
			description="It looks like you already started working on the medical intake survey for this service. Do you want to pickup where you left off?"
			onAgree={() => {
				onConfirmation(false);
			}}
			onCancel={() => {
				onConfirmation(true);
			}}
			cancelText="No, start over"
			confirmText="Yes, continue"
			show={show}
			onToggle={onClose}
		/>
	);
}
