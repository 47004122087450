// Download file from url.
// Note: for this to work, download URL has to be same origin.
// Otherwise it will just open the file in a new tab.
// https://stackoverflow.com/a/32226068/112731
export const downloadFile = (url: string) => {
	const link = document.createElement("a");
	link.setAttribute("href", url);
	link.setAttribute("download", "file");
	link.setAttribute("target", "_blank");
	link.style.display = "none";
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};
