import { useQuery } from "urql";

import { Header } from "@app/components/Header";
import { Main } from "@app/components/Main";
import { NotFound } from "@app/components/NotFound";
import { useFirst } from "@shared/hooks/useFirst";
import type { IntakeStage, IntakeStageProps } from "@shared/types/IntakeStages";
import { WidgetGetMenuDocument } from "@shared/types/graphql";

import { CustomMenu } from "./CustomMenu";

export function Menu({
	props: { menuId },
	onNextStage,
	onPreviousStage,
}: IntakeStageProps<IntakeStage.Menu>) {
	const [{ data, fetching, error }] = useQuery({
		query: WidgetGetMenuDocument,
		variables: {
			menuId,
		},
	});
	const menu = useFirst(data?.menu);

	if (fetching) {
		return (
			<>
				<Header onBack={onPreviousStage} />
				<Main loading={fetching} />
			</>
		);
	}

	if (!menu) {
		return (
			<>
				<Header onBack={onPreviousStage} />
				<NotFound />
			</>
		);
	}

	return (
		<CustomMenu
			menu={menu}
			fetching={fetching}
			error={error}
			onNextStage={onNextStage}
			onPreviousStage={onPreviousStage}
		/>
	);
}
