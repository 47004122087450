import type { JSX } from "preact";

import { useRef, useState } from "preact/hooks";

import { InputFiles } from "@shared/components/InputFiles";
import type { ChatFile } from "@shared/components/InputFiles/InputFiles";
import { UploadPreviews } from "@shared/components/InputFiles/UploadPreviews";
import { Stack } from "@shared/components/Stack";
import { TextArea } from "@shared/components/TextArea";

import { useConsentContext } from "./Context";
import { MessageButton } from "./MessageButton";

interface Props {
	sendMessage(text: string, files: ChatFile[]): void;
	isSending: boolean;
}

export function MessageComposer({ sendMessage }: Props) {
	const { requiresConsent, approvedAtMap } = useConsentContext();
	const [value, setValue] = useState("");
	const [files, setFiles] = useState<ChatFile[]>([]);
	const fileMapRef = useRef(new Map<string, File>());
	const isSendEnabled = value || files.length;
	const isDisabled = requiresConsent && !approvedAtMap;

	return (
		<div className="px-[10px] z-[1]">
			<Stack direction="vertical" align="end" gap={2}>
				<UploadPreviews
					fileIds={files.map((file) => file.id)}
					fileMap={fileMapRef.current}
					onRemoveFile={(fileId) =>
						setFiles((oldValue) =>
							oldValue.filter((file) => file.id !== fileId)
						)
					}
				/>
				<Stack direction="horizontal" justify="stretch" className="relative">
					<TextArea
						resizeable
						type="textarea"
						placeholder={
							isDisabled ? "Please consent to proceed." : "Start typing..."
						}
						disabled={isDisabled}
						value={value}
						className="border-[3px] rounded-[6px] border-gray-400 min-h-[70px] text-sm"
						onInput={(e: JSX.TargetedEvent<HTMLTextAreaElement, Event>) =>
							setValue(e.currentTarget.value)
						}
						onKeyDown={(e: JSX.TargetedKeyboardEvent<HTMLTextAreaElement>) => {
							const isEnterKey = e.key === "Enter";
							const isShiftKeyPressed = e.shiftKey;
							const shouldSend = isEnterKey && !isShiftKeyPressed;
							if (!shouldSend || !isSendEnabled) return;

							e.preventDefault();
							sendMessage(value, files);
							setValue("");
							setFiles([]);
						}}
					/>
				</Stack>
				<Stack
					direction="horizontal"
					align="center"
					justify="end"
					gap={10}
					className="mb-[24px] mt-[4px]"
				>
					<InputFiles
						fileMap={fileMapRef.current}
						onChange={setFiles}
						label="Attach Files"
						accept="application/pdf, image/png, image/jpeg, image/gif, image/svg+xml, image/webp, video/quicktime, video/mp4, audio/mp4, audio/mpeg"
						multiple
					/>
					<MessageButton
						data-testid="send-message-button"
						onClick={() => {
							sendMessage(value, files);
							setValue("");
							setFiles([]);
						}}
						disabled={!isSendEnabled}
					/>
				</Stack>
			</Stack>
		</div>
	);
}
