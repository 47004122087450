import { useLocation as useRouterLocation, useParams } from "react-router-dom";

export function useLocation() {
	const location = useRouterLocation();

	return {
		url: location.pathname + location.search,
		matches: useParams(), //variables,
		path: location.pathname,
	};
}
