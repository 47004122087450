import classNames from "classnames";

import type { InputWrapperProps as Props } from "@shared/components/Input";
import { useTheme } from "@shared/hooks/useTheme";

import styles from "./LabelWrapper.module.css";

export function LabelWrapper({ type, children, checked }: Props) {
	const { primaryForeground } = useTheme();
	return (
		<label
			className={classNames(styles.labelWrapper, styles[type], {
				[styles.isChecked]: checked,
			})}
			style={{
				borderColor: primaryForeground.withAlpha(0.2).color,
			}}
		>
			{children}
		</label>
	);
}
