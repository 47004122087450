import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	type ClipboardEvent,
} from "react";

import { Input } from "@shared/components/Input";

import styles from "./CodeInput.module.css";

interface Props {
	length: number;
	disabled?: boolean;
	onComplete: (code: string) => void;
}

export const CodeInput = forwardRef<{ onError: () => void }, Props>(
	({ length, disabled, onComplete }, ref) => {
		const inputRef = useRef<HTMLInputElement>(null);

		// Focus input on mount.
		useEffect(() => {
			inputRef.current?.focus();
		}, []);

		// Handle errors.
		useImperativeHandle(ref, () => ({
			onError() {
				const input = inputRef.current;
				if (!input) return;

				input.value = "";
			},
		}));

		// Handle all other input events.
		const onInput = () => {
			const input = inputRef.current;
			if (!input) return;

			const value = input.value;
			if (value.length > length) {
				input.value = value.slice(0, length);
			}

			const isComplete = input.value.length === length;
			if (isComplete) {
				onComplete(input.value.toUpperCase());
			}
		};

		// Handle pasting.
		const onPaste = (event: ClipboardEvent<HTMLInputElement>) => {
			event.preventDefault();

			const value = (event.clipboardData?.getData("text") ?? "").replace(
				/\D/g,
				""
			);

			const input = inputRef.current;
			if (!input) return;

			input.value = value.slice(0, length);

			const isComplete = input.value.length === length;
			if (isComplete) {
				onComplete(input.value.toUpperCase());
			}
		};

		return (
			<Input
				type="text"
				inputMode="numeric"
				className={styles.codeInput}
				ref={inputRef}
				maxLength={length}
				onInput={onInput}
				onPaste={onPaste}
				autoComplete="one-time-code"
				disabled={disabled}
				placeholder="------"
			/>
		);
	}
);
