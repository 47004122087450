import { useState } from "preact/hooks";
import { useQuery } from "urql";

import { useAuthContext } from "@app/components/Contexts";
import { StartLogin } from "@app/components/Forms/StartLogin";
import { Header } from "@app/components/Header";
import { Main } from "@app/components/Main";
import { TermsModal } from "@app/components/PatientHub/Visits/TermsModal";
import type { ContentInfo } from "@app/components/PatientHub/Visits/types";
import { Hero } from "@shared/components/Hero";
import { Stack } from "@shared/components/Stack";
import { useIntakeNavigation } from "@shared/hooks/useIntakeNavigation";
import { useTheme } from "@shared/hooks/useTheme";
import type { AuthStageProps } from "@shared/types/AuthStages";
import { AuthStage } from "@shared/types/AuthStages";
import { IntakeStage } from "@shared/types/IntakeStages";
import { WidgetLoadServiceDocument } from "@shared/types/graphql";

import { useContextualRedirect } from "../useContextualRedirect";

export function AuthStart({
	props: { serviceIntentId },
	onPreviousStage,
	onNextStage,
}: AuthStageProps<AuthStage.Start>) {
	const { navigateToIntake, navigateToIntakeStart } = useIntakeNavigation();
	const { spacing } = useTheme();
	const [{ data }] = useQuery({
		query: WidgetLoadServiceDocument,
		variables: {
			serviceIntentId: serviceIntentId as string,
		},
		pause: !serviceIntentId,
	});
	const service = data?.service_intent_by_pk?.service;
	const serviceId = service?.id;
	const serviceName = service?.name;
	const { patientId } = useAuthContext();
	const { fetching, error } = useContextualRedirect(!!patientId, {
		serviceIntentId,
	});
	const [contentInfo, showContent] = useState<ContentInfo>();

	return (
		<>
			<Header onBack={onPreviousStage} />
			<Main loading={fetching} error={error?.message}>
				<Stack
					direction="vertical"
					align="center"
					gap={spacing(5)}
					padding={spacing(3)}
				>
					<Hero
						title={
							serviceId
								? `Your ${`${
										serviceName?.toLowerCase() ?? ""
								  } treatment`.trim()} is almost ready.`
								: "Sign in"
						}
						subtitle={
							serviceId
								? "Please sign in to get connected with a specialist."
								: "View treatment updates and chat with your specialist."
						}
					/>

					<StartLogin
						serviceId={serviceId}
						onSuccess={({ phoneNumber, birthDate }) => {
							onNextStage(AuthStage.LoginCode, {
								phoneNumber,
								birthDate,
								serviceId,
								serviceIntentId,
							});
						}}
						// useTransitionState onPreviousStage doesnt support going back to the last stage (redirects to services instead)
						onBack={() =>
							serviceId
								? navigateToIntake(IntakeStage.Questions, {
										serviceId,
										serviceIntentId,
										isSecondaryQuestionStage: false,
								  })
								: navigateToIntakeStart()
						}
						showContent={showContent}
					/>
				</Stack>
			</Main>
			{contentInfo && (
				<TermsModal {...contentInfo} close={() => showContent(undefined)} />
			)}
		</>
	);
}
