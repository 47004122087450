import { createContext } from "preact";

import type { CombinedError, OperationResult } from "urql";

import type {
	WidgetGetTemporaryPatientMutation,
	WidgetGetTemporaryPatientMutationVariables,
	WidgetLoginWithCodeMutation,
	WidgetLoginWithCodeMutationVariables,
	WidgetStartExternalPatientLoginMutation,
	WidgetStartExternalPatientLoginMutationVariables,
	WidgetStartPatientLoginMutation,
	WidgetStartPatientLoginMutationVariables,
	WidgetVerifyLoginCodeQuery,
	WidgetVerifyLoginCodeQueryVariables,
} from "@shared/types/graphql";
import { asyncNoop, noop } from "@shared/utilities/noop";

interface AuthProviderType {
	patientId: string | null;
	authError: CombinedError | null;
	temporaryPatientId: string | null;
	temporaryPatientError?: CombinedError | null;
	getTemporaryPatient(
		widgetId?: string
	): Promise<
		| OperationResult<
				WidgetGetTemporaryPatientMutation,
				WidgetGetTemporaryPatientMutationVariables
		  >
		| undefined
	>;
	startPatientLogin(
		phoneNumber: string,
		birthDate: string,
		serviceId?: string,
		tempUserId?: string
	): Promise<
		OperationResult<
			WidgetStartPatientLoginMutation,
			WidgetStartPatientLoginMutationVariables
		>
	>;
	startExternalPatientLogin(
		externalId: string,
		loginToken: string
	): Promise<
		OperationResult<
			WidgetStartExternalPatientLoginMutation,
			WidgetStartExternalPatientLoginMutationVariables
		>
	>;
	verifyLoginCode(
		code: string,
		phoneNumber?: string,
		externalId?: string
	): Promise<
		OperationResult<
			WidgetVerifyLoginCodeQuery,
			WidgetVerifyLoginCodeQueryVariables
		>
	>;
	loginWithCode(
		code: string,
		phoneNumber?: string,
		dob?: string,
		externalId?: string
	): Promise<
		OperationResult<
			WidgetLoginWithCodeMutation,
			WidgetLoginWithCodeMutationVariables
		>
	>;

	logout(options?: { redirect?: boolean }): void;
}

export const AuthContext = createContext<AuthProviderType>({
	patientId: null,
	temporaryPatientId: null,
	getTemporaryPatient: asyncNoop,
	startPatientLogin: asyncNoop,
	verifyLoginCode: asyncNoop,
	loginWithCode: asyncNoop,
	logout: noop,
} as unknown as AuthProviderType);
