import { usePatientContext, useWidgetContext } from "@app/components/Contexts";
import { RouteMap } from "@app/constants/RouteMap";
import { useServiceIntent } from "@app/hooks/serviceIntent/useServiceIntent";
import { useNavigation } from "@shared/hooks/useNavigation";
import {
	EditableEncounterStatuses,
	StartedEncounterStatuses,
	EncounterStatus,
} from "@shared/types/EncounterStatus";

export function useChatButton() {
	const { push, queryParams } = useNavigation();
	const { widgetId } = useWidgetContext();
	const { patientId } = usePatientContext();
	const serviceIntentId = queryParams.serviceIntentId as string | undefined;

	const { serviceIntent } = useServiceIntent({ serviceIntentId });
	const { status, encounter } = serviceIntent || {};
	const { id: channelId, unread_count: unreadCount } =
		encounter?.portal_message_channel ?? {};

	const handleButtonClick = () => {
		if (!channelId) return;

		push(RouteMap.VisitChannel, {
			widgetId,
			channelId,
		});
	};

	const showUnreadIndicator = unreadCount != null && unreadCount > 0;
	const showChatButton =
		Boolean(patientId) &&
		Boolean(channelId) &&
		Boolean(status) &&
		[
			EncounterStatus.CAPTURE_QUESTIONS_COMPLETED_QUALIFIED,
			EncounterStatus.QUALIFICATION_QUESTIONS_COMPLETED_QUALIFIED,
			EncounterStatus.CAPTURE_QUESTIONS_COMPLETED_DISQUALIFIED,
			EncounterStatus.QUALIFICATION_QUESTIONS_COMPLETED_DISQUALIFIED,
			...EditableEncounterStatuses,
			...StartedEncounterStatuses,
		].includes(status as EncounterStatus);

	return {
		showChatButton,
		showUnreadIndicator,
		handleButtonClick,
	};
}
