import { compile } from "path-to-regexp";
import type { List, String } from "ts-toolbelt";

import { parse, stringify } from "@shared/utilities/queryString";

// Converts an array of strings, to an object with keys from the array.
// e.g.	input: ArrayToObject<["foo"], number>
// 			output: { "foo": number }
type ArrayToObject<Components extends string[], Values> = {
	[k in Components[number]]: Values;
};

// Filters an array of strings to only those starting with a prefix, and removes the prefix.
// e.g.	input: FilterWithPrefix<["foo", ":bar", ":baz"], ":">
// 			output: ["bar", "baz"]
type FilterWithPrefix<
	Components extends string[],
	Prefix extends string,
	Acc extends string[] = []
> = List.Length<Components> extends 0
	? Acc
	: FilterWithPrefix<
			List.Tail<Components>,
			Prefix,
			List.Head<Components> extends `${Prefix}${infer VariableName}`
				? [...Acc, VariableName]
				: Acc
	  >;

// Splits a string, S, by the path delimeter, "/".
// e.g.	input: PathComponents<"/foo/:bar/:baz", "/">
// 			output: ["foo", ":bar", ":baz"]
type PathComponents<S extends string> = String.Split<S, "/">;

// Makes an object out of path components in a string that start with ":".
// e.g.	input: PathVariables<"/foo/:bar/:baz">
// 			output: ["bar", "baz"]
export type PathVariables<Path extends string> = ArrayToObject<
	FilterWithPrefix<PathComponents<Path>, ":">,
	string | number
>;

/**
 * Inserts variables into a route path.
 * @param {String} templatePath - Route path in the format /foo/:bar/baz
 * @param {Object} variables - Object with variables your path requires.
 * @returns the path with the variables replaced.
 */
export function path<Path extends string>(
	templatePath: Path,
	variables: PathVariables<Path>,
	queryParams: Record<
		string,
		string | number | boolean | null | undefined
	> = {},
	// TODO: Deprecate, as we should always be replacing query params.
	replaceQueryParams = true
): string {
	const toEndpoint = compile(templatePath, {
		encode: encodeURIComponent,
	});
	const queryString = replaceQueryParams
		? stringify(queryParams ?? {})
		: stringify({
				...parse(window.location.href),
				...queryParams,
		  });

	return queryString.length
		? `${toEndpoint(variables)}?${queryString}`
		: toEndpoint(variables);
}
