import classNames from "classnames";
import { useLayoutEffect, useRef, useMemo, useCallback } from "preact/hooks";

import type { ChatFile } from "@shared/components/InputFiles/InputFiles";
import { Loader } from "@shared/components/Loader";
import type { PortalMessagePayload } from "@shared/components/Message/Message";
import { Stack } from "@shared/components/Stack";
import type { MessageFragment } from "@shared/types/graphql";
import { formatDate } from "@shared/utilities/formatDate";

import styles from "./MessageList.module.css";
import { MessageListItem } from "./MessageListItem";

const SCROLL_TOP_OFFSET = 200;

interface Props {
	messages: MessageFragment[];
	imageUrlsByUserId: Record<string, string>;
	fetchingOlder: boolean;
	fetchOlderMessages(): void;
	showImagePreview(url: string): void;
	onUploadFile(file: ChatFile): void;
}

type MessageWithPayload = MessageFragment & {
	payload: PortalMessagePayload | undefined;
};

export function MessageList({
	fetchingOlder,
	messages,
	imageUrlsByUserId,
	fetchOlderMessages,
	showImagePreview,
	onUploadFile,
}: Props) {
	const messageList = useMemo(() => {
		return (messages as MessageWithPayload[])?.map(
			(message, index, messages) => {
				const nextMessage = messages[index + 1];
				const previousMessage = messages[index - 1];

				return {
					...message,
					isFirstConsecutiveMessageBySender:
						!previousMessage ||
						previousMessage.sender_user_id !== message.sender_user_id ||
						(nextMessage && nextMessage.type === "event"),
					isFirstMessageOnDay:
						index === 0 ||
						formatDate(previousMessage?.created_at, "MM/DD/YYYY") !==
							formatDate(message.created_at, "MM/DD/YYYY"),
				};
			}
		);
	}, [messages]);

	const messageContainer = useRef<HTMLDivElement>(null);
	const onScroll = useCallback(() => {
		// When scrolled to top, load more messages.
		if (fetchingOlder) return;
		if (
			(messageContainer.current?.scrollTop ?? SCROLL_TOP_OFFSET) <
			SCROLL_TOP_OFFSET
		) {
			fetchOlderMessages();
		}
	}, [fetchOlderMessages, fetchingOlder]);
	useLayoutEffect(() => {
		const container = messageContainer.current;
		if (!container) return;

		// Make sure we're scrolled to the bottom.
		container.lastElementChild?.scrollIntoView();
		setTimeout(() => {
			container.scrollTop = container.scrollHeight;
		}, 10);
	}, [messageList?.length]);

	return (
		<div
			ref={messageContainer}
			className={classNames(styles.messageList, "pb-safe-8")}
			onScroll={onScroll}
		>
			{fetchingOlder && (
				<span>
					<Stack direction="vertical" align="center" justify="center">
						<Loader />
					</Stack>
				</span>
			)}
			{messageList?.map((props) => {
				if (props.type === "internal") return null;

				return (
					<MessageListItem
						key={props.id}
						{...props}
						onUploadFile={onUploadFile}
						imageUrl={
							props.sender_user_id
								? imageUrlsByUserId[props.sender_user_id]
								: null
						}
						showImagePreview={showImagePreview}
					/>
				);
			})}
		</div>
	);
}
