export type SurveyResponse = {
	currentPageNo: number;
	data: Record<string, unknown>;
};

export const isSurveyResponse = (
	maybeSurveyResponse: unknown
): maybeSurveyResponse is SurveyResponse => {
	return (
		!!maybeSurveyResponse &&
		typeof maybeSurveyResponse === "object" &&
		"currentPageNo" in maybeSurveyResponse &&
		"data" in maybeSurveyResponse &&
		typeof maybeSurveyResponse.currentPageNo === "number" &&
		typeof maybeSurveyResponse.data === "object"
	);
};

export const validateSurveyResponse = (maybeSurveyResponse: unknown) => {
	return isSurveyResponse(maybeSurveyResponse) ? maybeSurveyResponse : null;
};
