import { memo } from "preact/compat";

import { ContractLink } from "./ContractLink";
import styles from "./TermsButton.module.css";

interface Props {
	label: string;
	title: string;
	text: string;
	link?: Maybe<string>;
	onClick?: (content: { title: string; text: string }) => void;
}
function TermsButton({ label, title, text, link, onClick }: Props) {
	if (text) {
		return (
			<button
				type="button"
				className={styles.contractButton}
				onClick={() =>
					onClick?.({
						title,
						text,
					})
				}
			>
				{label}
			</button>
		);
	}
	return <ContractLink link={link}>{label}</ContractLink>;
}

export default memo(TermsButton);
