import { useState, useMemo, useEffect } from "preact/hooks";
import { useMutation, useQuery } from "urql";

import { usePatientContext } from "@app/components/Contexts";
import { Form } from "@shared/components/Form";
import { InputSelect } from "@shared/components/InputSelect";
import { InputZipCode } from "@shared/components/InputZipCode";
import { Stack } from "@shared/components/Stack";
import { Text } from "@shared/components/Text";
import { useTheme } from "@shared/hooks/useTheme";
import {
	WidgetGetPharmacyListDocument,
	WidgetSetPreferredPharmacyDocument,
} from "@shared/types/graphql";
import { debug } from "@shared/utilities/debug";
import { isValidZipCode } from "@shared/utilities/isValidZipCode";

const pascalCase = (str: string) =>
	str
		.split(" ")
		.map((word) => {
			const exceptions = ["CVS"];
			if (exceptions.includes(word)) return word;
			return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
		})
		.join(" ");

interface Props {
	serviceIntentId?: string;
	pharmacyId?: string;
	pharmacyZip?: string;
	title?: string;
	nextButtonText?: string;
	prevButtonText?: string;
	onBack?(): void;
	onSuccess?: (pharmacyId?: string) => void;
	showSuccessMessage?: boolean;
	isDetail?: boolean;
}

export function PharmacyPicker({
	serviceIntentId = "",
	pharmacyId: preferredPharmacyId,
	pharmacyZip,
	title,
	nextButtonText = "Save",
	prevButtonText,
	showSuccessMessage,
	isDetail,
	onBack,
	onSuccess,
}: Props) {
	const { spacing } = useTheme();
	const { patientId } = usePatientContext();
	const [zipCode, setZipCode] = useState("");
	const [pharmacyId, setPharmacyId] = useState<string | undefined>();
	const [successMessage, setSuccessMessage] = useState<string | null>(null);

	const isMissingRequiredInfo =
		!zipCode || !pharmacyId || (isDetail && pharmacyId === preferredPharmacyId);

	useEffect(() => {
		if (preferredPharmacyId) {
			setPharmacyId(preferredPharmacyId);
		}
		if (pharmacyZip) {
			setZipCode(pharmacyZip);
		}
	}, [preferredPharmacyId, pharmacyZip]);

	const [{ data: pharmacyListData, fetching: pharmacyListFetching }] = useQuery(
		{
			query: WidgetGetPharmacyListDocument,
			variables: { zipCode },
			pause: !isValidZipCode(zipCode),
		}
	);

	const pharmacyList = pharmacyListData?.WidgetGetPharmacyList;

	useEffect(() => {
		if (
			(pharmacyZip &&
				pharmacyZip !== zipCode &&
				pharmacyId &&
				pharmacyListFetching) ||
			(!pharmacyZip && pharmacyId && pharmacyListFetching)
		) {
			setPharmacyId(undefined);
		}
	}, [pharmacyId, pharmacyListFetching, pharmacyZip, zipCode]);

	const [, setPreferredPharmacy] = useMutation(
		WidgetSetPreferredPharmacyDocument
	);

	const onSubmitInfo = async () => {
		if (!patientId || !pharmacyId) {
			throw new Error(
				"Error: Missing required patient or pharmacy information."
			);
		}

		if (pharmacyId === preferredPharmacyId) {
			return onSuccess?.(pharmacyId);
		}

		try {
			await setPreferredPharmacy({
				pharmacyId,
				serviceIntentId,
			});
			if (showSuccessMessage) {
				setSuccessMessage(
					"We've successfully updated your preferred pharmacy."
				);
			}
		} catch (error) {
			debug("error", {
				context: "PharmacyPicker",
				message: "Error setting pharmacy for service intent",
				info: { error },
			});
		} finally {
			onSuccess?.(pharmacyId);
		}
	};

	const options = useMemo(() => {
		return (
			pharmacyList?.map((pharmacy) => ({
				label: (
					<Stack direction="vertical">
						<Text bold>{pascalCase(pharmacy?.name ?? "")}</Text>
						<Text>{pascalCase(pharmacy?.address ?? "")}</Text>
						<Text>
							{pascalCase(pharmacy?.city ?? "")},{" "}
							{pascalCase(pharmacy?.state ?? "")}
						</Text>
					</Stack>
				),
				searchableLabel: `${pharmacy?.name} ${pharmacy?.address} ${pharmacy?.city} ${pharmacy?.state}`,
				value: pharmacy?.ncpdp ?? "",
			})) ?? []
		);
	}, [pharmacyList]);

	const isLoading = pharmacyListFetching;

	return (
		<Form
			title={title}
			successMessage={successMessage}
			submitButtonProps={{
				text: nextButtonText,
				disabled: isLoading || isMissingRequiredInfo,
			}}
			additionalButtonProps={
				prevButtonText
					? {
							text: prevButtonText,
							variant: "secondary",
							onClick: onBack,
					  }
					: undefined
			}
			onSubmit={onSubmitInfo}
		>
			{({ isSubmitting }) => (
				<Stack direction="vertical" gap={spacing(3)}>
					<InputZipCode
						label="ZIP Code"
						value={zipCode}
						onInput={setZipCode}
						disabled={isSubmitting}
						maxLength={5}
						required
					/>
					<InputSelect
						label="Pharmacy"
						placeholder="Select a pharmacy"
						id="pharmacy-select"
						options={options}
						value={pharmacyId}
						onChange={setPharmacyId}
						isLoading={isLoading}
						filterOption={(option, inputValue) =>
							option.data.searchableLabel
								?.toLowerCase()
								.includes(inputValue.toLowerCase()) as boolean
						}
						isDisabled={isLoading || isSubmitting || !isValidZipCode(zipCode)}
						className="border-none"
					/>
				</Stack>
			)}
		</Form>
	);
}
