import type { ComponentChildren } from "preact";

import { useEffect, useMemo } from "preact/hooks";
import { useQuery } from "urql";

import { Main } from "@app/components/Main";
import { PAYTIENT_ORG_ID } from "@shared/constants/environments";
import { useFirst } from "@shared/hooks/useFirst";
import {
	WidgetLoadOrganizationContextDocument,
	WidgetLoadOrganizationLegalContextDocument,
	WidgetLoadOrganizationLegalContextWithoutAuthDocument,
} from "@shared/types/graphql";
import { debug } from "@shared/utilities/debug";

import { captureEvent } from "../../../utilities/eventTracker";
import { usePatientContext } from "../PatientContext";
import { useWidgetContext } from "../WidgetContext";
import type { PaymentType, ProviderNetworkName } from "./OrganizationContext";
import { OrganizationContext, LegalFormType } from "./OrganizationContext";

interface Props {
	children: ComponentChildren;
}

export function OrganizationContextProvider({ children }: Props) {
	const { patientId } = usePatientContext();
	const { widgetId } = useWidgetContext();
	const [{ data, fetching, error }] = useQuery({
		query: WidgetLoadOrganizationContextDocument,
		variables: {
			widgetId,
			isAuthenticated: !!patientId,
		},
		pause: !widgetId,
	});

	useEffect(() => {
		captureEvent("widget_initialized", { patientId, widgetId });
	}, [patientId, widgetId]);

	const organization = useFirst(data?.organization);
	const defaultMenuId = data?.defaultMenuId ?? null;
	const [
		{
			fetching: fetchingLegalNoAuth,
			data: legalDataWithoutAuth,
			error: errorLegalNoAuth,
		},
	] = useQuery({
		query: WidgetLoadOrganizationLegalContextWithoutAuthDocument,
		variables: {},
		pause: !!patientId,
	});
	const [{ fetching: fetchingLegal, data: legalData, error: errorLegal }] =
		useQuery({
			query: WidgetLoadOrganizationLegalContextDocument,
			variables: {
				widgetId,
			},
			pause: !widgetId || !patientId,
		});
	const organizationLegal = useMemo(() => {
		if (!legalData?.legalForms && !legalDataWithoutAuth?.legalForms)
			return null;
		if (legalData?.legalForms) {
			const termsOfUse = legalData.legalForms.find(
				(form) => form.consentFormType === LegalFormType.TermsOfUse
			);
			const privacyPolicy = legalData.legalForms.find(
				(form) => form.consentFormType === LegalFormType.PrivacyPolicy
			);
			return {
				termsOfUse: termsOfUse?.text,
				privacyPolicy: privacyPolicy?.text,
			};
		}

		const termsOfUse = legalDataWithoutAuth?.legalForms.find(
			(form) => form.consentFormType === LegalFormType.TermsOfUse
		);
		const privacyPolicy = legalDataWithoutAuth?.legalForms.find(
			(form) => form.consentFormType === LegalFormType.PrivacyPolicy
		);
		return { termsOfUse: termsOfUse?.text, privacyPolicy: privacyPolicy?.text };
	}, [legalData?.legalForms, legalDataWithoutAuth?.legalForms]);
	const isLoading = fetching || fetchingLegal || fetchingLegalNoAuth;
	const dataError = error || errorLegal || errorLegalNoAuth;

	useEffect(() => {
		if (!dataError) return;
		debug("error", {
			context: "OrganizationContext",
			message: "Error loading organization context",
			info: dataError,
		});
	}, [dataError]);

	const hasAllData = !!organization && !!organizationLegal;
	useEffect(() => {
		if (!hasAllData) return;

		debug("info", {
			context: "OrganizationContextProvider",
			message: "Organization context loaded.",
			info: {
				organization,
				organizationLegal,
			},
		});
	}, [hasAllData, organization, organizationLegal]);

	if (isLoading) {
		return <Main loading />;
	} else if (!widgetId) {
		return <Main error="[Error] Missing Widget ID." />;
	} else if (!hasAllData || dataError?.message) {
		return (
			<Main
				error={
					dataError?.message ?? "[Error] Failed to load organization data."
				}
			/>
		);
	}

	const organizationId = organization.exId ?? "";
	const isPaytient = organizationId === PAYTIENT_ORG_ID;

	return (
		<OrganizationContext.Provider
			value={{
				error,
				fetching,
				isPaytient,
				organizationId,
				organizationName: organization.name,
				termsOfUse: organization.termsOfUse,
				privacyPolicy: organization.privacyPolicy,
				consentToTelehealth: organization.consentToTelehealth,
				noticeToPrivacyPractices: organization.noticeToPrivacyPractices,
				logoUrl: organization.logoUrl,
				grayscaleLogoUrl: organization.grayscaleLogoUrl,
				paymentType: organization.paymentType as PaymentType,
				showShippingAddress: Boolean(organization.showShippingAddress),
				showSignoutButton: Boolean(organization.showSignoutButton),
				bypassPrepayment: Boolean(organization.bypassPrepayment),
				defaultMenuId,
				legalForm: organizationLegal,
				ehrIntegrated: organization?.ehrIntegrated,
				providerNetworkName:
					organization?.providerNetworkName as ProviderNetworkName,
			}}
		>
			{children}
		</OrganizationContext.Provider>
	);
}
