import { useMemo } from "preact/hooks";

import { useTheme } from "@shared/hooks/useTheme";
import { isDarkMode } from "@shared/utilities/isDarkMode";
import type { Appearance } from "@stripe/stripe-js";

export function useStripeAppearance() {
	const {
		primaryFont = "",
		primaryBackground,
		primaryForeground,
		primaryPlaceholder,
		primaryAccent600,
		primaryAccent50,
		successBackground,
		successForeground,
		dangerBackground,
		dangerForeground,
		warningBackground,
		warningForeground,
		spacing,
	} = useTheme();

	return useMemo(
		(): Appearance =>
			({
				theme: "none",
				disableAnimations: false,
				variables: {
					fontFamily: `"${primaryFont}", "Helvetica Neue", sans-serif`,
					fontLineHeight: "1.5",
					borderRadius: "0px",
					colorBackground: primaryBackground.rgb,
					colorPrimary: primaryAccent600?.rgb,
					colorPrimaryText: primaryAccent50?.rgb,
					colorText: primaryForeground?.rgb,
					colorTextSecondary: primaryForeground?.rgb,
					colorTextPlaceholder: primaryPlaceholder?.rgb,
					colorIcon: primaryPlaceholder?.rgb,
					colorIconHover: primaryForeground?.rgb,
					colorSuccess: successBackground.rgb,
					colorSuccessText: successForeground.rgb,
					colorDanger: dangerBackground.rgb,
					colorDangerText: dangerForeground.rgb,
					colorWarning: warningBackground.rgb,
					colorWarningText: warningForeground.rgb,
				},
				rules: {
					".CheckboxLabel": {
						color: "var(--colorText)",
						"-webkit-text-fill-color": "var(--colorText)",
					},
					".Block": {
						backgroundColor: "var(--colorBackground)",
						boxShadow: "none",
						padding: `${spacing(3)}px`,
					},
					".Input--empty": {
						backgroundColor: "transparent",
						boxShadow: "none",
						transition: "box-shadow 1ms",
					},
					".Input": {
						backgroundColor: "transparent",
						padding: `${spacing(2)}px ${spacing(1.5)}px ${spacing(1.5)}px`,
						borderBottom: "2px solid var(--colorPrimary)",
						borderRadius: "3px",
						color: "var(--colorText)",
						"-webkit-text-fill-color": "var(--colorText)",
						boxShadow: isDarkMode()
							? `0 0 0px 1000px var(--colorBackground) inset`
							: "none",
					},
					".Input::placeholder": {
						color: "var(--colorTextPlaceholder)",
						"-webkit-text-fill-color": "var(--colorTextPlaceholder)",
					},
					".Input:autofill": {
						boxShadow: isDarkMode()
							? `0 0 0px 1000px var(--colorBackground) inset`
							: "none",
						transition: "box-shadow 1ms",
					},
					".Input:focus": {
						outline: "3px solid var(--colorText)",
					},
					".Input:disabled, .Input--invalid:disabled": {
						color: "lightgray",
					},
					".Label": {
						color: "var(--colorText)",
						fontWeight: "600",
						fontSize: "0.5em",
						lineHeight: "1.5em",
						textTransform: "uppercase",
					},
				},
			} as const),
		[
			primaryFont,
			primaryAccent600?.rgb,
			primaryAccent50?.rgb,
			primaryBackground?.rgb,
			primaryForeground?.rgb,
			primaryPlaceholder?.rgb,
			successBackground.rgb,
			successForeground.rgb,
			dangerBackground.rgb,
			dangerForeground.rgb,
			warningBackground.rgb,
			warningForeground.rgb,
			spacing,
		]
	);
}
