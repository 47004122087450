import { useQuery } from "urql";

import {
	useOrganizationContext,
	useWidgetContext,
} from "@app/components/Contexts";
import { Header } from "@app/components/Header";
import { Main } from "@app/components/Main";
import { INERT_STATUSES } from "@app/components/PatientHub/Visits/VisitsList/constants";
import { RouteMap } from "@app/constants/RouteMap";
import { Button } from "@shared/components/Button";
import { Hero } from "@shared/components/Hero";
import { Highlight } from "@shared/components/Highlight";
import { Link } from "@shared/components/Link";
import { Stack } from "@shared/components/Stack";
import { Text } from "@shared/components/Text";
import { useAuthRequired } from "@shared/hooks/useAuthRequired";
import { useNavigation } from "@shared/hooks/useNavigation";
import { useTheme } from "@shared/hooks/useTheme";
import type { IntakeStageProps } from "@shared/types/IntakeStages";
import { IntakeStage } from "@shared/types/IntakeStages";
import type { ServiceIntentStatus } from "@shared/types/ServiceIntentStatus";
import { WidgetGetDisqualificationReasonDocument } from "@shared/types/graphql";

export function Disqualified({
	props: { serviceIntentId },
	onNextStage,
}: IntakeStageProps<IntakeStage.Disqualified>) {
	const { spacing } = useTheme();
	const { push } = useNavigation();
	const { widgetId } = useWidgetContext();
	const { defaultMenuId, organizationId } = useOrganizationContext();
	const [{ data, fetching, error }] = useQuery({
		query: WidgetGetDisqualificationReasonDocument,
		variables: {
			serviceIntentId,
		},
		pause: !serviceIntentId,
	});
	const serviceIntent = data?.service_intent_by_pk;
	const reasonText = serviceIntent?.disqualification_reason;
	const channelId = serviceIntent?.encounter?.portal_message_channel?.id;
	const needsAuth = useAuthRequired(true);

	const isEsseOrg = organizationId === "c2a81423-3646-432d-b720-14c3a0990e1a";
	const showSendMessageButton =
		channelId &&
		INERT_STATUSES.includes(serviceIntent?.status as ServiceIntentStatus);

	const handleBackToMenu = () => {
		if (defaultMenuId) {
			onNextStage(IntakeStage.Menu, {
				menuId: defaultMenuId,
			});

			return;
		}

		onNextStage(IntakeStage.Services, {});
	};

	const handleMessageSend = () => {
		if (!channelId) return;

		push(RouteMap.VisitChannel, {
			widgetId,
			channelId,
		});
	};

	return (
		<>
			<Header />
			<Main loading={needsAuth || fetching} error={error?.message}>
				{isEsseOrg ? (
					<Stack direction="vertical" align="center" gap={spacing(5)}>
						<Hero
							title="We want you Well"
							subtitle="Thanks for picking Esse Health. We think it's best for you to see a doctor in person."
						/>
						{reasonText && <Highlight caption="Reason:" text={reasonText} />}
						<Text variant="small" align="center">
							Call <Link to="tel:1-866-377-3669" text="1-866-377-3669" /> to
							connect with an Esse primary care provider.
						</Text>
						<Text variant="small" align="center">
							In the event of a medical emergency, dial 911 immediately. For
							urgent mental health support, including suicidal thoughts, the
							National Suicide Prevention Lifeline at 988 is available 24/7, or
							you can seek immediate help locally.
						</Text>
						<Button text="Back to Menu" onClick={handleBackToMenu} />
					</Stack>
				) : (
					<Stack direction="vertical" align="center" gap={spacing(5)}>
						<Hero
							title="Your health is the priority"
							subtitle="Based on the information you provided, we recommend booking an in-person visit with a primary care physician (PCP) or visiting an urgent care facility near you for a more thorough evaluation."
						/>
						{reasonText && <Highlight caption="Reason:" text={reasonText} />}
						<Text variant="small" align="center">
							If this is a medical emergency, please dial 911.
						</Text>
						<Text variant="small" align="center">
							If you have had suicidal thoughts or feel like hurting yourself or
							others please seek immediate local care, dial 911, or call the
							National Suicide Prevention Lifeline (24/7) at 988.
						</Text>
						{showSendMessageButton && (
							<Button
								variant="outline"
								text="Send us a Message"
								onClick={handleMessageSend}
							/>
						)}
						<Button text="Back to Menu" onClick={handleBackToMenu} />
					</Stack>
				)}
			</Main>
		</>
	);
}
