import type { FunctionalComponent } from "preact";

import { useCallback } from "preact/hooks";

import {
	createAnimationTransition,
	useTransitionState,
} from "@shared/components/AnimationTransition";
import { useAuthRequired } from "@shared/hooks/useAuthRequired";
import type { IntakeStage, IntakeStageProps } from "@shared/types/IntakeStages";

import { Address } from "./Address";
import type { CheckoutStepProps } from "./CheckoutStep";
import { CheckoutStep } from "./CheckoutStep";
import { Payment } from "./Payment";
import { Pharmacy } from "./Pharmacy";
import { Success } from "./Success";

const CheckoutStepTransition = createAnimationTransition<CheckoutStep>();
export type CheckoutStepComponent = FunctionalComponent<CheckoutStepProps>;
const CHECKOUT_FLOW_COMPONENTS: Record<CheckoutStep, CheckoutStepComponent> = {
	[CheckoutStep.Pharmacy]: Pharmacy,
	[CheckoutStep.Address]: Address,
	[CheckoutStep.Payment]: Payment,
	[CheckoutStep.Success]: Success,
};
const DEFAULT_STEP_ORDER = [
	CheckoutStep.Pharmacy,
	CheckoutStep.Address,
	CheckoutStep.Payment,
	CheckoutStep.Success,
];

export function Checkout({
	props: { serviceIntentId, step = CheckoutStep.Pharmacy },
	onPreviousStage,
}: IntakeStageProps<IntakeStage.Checkout>) {
	const [currentStep, { direction, onPrevious, onNext }] = useTransitionState(
		DEFAULT_STEP_ORDER.slice(0, DEFAULT_STEP_ORDER.indexOf(step) + 1).reverse()
	);
	const onPreviousStep = useCallback(async () => {
		const previousStep = await onPrevious();
		if (previousStep === currentStep) {
			onPreviousStage();
			return;
		}
	}, [currentStep, onPrevious, onPreviousStage]);
	useAuthRequired(true);

	return (
		<CheckoutStepTransition
			activeStage={currentStep}
			direction={direction}
			renderStage={(page) => {
				const StepComponent = CHECKOUT_FLOW_COMPONENTS[page];

				return (
					<StepComponent
						serviceIntentId={serviceIntentId}
						onPreviousStep={onPreviousStep}
						onNextStep={(step) => onNext(step)}
					/>
				);
			}}
		/>
	);
}
