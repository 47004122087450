import { useEffect } from "preact/hooks";

import { Main } from "@app/components/Main";
import { useLocation } from "@shared/hooks/useLocation";
import { debug } from "@shared/utilities/debug";

export const NotFound = () => {
	const { url } = useLocation();
	useEffect(
		() =>
			debug("error", {
				context: "NotFound",
				message: "Error resolving route.",
				info: { url },
			}),
		[url]
	);
	return <Main error="This is not the page you are looking for." />;
};
