import { Text } from "../Text";
import styles from "./Link.module.css";

interface Props {
	to: string;
	text: string;
	opensInNewTab?: boolean;
}

export function Link({ to, text, opensInNewTab }: Props) {
	return (
		<a
			href={to}
			rel="noopener noreferrer"
			className={styles.link}
			{...(opensInNewTab ? { target: "_blank" } : {})}
		>
			<Text variant="link">{text}</Text>
		</a>
	);
}
