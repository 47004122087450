/* eslint-disable jsx-a11y/no-static-element-interactions */
import type { ReactNode, MouseEvent } from "react";
import { useRef } from "react";

import { useKeyboardEvent } from "@shared/hooks/useKeyboardEvent";

interface Props {
	children: ReactNode;
	close(): void;
}

export function ModalBackdrop({ children, close }: Props) {
	useKeyboardEvent((event) => {
		if (event.key === "Escape") {
			close();
		}
	});
	const backdropRef = useRef<HTMLDivElement>(null);

	const handleClick = (event: MouseEvent<HTMLDivElement>) => {
		if (event.target === backdropRef.current) {
			close();
		}
	};

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events -- handled by useKeyboardEvent
		<div
			ref={backdropRef}
			onClick={handleClick}
			className="absolute top-0 left-0 w-screen h-screen flex flex-col justify-center items-center bg-opacity-75 bg-gray-500 backdrop-blur-md px-4 py-8 z-10"
		>
			{children}
		</div>
	);
}
