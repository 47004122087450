import type { Theme } from "./Theme";
import { ThemeColor } from "./ThemeColor";
import type { Color, ThemeColors, ThemeKey } from "./types";

export function transformToThemeColor(
	colors?: Record<string, Color>
): Partial<Theme> | null {
	if (!colors) {
		return null;
	}

	return Object.fromEntries(
		Object.entries(colors).map(([key, value]) => {
			return [key, new ThemeColor(value)];
		})
	);
}

export function transformToPaletteThemeColor(
	colors?: ThemeColors
): Record<string, ThemeColor> | null {
	if (!colors) {
		return null;
	}

	const transformedColors: Record<string, ThemeColor> = {};

	for (const themeKey of Object.keys(colors) as Array<keyof ThemeColors>) {
		for (const shadeKey of Object.keys(colors[themeKey]) as Array<
			keyof (typeof colors)[ThemeKey]
		>) {
			const color = colors[themeKey][shadeKey];
			transformedColors[`${themeKey}${shadeKey}`] = new ThemeColor(color);
		}
	}

	return transformedColors;
}
