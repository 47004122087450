import type { CSSProperties } from "react";

import classNames from "classnames";

import type { ThemeColor } from "@shared/hooks/useTheme";

import styles from "./Icon.module.css";

export interface Props {
	icon: string;
	fill?: ThemeColor;
	stroke?: ThemeColor;
	strokeWidth?: number;
	size?: 64 | 48 | 36 | 24 | 20 | 18 | 16 | 14 | 10;
	inline?: boolean;
	title?: string;
	className?: string;
	style?: CSSProperties;
}

export function Icon({
	icon,
	fill,
	stroke,
	strokeWidth = 2,
	size = 24,
	inline,
	title,
	className,
	...restProps
}: Props) {
	const svg = icon.replace("data:image/svg+xml;utf8,", "");
	return (
		<div
			className={classNames(className, styles.icon)}
			style={
				{
					fill: fill?.color ?? "transparent",
					stroke: stroke?.color ?? "transparent",
					strokeWidth: strokeWidth.toString(),
					width: size,
					height: size,
					display: inline ? "inline-block" : "block",
				} as CSSProperties
			}
			title={title}
			{...restProps}
		>
			{/* eslint-disable-next-line react/no-danger */}
			<div className="icon-inner" dangerouslySetInnerHTML={{ __html: svg }} />
		</div>
	);
}
