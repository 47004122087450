import { useState } from "preact/hooks";

import { Form, FormContent } from "@shared/components/Form";
import { Stack } from "@shared/components/Stack";
import { useTheme } from "@shared/hooks/useTheme";
import type { AddressInput } from "@shared/types/graphql";

import { AddressPicker } from "../AddressPicker";

interface Props {
	buttonText?: string;
	prevButtonText?: string;
	buttonDisabled?: boolean;
	onBack?(): void;
	onSuccess?: () => void;
}

export function BypassForm({
	buttonText,
	prevButtonText,
	buttonDisabled,
	onBack,
	onSuccess,
}: Props) {
	const { spacing } = useTheme();
	const [billingAddress, setBillingAddress] = useState<AddressInput | null>(
		null
	);

	return (
		<Form
			plain
			onSubmit={(event) => {
				event.preventDefault();
				onSuccess?.();
				return Promise.resolve();
			}}
		>
			{({ errorMessage, successMessage, isSubmitting }) => (
				<Stack direction="vertical" gap={spacing(5)}>
					<FormContent isSubmitting={isSubmitting}>
						<AddressPicker
							showAsBilling
							showShippingAddress={false}
							onChange={setBillingAddress}
							buttonDisabled={isSubmitting}
						/>
					</FormContent>
					<FormContent
						title=""
						subtitle=""
						errorMessage={errorMessage}
						successMessage={successMessage}
						submitButtonProps={{
							text: buttonText,
							disabled: buttonDisabled || !billingAddress,
						}}
						additionalButtonProps={{
							text: prevButtonText,
							variant: "secondary",
							onClick: onBack,
						}}
						isSubmitting={isSubmitting}
					>
						<></>
					</FormContent>
				</Stack>
			)}
		</Form>
	);
}
