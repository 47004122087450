import { closeOutline, documentTextOutline, print } from "ionicons/icons";
import { useMemo } from "preact/hooks";
import { useQuery } from "urql";

import { Button } from "@shared/components/Button";
import { Hero } from "@shared/components/Hero";
import { Icon } from "@shared/components/Icon";
import { ModalBackdrop } from "@shared/components/ModalBackdrop";
import { Stack } from "@shared/components/Stack";
import { Text } from "@shared/components/Text";
import { useIconStyles } from "@shared/hooks/useIconStyles";
import { useTheme } from "@shared/hooks/useTheme";
import { WidgetGetConsentFormTextForPractitionersDocument } from "@shared/types/graphql";

import styles from "./ConsentModal.module.css";

interface Props {
	consentFormId: string;
	practitionerIds: string[];
	title: string;
	close(): void;
}

export function ConsentModal({
	consentFormId,
	practitionerIds,
	title,
	close,
}: Props) {
	const { primaryForeground } = useTheme();
	const iconProps = useIconStyles({
		icon: documentTextOutline,
		size: 36,
		strokeWidth: 20,
	});
	const [{ data, fetching }] = useQuery({
		query: WidgetGetConsentFormTextForPractitionersDocument,
		variables: {
			consentFormId,
			practitionerIds,
		},
	});

	const texts = useMemo(
		() => data?.getConsentFormTextForPractitioners ?? [],
		[data?.getConsentFormTextForPractitioners]
	);

	if (fetching) return null;

	return (
		<ModalBackdrop close={close}>
			<div className={styles.modalContent}>
				<Stack direction="vertical" gap={0}>
					<Stack direction="horizontal" justify="end" gap={4}>
						<Button variant="plain" onClick={() => window.print()}>
							<Icon icon={print} size={24} fill={primaryForeground} />
						</Button>
						<Button variant="plain" onClick={close}>
							<Icon
								icon={closeOutline}
								size={24}
								stroke={primaryForeground}
								strokeWidth={60}
							/>
						</Button>
					</Stack>
					<Hero title={title} iconProps={iconProps} />
					{texts.map(({ text, practitionerId }) => (
						<Text
							key={practitionerId}
							variant="body"
							className="whitespace-pre-wrap leading-5 border-b-2 border-b-white border-solid border-0 mb-12 pb-12 last:border-b-0 last:mb-0"
						>
							{text}
						</Text>
					))}
				</Stack>
			</div>
		</ModalBackdrop>
	);
}
