import { createContext } from "preact";

import { useContext } from "preact/hooks";

import type {
	AuthStage,
	AuthStageTransitionProps,
} from "@shared/types/AuthStages";
import { noop } from "@shared/utilities/noop";

interface AuthStageContextValue<CurrentStage extends AuthStage> {
	currentStage: CurrentStage | undefined;
	currentStageProps: AuthStageTransitionProps[CurrentStage];
	onPreviousStage: () => void;
	onNextStage: <NextStage extends AuthStage>(
		stage: NextStage,
		props: AuthStageTransitionProps[NextStage]
	) => void;
	goToStage: <NextStage extends AuthStage>(
		stage: NextStage,
		props: AuthStageTransitionProps[NextStage]
	) => void;
	stages: AuthStage[];
}

export const AuthStageContext = createContext<AuthStageContextValue<AuthStage>>(
	{
		currentStage: undefined,
		currentStageProps: {} as $FixMe,
		onPreviousStage: noop,
		onNextStage: noop,
		goToStage: noop,
		stages: [],
	}
);

export function useAuthStages() {
	return useContext(AuthStageContext);
}
