import type { CombinedError } from "urql";

export const formatInternalError = (error?: CombinedError) => {
	if (!error) {
		return "";
	}

	const { graphQLErrors, networkError } = error;

	if (graphQLErrors) {
		return graphQLErrors
			.map(({ extensions, message }) => {
				if (!extensions?.internal) {
					return message;
				}
				const { response } = extensions?.internal as unknown as {
					response: { body: { error: string } };
				};

				return response?.body?.error ?? message;
			})
			.join(", ");
	}

	if (networkError) {
		return networkError.message;
	}

	return error.message;
};
