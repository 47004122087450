import { Component } from "react";
import type { ErrorInfo, ReactNode } from "react";

import { debug } from "@shared/utilities/debug";

interface Props {
	children: ReactNode;
	fallback?: (props: { errorMessage: string }) => ReactNode;
}

interface State {
	error: Error | null;
}

export class ErrorBoundary extends Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = { error: null };
	}

	static getDerivedStateFromError(error: Error) {
		return { error };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		debug("info", {
			context: "ErrorBoundary",
			message: "errorInfo",
			info: errorInfo,
		});
	}

	render() {
		if (this.state.error) {
			return this.props.fallback
				? this.props.fallback({ errorMessage: this.state.error.message })
				: null;
		}

		return this.props.children;
	}
}
