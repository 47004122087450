import { useMemo, useRef } from "preact/hooks";
import { useReactToPrint } from "react-to-print";
import { useQuery } from "urql";

import {
	useOrganizationContext,
	usePatientContext,
} from "@app/components/Contexts";
import { ProviderNetworkNameMapping } from "@app/components/Contexts/OrganizationContext/OrganizationContext";
import { Header } from "@app/components/Header";
import { Main } from "@app/components/Main";
import { getTextFromMapping } from "@app/utilities/getTextFromMapping";
import { Button } from "@shared/components/Button";
import { Highlight } from "@shared/components/Highlight";
import { HorizontalLine } from "@shared/components/HorizontalLine";
import { Stack } from "@shared/components/Stack";
import { Text } from "@shared/components/Text";
import { useFirst } from "@shared/hooks/useFirst";
import { useFormatter } from "@shared/hooks/useFormatter";
import { useLocation } from "@shared/hooks/useLocation";
import { useTheme } from "@shared/hooks/useTheme";
import { OrderStatus } from "@shared/types/OrderStatus";
import { WidgetGetVisitChannelDetailsDocument } from "@shared/types/graphql";
import { capitalize } from "@shared/utilities/capitalize";
import { formatDate } from "@shared/utilities/formatDate";

import type { HubPageProps } from "../HubPage";
import styles from "./VisitChannelDetails.module.css";

export function VisitChannelDetails({ onPreviousPage }: HubPageProps) {
	const { spacing } = useTheme();
	const { matches } = useLocation();
	const { patientFullName } = usePatientContext();
	const organization = useOrganizationContext();
	const componentRef = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	const channelId = matches?.channelId;

	const formatter = useFormatter({
		style: "currency",
		currency: "USD",
	});
	const [{ data, fetching, error }] = useQuery({
		query: WidgetGetVisitChannelDetailsDocument,
		variables: {
			channelId: channelId as string,
		},
		pause: !channelId,
	});

	const encounter = useFirst(data?.encounter);
	const channel = useFirst(data?.portal_message_channel);

	const {
		pharmacy,
		prescriptions,
		date,
		storeName,
		addressLine1,
		cityStateZip,
		treatmentPlanText,
		serviceName,
		servicePrice,
		externalOrderId,
		cardBrand,
		cardLastFour,
		orderDate,
		discountCode,
		orderAmount,
		paymentDate,
		status,
		practitioner,
		network,
	} = useMemo(() => {
		const pharmacy = encounter?.pharmacy;
		const prescriptions = encounter?.prescriptions ?? [];

		const date = channel?.created_at
			? formatDate(channel.created_at, "MM/DD/YYYY")
			: "-";
		const storeName = `${pharmacy?.name ?? "-"}`;
		const addressLine1 = `${pharmacy?.address ?? "-"}`;

		const cityStateZip = `${pharmacy?.city ?? "-"}, ${pharmacy?.state ?? "-"} ${
			pharmacy?.zip ?? "-"
		}`;
		const treatmentPlanText = "";

		const serviceName = encounter?.service?.name;
		// Note that service.price is dynamic an can change after the service is rendered.
		// We have an open ticket to address this.
		const servicePrice = encounter?.service?.price ?? 0;

		const order = encounter?.order;
		const externalOrderId = order?.ref_id;
		const cardBrand = order?.card_brand ?? "-";
		const cardLastFour = order?.card_last_four ?? "-";
		const orderDate = order?.order_date
			? formatDate(order?.order_date * 1000, "MM/DD/YYYY")
			: "-";
		const discountCode = order?.discount_code ?? null;
		const orderAmount = (order?.order_amount ?? 0) / 100;
		const paymentDate = order?.payment_date
			? formatDate(order?.payment_date * 1000, "MM/DD/YYYY")
			: "-";
		const status = order?.status ?? "-";

		const practitionerInteraction = encounter?.practitioner_interaction;
		const practitioner = practitionerInteraction?.practitioner;
		const network = getTextFromMapping(
			ProviderNetworkNameMapping,
			organization.providerNetworkName,
			""
		);

		return {
			pharmacy,
			prescriptions,
			date,
			storeName,
			addressLine1,
			cityStateZip,
			treatmentPlanText,
			serviceName,
			servicePrice,
			order,
			externalOrderId,
			cardBrand,
			cardLastFour,
			orderDate,
			discountCode,
			orderAmount,
			paymentDate,
			status,
			practitionerInteraction,
			practitioner,
			network,
		};
	}, [encounter, channel, organization]);

	return (
		<div ref={componentRef} className={styles.root}>
			<Header
				className={styles.hiddenOnPrint}
				onBack={() => onPreviousPage()}
			/>
			<Main loading={fetching} error={error?.message}>
				<Stack direction="vertical" gap={spacing(3)}>
					<Highlight variant="primary">
						<Stack direction="vertical" gap={spacing(5)}>
							<Stack direction="vertical" gap={spacing(1)}>
								<Text variant="h2">{serviceName} Treatment</Text>
								<Text variant="caption" bold>
									FSA or HSA Eligible
								</Text>
							</Stack>
							<Stack direction="vertical" gap={spacing(2)}>
								<Stack direction="horizontal" justify="space-between">
									<Text variant="body" bold>
										Patient:
									</Text>
									<Text variant="body">{patientFullName}</Text>
								</Stack>
								<Stack direction="horizontal" justify="space-between">
									<Text variant="body" bold>
										Clinician:
									</Text>
									<Text variant="body">
										{practitioner?.first_name} {practitioner?.last_name}
									</Text>
								</Stack>
								<Stack direction="horizontal" justify="space-between">
									<Text variant="body" bold>
										Provider NABP/NPI:
									</Text>
									<Text variant="body">{practitioner?.npi_number}</Text>
								</Stack>
								<Stack direction="horizontal" justify="space-between">
									<Text variant="body" bold>
										Service provided by:
									</Text>
									<Text variant="body">{network}</Text>
								</Stack>
								<Stack direction="horizontal" justify="space-between">
									<Text variant="body" bold>
										Service Date
									</Text>
									<Text variant="body">{date}</Text>
								</Stack>
							</Stack>
						</Stack>
					</Highlight>

					{prescriptions.map((prescription, key) => {
						const { medication_title, days_supply, directions, created_at } =
							prescription;

						return (
							<Highlight
								className={styles.hiddenOnPrint}
								variant="primary"
								key={key}
							>
								<Stack direction="vertical" gap={spacing(1)}>
									<Text variant="h2">Prescription</Text>
									<Stack direction="vertical">
										{medication_title && <Text>{`${medication_title}`}</Text>}
										{days_supply && <Text>{`${days_supply}`}-day supply</Text>}
										{directions && <Text>{`${directions}`}</Text>}
									</Stack>
									{created_at && (
										<Text variant="caption">
											Sent to pharmacy on {`${created_at}`}
										</Text>
									)}
								</Stack>
							</Highlight>
						);
					})}
					{pharmacy && (
						<Highlight className={styles.hiddenOnPrint} variant="primary">
							<Stack direction="vertical" gap={spacing(1)}>
								<Text variant="h2">Pharmacy</Text>
								<Stack direction="vertical">
									<Text>{storeName}</Text>
									<Text>{addressLine1}</Text>
									{/* <Text>{addressLine2}</Text> */}
									<Text>{cityStateZip}</Text>
								</Stack>
							</Stack>
						</Highlight>
					)}
					{treatmentPlanText && (
						<Highlight className={styles.hiddenOnPrint} variant="primary">
							<Stack direction="vertical" gap={spacing(1)}>
								<Text variant="h2">Treatment</Text>
								<Text>{treatmentPlanText}</Text>
							</Stack>
						</Highlight>
					)}
					<Highlight variant="primary">
						<Stack direction="vertical" gap={spacing(2)}>
							<Stack
								direction="horizontal"
								align="start"
								justify="space-between"
								gap={spacing(4)}
							>
								<Text variant="h2">Order Information</Text>
								<Text variant="body" align="end" clip code>
									#{externalOrderId}
								</Text>
							</Stack>
							<Stack direction="horizontal" justify="space-between">
								<Text variant="body" bold>
									Date:
								</Text>
								<Text variant="body" align="end">
									{orderDate}
								</Text>
							</Stack>
							{discountCode && (
								<Stack direction="horizontal" justify="space-between">
									<Text variant="body" bold>
										Discount Code:
									</Text>
									<Text variant="body">{discountCode}</Text>
								</Stack>
							)}
							<Stack direction="horizontal" justify="space-between">
								<Text variant="body" bold>
									Payment:
								</Text>
								<Stack direction="vertical" align="end" gap={spacing(0)}>
									<Text variant="body">
										{formatter.format(orderAmount)} on {capitalize(cardBrand)}{" "}
										ending in {cardLastFour}
									</Text>
									{status && (
										<Text variant="caption" align="end">
											Transaction{" "}
											{(() => {
												switch (status) {
													case "requires_capture": {
														return "pending";
													}
													case "succeeded": {
														return `on ${paymentDate}`;
													}
													case "canceled": {
														return "canceled";
													}
												}
											})()}
										</Text>
									)}
								</Stack>
							</Stack>
							<Stack
								direction="horizontal"
								gap={spacing(3)}
								justify="space-between"
							>
								<Text variant="body" bold>
									Billing Address:
								</Text>
								<Stack direction="vertical" align="end">
									<Text variant="body" align="end">
										CONFIDENTIAL
									</Text>
									<Text variant="body" align="end">
										CONFIDENTIAL, CONFIDENTIAL
									</Text>
									<Text variant="body" align="end">
										00000
									</Text>
									<Text variant="body" align="end">
										United States
									</Text>
								</Stack>
							</Stack>
							<HorizontalLine />
							<Stack direction="vertical" gap={spacing(1)}>
								<Stack
									direction="horizontal"
									gap={spacing(3)}
									justify="space-between"
								>
									<Text variant="body" bold>
										{serviceName} Treatment:
									</Text>
									<Text variant="body" align="end" numeric>
										{formatter.format(servicePrice)}
									</Text>
								</Stack>
								{discountCode && (
									<Stack
										direction="horizontal"
										gap={spacing(3)}
										justify="space-between"
									>
										<Text variant="body" bold>
											Discount:
										</Text>
										<Text variant="body" align="end" numeric>
											{formatter.format(orderAmount - servicePrice)}
										</Text>
									</Stack>
								)}
								<Stack
									direction="horizontal"
									gap={spacing(3)}
									justify="space-between"
								>
									<Text variant="body" bold>
										Tax:
									</Text>
									<Text variant="body" align="end" numeric>
										{formatter.format(0)}
									</Text>
								</Stack>
								<Stack
									direction="horizontal"
									gap={spacing(3)}
									justify="space-between"
								>
									<Text variant="body" bold>
										Total:
									</Text>
									<Text variant="body" align="end" numeric>
										{formatter.format(orderAmount)}
									</Text>
								</Stack>
							</Stack>
							<Stack direction="horizontal" justify="end">
								<Button
									className={styles.hiddenOnPrint}
									text="Download Receipt"
									onClick={handlePrint}
									disabled={status !== OrderStatus.SUCCEEDED}
								/>
							</Stack>
						</Stack>
					</Highlight>
				</Stack>
			</Main>
		</div>
	);
}
