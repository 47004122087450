import classNames from "classnames";
import { useState } from "preact/hooks";

import type { Props as IconProps } from "@shared/components/Icon";
import { Icon } from "@shared/components/Icon";
import { Text } from "@shared/components/Text";
import type { TooltipStyleProps } from "@shared/components/Tooltip";
import { Tooltip } from "@shared/components/Tooltip";
import { UserImage } from "@shared/components/UserImage";
import { noop } from "@shared/utilities/noop";

import styles from "./UserAvatar.module.css";

interface Props {
	withIndicator?: boolean;
	className?: string;
	iconProps?: IconProps;
	image?: string | null;
	userName?: string;
	userInitials?: string;
	clinicianSuffixes?: string;
	size: number;
	tooltipAlignment?: TooltipStyleProps["horizontalAlignment"];
	showTooltipOnHover?: boolean;
}

export function UserAvatar({
	className,
	iconProps,
	image,
	userName,
	userInitials,
	size,
	withIndicator = false,
	tooltipAlignment = "left",
	showTooltipOnHover = true,
}: Props) {
	const [isHovering, setIsHovering] = useState(false);
	const avatarImage = image && <UserImage url={image} alt={userName} />;
	const avatarInitials = userInitials && (
		<Text variant="body" bold>
			{userInitials}
		</Text>
	);
	const avatarIcon = iconProps && <Icon {...iconProps} />;

	return (
		<Tooltip
			verticalAlignment="top"
			horizontalAlignment={tooltipAlignment}
			verticalOffset={1}
			text={userName ?? ""}
			show={showTooltipOnHover && isHovering}
		>
			{(ref) => (
				<span
					ref={ref}
					className={classNames(styles.userAvatar, className)}
					style={{ width: size, height: size }}
					onMouseOver={() => setIsHovering(true)}
					onMouseOut={() => setIsHovering(false)}
					onFocus={noop}
					onBlur={noop}
				>
					{avatarImage ?? avatarInitials ?? avatarIcon}
					{withIndicator && <div className={styles.indicator} />}
				</span>
			)}
		</Tooltip>
	);
}
