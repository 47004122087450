import { useWidgetContext } from "@app/components/Contexts";
import { Header } from "@app/components/Header";
import { Main } from "@app/components/Main";
import { RouteMap } from "@app/constants/RouteMap";
import { Button } from "@shared/components/Button";
import { Hero } from "@shared/components/Hero";
import { Stack } from "@shared/components/Stack";
import { useNavigation } from "@shared/hooks/useNavigation";
import { useTheme } from "@shared/hooks/useTheme";

export function Success() {
	const { push } = useNavigation();
	const { widgetId } = useWidgetContext();
	const { spacing } = useTheme();

	return (
		<>
			<Header />
			<Main>
				<Stack direction="vertical" align="center" gap={spacing(5)}>
					<Hero
						title="You're on your way!"
						subtitle="A member of our medical team will message you within 24 hours with next steps. We'll notify you when there's a new secure message in your account."
					/>
					<Button
						text="View My Visits"
						onClick={() => push(RouteMap.Visits, { widgetId })}
					/>
				</Stack>
			</Main>
		</>
	);
}
