import { useState } from "preact/hooks";
import { useMutation, useQuery } from "urql";

import {
	useOrganizationContext,
	useWidgetContext,
} from "@app/components/Contexts";
import { useFeatureFlag } from "@app/components/Contexts/LaunchDarklyContext";
import { PaymentType } from "@app/components/Contexts/OrganizationContext/OrganizationContext";
import { Purchase } from "@app/components/Forms/Purchase";
import { Header } from "@app/components/Header";
import { Main } from "@app/components/Main";
import { RouteMap } from "@app/constants/RouteMap";
import { useServiceIntent } from "@app/hooks/serviceIntent/useServiceIntent";
import { Hero } from "@shared/components/Hero";
import { Stack } from "@shared/components/Stack";
import { useFirst } from "@shared/hooks/useFirst";
import { useNavigation } from "@shared/hooks/useNavigation";
import { useTheme } from "@shared/hooks/useTheme";
import {
	GetOrderDiscountDocument,
	WidgetGetPaymentAmountDocument,
} from "@shared/types/graphql";
import { debug } from "@shared/utilities/debug";

import type { CheckoutStepProps } from "../CheckoutStep";
import { CheckoutStep } from "../CheckoutStep";
import { CostSummary } from "./CostSummary";

function PaytientCard() {
	return (
		<img
			src="/assets/paytient-card.png"
			alt="Paytient Card"
			style={{
				width: "100%",
				maxWidth: "400px",
			}}
		/>
	);
}

export function Payment({
	serviceIntentId,
	onNextStep,
	onPreviousStep,
}: CheckoutStepProps) {
	const { spacing } = useTheme();
	const { serviceIntent } = useServiceIntent({ serviceIntentId });
	const { bypassPrepayment } = useOrganizationContext();
	const serviceId = serviceIntent?.service_id as string;
	const [{ data }] = useQuery({
		query: WidgetGetPaymentAmountDocument,
		variables: {
			serviceId: serviceId,
			serviceIdForPrice: serviceId,
		},
		pause: !serviceId,
	});

	const { paymentType } = useOrganizationContext();
	const { push } = useNavigation();
	const { widgetId } = useWidgetContext();
	const hasConsentForms = useFeatureFlag("consentForms");
	const [, getOrderDiscountCode] = useMutation(GetOrderDiscountDocument);
	const [discount, setDiscount] = useState(0);
	const [appliedDiscountCode, setAppliedDiscountCode] = useState<string | null>(
		null
	);
	const onApplyDiscount = async (code: string) => {
		if (!code.trim().length) {
			setAppliedDiscountCode(null);
			setDiscount(0);
			return;
		}

		const { data, error } = await getOrderDiscountCode({
			serviceId,
			discountCode: code.toUpperCase(),
		});

		if (error) {
			debug("error", {
				context: "Payment",
				message: "Error applying discount code",
				info: { error, code, serviceId },
			});
			setAppliedDiscountCode(null);
			setDiscount(0);
			return;
		}

		const { discounted_order_amount: priceInCentsAfterDiscount } =
			data?.applyDiscount ?? {};
		const priceInDollarsAfterDiscount = priceInCentsAfterDiscount
			? priceInCentsAfterDiscount / 100
			: 0;

		setAppliedDiscountCode(code);
		setDiscount(consultPrice - priceInDollarsAfterDiscount);
	};

	const encounter = useFirst(data?.encounter);
	const channelId = encounter?.portal_message_channel?.id;

	const consultPrice = data?.servicePrice ?? 0;
	const lineItems = [
		{
			name: "Visit Fee",
			price: consultPrice,
		},
	];
	const isPaytient = paymentType === PaymentType.Paytient;

	const determineSubtitle = (
		isPaytient: boolean,
		bypassPrepayment: boolean
	): string => {
		if (isPaytient) {
			return "Place your order using your Paytient account, and we'll find you a doctor.";
		} else if (!bypassPrepayment) {
			return "We'll need your payment info to get you treated as quickly as possible.";
		}
		return "";
	};

	return (
		<>
			<Header />
			<Main>
				<Stack direction="vertical" align="center" gap={spacing(5)}>
					<Hero
						title="One last step"
						subtitle={determineSubtitle(isPaytient, bypassPrepayment)}
					/>
					{isPaytient && <PaytientCard />}
					<CostSummary
						items={lineItems}
						appliedDiscountCode={appliedDiscountCode}
						discountAmount={discount}
						onApplyDiscount={onApplyDiscount}
					/>
					<Purchase
						serviceIntentId={serviceIntentId}
						prevButtonText="Go back"
						buttonText="Place Order & Start Visit"
						onBack={onPreviousStep}
						onSuccess={() => {
							if (hasConsentForms && channelId) {
								push(RouteMap.VisitChannel, {
									widgetId,
									channelId,
								});
								return;
							}

							onNextStep(CheckoutStep.Success, {});
						}}
					/>
				</Stack>
			</Main>
		</>
	);
}
