import { useEffect, useRef } from "react";

import { useWidgetContext } from "@app/components/Contexts";
import { useFont } from "@shared/hooks/useFont";
import { useIsDarkMode } from "@shared/hooks/useIsDarkMode";
import type { Theme } from "@shared/hooks/useTheme/Theme";
import { THEME_KEYS } from "@shared/hooks/useTheme/Theme";
import { ThemeColor } from "@shared/hooks/useTheme/ThemeColor";

import { useWidgetSettings } from "../useWidgetSettings";
import type { ThemeProviderProps } from "./ThemeProvider";
import { FALLBACK_FONTS } from "./consts";

export function useThemeProvider({
	dark,
	light,
	children,
	overrideIsDark,
}: ThemeProviderProps) {
	const { widgetId } = useWidgetContext();
	const hasTheming = light && dark;
	const { lightTheme: configLightTheme, darkTheme: configDarkTheme } =
		useWidgetSettings(!!widgetId && !hasTheming);

	const hasFunctionChildren = typeof children === "function";
	const rootElementRef = useRef<HTMLDivElement>(null);
	const isDarkMode = useIsDarkMode(overrideIsDark);
	const darkTheme = dark || configDarkTheme;
	const lightTheme = light || configLightTheme;
	const theme = isDarkMode ? darkTheme : lightTheme;

	useFont(theme.primaryFont);

	useEffect(() => {
		const rootElement = hasFunctionChildren
			? rootElementRef.current
			: document.documentElement;

		Object.keys(THEME_KEYS).forEach((key) => {
			const value = theme[key as keyof Theme];

			if (!value) return;

			rootElement?.style.setProperty(
				THEME_KEYS[key as keyof typeof THEME_KEYS],
				(() => {
					if (value instanceof ThemeColor) {
						return value.color;
					} else if (key === "primaryFont") {
						return value ? `${value}, ${FALLBACK_FONTS}` : FALLBACK_FONTS;
					} else if (key === "borderRadius") {
						return `${value ?? 0}px`;
					} else if (key === "spacingMultiplier") {
						return `${value ?? 0}px`;
					}

					return "";
				})()
			);
		});
	}, [theme, hasFunctionChildren]);

	return { theme, darkTheme, lightTheme, rootElementRef, hasFunctionChildren };
}
