type PaddingShortForm = { horizontal?: number; vertical?: number };
type PaddingLongForm = {
	top?: number;
	bottom?: number;
	start?: number;
	end?: number;
};
export type Padding = number | PaddingShortForm | PaddingLongForm;

const isShortForm = (
	padding: Padding | undefined
): padding is PaddingShortForm =>
	typeof padding !== "number" &&
	!!padding &&
	("horizontal" in padding || "vertical" in padding);

export function usePaddingValue(padding: Padding | undefined) {
	if (typeof padding === "number") return { padding };
	if (isShortForm(padding)) {
		return {
			paddingTop: padding.vertical,
			paddingBottom: padding.vertical,
			paddingLeft: padding.horizontal,
			paddingRight: padding.horizontal,
		};
	}
	if (!padding) return { padding: undefined };

	return {
		paddingTop: padding.top,
		paddingBottom: padding.bottom,
		paddingLeft: padding.start,
		paddingRight: padding.end,
	};
}
