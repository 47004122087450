import { defineConfig } from "@twind/core";
import presetAutoprefix from "@twind/preset-autoprefix";
import presetTailwind from "@twind/preset-tailwind";

export default defineConfig({
	theme: {
		extend: {
			animation: {
				// Keyframes from parent (tailwind.config.js)
				jiggle: "jiggle 0.5s ease-in-out infinite",
				// Add new Twind-specific keyframes here...
			},
			keyframes: {
				// Keyframes from parent (tailwind.config.js)
				jiggle: {
					"0%, 100%": { transform: "rotate(0)" },
					"25%": { transform: "rotate(-1.67deg)" },
					"75%": { transform: "rotate(1.7deg)" },
				},
				// Add new Twind-specific keyframes here...
			},
			colors: {
				// Colors from parent (tailwind.config.js)
				"primary-foreground": "var(--primary-foreground)",
				"primary-background": "var(--primary-background)",
				"primary-accent-50": "var(--primary-accent-50)",
				"primary-accent-100": "var(--primary-accent-100)",
				"primary-accent-200": "var(--primary-accent-200)",
				"primary-accent-300": "var(--primary-accent-300)",
				"primary-accent-400": "var(--primary-accent-400)",
				"primary-accent-500": "var(--primary-accent-500)",
				"primary-accent-600": "var(--primary-accent-600)",
				"primary-accent-700": "var(--primary-accent-700)",
				"primary-accent-800": "var(--primary-accent-800)",
				"primary-accent-900": "var(--primary-accent-900)",
				"secondary-accent-50": "var(--secondary-accent-50)",
				"secondary-accent-100": "var(--secondary-accent-100)",
				"secondary-accent-200": "var(--secondary-accent-200)",
				"secondary-accent-300": "var(--secondary-accent-300)",
				"secondary-accent-400": "var(--secondary-accent-400)",
				"secondary-accent-500": "var(--secondary-accent-500)",
				"secondary-accent-600": "var(--secondary-accent-600)",
				"secondary-accent-700": "var(--secondary-accent-700)",
				"secondary-accent-800": "var(--secondary-accent-800)",
				"secondary-accent-900": "var(--secondary-accent-900)",
				"grayscale-50": "var(--grayscale-50)",
				"grayscale-100": "var(--grayscale-100)",
				"grayscale-200": "var(--grayscale-200)",
				"grayscale-300": "var(--grayscale-300)",
				"grayscale-400": "var(--grayscale-400)",
				"grayscale-500": "var(--grayscale-500)",
				"grayscale-600": "var(--grayscale-600)",
				"grayscale-700": "var(--grayscale-700)",
				"grayscale-800": "var(--grayscale-800)",
				"grayscale-900": "var(--grayscale-900)",

				// Add new Twind-specific colors here...
				clifford: "#da373d",
			},
		},
	},
	plugins: [
		function ({ addUtilities }) {
			const newUtilities = {
				".pb-safe": {
					"padding-bottom": "env(safe-area-inset-bottom)",
				},
				".tap-highlight-none": {
					"-webkit-tap-highlight-color": "transparent",
				},
			};

			addUtilities(newUtilities);
		},
		function ({ addUtilities, theme }) {
			const safeAreaPaddingUtilities = {};
			const spacing = theme("spacing");

			Object.keys(spacing).forEach((key) => {
				safeAreaPaddingUtilities[`.pb-safe-${key}`] = {
					"padding-bottom": `calc(env(safe-area-inset-bottom) + ${spacing[key]})`,
				};
			});

			addUtilities(safeAreaPaddingUtilities);
		},
	],
	presets: [presetTailwind(), presetAutoprefix()],
});
