import { useCallback, useState } from "preact/hooks";
import { useMutation } from "urql";

import { useFeatureFlag } from "@app/components/Contexts/LaunchDarklyContext";
import type { AddressInput } from "@shared/types/graphql";
import { WidgetUpdateAddressesDocument } from "@shared/types/graphql";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import type { PaymentIntent } from "@stripe/stripe-js";

export function useStripePurchase(
	onSuccess?: (paymentIntent: PaymentIntent) => void | Promise<void>,
	billingAddress?: AddressInput | null
) {
	const stripe = useStripe();
	const elements = useElements();
	const isUSStateValidationDisabled = useFeatureFlag(
		"usStateValidationDisabled"
	);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [isPurchasing, setIsPurchasing] = useState(false);
	const [, updateAddresses] = useMutation(WidgetUpdateAddressesDocument);

	const purchase = useCallback(async () => {
		setIsPurchasing(true);

		if (!stripe || !elements) {
			setIsPurchasing(false);
			throw new Error("Failed to initialize payment elements.");
		}

		const { error, paymentIntent } = await stripe.confirmPayment({
			//`Elements` instance that was used to create the Payment Element
			elements,
			redirect: "if_required",
			confirmParams: {
				return_url: "",
				payment_method_data: {
					billing_details: {
						address: {
							line1: billingAddress?.line_1 || "",
							line2: billingAddress?.line_2 || "",
							city: billingAddress?.city || "",
							state: billingAddress?.state || "",
							postal_code: billingAddress?.zip || "",
							country: isUSStateValidationDisabled
								? billingAddress?.country || ""
								: "US",
						},
					},
				},
			},
		});

		if (error) {
			// This point will only be reached if there is an immediate error when
			// confirming the payment. Show error to your customer (for example, payment
			// details incomplete)
			setIsPurchasing(false);
			setErrorMessage(error.message ?? "Unknown payment error.");
			throw new Error(error.message ?? "Unknown payment error.");
		}

		if (billingAddress) {
			await updateAddresses({
				addresses: [billingAddress],
			});
		}

		await onSuccess?.(paymentIntent);
		setIsPurchasing(false);
	}, [
		stripe,
		elements,
		billingAddress,
		isUSStateValidationDisabled,
		onSuccess,
		updateAddresses,
	]);

	return [purchase, { isPurchasing, errorMessage }] as const;
}
