import { calendarClear } from "ionicons/icons";

import { DetailsButton } from "@app/components/Header/components/DetailsButton";
import { CONTENT_MAX_WIDTH } from "@app/components/Main/Main";
import { Icon } from "@shared/components/Icon";
import { Stack } from "@shared/components/Stack";
import { Text } from "@shared/components/Text";
import { useIconStyles } from "@shared/hooks/useIconStyles";
import { useTheme } from "@shared/hooks/useTheme";
import { useWindowSize } from "@shared/hooks/useWindowSize";
import { formatDate } from "@shared/utilities/formatDate";

import styles from "./VisitStatus.module.css";

interface Props {
	name: string;
	color: string;
	createdAt?: number;
	title?: string;
	hideCreatedAt?: boolean;
	onDetailsClick: () => void;
}

export function VisitStatus({
	title,
	name,
	color,
	createdAt,
	hideCreatedAt = false,
	onDetailsClick,
}: Props) {
	const { primaryForeground, spacing } = useTheme();
	const { width } = useWindowSize();

	const calendarIcon = useIconStyles({
		icon: calendarClear,
		stroke: primaryForeground,
	});

	return (
		<div
			className={styles.root}
			style={{ width: Math.min(CONTENT_MAX_WIDTH, width) }}
		>
			<Stack direction="horizontal" justify="space-between" gap={spacing(1)}>
				<Stack direction="vertical" gap={5}>
					<Stack direction="vertical" align="stretch" gap={spacing(1)}>
						<Text variant="h3">{title}</Text>
					</Stack>
					{!hideCreatedAt && (
						<Stack direction="horizontal" gap={spacing(2)} align="center">
							<Icon size={16} {...calendarIcon} />
							{createdAt && (
								<Text>
									Started {formatDate(new Date(createdAt), "MM/DD/YY")}
								</Text>
							)}
						</Stack>
					)}
					<Stack direction="horizontal" gap={spacing(2)} align="center">
						<div className={styles.circle} style={{ backgroundColor: color }} />
						<Text>{name}</Text>
					</Stack>
				</Stack>
				<DetailsButton onDetailsClick={onDetailsClick} />
			</Stack>
			<div className={styles.borderBottom} />
		</div>
	);
}
