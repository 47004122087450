import { useEffect } from "react";

import { matchPath } from "react-router-dom";

import { usePatientContext, useWidgetContext } from "@app/components/Contexts";
import { RouteMap } from "@app/constants/RouteMap";
import { parse } from "@shared/utilities/queryString";

import { useLocation } from "./useLocation";
import { useNavigation } from "./useNavigation";

export const useQuickLoginRedirect = () => {
	const { url, path } = useLocation();
	const { replace } = useNavigation();
	const { widgetId } = useWidgetContext();
	const { patientId } = usePatientContext();

	useEffect(() => {
		// There's no need to redirect if user is logged in
		if (patientId) {
			return;
		}

		const externalId = parse(url)?.externalId as string | undefined;
		const loginToken = parse(url)?.loginToken as string | undefined;

		if (externalId && loginToken && !matchPath(RouteMap.Default, path)) {
			// Remove the externalId and loginToken from the URL
			const [pathname, search] = url.split("?");
			const redirectUrl = new URLSearchParams(search);

			redirectUrl.delete("externalId");
			redirectUrl.delete("loginToken");

			replace(
				RouteMap.Default,
				{
					widgetId,
				},
				{
					redirectUrl: `${pathname}?${redirectUrl.toString()}`,
				}
			);
		}
	}, [url, replace, widgetId, path, patientId]);
};
