export const Time = {
	SECOND: 1,
	SECOND_MS: 1e3,
	MINUTE: 60,
	MINUTE_MS: 60e3,
	HOUR: 3600,
	HOUR_MS: 3600e3,
	DAY: 86400,
	DAY_MS: 86400e3,
	WEEK: 604800,
	WEEK_MS: 604800e3,
} as const;
