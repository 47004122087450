import { useEffect, useState } from "react";

import { urlToDataUrl } from "@shared/utilities/urlToDataUrl";

const imageCache = new Map<string, string | null>();

export function useImageCache(url: string | null) {
	const [cachedImage, setCachedImage] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	useEffect(() => {
		if (!url) return;

		const cachedImage = imageCache.get(url);
		if (!cachedImage) {
			setIsLoading(true);
			urlToDataUrl(url)
				.then((dataUrl) => {
					imageCache.set(url, dataUrl);
					setCachedImage(dataUrl);
				})
				.catch(() => setIsError(true))
				.finally(() => setIsLoading(false));
			return;
		}

		setCachedImage(cachedImage);
	}, [url]);

	return [cachedImage, { isLoading, isError }] as const;
}
