import { useCallback, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import type { RouteMap } from "@app/constants/RouteMap";
import { useLocation } from "@shared/hooks/useLocation";
import type { PathHistory } from "@shared/utilities/MemoryLocationHistory";
import {
	MemoryLocationHistory,
	LocationType,
} from "@shared/utilities/MemoryLocationHistory";
import { debug } from "@shared/utilities/debug";
import type { PathVariables } from "@shared/utilities/path";
import { path } from "@shared/utilities/path";
import { parse, stringify } from "@shared/utilities/queryString";

const memoryHistory = new MemoryLocationHistory();

export function useNavigation() {
	const internalLocation = useLocation();
	const navigate = useNavigate();

	const queryParams = parse(internalLocation.url);
	useEffect(() => {
		memoryHistory.init({
			type: LocationType.Path,
			route: internalLocation.path as PathHistory["route"],
			variables: internalLocation.matches as PathHistory["variables"],
			queryParams,
			replaceQueryParams: false,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps -- Only run once
	}, []);

	const navigateTo = useCallback(
		(url: string, replaceHistory?: boolean) => {
			memoryHistory[replaceHistory ? "replace" : "push"]({
				type: LocationType.Url,
				url,
			});

			debug("info", {
				context: "useNavigation",
				message: "push",
				info: {
					url,
					replaceHistory,
				},
			});

			navigate(url, { replace: replaceHistory });
		},
		[navigate]
	);

	const push = useCallback(
		<Path extends ValueOf<typeof RouteMap>>(
			route: Path,
			variables: PathVariables<Path>,
			queryParams: Record<
				string,
				string | number | boolean | null | undefined
			> = {},
			replaceQueryParams = true
		) => {
			memoryHistory.push({
				type: LocationType.Path,
				route,
				variables,
				queryParams,
				replaceQueryParams,
			});

			debug("info", {
				context: "useNavigation",
				message: "push",
				info: {
					route,
					variables,
					queryParams,
					replaceQueryParams,
				},
			});

			navigate(path(route, variables, queryParams, replaceQueryParams), {
				replace: true,
			});
		},
		[navigate]
	);
	const replace = useCallback(
		<Path extends ValueOf<typeof RouteMap>>(
			route: Path,
			variables: PathVariables<Path>,
			queryParams: Record<
				string,
				string | number | boolean | null | undefined
			> = {},
			replaceQueryParams = false
		) => {
			memoryHistory.replace({
				type: LocationType.Path,
				route,
				variables,
				queryParams,
				replaceQueryParams,
			});

			navigate(path(route, variables, queryParams, replaceQueryParams), {
				replace: true,
			});
		},
		[navigate]
	);
	const goTo = useCallback(
		<Path extends ValueOf<typeof RouteMap>>(
			route: Path,
			variables: PathVariables<Path>,
			queryParams: Record<
				string,
				string | number | boolean | null | undefined
			> = {},
			replaceQueryParams = false
		) => {
			memoryHistory.push({
				type: LocationType.Path,
				route,
				variables,
				queryParams,
				replaceQueryParams,
			});
			window.location.href = path(
				route,
				variables,
				queryParams,
				replaceQueryParams
			);
		},
		[]
	);
	const back = useCallback(() => {
		const previousRoute = memoryHistory.popPrevious();

		if (!previousRoute) {
			return;
		}

		const { type } = previousRoute;

		if (type === LocationType.Path) {
			const { route, variables, queryParams, replaceQueryParams } =
				previousRoute;
			push(route, variables, queryParams, replaceQueryParams);
		} else {
			navigateTo(previousRoute.url);
		}
	}, [push, navigateTo]);
	const replaceQueryParams = useCallback(
		(
			queryParamsToChange: Record<
				string,
				string | number | boolean | null | undefined
			> = {}
		) => {
			const currentRoute = memoryHistory.getCurrent();

			if (!currentRoute) {
				return;
			}

			const { type } = currentRoute;

			const queryParams =
				type === LocationType.Path
					? currentRoute.queryParams
					: parse(currentRoute.url);
			// Remove query params that are undefined, update the rest
			const updatedQueryParams = Object.entries(queryParamsToChange).reduce(
				(acc, [key, value]) => {
					if (!value) {
						delete acc[key];
					} else {
						acc[key] = value;
					}
					return acc;
				},
				{ ...queryParams }
			);

			if (type === LocationType.Path) {
				const { route, variables } = currentRoute;
				return replace(route, variables, updatedQueryParams, true);
			}

			if (type === LocationType.Url) {
				const { url } = currentRoute;
				const urlWithoutQueryParams = url.split("?")[0];
				const newUrl = `${urlWithoutQueryParams}?${stringify(
					updatedQueryParams
				)}`;
				return navigateTo(newUrl, true);
			}
		},
		[replace, navigateTo]
	);

	return {
		navigateTo,
		push,
		replace,
		back,
		goTo,
		replaceQueryParams,
		queryParams,
	};
}
