import { logOut, settingsOutline } from "ionicons/icons";
import { useMemo } from "preact/hooks";

import {
	useAuthContext,
	useOrganizationContext,
} from "@app/components/Contexts";
import type { HubPageProps } from "@app/components/PatientHub";
import { HubPage } from "@app/components/PatientHub";
import { List } from "@shared/components/ButtonList";
import { Icon } from "@shared/components/Icon";
import { useIconStyles } from "@shared/hooks/useIconStyles";
import { useTheme } from "@shared/hooks/useTheme";
import { isDarkMode } from "@shared/utilities/isDarkMode";

import styles from "./PatientLinks.module.css";
import { Tool } from "./PatientLinks.types";
import { getLinksData } from "./PatientLinks.utils";

interface Props {
	onNextPage: HubPageProps["onNextPage"];
}

export function PatientLinks({ onNextPage }: Props) {
	const { logout } = useAuthContext();
	const { primaryForeground, primaryAccent700 } = useTheme();
	const { showSignoutButton } = useOrganizationContext();
	const stroke = isDarkMode() ? primaryForeground : primaryAccent700;

	const toolIcons = {
		[Tool.UpdateAccount]: useIconStyles({
			stroke,
			icon: settingsOutline,
		}),
		[Tool.Logout]: useIconStyles({
			stroke,
			strokeWidth: 30,
			icon: logOut,
		}),
	};

	const linksData = useMemo(
		() => getLinksData(showSignoutButton),
		[showSignoutButton]
	);

	return (
		<List
			items={linksData.map(({ id, title, description }) => ({
				key: id,
				title,
				description,
				startIcon: (
					<div className={styles.iconWrapper}>
						<Icon {...toolIcons[id]} />
					</div>
				),
				onClick() {
					if (id === Tool.UpdateAccount) {
						onNextPage(HubPage.AccountDetails, {});
					} else if (id === Tool.Logout) {
						logout();
					}
				},
			}))}
		/>
	);
}
