import { matchPath } from "react-router-dom";

import { usePatientContext, useWidgetContext } from "@app/components/Contexts";
import { RouteMap } from "@app/constants/RouteMap";
import { useLocation } from "@shared/hooks/useLocation";
import { useNavigation } from "@shared/hooks/useNavigation";

export function useRefreshButton() {
	const { path } = useLocation();
	const { push } = useNavigation();
	const { patientId } = usePatientContext();
	const { widgetId } = useWidgetContext();

	const handleRefresh = (serviceId?: string) => {
		push(
			patientId ? RouteMap.Visits : RouteMap.Default,
			{ widgetId },
			serviceId ? { serviceId: serviceId } : undefined,
			true
		);
	};

	return {
		handleRefresh,
		hideConfirmation: matchPath(RouteMap.AuthStages, path),
	};
}
