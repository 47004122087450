import { useMemo, useState } from "preact/hooks";
import { useMutation } from "urql";

import { CONTRACT_AGREEMENTS } from "@app/components/Auth/TermsOfService/constants";
import {
	useOrganizationContext,
	usePatientContext,
} from "@app/components/Contexts";
import { Form } from "@shared/components/Form";
import { Input } from "@shared/components/Input";
import { useTheme } from "@shared/hooks/useTheme";
import { WidgetUpdatePatientAgreementsDocument } from "@shared/types/graphql";

import styles from "./AcceptTerms.module.css";
import TermsButton from "./TermsButton";

interface Props {
	onSuccess?: () => void;
	showContent?: (content: { title: string; text: string }) => void;
}

export function AcceptTerms({ onSuccess, showContent }: Props) {
	const {
		termsOfUse,
		privacyPolicy,
		isPaytient,
		organizationName,
		legalForm,
		organizationId,
	} = useOrganizationContext();
	const { refetchPatientContext } = usePatientContext();
	const { primaryAccent50 } = useTheme();
	const [acceptedTerms, setAcceptedTerms] = useState(false);
	const [{ error }, consentToAgreement] = useMutation(
		WidgetUpdatePatientAgreementsDocument
	);
	const isEsseOrg = organizationId === "c2a81423-3646-432d-b720-14c3a0990e1a";

	const onSubmitInfo = async () => {
		for (const agreementName of [
			CONTRACT_AGREEMENTS.TermsOfService,
			// For now, we don't ask for consent to the privacy policy
			// CONTRACT_AGREEMENTS.PrivacyPolicy,
		]) {
			const { data, error } = await consentToAgreement({
				agreementName,
			});

			if (error || !data?.consentToAgreement?.id) {
				throw new Error(
					error?.message ??
						"An unknown error occurred while updating your info."
				);
			}
		}
		refetchPatientContext();
		onSuccess?.();
	};

	const termsLink = useMemo(() => {
		return (
			<TermsButton
				label="Terms of Use"
				title={`${organizationName} Terms of Use`}
				text={legalForm.termsOfUse ?? ""}
				link={termsOfUse}
				onClick={showContent}
			/>
		);
	}, [legalForm.termsOfUse, organizationName, showContent, termsOfUse]);
	const privacyLink = useMemo(() => {
		return (
			<TermsButton
				label="Privacy Policy"
				title={`${organizationName} Privacy Policy`}
				text={legalForm.privacyPolicy ?? ""}
				link={privacyPolicy}
				onClick={showContent}
			/>
		);
	}, [legalForm.privacyPolicy, privacyPolicy, organizationName, showContent]);

	return (
		<Form
			errorMessage={error?.message}
			justifyButtons="center"
			submitButtonProps={{
				text: "Get Care Now",
			}}
			onSubmit={onSubmitInfo}
		>
			{({ isSubmitting }) => (
				<div
					className={styles.termsWrapper}
					style={{ background: primaryAccent50?.rgba }}
				>
					<Input
						type="checkbox"
						className={styles.termsCheckbox}
						value={`${acceptedTerms}`}
						onChange={(e: Event) =>
							setAcceptedTerms((e.target as HTMLInputElement)?.checked)
						}
						disabled={isSubmitting}
						label={
							<p className={styles.terms}>
								I’ve read and agree to the {termsLink}
								{!isPaytient && !isEsseOrg ? <> and {privacyLink}</> : null}.
							</p>
						}
						required
					/>
				</div>
			)}
		</Form>
	);
}
