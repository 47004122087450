import type { ComponentChildren } from "preact";

import { useCallback, useEffect, useState } from "preact/hooks";
import type { CombinedError } from "urql";
import { useClient as usePreactClient, useMutation } from "urql";

import { useWidgetContext } from "@app/components/Contexts/WidgetContext";
import {
	WidgetGetTemporaryPatientDocument,
	WidgetLoginWithCodeDocument,
	WidgetStartExternalPatientLoginDocument,
	WidgetStartPatientLoginDocument,
	WidgetVerifyLoginCodeDocument,
} from "@shared/types/graphql";
import { debug } from "@shared/utilities/debug";
import { formatInternalError } from "@shared/utilities/formatInternalError";
import { setHeader } from "@shared/utilities/gql/headers";
import { parse } from "@shared/utilities/queryString";

import { AuthContext } from "./AuthContext";
import { useTokenManager } from "./useTokenManager";

interface Props {
	children: ComponentChildren;
}

export function AuthContextProvider({ children }: Props) {
	const { widgetId } = useWidgetContext();
	const [authError, setAuthError] = useState<CombinedError>();

	debug("info", {
		context: "AuthContextProvider",
		message: "widgetId",
		info: {
			widgetId,
		},
	});

	// Temp user.
	const [{ error: temporaryPatientError }, _getTemporaryPatient] = useMutation(
		WidgetGetTemporaryPatientDocument
	);
	const tempUserIdFromUrl = parse(window.location.href).tempUserId;
	const [temporaryPatientId, setTemporaryPatientId] = useState<string | null>(
		tempUserIdFromUrl ? `${tempUserIdFromUrl}` : null
	);
	useEffect(() => {
		if (!temporaryPatientId) {
			return;
		}

		setHeader("x-hasura-temp-user-id", temporaryPatientId);
	}, [temporaryPatientId, widgetId]);

	const getTemporaryPatient = useCallback(
		async () =>
			await _getTemporaryPatient({
				widgetId,
			}).then((res) => {
				const tempPatientId = res.data?.getTemporaryPatientId?.userId ?? null;
				setTemporaryPatientId(tempPatientId);
				if (!tempPatientId) {
					return res;
				}
				setHeader("x-hasura-temp-user-id", tempPatientId);
				return res;
			}),
		[widgetId, _getTemporaryPatient]
	);

	// Start login (e.g. send a code).
	const { patientId, onLogin, onLogout } = useTokenManager();
	const [, _startPatientLogin] = useMutation(WidgetStartPatientLoginDocument);
	const startPatientLogin = useCallback(
		(phoneNumber: string, birthDate: string, serviceId: string | null = null) =>
			_startPatientLogin({
				phoneNumber,
				birthDate,
				serviceId,
				tempUserId: patientId ?? temporaryPatientId,
			}),
		[_startPatientLogin, patientId, temporaryPatientId]
	);
	const [, _startExternalPatientLogin] = useMutation(
		WidgetStartExternalPatientLoginDocument
	);
	const startExternalPatientLogin = useCallback(
		async (externalId: string, loginToken: string) => {
			const response = await _startExternalPatientLogin({
				externalId,
				loginToken,
			});

			const authToken = response.data?.startExternalPatientLogin?.jwt;

			if (!authToken) {
				debug("error", {
					context: "AuthContextProvider",
					message: "Error logging in with external patient.",
					info: {
						authToken,
						error: response.error,
					},
				});
				setAuthError(response.error);
				throw new Error(
					formatInternalError(response.error) ?? "Failed to get valid JWT."
				);
			}

			onLogin(authToken);
			return response;
		},
		[_startExternalPatientLogin, onLogin]
	);

	// Validate login attempt.
	const client = usePreactClient();
	const verifyLoginCode = useCallback(
		(code: string, phoneNumber?: string, externalId?: string) =>
			client
				.query(WidgetVerifyLoginCodeDocument, {
					code,
					phoneNumber,
					externalId,
				})
				.toPromise(),
		[client]
	);

	// Finish login.
	const [, _loginWithCode] = useMutation(WidgetLoginWithCodeDocument);
	const loginWithCode = useCallback(
		async (
			code: string,
			phoneNumber?: string,
			dob?: string,
			externalId?: string
		) => {
			const response = await _loginWithCode({
				code,
				phoneNumber,
				dob,
				tempUserId: temporaryPatientId,
				externalId,
			});

			// Handle auth token errors.
			const refreshToken = response.data?.loginWithCode?.refreshToken;
			const authToken = response.data?.loginWithCode?.jwt;
			if (!authToken) {
				debug("error", {
					context: "AuthContextProvider",
					message: "Error logging in with code.",
					info: {
						authToken,
						refreshToken,
						error: response.error,
					},
				});
				setAuthError(response.error);

				throw new Error(
					formatInternalError(response.error) ?? "Failed to get valid JWT."
				);
			}

			onLogin(authToken, refreshToken);
			return response;
		},
		[_loginWithCode, temporaryPatientId, onLogin]
	);

	const logout = useCallback(
		({ redirect = true } = {}) => {
			onLogout({ redirect });
			setTemporaryPatientId(null);
		},
		[setTemporaryPatientId, onLogout]
	);

	return (
		<AuthContext.Provider
			ref={() =>
				debug("info", {
					context: "AuthContextProvider",
					message: "Auth context loaded.",
					info: {
						patientId,
						temporaryPatientId,
					},
				})
			}
			value={{
				patientId,
				authError: authError || null,
				temporaryPatientId: patientId ?? temporaryPatientId,
				temporaryPatientError,
				getTemporaryPatient,
				startPatientLogin,
				startExternalPatientLogin,
				verifyLoginCode,
				loginWithCode,
				logout,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}
