import { useState } from "preact/hooks";

import { Form, FormContent } from "@shared/components/Form";
import { Stack } from "@shared/components/Stack";
import { useTheme } from "@shared/hooks/useTheme";
import type { AddressInput } from "@shared/types/graphql";
import { debug } from "@shared/utilities/debug";
import { PaymentElement } from "@stripe/react-stripe-js";

import { AddressPicker } from "../AddressPicker";
import { useStripePurchase } from "./useStripePurchase";

interface Props {
	buttonText?: string;
	prevButtonText?: string;
	buttonDisabled?: boolean;
	onBack?(): void;
	onSuccess?: () => void;
}

export function PurchaseForm({
	buttonText,
	prevButtonText,
	buttonDisabled,
	onBack,
	onSuccess,
}: Props) {
	const { spacing } = useTheme();
	const [billingAddress, setBillingAddress] = useState<AddressInput | null>(
		null
	);
	const [purchase, { isPurchasing, errorMessage: stripeErrorMessage }] =
		useStripePurchase((paymentIntent) => {
			debug("info", {
				context: "PurchaseForm",
				message: "Payment intent created",
				info: paymentIntent,
			});
			onSuccess?.();
		}, billingAddress);

	return (
		<Form plain onSubmit={purchase}>
			{({ errorMessage, successMessage, isSubmitting, setErrorText }) => (
				<Stack direction="vertical" gap={spacing(5)}>
					<FormContent isSubmitting={isSubmitting}>
						<AddressPicker
							showAsBilling
							showShippingAddress={false}
							onChange={setBillingAddress}
							buttonDisabled={isSubmitting}
						/>
					</FormContent>
					<FormContent
						title="Payment details"
						subtitle="After our medical team reviews your orders, we'll place a temporary hold on your card."
						errorMessage={stripeErrorMessage ?? errorMessage}
						successMessage={successMessage}
						submitButtonProps={{
							text: buttonText,
							disabled: isPurchasing || buttonDisabled || !billingAddress,
						}}
						additionalButtonProps={{
							text: prevButtonText,
							variant: "secondary",
							onClick: onBack,
						}}
						isSubmitting={isSubmitting}
					>
						<PaymentElement
							id="payment-details-element"
							onLoadError={({ error }) =>
								setErrorText(
									`Failed to load payment element. ${
										error.message ?? ""
									}`.trim()
								)
							}
						/>
					</FormContent>
				</Stack>
			)}
		</Form>
	);
}
