import type { ComponentType } from "react";

import type { TransitionDirection } from "@shared/components/AnimationTransition";
import type { Props as ButtonProps } from "@shared/components/Button";

export enum AuthStage {
	Start = "login",
	LoginCode = "confirm",
	Registration = "register",
	Consent = "consent",
	QuickLogin = "quick",
	TermsOfService = "terms-of-service",
}

export type AuthStageTransitionProps = {
	[AuthStage.Start]: {
		serviceIntentId: string | undefined;
		isDisqualified?: boolean;
	};
	[AuthStage.LoginCode]: {
		serviceId: string | undefined;
		serviceIntentId: string | undefined;
		phoneNumber: string | number;
		birthDate: string;
		code?: string | number;
		externalId?: string;
	};
	[AuthStage.QuickLogin]: {
		serviceIntentId: string | undefined;
		phoneNumber: string | number;
		code?: string | number;
	};
	[AuthStage.Registration]: {
		serviceIntentId: string | undefined;
	};
	[AuthStage.Consent]: {
		serviceIntentId: string | undefined;
	};
	[AuthStage.TermsOfService]: {
		serviceIntentId: string | undefined;
		wrapperComponent?: ComponentType<{ buttonProps?: ButtonProps }>;
	};
};

export interface AuthStageProps<StageType extends AuthStage> {
	props: AuthStageTransitionProps[StageType];
	onPreviousStage: (count?: number) => void;
	onNextStage: <StageType extends AuthStage>(
		nextStage: StageType,
		nextStageProps: AuthStageTransitionProps[StageType]
	) => void;
	goToStage: <StageType extends AuthStage>(
		nextStage: StageType,
		nextStageProps: AuthStageTransitionProps[StageType]
	) => void;
	direction: TransitionDirection;
}

export const isValidAuthStage = (stageId: string): stageId is AuthStage =>
	Object.values(AuthStage).includes(stageId as AuthStage);
