import { Stack } from "@shared/components/Stack";
import { Text } from "@shared/components/Text";
import { useTheme } from "@shared/hooks/useTheme";

export interface LineItem {
	name: string;
	detail?: string;
	caption?: string;
	price: number | null;
	originalPrice?: number | null;
}

interface LineItemProps extends LineItem {
	formatter: Intl.NumberFormat;
}

export function LineItem({
	name,
	detail,
	caption,
	price,
	originalPrice,
	formatter,
}: LineItemProps) {
	const { spacing } = useTheme();
	const formattedPrice = price ? formatter.format(price) : null;
	const formattedOriginalPrice = originalPrice
		? formatter.format(originalPrice)
		: "";
	return (
		<Stack direction="vertical" gap={spacing(1)}>
			<Stack direction="horizontal" align="center" justify="space-between">
				<Text variant="h3" align="center">
					{name}
				</Text>
				<Text align="center">{formattedPrice}</Text>
			</Stack>
			{(detail || originalPrice) && (
				<Stack direction="horizontal" align="center" justify="space-between">
					<Text align="center">{detail ?? ""}</Text>
					<Text align="center" strikethrough>
						{formattedOriginalPrice}
					</Text>
				</Stack>
			)}
			{caption && <Text variant="caption">{caption}</Text>}
		</Stack>
	);
}
