import type { ReactNode } from "react";

import styles from "./Overlay.module.css";

interface Props {
	children: ReactNode;
}

export function Overlay({ children }: Props) {
	return <div className={styles.overlay}>{children}</div>;
}
