import type { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { Props as ButtonProps } from "@shared/components/Button";
import { Button } from "@shared/components/Button";
import type { Props as IconProps } from "@shared/components/Icon";
import { Icon } from "@shared/components/Icon";
import { Stack } from "@shared/components/Stack";
import type { Props as TextProps } from "@shared/components/Text";
import { Text } from "@shared/components/Text";

interface Props {
	buttonProps?: ButtonProps;
	iconProps?: IconProps;
	faIconProps?: FontAwesomeIconProps;
	textProps?: TextProps;
	textPosition?: "before" | "after";
}

export function IconButton({
	buttonProps = { variant: "plain" },
	iconProps,
	faIconProps,
	textProps,
	textPosition = "after",
}: Props) {
	return (
		<Button {...buttonProps}>
			<Stack direction="horizontal" align="center" justify="start">
				{textPosition === "before" && !!textProps?.children && (
					<Text {...textProps} />
				)}
				{iconProps && <Icon {...iconProps} />}
				{faIconProps && <FontAwesomeIcon {...faIconProps} />}
				{textPosition === "after" && !!textProps?.children && (
					<Text {...textProps} />
				)}
			</Stack>
		</Button>
	);
}
