import classNames from "classnames";
import { headset } from "ionicons/icons";

import type { ChatFile } from "@shared/components/InputFiles/InputFiles";
import { Message } from "@shared/components/Message";
import type { PortalMessagePayload } from "@shared/components/Message/Message";
import { Stack } from "@shared/components/Stack";
import { useIconStyles } from "@shared/hooks/useIconStyles";
import { useTheme } from "@shared/hooks/useTheme";
import type { MessageFragment } from "@shared/types/graphql";
import { formatDate } from "@shared/utilities/formatDate";
import { isDarkMode } from "@shared/utilities/isDarkMode";
import { isEventType } from "@shared/utilities/isEventType";

import styles from "./MessageList.module.css";
import { UserAvatar } from "./UserAvatar";
import { useSenderInfo } from "./useSenderInfo";

interface Props extends MessageFragment {
	imageUrl?: string | null;
	isFirstMessageOnDay: boolean;
	isFirstConsecutiveMessageBySender: boolean;
	showImagePreview(url: string): void;
	onUploadFile(file: ChatFile): void;
}

const IMAGE_SIZE = 33;

export function MessageListItem({
	imageUrl,
	isFirstConsecutiveMessageBySender,
	showImagePreview,
	onUploadFile,
	...message
}: Props) {
	const { id, created_at, sender_expanded } = message;
	const { spacing, primaryForeground, primaryAccent700 } = useTheme();
	const { userName, userInitials, isPatient } = useSenderInfo(sender_expanded);
	const isIncoming = !isPatient && !isEventType(message);
	const isOutgoing = isPatient && !isEventType(message);
	const payload = message.payload as PortalMessagePayload | undefined;
	const isConsentRequest = payload?.type === "consent_form_request";
	const messageDate = formatDate(created_at, "MM/DD/YY HH:MM");

	const headsetIcon = useIconStyles({
		icon: headset,
		strokeWidth: 20,
		stroke: isDarkMode() ? primaryForeground : primaryAccent700,
	});

	const messageContent = (
		<Message
			message={message}
			senderName={userName}
			isIncoming={isIncoming}
			messageDate={messageDate}
			showImagePreview={showImagePreview}
			showBeforeArrow={isFirstConsecutiveMessageBySender}
			onUploadFile={onUploadFile}
		/>
	);

	if (isOutgoing) {
		return <span key={id}>{messageContent}</span>;
	}

	return (
		<span key={id}>
			<Stack
				direction="horizontal"
				gap={spacing(4)}
				align="start"
				justify={(() => {
					if (isIncoming || isConsentRequest) {
						return "start";
					} else if (isEventType(message)) {
						return "center";
					}

					return "end";
				})()}
				template={(() => {
					if (isIncoming || isConsentRequest) {
						return `${IMAGE_SIZE}px 1fr`;
					} else if (isEventType(message)) {
						return "1fr";
					}

					return `1fr ${IMAGE_SIZE}px`;
				})()}
				style={{ position: "relative" }}
			>
				{isIncoming && (
					<>
						{!isFirstConsecutiveMessageBySender || isConsentRequest ? (
							<span />
						) : (
							<UserAvatar
								withIndicator
								className={classNames(styles.messageListAvatar, {
									[styles.messageListAvatarIncoming]: isIncoming,
									[styles.messageListAvatarOutgoing]: isOutgoing,
								})}
								userName={userName || "Support Agent"}
								userInitials={userInitials}
								image={imageUrl}
								iconProps={headsetIcon}
								size={IMAGE_SIZE}
								tooltipAlignment="left"
							/>
						)}
					</>
				)}
				{isConsentRequest && <span />}
				{messageContent}
			</Stack>
		</span>
	);
}
