import { Button } from "@shared/components/Button";
import { Drawer } from "@shared/components/Drawer";
import { IconButton } from "@shared/components/IconButton";
import { useIconStyles } from "@shared/hooks/useIconStyles";
import { useToggle } from "@shared/hooks/useToggle";
import { cross } from "@shared/icons/cross";

type Props = {
	title: string;
	description?: string;
	cancelText?: string;
	confirmText?: string;
	onAgree?(): void;
	onCancel?(): void;
	children?(cb: () => void): void;
	show?: boolean;
	onToggle?(show: boolean): void;
};

export function ConfirmDrawer({
	title,
	description,
	cancelText = "Cancel",
	confirmText = "Confirm",
	onAgree,
	onCancel,
	children,
	show,
	onToggle,
}: Props) {
	const [open, toggleOpen] = useToggle();
	const iconProps = useIconStyles({
		icon: cross,
		size: 24,
	});

	const isOpen = show ?? open;
	const handleToggle = () => {
		if (onToggle) return onToggle(!open);
		toggleOpen();
	};

	const handleAgree = () => {
		onAgree?.();
		handleToggle();
	};

	const handleCancel = () => {
		onCancel?.();
		handleToggle();
	};

	return (
		<>
			{children?.(toggleOpen)}
			<Drawer show={isOpen} onToggle={handleToggle}>
				<div className="relative flex items-center justify-center px-4 py-3 border-b border-b-gray-200">
					<IconButton
						iconProps={iconProps}
						buttonProps={{
							onClick: handleToggle,
							variant: "plain",
							className: "absolute left-5",
						}}
					/>
					<span className="text-center text-lg font-semibold leading-6 tracking-tighter">
						{title}
					</span>
				</div>
				{description && (
					<p className="p-6 border-b border-b-gray-200 text-base leading-6 tracking-tighter">
						{description}
					</p>
				)}
				<div className="flex items-center justify-between gap-3 text-center p-4 text-base font-normal leading-6 tracking-tighter">
					<Button variant="secondary" onClick={handleCancel}>
						{cancelText}
					</Button>
					<Button variant="primary" onClick={handleAgree}>
						{confirmText}
					</Button>
				</div>
			</Drawer>
		</>
	);
}
