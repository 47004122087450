import { useCallback, useState } from "react";

import type { Options } from "@app/components/PatientHub/HubPage";
import { useFirst } from "@shared/hooks/useFirst";
import { debug } from "@shared/utilities/debug";

export enum TransitionDirection {
	Forwards = "forwards",
	Backwards = "backwards",
}

export function useTransitionState<T>(initialStage: T[]) {
	const [direction, setDirection] = useState<TransitionDirection>(
		TransitionDirection.Forwards
	);
	const [stages, setStages] = useState<T[]>(initialStage);
	const currentStage = useFirst(stages);

	debug("info", {
		context: "useTransitionState",
		message: "onNext",
		info: {
			initialStage,
			stages,
		},
	});
	const onPrevious = useCallback(
		(): Promise<T> =>
			new Promise((resolve) => {
				setDirection(TransitionDirection.Backwards);
				setStages((oldValue) => {
					// We've reached the root stage; we can't back out any further
					// or else we risk undefined behavior.
					if (oldValue.length === 1) {
						resolve(oldValue[0]);
						return oldValue;
					}

					const newValue = oldValue.slice(1);
					resolve(newValue[0]);
					return newValue;
				});
			}),
		[]
	);
	const onNext = useCallback(
		(stage: T, options?: Options): Promise<T> =>
			new Promise((resolve) => {
				setDirection(TransitionDirection.Forwards);
				setStages((oldValue) => {
					const newValue = options?.replace
						? [stage, ...oldValue.slice(1)]
						: [stage, ...oldValue];
					resolve(stage);
					return newValue;
				});
			}),
		[]
	);
	const goToStage = useCallback(
		(stage: T): Promise<T> =>
			new Promise((resolve) => {
				setDirection(TransitionDirection.Forwards);
				const newValue = [stage];
				setStages(newValue);
				resolve(stage);
			}),
		[]
	);
	if (currentStage === undefined) {
		throw new Error("Failed to find current stage");
	}

	return [
		currentStage,
		{
			direction,
			onPrevious,
			onNext,
			goToStage,
			stages,
		},
	] as const;
}
