type SupportedFormat = "pretty" | "numeric" | "numeric-no-country-code";

export function formatPhoneNumber(
	rawPhoneNumber: string | number,
	format: SupportedFormat
) {
	const phoneNumber = String(rawPhoneNumber);
	const numerical = phoneNumber.replace(/[^0-9]/g, "");
	const countryCode = numerical.slice(0, -10);
	const areaCode = numerical.slice(-10, -7);
	const exchangeCode = numerical.slice(-7, -4);
	const subscriberNumber = numerical.slice(-4);

	switch (format) {
		case "pretty": {
			return `${countryCode} (${areaCode}) ${exchangeCode}-${subscriberNumber}`.trim();
		}
		case "numeric": {
			return `${countryCode}${areaCode}${exchangeCode}${subscriberNumber}`.trim();
		}
		case "numeric-no-country-code": {
			return `${areaCode}${exchangeCode}${subscriberNumber}`.trim();
		}
	}
}
