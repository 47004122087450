import { faComment } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@shared/components/Button";

import { useChatButton } from "./ChatButton.hooks";

export function ChatButton() {
	const { showChatButton, showUnreadIndicator, handleButtonClick } =
		useChatButton();

	if (!showChatButton) return null;

	return (
		<Button
			variant="plain"
			onClick={handleButtonClick}
			data-testid="chat-button"
			className="relative"
		>
			<FontAwesomeIcon icon={faComment} />
			{showUnreadIndicator && (
				<span className="absolute top-3 right-1 h-2 w-2 bg-sky-300 rounded-full" />
			)}
		</Button>
	);
}
