import { chatboxOutline, chevronForward, calendarClear } from "ionicons/icons";
import { useMemo } from "preact/hooks";

import { useWidgetContext } from "@app/components/Contexts";
import { getServiceIntentStepRoute } from "@app/utilities/getServiceIntentStepRoute";
import { Badge } from "@shared/components/Badge";
import { List } from "@shared/components/ButtonList";
import { Icon } from "@shared/components/Icon";
import { Stack } from "@shared/components/Stack";
import { Text } from "@shared/components/Text";
import { useIconStyles } from "@shared/hooks/useIconStyles";
import { useNavigation } from "@shared/hooks/useNavigation";
import { useTheme } from "@shared/hooks/useTheme";
import { EncounterStatus } from "@shared/types/EncounterStatus";
import type { ServiceIntentStatus } from "@shared/types/ServiceIntentStatus";
import type { Encounter } from "@shared/types/graphql";
import { debug } from "@shared/utilities/debug";
import { formatDate } from "@shared/utilities/formatDate";
import { isDarkMode } from "@shared/utilities/isDarkMode";

import { UserAvatar } from "../../VisitChannel/UserAvatar";
import { useGetVisitStatusInfo } from "../../useGetVisitStatusInfo";
import { getPractitionerAvatarData } from "../getPractitionerAvatarData";
import { useVisitUnreadCounts } from "../useVisitUnreadCounts";
import styles from "./VisitsList.module.css";
import { INERT_STATUSES } from "./constants";
import type { Props } from "./types";

export function VisitsList({ serviceIntents, practitionerImages }: Props) {
	const { widgetId } = useWidgetContext();
	const { navigateTo } = useNavigation();
	const { primaryForeground, primaryAccent700, spacing } = useTheme();
	const chatIconStroke = isDarkMode() ? primaryForeground : primaryAccent700;

	const chevronIcon = useIconStyles({
		icon: chevronForward,
		stroke: primaryForeground,
	});
	const calendarIcon = useIconStyles({
		icon: calendarClear,
		stroke: primaryForeground,
	});
	const chatIcon = useIconStyles({
		icon: chatboxOutline,
		size: 18,
		stroke: chatIconStroke,
		strokeWidth: 60,
	});
	const getStatusInfo = useGetVisitStatusInfo();

	const channelUnreads = useVisitUnreadCounts(serviceIntents);
	const practitionerImagesByUserId = useMemo(
		() =>
			practitionerImages.reduce(
				(acc, image) => ({
					...acc,
					[image?.practitionerId ?? ""]: image?.url,
				}),
				{} as Record<string, string | undefined>
			),
		[practitionerImages]
	);

	const handleClickService = (serviceIntent: (typeof serviceIntents)[0]) => {
		const stepRoute = getServiceIntentStepRoute({
			widgetId,
			serviceId: serviceIntent.service_id,
			serviceIntent,
		});

		debug("info", {
			context: "VisitsList",
			message: "stepRoute",
			info: { stepRoute },
		});

		navigateTo(stepRoute);
	};

	return (
		<List
			items={serviceIntents.map((serviceIntent) => {
				const {
					id,
					status,
					created_at: createdAt,
					service,
					encounter,
				} = serviceIntent;

				const isInert = INERT_STATUSES.includes(status as ServiceIntentStatus);
				const practitionerInteraction = encounter?.practitioner_interaction;
				const practitioner = practitionerInteraction?.practitioner;
				const { userName, userInitials } =
					getPractitionerAvatarData(practitioner);
				const avatarUrl = practitionerImagesByUserId[practitioner?.id ?? ""];
				const channel = encounter?.portal_message_channel;
				const hasUnread = channel && (channelUnreads[channel.id] ?? 0) > 0;
				const statusInfo = getStatusInfo(
					(encounter?.status as Encounter["status"]) ?? EncounterStatus.CANCELED
				);
				const statusInfoLabel =
					service?.status === "draft"
						? "Service no longer offered"
						: statusInfo.name;

				const hideCreatedAt =
					!encounter?.status ||
					encounter?.status === EncounterStatus.CANCELED ||
					encounter?.status === EncounterStatus.CANCELLED ||
					encounter?.status === EncounterStatus.COMPLETED;

				return {
					key: id,
					title: service?.name ?? "",
					description: (
						<Stack direction="vertical" gap={spacing(1)}>
							{!hideCreatedAt && (
								<Stack direction="horizontal" gap={spacing(2)} align="center">
									<Icon size={16} {...calendarIcon} />
									<Text>Started {formatDate(new Date(createdAt))}</Text>
								</Stack>
							)}
							<Stack direction="horizontal" gap={spacing(2)} align="center">
								<div
									className={styles.circle}
									style={{ backgroundColor: statusInfo.color }}
								/>
								<Text>{statusInfoLabel}</Text>
							</Stack>
						</Stack>
					),
					iconPosition: "after",
					iconProps: (() => {
						if (isInert || service?.status === "draft") return undefined;
						return chevronIcon;
					})(),
					startIcon: (
						<Stack direction="horizontal" padding={{ start: spacing(4) }}>
							<UserAvatar
								image={avatarUrl}
								userName={userName}
								userInitials={userInitials}
								iconProps={chatIcon}
								size={28}
								showTooltipOnHover={false}
							/>
						</Stack>
					),
					endIcon: (() => {
						if (isInert) return null;
						return (
							<Stack direction="horizontal" padding={{ end: spacing(4) }}>
								{hasUnread ? (
									<div className={styles.badge}>
										<Badge
											circle
											backgroundColor="#7DD3FC"
											noBorder
											data-testid="unread-notification-badge"
										/>
									</div>
								) : null}
								<Icon {...chevronIcon} />
							</Stack>
						);
					})(),
					onClick: () => handleClickService(serviceIntent),
					align: "start",
				};
			})}
		/>
	);
}
