import { useEffect, useState } from "react";

import { close, document } from "ionicons/icons";

import { Button } from "@shared/components/Button";
import { Icon } from "@shared/components/Icon";
import { Text } from "@shared/components/Text";
import { useIconStyles } from "@shared/hooks/useIconStyles";
import { useTheme } from "@shared/hooks/useTheme";
import { fileToDataUrl } from "@shared/utilities/fileToDataUrl";
import { isImageFile } from "@shared/utilities/isImageFile";

import styles from "./UploadPreviews.module.css";

interface Props {
	fileMap: Map<string, File>;
	fileIds: string[];
	onRemoveFile: (fileId: string) => void;
	previewSize?: number;
}

export function UploadPreviews({
	fileMap,
	fileIds,
	onRemoveFile,
	previewSize,
}: Props) {
	const { primaryForeground, primaryAccent50 } = useTheme();
	const closeIconProps = useIconStyles(
		{
			icon: close,
			size: 16,
			fill: primaryAccent50,
		},
		true
	);
	const [filePreviews, setFilePreviews] = useState<[File, string][]>([]);
	useEffect(() => {
		fileIds.forEach((fileId) => {
			const file = fileMap.get(fileId);
			if (!file || !isImageFile(file)) return;

			fileToDataUrl(file).then((dataUrl) => {
				setFilePreviews((prevValue) => [
					...prevValue.filter(([previewFile]) => previewFile !== file),
					[file, dataUrl],
				]);
			});
		});
	}, [fileIds, fileMap]);

	if (!fileIds.length) return null;
	return (
		<div className={styles.previewFiles}>
			{fileIds.map((fileId) => {
				const file = fileMap.get(fileId);
				const fileName = file?.name ?? "File Attachment";
				const data = filePreviews.find(([previewFile]) => previewFile === file);
				const [, preview] = data ?? [];
				if (!preview) {
					return (
						<div key={fileId} className={styles.previewItem}>
							<div className={styles.previewFileContainer}>
								<Icon icon={document} fill={primaryForeground} size={24} />
								<Text bold clip align="center" variant="small">
									{fileName}
								</Text>
								<div className={styles.closeButton}>
									<Button
										variant="plain"
										iconProps={closeIconProps}
										onClick={() => onRemoveFile(fileId)}
									/>
								</div>
							</div>
						</div>
					);
				}

				return (
					<div key={fileId} className={styles.previewItem}>
						<div
							className={styles.previewImageContainer}
							style={{ width: previewSize, height: previewSize }}
						>
							<img
								className={styles.previewImage}
								src={preview}
								alt={`Preview of ${fileName}`}
							/>
						</div>
						<div className={styles.closeButton}>
							<Button
								variant="plain"
								iconProps={closeIconProps}
								onClick={() => onRemoveFile(fileId)}
							/>
						</div>
					</div>
				);
			})}
		</div>
	);
}
