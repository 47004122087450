export function getTextFromMapping<Type extends string>(
	mapping: Record<Type, string>,
	key: string | null | undefined,
	defaultText = "Unknown"
) {
	if (!key) {
		return defaultText;
	}

	return mapping[key as keyof typeof mapping] ?? key;
}
