import { useEffect } from "react";

export function useKeyboardEvent(
	callback: (event: KeyboardEvent) => void,
	element = document.body
) {
	useEffect(() => {
		element.addEventListener("keydown", callback);
		return () => element.removeEventListener("keydown", callback);
	}, [callback, element]);
}
