import { useEffect, useRef, useState, type KeyboardEvent } from "react";

import { calendarClear } from "ionicons/icons";

import { InputGroup } from "@shared/components/InputGroup";
import { useTheme } from "@shared/hooks/useTheme";
import { formatDate } from "@shared/utilities/formatDate";

const ONE_HUNDRED_FIFTY_YEARS = 150 * 365 * 24 * 60 * 60 * 1000;
const minDate = new Date(Date.now() - ONE_HUNDRED_FIFTY_YEARS);
const maxDate = new Date();

type Props = {
	label?: string;
	autocomplete?: string;
	value?: Date | null;
	onInput(newValue: Date | null): void;
	disabled?: boolean;
	required?: boolean;
};

// Prevent user from typing non-numeric characters
const preventNonNumericalInput = (e: KeyboardEvent<HTMLInputElement>) => {
	const validNonNumericKeys = [
		"Backspace",
		"Delete",
		"Tab",
		"ArrowLeft",
		"ArrowRight",
		"Home",
		"End",
		"Meta",
		"Control",
		"Alt",
		"Shift",
		"Enter",
		"/",
	];
	if (e.metaKey || validNonNumericKeys.includes(e.key)) return;
	if ((e.key ?? "").match(/[^0-9]/)) e.preventDefault();
};

export function InputBirthdate({
	label,
	value,
	onInput,
	disabled,
	required,
}: Props) {
	const { primaryPlaceholder } = useTheme();
	const [currentValue, setCurrentValue] = useState<string>("");
	const prevValue = useRef<Date | null>(null);
	useEffect(() => {
		if (!value) return;

		const [month, day, year] = formatDate(value, "MM/DD/YYYY").split("/");
		const parsedYear = parseInt(year, 10);
		const parsedMonth = parseInt(month, 10);
		const parsedDay = parseInt(day, 10);
		if (!parsedYear || !parsedMonth || !parsedDay) {
			return;
		}

		setCurrentValue(`${month}/${day}/${year}`);
	}, [value]);
	useEffect(() => {
		// Validate inputs might be worth checking.
		const [month = "", day = "", year = ""] = (currentValue ?? "").split("/");
		if (month.length < 1 || day.length < 1 || year.length < 4) {
			prevValue.current = null;
			return onInput(null);
		}

		// Validate inputs are valid.
		const parsedYear = parseInt(year, 10);
		const parsedMonth = Math.min(parseInt(month, 10), 12);
		const daysInMonth = new Date(
			// @ts-expect-error - isNaN works for strings too
			isNaN(year) ? new Date().getFullYear() : parsedYear,
			parsedMonth,
			0
		).getDate();

		const parsedDay = Math.min(parseInt(day, 10), daysInMonth);

		const displayYear = parsedYear || year;
		// @ts-expect-error - isNaN works for strings too
		const displayMonth = isNaN(month)
			? month
			: `${parsedMonth}`.padStart(2, "0");
		// @ts-expect-error - isNaN works for strings too
		const displayDay = isNaN(day) ? day : `${parsedDay}`.padStart(2, "0");

		setCurrentValue(`${displayMonth}/${displayDay}/${displayYear}`);

		if (!parsedYear || !parsedMonth || !parsedDay) {
			prevValue.current = null;
			return onInput(null);
		}

		// Validate date changed.
		const date = new Date(`${parsedYear}/${parsedMonth}/${parsedDay}`);
		if (date.getTime() === prevValue.current?.getTime()) {
			return;
		}

		// Validate date is within range.
		const isTooOld = date.getTime() < minDate.getTime();
		const isTooYoung = date.getTime() >= maxDate.getTime();
		if (isTooOld || isTooYoung) {
			prevValue.current = null;
			return onInput(null);
		}

		// Update last date and fire callback.
		prevValue.current = date;
		onInput(date);
	}, [currentValue, onInput]);

	const handleInput = (value: string) => {
		// Clear input if user deletes all numbers.
		setCurrentValue(value.match(/\d/g) ? value : "");
	};

	return (
		<InputGroup
			type="text"
			label={label}
			iconProps={{
				icon: calendarClear,
				fill: primaryPlaceholder,
				size: 16,
			}}
			pattern="(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d"
			autoComplete="bday"
			value={currentValue}
			onKeyDown={preventNonNumericalInput}
			onInput={handleInput}
			disabled={disabled}
			required={required}
			inputMode="numeric"
			mask="99/99/9999"
			placeholder="MM/DD/YYYY"
		/>
	);
}
