import {
	type SubscribePayload,
	createClient as createWSClient,
} from "graphql-ws";
import {
	createClient,
	fetchExchange,
	mapExchange,
	subscriptionExchange,
} from "urql";

import * as Sentry from "@sentry/browser";
import { GQL_ENDPOINT, WS_ENDPOINT } from "@shared/constants/environments";
import { cacheExchange } from "@urql/exchange-graphcache";
import { simplePagination } from "@urql/exchange-graphcache/extras";

import { retryExchange } from "./exchanges/retry";
import { getHeaders } from "./headers";

const wsClient = createWSClient({
	url: WS_ENDPOINT,
	connectionParams() {
		return {
			headers: getHeaders(),
		};
	},
});

export const makeClient = () =>
	createClient({
		url: GQL_ENDPOINT,
		fetch: (uri, options) => {
			try {
				const { operationName } = JSON.parse(
					options?.body as unknown as string
				);
				return fetch(`${uri}?opname=${operationName}`, options);
			} catch (error) {
				return fetch(uri, options);
			}
		},

		fetchOptions: () => ({
			headers: getHeaders(),
			credentials: "include",
		}),
		exchanges: [
			mapExchange({
				onError: (error, operation) => {
					const operationDefinition = operation.query.definitions[0];
					let operationName = "Unknown";

					if (operationDefinition?.kind === "OperationDefinition") {
						operationName = operationDefinition.name?.value ?? operationName;
					}

					Sentry.captureException(error, {
						extra: {
							operationKind: operation.kind,
							operationName,
							variables: operation.variables,
						},
						fingerprint: ["GraphQL", operationName, error.message],
					});
				},
			}),
			retryExchange,
			cacheExchange({
				keys: {
					organization: (result) => `${result.exId}`,
					VerifyLoginCodeOutput: () => null,
					RefreshTokenOutput: (result) => `${result.jwt}`,
					GetStripeSecretOutput: (result) => `${result.clientSecret}`,
					GetFileUploadURLOutput: (result) => `${result.fileId}`,
					EncounterQuestionnaireResponses: (result) => `${result.encounterId}`,
					ConsentFormsTextForOrganization: () => null,
					GetPractitionerImagesForServiceIntentsOutput: (result) =>
						`${result.practitionerId}`,
					Order: (result) => `${result.ref_id}`,
					widget_config: (result) => `${result.public_widget_key}`,
				},
				resolvers: {
					Query: {
						portal_message: simplePagination({
							limitArgument: "limit",
							offsetArgument: "offset",
							mergeMode: "before",
						}),
					},
				},
				updates: {
					Mutation: {},
				},
			}),
			fetchExchange,
			subscriptionExchange({
				forwardSubscription(request) {
					return {
						subscribe: (sink) => ({
							unsubscribe: wsClient.subscribe(
								request as SubscribePayload,
								sink
							),
						}),
					};
				},
			}),
		],
	});
