import type { ComponentChildren } from "preact";

import { AuthContextProvider } from "@app/components/Contexts/AuthContext";
import { LaunchDarklyContext } from "@app/components/Contexts/LaunchDarklyContext";
import { OrganizationContextProvider } from "@app/components/Contexts/OrganizationContext";
import { PatientContextProvider } from "@app/components/Contexts/PatientContext";
import { WidgetContextProvider } from "@app/components/Contexts/WidgetContext";
import { Theme as RadixThemeProvider } from "@radix-ui/themes";
import type { Theme } from "@shared/hooks/useTheme";
import { ThemeProvider } from "@shared/hooks/useTheme";

interface Props {
	widgetId: string;
	lightTheme?: Theme;
	darkTheme?: Theme;
	children: ComponentChildren;
}

export function Providers({
	widgetId,
	lightTheme,
	darkTheme,
	children,
}: Props) {
	return (
		<RadixThemeProvider>
			<WidgetContextProvider widgetId={widgetId}>
				<ThemeProvider dark={darkTheme} light={lightTheme}>
					<AuthContextProvider>
						<PatientContextProvider>
							<OrganizationContextProvider>
								<LaunchDarklyContext>{children}</LaunchDarklyContext>
							</OrganizationContextProvider>
						</PatientContextProvider>
					</AuthContextProvider>
				</ThemeProvider>
			</WidgetContextProvider>
		</RadixThemeProvider>
	);
}
