import classNames from "classnames";

import { Stack } from "@shared/components/Stack";
import type { ThemeColor } from "@shared/hooks/useTheme";
import { useTheme } from "@shared/hooks/useTheme";

import styles from "./Loader.module.css";

interface Props {
	compact?: boolean;
	color?: ThemeColor;
}

export function Loader({ compact, color }: Props) {
	const { primaryAccent600 } = useTheme();
	const loaderStyle = {
		backgroundColor: (color ?? primaryAccent600)?.rgba,
	};

	return (
		<Stack direction="vertical" align="center" justify="center">
			<div
				className={classNames(styles.loader, {
					[styles.compact]: compact,
				})}
				aria-label="Loading"
			>
				<div className={classNames(styles.loaderChild)} style={loaderStyle} />
				<div className={classNames(styles.loaderChild)} style={loaderStyle} />
				<div className={classNames(styles.loaderChild)} style={loaderStyle} />
				<div className={classNames(styles.loaderChild)} style={loaderStyle} />
			</div>
		</Stack>
	);
}
