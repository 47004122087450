import { useEffect, useState } from "preact/hooks";

import { useFeatureFlag } from "@app/components/Contexts/LaunchDarklyContext";
import { Time } from "@shared/constants/utils";
import { debug } from "@shared/utilities/debug";

interface CustomWindow extends Window {
	triggerConfirmReload?: boolean;
}

declare let window: CustomWindow;

export function useAppVersionSync(pause?: boolean) {
	const versionSyncEnabled = useFeatureFlag("appVersionSyncEnabled");
	const [showConfirmReload, setShowConfirmReload] = useState(false);

	const reloadPage = () => {
		window.location.reload();
	};

	const hideConfirmReloadDrawer = () => {
		setShowConfirmReload(false);
	};

	const checkVersion = async () => {
		try {
			const response = await fetch("/api/version");
			const { version } = await response.json();

			debug("info", {
				context: "/api/version",
				message: "Vercel app version",
				info: {
					appVersion: __APP_COMMIT_HASH__,
					vercelAppVersion: version,
				},
			});

			if (version !== __APP_COMMIT_HASH__) {
				setShowConfirmReload(true);
			}
		} catch (error) {
			debug("error", {
				context: "/api/version",
				message: "Error fetching the latest version",
				info: {
					error,
					appVersion: __APP_COMMIT_HASH__,
				},
			});
			return null;
		}
	};

	useEffect(() => {
		if (pause || !versionSyncEnabled) return;

		// Polling every 10 minutes
		const interval = setInterval(checkVersion, 10 * Time.MINUTE_MS);
		return () => {
			clearInterval(interval);
			delete window.triggerConfirmReload;
		};
	}, [pause, versionSyncEnabled]);

	return { showConfirmReload, reloadPage, hideConfirmReloadDrawer };
}
