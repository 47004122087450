import type { JSX } from "preact";

import { FormContent } from "@shared/components/Form";
import type { Address } from "@shared/components/Input/Input";
import { Input } from "@shared/components/Input/Input";
import { InputGroup } from "@shared/components/InputGroup";
import { Stack } from "@shared/components/Stack";
import { useTheme } from "@shared/hooks/useTheme";

type Props = {
	title: string;
	subtitle?: string | null;
	value: Address | null;
	onChange: (value: Address) => void;
	isSubmitting: boolean;
	sameAddressLabel?: string;
	shouldUseSameAddress?: boolean;
	onChangeUseSameAddress?: (value: boolean) => void;
};

export function Picker({
	title,
	subtitle,
	value,
	onChange,
	isSubmitting,
	sameAddressLabel = "Use same address",
	shouldUseSameAddress,
	onChangeUseSameAddress,
}: Props) {
	const { spacing } = useTheme();

	const showSameAddressCheckbox = shouldUseSameAddress !== undefined;
	const showAddressPicker =
		shouldUseSameAddress === undefined || !shouldUseSameAddress;

	return (
		<FormContent title={title} subtitle={subtitle} isSubmitting={isSubmitting}>
			{showSameAddressCheckbox && (
				<Input
					type="checkbox"
					data-testid="same-address-checkbox"
					label={sameAddressLabel}
					value={`${shouldUseSameAddress}`}
					checked={shouldUseSameAddress}
					onInput={(e: JSX.TargetedEvent) => {
						onChangeUseSameAddress?.((e.target as HTMLInputElement)?.checked);
					}}
				/>
			)}

			{showAddressPicker && (
				<InputGroup
					type="address"
					label="Address 1"
					value={value?.line1}
					onInput={onChange}
					placeholder="Street Address"
				/>
			)}
			{showAddressPicker && value && (
				<>
					<InputGroup
						type="text"
						label="Address 2"
						value={value.line2 || ""}
						onInput={(line2) => onChange({ ...value, line2 })}
						placeholder="Apt, Suite, etc."
					/>
					<InputGroup
						type="text"
						label="City"
						value={value.city}
						onInput={(city) => onChange({ ...value, city })}
						placeholder="City"
					/>
					<Stack direction="horizontal" gap={spacing(2)} justify="stretch">
						<InputGroup
							type="text"
							label="State"
							value={value.state}
							onInput={(state) => onChange({ ...value, state })}
							placeholder="State"
						/>
						<InputGroup
							type="text"
							label="Zip"
							value={value.zip}
							onInput={(zip) => onChange({ ...value, zip })}
							placeholder="Zip"
						/>
					</Stack>
				</>
			)}
		</FormContent>
	);
}
