import { useCallback } from "react";

import * as mime from "mime-types";
import { useMutation } from "urql";

import {
	WidgetCreateFileUploadUrlDocument,
	WidgetCreatePatientFileUploadUrlDocument,
} from "@shared/types/graphql";

export function useUploadFile() {
	const [, createFileUploadUrl] = useMutation(
		WidgetCreateFileUploadUrlDocument
	);
	const [, createPatientFileUploadUrl] = useMutation(
		WidgetCreatePatientFileUploadUrlDocument
	);
	const uploadFile = useCallback(
		async (file: File, questionId = file.name, patientId?: string | null) => {
			const fileType = questionId.split(" ").join("_").toLowerCase();
			let fileId: string | undefined;
			let signedFileUploadURL: string | undefined;

			if (patientId) {
				const { data } = await createPatientFileUploadUrl({
					patientId,
					contentType: file.type,
					fileName: file.name,
					fileType,
				});
				const response = data?.createPatientFile;

				fileId = response?.fileId;
				signedFileUploadURL = response?.signedFileUploadURL;
			} else {
				const { data } = await createFileUploadUrl({
					contentType: file.type,
					fileName: file.name,
				});

				const response = data?.createFileUploadURL;

				fileId = response?.fileId;
				signedFileUploadURL = response?.signedURL;
			}

			if (!file || !fileId || !signedFileUploadURL) {
				throw new Error("Failed to create file upload URL");
			}

			// Upload file.
			await fetch(signedFileUploadURL, {
				method: "PUT",
				body: file,
				headers: {
					"Content-Disposition": `attachment; filename=${fileId}.${mime.extension(
						file.type
					)}`,
				},
			});

			return {
				file,
				fileId,
				content: URL.createObjectURL(file),
			};
		},
		[createFileUploadUrl, createPatientFileUploadUrl]
	);

	return { uploadFile };
}
