import type {
	ReactNode,
	ComponentType,
	FormEvent,
	UIEventHandler,
} from "react";
import { useMemo, useState } from "react";

import { Alert } from "@shared/components/Alert";
import type { Props as ButtonProps } from "@shared/components/Button";
import { Highlight } from "@shared/components/Highlight";
import { Stack } from "@shared/components/Stack";
import { Text } from "@shared/components/Text";
import { useTheme } from "@shared/hooks/useTheme";
import { formatErrorMessage } from "@shared/utilities/formatErrorMessage";

import styles from "./Form.module.css";

interface SharedProps {
	title?: string;
	subtitle?: string | null;
	errorMessage?: string | null;
	successMessage?: string | null;
	submitButtonProps?: ButtonProps;
	additionalButtonProps?: ButtonProps;
	wrapperComponent?: ComponentType<{
		buttonProps?: ButtonProps;
		additionalButtonProps?: ButtonProps;
		children: ReactNode;
	}>;
	justifyButtons?: "center" | "start" | "end";
	formSubtextDescription?: JSX.Element | string | null;
}

interface FormProps extends SharedProps {
	onSubmit(event: FormEvent<HTMLFormElement>): Promise<void>;
	children(info: {
		errorMessage?: string | null;
		successMessage?: string | null;
		setErrorText: (message: string | null) => void;
		submitForm: (() => void) | null;
		isSubmitting: boolean;
	}): ReactNode;
	plain?: boolean;
}

interface FormContentProps extends SharedProps {
	children: ReactNode;
	isSubmitting: boolean;
}

export function FormContent({
	title,
	subtitle,
	errorMessage,
	successMessage,
	children,
	submitButtonProps,
	additionalButtonProps,
	isSubmitting,
	wrapperComponent: Wrapper = Highlight,
	formSubtextDescription,
}: FormContentProps) {
	const { spacing } = useTheme();
	return (
		<Wrapper
			buttonProps={
				submitButtonProps
					? {
							...submitButtonProps,
							type: "submit",
							...(isSubmitting ? { disabled: true } : {}),
					  }
					: undefined
			}
			additionalButtonProps={additionalButtonProps}
		>
			<Stack direction="vertical" gap={spacing(1)}>
				<Stack direction="vertical" gap={spacing(3)}>
					<Stack direction="vertical" gap={spacing(5)}>
						{title && <Text variant="h2">{title}</Text>}
						{subtitle && <Text variant="body">{subtitle}</Text>}
					</Stack>
					{errorMessage && (
						<Alert variant="danger" caption="Uh-oh" text={errorMessage ?? ""} />
					)}
					{successMessage && (
						<Alert
							variant="success"
							caption="Success"
							text={successMessage ?? ""}
						/>
					)}
					<Stack direction="vertical" align="stretch" gap={spacing(2)}>
						{children}
					</Stack>
				</Stack>
				{formSubtextDescription && (
					<div className="self-stretch flex flex-row py-0 sm:px-2 items-center justify-center text-xs text-gray-800 dark:text-gray-200">
						<div className="flex-1 relative leading-[20px]">
							{formSubtextDescription}
						</div>
					</div>
				)}
			</Stack>
		</Wrapper>
	);
}

export function Form({
	errorMessage,
	successMessage,
	title,
	subtitle,
	children,
	submitButtonProps,
	additionalButtonProps,
	onSubmit,
	plain,
	justifyButtons,
	wrapperComponent,
	formSubtextDescription,
}: FormProps) {
	const [form, setForm] = useState<HTMLFormElement | null>(null);
	const [errorText, setErrorText] = useState<string | null>(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const submitForm = useMemo(() => {
		if (!form) return null;
		return () => form.dispatchEvent(new Event("submit"));
	}, [form]);
	const onFormSubmit: UIEventHandler<HTMLFormElement> = async (
		event: FormEvent<HTMLFormElement>
	) => {
		event.preventDefault();
		event.stopPropagation();
		if (isSubmitting) return;

		setErrorText(null);
		setIsSubmitting(true);
		try {
			await onSubmit(event);
		} catch (error) {
			setErrorText(
				(error as Error).message ??
					"An unknown error occurred. Please try again."
			);
		} finally {
			setIsSubmitting(false);
		}
	};

	const errorTextValue = formatErrorMessage(errorText || errorMessage);

	return (
		<form className={styles.form} ref={setForm} onSubmit={onFormSubmit}>
			{plain ? (
				children({ isSubmitting, submitForm, setErrorText })
			) : (
				<FormContent
					title={title}
					subtitle={subtitle}
					errorMessage={errorTextValue}
					successMessage={successMessage}
					submitButtonProps={submitButtonProps}
					additionalButtonProps={additionalButtonProps}
					isSubmitting={isSubmitting}
					justifyButtons={justifyButtons}
					wrapperComponent={wrapperComponent}
					formSubtextDescription={formSubtextDescription}
				>
					{children({
						successMessage,
						isSubmitting,
						submitForm,
						setErrorText,
						errorMessage: errorTextValue,
					})}
				</FormContent>
			)}
		</form>
	);
}
