import classNames from "classnames";
import { close, search as searchIcon } from "ionicons/icons";
import { useState } from "preact/hooks";

import { InputGroup } from "@shared/components/InputGroup";
import { useTheme } from "@shared/hooks/useTheme";

import styles from "./SearchBar.module.css";

type Props = {
	"data-testid"?: string;
	onSearch: (search: string) => void;
};

export function SearchBar({ onSearch, ...restProps }: Props) {
	const [search, setSearch] = useState<string>("");
	const { primaryForeground } = useTheme();

	const handleInputChange = (value: string) => {
		setSearch(value);
		onSearch(value);
	};

	return (
		<InputGroup
			type="text"
			placeholder="Search"
			label="Search"
			onInput={handleInputChange}
			value={search}
			iconProps={{
				icon: searchIcon,
				size: 16,
				fill: primaryForeground,
			}}
			buttonIconProps={{
				icon: close,
				size: 16,
				fill: primaryForeground,
				className: classNames({
					[styles.endIconHidden]: !search,
				}),
				onClick: () => handleInputChange(""),
			}}
			{...restProps}
		/>
	);
}
