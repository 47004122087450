import { useMemo } from "preact/hooks";
import { useQuery } from "urql";

import type { ServiceIntentStatus } from "@shared/types/ServiceIntentStatus";
import {
	SERVICE_INTENT_EDITABLE_STATUSES,
	SERVICE_INTENT_INPROGRESS_STATUSES,
} from "@shared/types/ServiceIntentStatus";
import { WidgetGetServiceIntentsForServiceDocument } from "@shared/types/graphql";

export function useServiceIntentByService({
	serviceId,
	skip,
}: {
	serviceId: string;
	skip: boolean;
}) {
	const [{ data, fetching, error }] = useQuery({
		query: WidgetGetServiceIntentsForServiceDocument,
		variables: {
			serviceId,
		},
		pause: skip,
		// We need to fetch the service intent every time, because it may have changed
		requestPolicy: "network-only",
	});

	const serviceIntent = useMemo(() => {
		if (data === undefined) {
			return undefined;
		}

		const serviceIntents = data?.service_intent;
		if (!serviceIntents) {
			return null;
		}
		const sortedServiceIntents = serviceIntents.sort((a, b) => {
			// Sort by updated_at in descending order
			return (
				new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
			);
		});

		// Returns serviceIntent object in index 0 of sorted array.
		return (
			sortedServiceIntents.find((serviceIntent) => {
				// Prioritize SERVICE_INTENT_INPROGRESS_STATUSES, then SERVICE_INTENT_EDITABLE_STATUSES, then any other status
				if (
					Array.from(SERVICE_INTENT_INPROGRESS_STATUSES).includes(
						serviceIntent.status as ServiceIntentStatus
					)
				) {
					return true;
				} else if (
					Array.from(SERVICE_INTENT_EDITABLE_STATUSES).includes(
						serviceIntent.status as ServiceIntentStatus
					)
				) {
					return true;
				} else {
					return false;
				}
			}) ?? null
		);
	}, [data]);

	return {
		done: skip || !!data,
		serviceIntent,
		fetching,
		error,
	};
}
