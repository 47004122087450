/* eslint-disable no-console */
const DEBUG_MODE = window.location.host !== "app.catchhealth.com";

export function debug(
	level: "error" | "warn" | "info" | "log" | "debug",
	payload: {
		context: string;
		message: string;
		info?: unknown;
	}
) {
	if (!DEBUG_MODE) return;

	if (level === "error") {
		console.error(`LOG-${level.toUpperCase()}`, payload);
	} else if (level === "warn") {
		console.warn(`LOG-${level.toUpperCase()}`, payload);
	} else if (level === "info") {
		console.info(`LOG-${level.toUpperCase()}`, payload);
	} else if (level === "log") {
		console.log(`LOG-${level.toUpperCase()}`, payload);
	} else if (level === "debug") {
		console.debug(`LOG-${level.toUpperCase()}`, payload);
	}
}
