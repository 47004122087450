import { faNotesMedical } from "@fortawesome/pro-solid-svg-icons";
import { IconButton } from "@shared/components/IconButton";
import { useTheme } from "@shared/hooks/useTheme";

export type DetailsButtonProps = {
	onDetailsClick: () => void;
};

export function DetailsButton({ onDetailsClick }: DetailsButtonProps) {
	const { primaryAccent700 } = useTheme();

	return (
		<IconButton
			buttonProps={{
				variant: "plain",
				onClick: onDetailsClick,
			}}
			faIconProps={{ icon: faNotesMedical, color: primaryAccent700?.rgba }}
			textProps={{
				children: "Visit details",
				className:
					"mr-[10px] capitalize text-sm font-semibold leading-5 text-primary-accent-700 border-solid border-0 border-b-[1px] border-primary-accent-700",
			}}
			textPosition="before"
		/>
	);
}

DetailsButton.componentName = "DetailsButton";
