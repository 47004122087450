import { useCallback } from "preact/hooks";
import { useQuery } from "urql";

import { PharmacyPicker } from "@app/components/Forms/PharmacyPicker";
import { Header } from "@app/components/Header";
import { Main } from "@app/components/Main";
import { Hero } from "@shared/components/Hero";
import { Stack } from "@shared/components/Stack";
import { useTheme } from "@shared/hooks/useTheme";
import {
	WidgetGetPharmacyDocument,
	WidgetGetServiceIntentForPharmacyDocument,
} from "@shared/types/graphql";

import type { CheckoutStepProps } from "../CheckoutStep";
import { CheckoutStep } from "../CheckoutStep";

export function Pharmacy({
	serviceIntentId,
	onNextStep,
	onPreviousStep,
}: CheckoutStepProps) {
	const { spacing } = useTheme();
	const [{ data: serviceIntentData }, fetchServiceIntent] = useQuery({
		query: WidgetGetServiceIntentForPharmacyDocument,
		variables: { serviceIntentId },
		pause: !serviceIntentId,
	});
	const pharmacyId = serviceIntentData?.service_intent_by_pk
		?.requested_pharmacy_ncpdp_id as string;

	const [{ data: pharmacyData }] = useQuery({
		query: WidgetGetPharmacyDocument,
		variables: { pharmacyId },
		pause: !pharmacyId,
	});

	const handleSuccess = useCallback(
		(selectedPharmacyId?: string) => {
			if (selectedPharmacyId && selectedPharmacyId !== pharmacyId) {
				fetchServiceIntent({ requestPolicy: "network-only" });
			}
			onNextStep(CheckoutStep.Address, {});
		},
		[fetchServiceIntent, onNextStep, pharmacyId]
	);

	return (
		<>
			<Header />
			<Main>
				<Stack direction="vertical" align="center" gap={spacing(5)}>
					<Hero
						title="Find a Pharmacy"
						subtitle="Medications you're prescribed will be delivered to your pharmacy of choice."
					/>
					<PharmacyPicker
						serviceIntentId={serviceIntentId}
						pharmacyId={pharmacyData?.pharmacyById?.id}
						pharmacyZip={pharmacyData?.pharmacyById?.zip}
						onBack={onPreviousStep}
						onSuccess={handleSuccess}
						nextButtonText="Continue"
						prevButtonText="Go back"
					/>
				</Stack>
			</Main>
		</>
	);
}
