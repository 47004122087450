export enum HubPage {
	Visits = "Visits",
	VisitChannel = "VisitChannel",
	VisitChannelDetails = "VisitChannelDetails",
	AccountDetails = "AccountDetails",
}
export type HubPageTransitionProps = {
	[HubPage.Visits]: Record<never, unknown>;
	[HubPage.VisitChannel]: {
		channelId: string;
	};
	[HubPage.VisitChannelDetails]: {
		channelId: string;
	};
	[HubPage.AccountDetails]: Record<never, unknown>;
};

export type Options = {
	replace: boolean;
};

export type HubPageProps = {
	onPreviousPage: () => void;
	onNextPage: <Page extends HubPage>(
		page: Page,
		props: HubPageTransitionProps[Page],
		options?: Options
	) => void;
};
