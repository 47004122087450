import { debug } from "@shared/utilities/debug";
import { MemoryStorage } from "@shared/utilities/MemoryStorage";

export const memoryStorage = new MemoryStorage();

// Google Chrome throws an error when trying to access localStorage or sessionStorage
// from incognito, so here we wrap getting the storage in a try/catch. MemoryStorage
// is exposed as a fallback in case localStorage or sessionStorage is not available.
export const BrowserStorage = {
	get localStorage() {
		try {
			return window.localStorage;
		} catch (error) {
			debug("error", {
				context: "BrowserStorage",
				message: "Error getting local storage",
				info: { error },
			});
			return null;
		}
	},

	get sessionStorage() {
		try {
			return window.sessionStorage;
		} catch (error) {
			debug("error", {
				context: "BrowserStorage",
				message: "Error getting session storage",
				info: { error },
			});
			return null;
		}
	},

	get memoryStorage() {
		return memoryStorage;
	},
};
