import { useFeatureFlag } from "@app/components/Contexts/LaunchDarklyContext";

import { VisitsList as LegacyVisitsList } from "./LegacyVisitsList";
import { VisitsList as NewVisitsList } from "./VisitsList";
import type { Props } from "./types";

export function VisitsList(props: Props) {
	const hasConsentForms = useFeatureFlag("consentForms");
	const List = hasConsentForms ? NewVisitsList : LegacyVisitsList;
	return <List {...props} />;
}
