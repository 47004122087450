import { useEffect, type RefObject } from "react";

export function useClickOutside<T extends HTMLElement>(
	ref: RefObject<T>,
	onClose?: () => void,
	exceptions?: `#${string}`[]
): void {
	useEffect(() => {
		const rootElement = ref.current;

		function handleClickOutside(evt: MouseEvent) {
			const isExceptionClicked = exceptions?.some((exception) => {
				const target = evt.target as HTMLElement;

				const clickedElement = target.closest(exception);
				const isTargetClicked = target.id === exception.replace("#", "");

				return isTargetClicked || clickedElement !== null;
			});

			if (
				!rootElement ||
				isExceptionClicked ||
				!checkIsOutsideClick(rootElement, evt)
			) {
				return;
			}

			onClose?.();
		}

		document.addEventListener("click", handleClickOutside, true);

		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, [ref, exceptions, onClose]);
}

export function checkIsOutsideClick<T extends HTMLElement>(
	rootElement: T,
	evt: MouseEvent
) {
	if (
		rootElement === evt.target ||
		rootElement.contains(evt.target as HTMLElement)
	) {
		return false;
	}

	return true;
}
