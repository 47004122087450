import { useCallback, useState } from "react";

import type { StorageKeys } from "@app/types/StorageKeys";
import {
	usePublisher,
	PubSubMessage,
	useSubscription,
} from "@shared/hooks/usePubSub";
import { safeLocalStorage } from "@shared/utilities/safeLocalStorage";

type BaseValue = string | number | boolean | null | undefined;

function readValueFromStorage<
	Value extends
		| Record<string, BaseValue[] | BaseValue>
		| BaseValue[]
		| BaseValue
		| null = string,
	DefaultValue = Value
>(
	storage: Storage,
	key: string,
	defaultValue: DefaultValue,
	deserialize: (string: string | null) => Value
) {
	try {
		return deserialize(storage.getItem(key)) ?? defaultValue;
	} catch (e) {
		return defaultValue;
	}
}

export function useStorage<
	Value extends
		| Record<string, BaseValue[] | BaseValue>
		| BaseValue[]
		| BaseValue = string,
	DefaultValue = Value
>(
	key: StorageKeys,
	defaultValue: DefaultValue,
	{
		serialize = JSON.stringify,
		deserialize = (value) => JSON.parse(value as string),
		storage = safeLocalStorage,
	}: {
		serialize?: (value: Value) => string;
		deserialize?: (string: string | null) => Value;
		storage?: Storage;
	} = {}
): [
	Value | DefaultValue | undefined,
	(
		newValue:
			| Value
			| ((currentValue: Value | DefaultValue | undefined) => Value)
	) => void
] {
	const [value, setValueInState] = useState<Value | DefaultValue | undefined>(
		readValueFromStorage(storage, key, defaultValue, deserialize)
	);

	// Pub/Sub for universal updates.
	const onValueUpdate = usePublisher({
		type: PubSubMessage.Storage,
		recipient: typeof window !== "undefined" ? window : null,
	});
	useSubscription(PubSubMessage.Storage, ({ payload }) => {
		if (payload.key !== key) return;
		setValueInState(
			readValueFromStorage(storage, key, defaultValue, deserialize)
		);
	});

	// Update value in storage, in state, and elsewhere.
	const setValue = useCallback(
		(
			newValue:
				| Value
				| ((currentValue: Value | DefaultValue | undefined) => Value)
		) => {
			// Update Storage.
			const serializedNextValue = serialize(
				typeof newValue === "function" ? newValue(value) : newValue
			);
			storage.setItem(key, serializedNextValue);

			// Update state.
			setValueInState(
				readValueFromStorage(storage, key, defaultValue, deserialize)
			);

			// Trigger PubSub.
			onValueUpdate({ key });
		},
		[key, value, defaultValue, serialize, deserialize, onValueUpdate, storage]
	);

	return [value, setValue];
}
