import { useState } from "preact/hooks";

import { Button } from "@shared/components/Button";
import { trpc } from "@shared/utilities/trpc/trpc";

import styles from "./AppointmentScheduling.module.css";

// Is a component for scheduling appointments for a patient using available slots
export function Scheduler({
	serviceIntentId,
	onBooking,
}: {
	serviceIntentId: string;
	onBooking: () => void;
}) {
	const [selectedDateTime, setSelectedDateTime] = useState<string | null>(null);

	const { data, isLoading } = trpc.getAvailabilityForEncounterSchedule.useQuery(
		{ serviceIntentId: serviceIntentId }
	);

	const scheduleEncounterMutation = trpc.scheduleEncounter.useMutation();

	const handleSelection = async () => {
		if (selectedDateTime != null) {
			try {
				await scheduleEncounterMutation.mutateAsync({
					serviceIntentId,
					slotDateTime: selectedDateTime,
				});

				onBooking();
			} catch (error) {
				setSelectedDateTime(null);
			}
		}
	};

	// Converts to an array of objects with date and availability
	const dateMap = new Map<
		string,
		{
			dayOfWeek: string;
			availability: Array<{ time: string; dateString: string }>;
		}
	>();

	data?.availability.forEach((dateString) => {
		const date = new Date(dateString);

		const formattedTime = date.toLocaleTimeString([], {
			hour: "numeric",
			minute: "2-digit",
		});
		const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "long" });
		const formattedDate = date.toLocaleDateString("en-US", {
			month: "long",
			day: "numeric",
		});

		if (dateMap.has(formattedDate)) {
			const availability = dateMap.get(formattedDate)!.availability;
			availability.push({ time: formattedTime, dateString });
		} else {
			dateMap.set(formattedDate, {
				dayOfWeek,
				availability: [{ time: formattedTime, dateString }],
			});
		}
	});

	const transformedData = Array.from(dateMap, ([date, ob]) => ({
		date,
		availability: ob.availability,
		dayOfWeek: ob.dayOfWeek,
	}));
	return (
		<>
			{isLoading ? (
				<>
					<div>Loading Appointments...</div>
					<div className="flex flex-col items-center p-4 grow">
						<div className="flex flex-col space-y-6 grow">
							<div className="text-center">
								<h2 className="text-lg font-semibold">Monday • May 3rd</h2>

								<Button className="mt-2 rounded-full border border-blue-500 px-4 py-2 text-blue-500 bg-white">
									0:00 PM
								</Button>
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					<div className="flex flex-col items-center justify-center p-4 grow">
						<div className="flex flex-col space-y-6 grow">
							<div className="text-center ">
								Time Zone:{" "}
								<strong>
									{" "}
									{Intl.DateTimeFormat().resolvedOptions().timeZone} (
									{getTimezoneShort()})
								</strong>
							</div>
							{transformedData?.map(({ date, availability, dayOfWeek }) => (
								<div className="text-center">
									<h2 className="text-lg font-semibold">
										{dayOfWeek} • {date}
									</h2>
									<div className="flex flex-row flex-wrap gap-5 justify-center">
										{availability.map(({ time, dateString }) => (
											<Button
												className={`mt-2 rounded-full border px-4 py-2  max-w-[138px] ${
													selectedDateTime === dateString
														? "bg-blue-600 text-white"
														: "border-blue-500 text-blue-500 bg-white"
												}`}
												onClick={() => setSelectedDateTime(dateString)}
											>
												{time}
											</Button>
										))}
									</div>
								</div>
							))}
						</div>
					</div>
				</>
			)}
			<div className={styles.continueButtonWrapper}>
				<Button
					variant="primary"
					text="Book this Time"
					className={styles.continueButton}
					disabled={!selectedDateTime}
					onClick={() => handleSelection()}
				/>
			</div>
		</>
	);
}

function getTimezoneShort() {
	return new Intl.DateTimeFormat("en-US", {
		timeZoneName: "short",
	})
		.formatToParts(new Date())
		.find((part) => part.type == "timeZoneName")!.value;
}
