import { FALLBACK_FONTS } from "./consts";
import { ThemeColor } from "./ThemeColor";

interface ThemeConstructorProps {
	primaryFont: string;
	borderRadius: number;
	spacingMultiplier: number;
	[key: string]: string | number | ThemeColor;
}

export const THEME_KEYS = {
	primaryFont: "--primary-font",
	borderRadius: "--border-radius",
	spacingMultiplier: "--spacing-multiplier",
	primaryBackground: "--primary-background",
	primaryForeground: "--primary-foreground",
	primaryShadow: "--primary-shadow",
	primaryPlaceholder: "--primary-placeholder",
	successForeground: "--success-foreground",
	successBackground: "--success-background",
	warningForeground: "--warning-foreground",
	warningBackground: "--warning-background",
	dangerForeground: "--danger-foreground",
	dangerBackground: "--danger-background",
	primaryAccent50: "--primary-accent-50",
	primaryAccent100: "--primary-accent-100",
	primaryAccent200: "--primary-accent-200",
	primaryAccent300: "--primary-accent-300",
	primaryAccent400: "--primary-accent-400",
	primaryAccent500: "--primary-accent-500",
	primaryAccent600: "--primary-accent-600",
	primaryAccent700: "--primary-accent-700",
	primaryAccent800: "--primary-accent-800",
	primaryAccent900: "--primary-accent-900",
	secondaryAccent50: "--secondary-accent-50",
	secondaryAccent100: "--secondary-accent-100",
	secondaryAccent200: "--secondary-accent-200",
	secondaryAccent300: "--secondary-accent-300",
	secondaryAccent400: "--secondary-accent-400",
	secondaryAccent500: "--secondary-accent-500",
	secondaryAccent600: "--secondary-accent-600",
	secondaryAccent700: "--secondary-accent-700",
	secondaryAccent800: "--secondary-accent-800",
	secondaryAccent900: "--secondary-accent-900",
	grayscale50: "--grayscale-50",
	grayscale100: "--grayscale-100",
	grayscale200: "--grayscale-200",
	grayscale300: "--grayscale-300",
	grayscale400: "--grayscale-400",
	grayscale500: "--grayscale-500",
	grayscale600: "--grayscale-600",
	grayscale700: "--grayscale-700",
	grayscale800: "--grayscale-800",
	grayscale900: "--grayscale-900",
} as const;

export class Theme {
	primaryFont!: string;
	borderRadius!: number;
	spacingMultiplier!: number;
	primaryPlaceholder!: ThemeColor;
	primaryBackground!: ThemeColor;
	primaryForeground!: ThemeColor;
	primaryShadow!: ThemeColor;
	successForeground!: ThemeColor;
	successBackground!: ThemeColor;
	warningForeground!: ThemeColor;
	warningBackground!: ThemeColor;
	dangerForeground!: ThemeColor;
	dangerBackground!: ThemeColor;
	primaryAccent50?: ThemeColor;
	primaryAccent100?: ThemeColor;
	primaryAccent200?: ThemeColor;
	primaryAccent300?: ThemeColor;
	primaryAccent400?: ThemeColor;
	primaryAccent500?: ThemeColor;
	primaryAccent600?: ThemeColor;
	primaryAccent700?: ThemeColor;
	primaryAccent800?: ThemeColor;
	primaryAccent900?: ThemeColor;
	secondaryAccent50?: ThemeColor;
	secondaryAccent100?: ThemeColor;
	secondaryAccent200?: ThemeColor;
	secondaryAccent300?: ThemeColor;
	secondaryAccent400?: ThemeColor;
	secondaryAccent500?: ThemeColor;
	secondaryAccent600?: ThemeColor;
	secondaryAccent700?: ThemeColor;
	secondaryAccent800?: ThemeColor;
	secondaryAccent900?: ThemeColor;
	grayscale50?: ThemeColor;
	grayscale100?: ThemeColor;
	grayscale200?: ThemeColor;
	grayscale300?: ThemeColor;
	grayscale400?: ThemeColor;
	grayscale500?: ThemeColor;
	grayscale600?: ThemeColor;
	grayscale700?: ThemeColor;
	grayscale800?: ThemeColor;
	grayscale900?: ThemeColor;

	constructor(values: ThemeConstructorProps) {
		Object.assign(this, values);
	}

	static isThemeColorKey(key: string): key is keyof typeof THEME_KEYS {
		return key in THEME_KEYS;
	}

	static fromString(jsonString: string): Theme {
		const json = JSON.parse(jsonString);
		const themeProps: { [key: string]: string | number | ThemeColor } = {};

		for (const key of Object.keys(THEME_KEYS)) {
			if (json[key]) {
				if (key === "borderRadius" || key === "spacingMultiplier") {
					themeProps[key] = json[key];
				} else if (key === "primaryFont") {
					themeProps[key] = json[key];
				} else if (Theme.isThemeColorKey(key)) {
					themeProps[key] = new ThemeColor(json[key]);
				}
			}
		}

		themeProps.primaryFont = themeProps.primaryFont || FALLBACK_FONTS;
		themeProps.borderRadius = themeProps.borderRadius || 0;
		themeProps.spacingMultiplier = themeProps.spacingMultiplier || 1;

		return new Theme(themeProps as ThemeConstructorProps);
	}
}
