import type { ComponentType, ComponentChildren } from "preact";

import { useEffect, useState, useMemo } from "preact/hooks";
import { useMutation, useQuery } from "urql";

import { CONTRACT_AGREEMENTS } from "@app/components/Auth/TermsOfService/constants";
import { useOrganizationContext } from "@app/components/Contexts";
import type { Props as ButtonProps } from "@shared/components/Button";
import { Form } from "@shared/components/Form";
import { Input } from "@shared/components/Input";
import { InputGroup } from "@shared/components/InputGroup";
import { InputPhoneNumber } from "@shared/components/InputPhoneNumber";
import { Stack } from "@shared/components/Stack";
import { useFirst } from "@shared/hooks/useFirst";
import { useTheme } from "@shared/hooks/useTheme";
import {
	WidgetGetPatientInfoDocument,
	WidgetUpdatePatientAgreementsDocument,
	WidgetUpdatePatientContactInfoDocument,
	WidgetUpdatePatientInfoDocument,
} from "@shared/types/graphql";
import { isValidEmailAddress } from "@shared/utilities/isValidEmailAddress";

import styles from "./EditInfo.module.css";
import TermsButton from "./TermsButton";

const validateEmailAddress = (input: HTMLInputElement) => {
	const emailAddress = input.value;
	if (!isValidEmailAddress(emailAddress)) {
		input.setCustomValidity("Email address is invalid.");
		return;
	}

	// Everything is fine.
	input.setCustomValidity("");
	input.reportValidity();
};

interface Props {
	title?: string;
	buttonText?: string;
	buttonType?: "regular" | "rounded";
	onSuccess?: () => void;
	showContent?: (content: { title: string; text: string }) => void;
	hideSubmitButton?: boolean;
	showPersonalInfo?: boolean;
	showPhoneNumber?: boolean;
	showTermsOfUse?: boolean;
	showSuccessMessage?: boolean;
	shouldDisableIfUnchanged?: boolean;
	disabled?: boolean;
	wrapperComponent?: ComponentType<{ buttonProps?: ButtonProps }>;
}

export function EditInfo({
	title,
	disabled,
	wrapperComponent,
	showPersonalInfo,
	showPhoneNumber,
	showTermsOfUse,
	showSuccessMessage,
	shouldDisableIfUnchanged,
	onSuccess,
	showContent,
	buttonText = "Save",
	buttonType = "regular",
	hideSubmitButton = false,
}: Props) {
	const {
		termsOfUse,
		privacyPolicy,
		legalForm,
		organizationName,
		organizationId,
	} = useOrganizationContext();
	const { spacing } = useTheme();
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [emailAddress, setEmailAddress] = useState("");
	const [acceptedTerms, setAcceptedTerms] = useState(false);
	const isMissingRequiredInfo =
		!firstName ||
		!lastName ||
		(showPhoneNumber && !phoneNumber) ||
		!emailAddress ||
		(showTermsOfUse && !acceptedTerms);

	const [hasModifiedInfo, setHasModifiedInfo] = useState(false);
	const [hasModifiedContactInfo, setHasModifiedContactInfo] = useState(false);
	const [hasModifiedAgreements, setHasModifiedAgreements] = useState(false);
	const [successMessage, setSuccessMessage] = useState<string | null>(null);

	const [{ data, fetching, error }] = useQuery({
		query: WidgetGetPatientInfoDocument,
		requestPolicy: "network-only",
	});
	const patient = useFirst(data?.patient);
	const patientContact = useFirst(data?.patient_contact);
	const isEsseOrg = organizationId === "c2a81423-3646-432d-b720-14c3a0990e1a";
	useEffect(() => {
		if (!patient || !patientContact) return;

		setFirstName((value) => patient.first_name?.trim() || value);
		setLastName((value) => patient.last_name?.trim() || value);
		setPhoneNumber((value) => patientContact.phone_number?.trim() || value);
		setEmailAddress((value) => patientContact.email?.trim() || value);
		setHasModifiedInfo(false);
	}, [patient, patientContact]);
	useEffect(() => {
		setHasModifiedInfo(
			firstName !== patient?.first_name || lastName !== patient?.last_name
		);

		setHasModifiedContactInfo(
			phoneNumber !== patientContact?.phone_number ||
				emailAddress !== patientContact?.email
		);

		setHasModifiedAgreements(acceptedTerms !== false);
	}, [
		firstName,
		lastName,
		phoneNumber,
		emailAddress,
		patient,
		patientContact,
		acceptedTerms,
	]);
	const hasModifications =
		hasModifiedInfo || hasModifiedContactInfo || hasModifiedAgreements;

	const [, editPatient] = useMutation(WidgetUpdatePatientInfoDocument);
	const [, editPatientContact] = useMutation(
		WidgetUpdatePatientContactInfoDocument
	);
	const [, consentToAgreement] = useMutation(
		WidgetUpdatePatientAgreementsDocument
	);
	const onSubmitInfo = async () => {
		setSuccessMessage(null);

		// This shouldn't be possible.
		if (!patient || !patientContact) {
			throw new Error("An unknown error occurred.");
		}

		// Missing fields.
		if (isMissingRequiredInfo) {
			throw new Error(
				"We're missing some important information. Please fill out all fields and try again."
			);
		}

		if (hasModifiedInfo) {
			const { data, error } = await editPatient({
				patientId: patient.id,
				firstName,
				lastName,
			});
			const newPatient = data?.update_patient?.returning[0];

			if (error || !newPatient) {
				throw new Error(
					error?.message ??
						"An unknown error occurred while updating your info."
				);
			}
		}

		if (hasModifiedContactInfo) {
			const { data, error } = await editPatientContact({
				patientContactId: patientContact.id,
				phoneNumber,
				emailAddress,
			});
			const newPatientContact = data?.update_patient_contact?.returning[0];
			if (error || !newPatientContact) {
				throw new Error(
					error?.message ??
						"An unknown error occurred while updating your info."
				);
			}
		}

		if (hasModifiedAgreements) {
			for (const agreementName of [
				CONTRACT_AGREEMENTS.TermsOfService,
				// For now, we don't ask for consent to the privacy policy
				// CONTRACT_AGREEMENTS.PrivacyPolicy,
			]) {
				const { data, error } = await consentToAgreement({
					agreementName,
				});

				if (error || !data?.consentToAgreement?.id) {
					throw new Error(
						error?.message ??
							"An unknown error occurred while updating your agreement info."
					);
				}
			}
		}

		onSuccess?.();
		if (showSuccessMessage) {
			setSuccessMessage("We've successfully updated your info.");
		}
	};

	const termsLink = useMemo(() => {
		return (
			<TermsButton
				label="Terms of Use"
				title={`${organizationName} Terms of Use`}
				text={legalForm.termsOfUse ?? ""}
				link={termsOfUse}
				onClick={showContent}
			/>
		);
	}, [legalForm.termsOfUse, organizationName, showContent, termsOfUse]);
	const privacyLink = useMemo(() => {
		return (
			<TermsButton
				label="Privacy Policy"
				title={`${organizationName} Privacy Policy`}
				text={legalForm.privacyPolicy ?? ""}
				link={privacyPolicy}
				onClick={showContent}
			/>
		);
	}, [legalForm.privacyPolicy, privacyPolicy, organizationName, showContent]);

	return (
		<Form
			title={title}
			wrapperComponent={
				wrapperComponent as ComponentType<{
					buttonProps?: ButtonProps | undefined;
					additionalButtonProps?: ButtonProps | undefined;
					children: ComponentChildren;
				}>
			}
			errorMessage={error?.message}
			successMessage={successMessage}
			justifyButtons={buttonType === "rounded" ? "center" : undefined}
			submitButtonProps={
				hideSubmitButton
					? undefined
					: {
							text: buttonText,
							disabled:
								disabled ||
								fetching ||
								isMissingRequiredInfo ||
								(shouldDisableIfUnchanged && !hasModifications),
					  }
			}
			onSubmit={onSubmitInfo}
		>
			{({ isSubmitting }) => (
				<>
					{showPersonalInfo && (
						<>
							<Stack
								direction="horizontal"
								align="center"
								justify="stretch"
								gap={spacing(2)}
							>
								<InputGroup
									type="text"
									placeholder="John"
									label="First name"
									value={firstName}
									onInput={setFirstName}
									autocomplete="given-name"
									disabled={
										(firstName &&
											patient?.integration_status &&
											["new", "established"].includes(
												patient?.integration_status
											)) ||
										disabled ||
										fetching ||
										isSubmitting
									}
									required
								/>
								<InputGroup
									type="text"
									placeholder="Smith"
									label="Last name"
									value={lastName}
									onInput={setLastName}
									autocomplete="family-name"
									disabled={
										(lastName &&
											patient?.integration_status &&
											["new", "established"].includes(
												patient?.integration_status
											)) ||
										disabled ||
										fetching ||
										isSubmitting
									}
									required
								/>
							</Stack>
							<InputGroup
								type="email"
								label="Email address"
								placeholder="you@email.net"
								value={emailAddress}
								onInput={setEmailAddress}
								disabled={
									(emailAddress &&
										patient?.integration_status &&
										["new", "established"].includes(
											patient?.integration_status
										)) ||
									disabled ||
									fetching ||
									isSubmitting
								}
								validate={validateEmailAddress}
								autocomplete="email"
								required
							/>
						</>
					)}
					{showPhoneNumber && (
						<InputPhoneNumber
							label="Phone number"
							placeholder="(xxx) xxx - xxxx"
							value={phoneNumber}
							onInput={setPhoneNumber}
							disabled={disabled || fetching || isSubmitting}
							required
						/>
					)}
					{showTermsOfUse && (
						<Input
							type="checkbox"
							className={styles.termsCheckbox}
							value={`${acceptedTerms}`}
							onChange={(e: Event) =>
								setAcceptedTerms((e.target as HTMLInputElement)?.checked)
							}
							disabled={disabled || fetching || isSubmitting}
							label={
								<>
									I agree to the {termsLink}{" "}
									{!isEsseOrg ? <> and {privacyLink}</> : null}.
								</>
							}
							required
						/>
					)}
				</>
			)}
		</Form>
	);
}
