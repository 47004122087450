import { useEffect, useMemo, useRef, useState } from "preact/hooks";
import { useQuery } from "urql";

import type { WidgetGetServiceIntentsSubscription } from "@shared/types/graphql";
import { WidgetGetChannelUnreadCountsDocument } from "@shared/types/graphql";

import type { Channel } from "./types";

function getServiceIntentChannels(
	serviceIntents: WidgetGetServiceIntentsSubscription["service_intent"]
) {
	return serviceIntents.reduce<Channel[]>((acc, curr) => {
		const channel = curr.encounter?.portal_message_channel;
		if (!channel) {
			return acc;
		}

		return [...acc, channel];
	}, []);
}

export function useVisitUnreadCounts(
	serviceIntents: WidgetGetServiceIntentsSubscription["service_intent"]
) {
	// Get the unread counts for all channels.
	const channelIds = useMemo(
		() => getServiceIntentChannels(serviceIntents).map((channel) => channel.id),
		[serviceIntents]
	);
	const [channelUnreads, setChannelUnreads] = useState<Record<string, number>>(
		{}
	);
	const [{ data }] = useQuery({
		query: WidgetGetChannelUnreadCountsDocument,
		variables: {
			channelIds,
		},
		pause: channelIds.length === 0,
		requestPolicy: "cache-and-network",
	});
	useEffect(() => {
		if (!data) return;

		setChannelUnreads(
			data.portal_message_channel.reduce((acc, curr) => {
				return {
					...acc,
					[curr.id]: curr.unread_count || 0,
				};
			}, {})
		);
	}, [data]);

	// If a new message comes in, increment the unread count for that channel.
	const prevServiceIntents = useRef(serviceIntents);
	useEffect(() => {
		const prevChannels = getServiceIntentChannels(prevServiceIntents.current);
		const channels = getServiceIntentChannels(serviceIntents);
		channels.forEach((channel) => {
			const prevChannel = prevChannels.find(({ id }) => id === channel.id);
			const hasNewMessage =
				prevChannel?.last_message_time !== channel.last_message_time;
			if (hasNewMessage) {
				setChannelUnreads((prev) => ({
					...prev,
					[channel.id]: (prev[channel.id] || 0) + 1,
				}));
			}
		});

		prevServiceIntents.current = serviceIntents;
	}, [serviceIntents]);

	return channelUnreads;
}
