import type {
	ReactNode,
	ReactElement,
	InputHTMLAttributes,
	ChangeEvent,
	ChangeEventHandler,
	FC,
	FocusEvent,
} from "react";
import { forwardRef, useState } from "react";

import classNames from "classnames";
import ReactGoogleAutocomplete from "react-google-autocomplete";

import { GOOGLE_MAPS_API_KEY } from "@shared/constants/environments";
import { useTheme } from "@shared/hooks/useTheme";

import type { Props as IconProps } from "../Icon";
import { Icon } from "../Icon";
import { Stack } from "../Stack";
import { Text } from "../Text";
import styles from "./Input.module.css";
import { MaskedInput } from "./MaskedInput";

type PlaceResult = {
	address_components: {
		long_name: string;
		short_name: string;
		types: string[];
	}[];
};

export type Address = {
	line1: string;
	line2?: string | null;
	city: string;
	state: string;
	zip: string;
};

interface AddressEventTarget extends EventTarget {
	value: Address;
}
interface AddressEvent extends ChangeEvent<AddressEventTarget> {
	target: AddressEventTarget;
}

function googleLocationToAddress(location: PlaceResult) {
	const line1 = location.address_components.reduce((address, component) => {
		if (component.types.includes("street_number")) {
			return `${address} ${component.long_name}`;
		}
		if (component.types.includes("route")) {
			return `${address} ${component.long_name}`;
		}
		return address;
	}, "");
	const line2 =
		location.address_components.find((component) =>
			component.types.includes("subpremise")
		)?.long_name || "";
	const city =
		location.address_components.find((component) =>
			component.types.includes("locality")
		)?.long_name || "";
	const state =
		location.address_components.find((component) =>
			component.types.includes("administrative_area_level_1")
		)?.short_name || "";
	const zip =
		location.address_components.find((component) =>
			component.types.includes("postal_code")
		)?.long_name || "";

	return { line1, line2, city, state, zip };
}

const InputDefault = ({
	children,
	isInlineInput,
}: {
	children: ReactNode[];
	isInlineInput: boolean;
}) => {
	const { spacing } = useTheme();
	return (
		<Stack
			element="label"
			direction={isInlineInput ? "horizontal" : "vertical"}
			align={isInlineInput ? "center" : "stretch"}
			justify={isInlineInput ? "start" : "stretch"}
			gap={spacing(isInlineInput ? 3 : 0)}
		>
			{children}
		</Stack>
	);
};

type InputType =
	| "text"
	| "number"
	| "tel"
	| "password"
	| "checkbox"
	| "radio"
	| "range"
	| "date"
	| "time"
	| "datetime"
	| "email"
	| "address";

export interface InputWrapperProps {
	type: InputType;
	children: ReactNode[];
	isInlineInput: boolean;
	checked?: boolean;
}

export interface Props
	extends Omit<InputHTMLAttributes<HTMLInputElement>, "label" | "default"> {
	type: InputType;
	iconProps?: IconProps;
	buttonIconProps?: IconProps & { onClick: () => void };
	label?: ReactElement | string;
	renderInputWrapper?: FC<InputWrapperProps>;
	mask?: string;
}

export const Input = forwardRef<HTMLInputElement, Props>(
	(
		{
			renderInputWrapper = InputDefault,
			label,
			iconProps,
			buttonIconProps,
			className,
			type,
			...props
		},
		ref
	) => {
		const inputClassName = classNames(
			styles.input,
			{
				[styles.withIcon]: !!iconProps,
			},
			className
		);
		const [isFocused, setIsFocused] = useState(false);
		const hasValueWithMask = !!props?.value && !!props.mask;
		// note: undefined value sets the default placeholder generated by the library
		const placeholder =
			!isFocused && !hasValueWithMask && label ? "" : props.placeholder;
		let input: ReactNode = (
			<MaskedInput
				ref={ref}
				type={type}
				className={inputClassName}
				{...props}
				placeholder={placeholder}
				onFocus={(event: FocusEvent<HTMLInputElement>) => {
					setIsFocused(true);
					props.onFocus?.(event);
				}}
				onBlur={(event: FocusEvent<HTMLInputElement>) => {
					setIsFocused(false);
					props.onBlur?.(event);
				}}
			/>
		);

		if (type === "address") {
			const { onInput, ...rest } = props;

			input = (
				<ReactGoogleAutocomplete
					data-testid="google-autocomplete"
					apiKey={GOOGLE_MAPS_API_KEY}
					ref={ref}
					// @ts-ignore ReactGoogleAutocomplete and react-preact types mismatch
					type="text"
					options={{
						types: ["address"],
						componentRestrictions: { country: "us" },
					}}
					onPlaceSelected={(place: PlaceResult) => {
						const address = googleLocationToAddress(place);

						(
							onInput as ChangeEventHandler<AddressEventTarget> | undefined
						)?.call(
							this as never,
							{
								target: {
									value: address,
								},
							} as AddressEvent
						);
					}}
					className={inputClassName}
					{...rest}
					placeholder={placeholder as string}
					onFocus={(event: FocusEvent<HTMLInputElement>) => {
						setIsFocused(true);
						props.onFocus?.(event);
					}}
					onBlur={(event: FocusEvent<HTMLInputElement>) => {
						setIsFocused(false);
						props.onBlur?.(event);
					}}
				/>
			);
		}

		const isInlineInput = type === "checkbox" || type === "radio";
		if (!label) return input;

		const isPlaceholder = !isInlineInput && !props?.value && !isFocused;
		const isHeader = !isInlineInput && (props?.value || isFocused);
		const children = [
			<Text
				key="label"
				className={classNames({
					[styles.label]: !isInlineInput,
					[styles.labelWithIcon]: !!iconProps,
					[styles.placeholder]: isPlaceholder,
					[styles.header]: isHeader,
				})}
				variant={isInlineInput || isPlaceholder ? "body" : "xsmall"}
				bold={!!props.checked}
				selectable={isInlineInput}
			>
				{label}
			</Text>,
			<span
				key="input"
				className={isInlineInput ? undefined : styles.inputWrapper}
			>
				{!!iconProps && (
					<div className={styles.icon}>
						<Icon {...iconProps} />
					</div>
				)}
				{input}
				{!!buttonIconProps && (
					<button
						type="button"
						className={classNames(styles.icon, styles.endIcon)}
						onClick={buttonIconProps.onClick}
					>
						<Icon {...buttonIconProps} />
					</button>
				)}
			</span>,
		];

		return renderInputWrapper({
			type,
			children: isInlineInput ? children.reverse() : children,
			checked: !!props.checked,
			isInlineInput,
		});
	}
);
