type Payload = {
	exp: number;
	iat: number;
	"https://hasura.io/jwt/claims": {
		"X-User-Fingerprint": string;
		"x-hasura-allowed-roles": string[];
		"x-hasura-default-role": string;
		"x-hasura-org-id": string;
		"x-hasura-user-id": string;
		"x-hasura-role": string;
	};
};

export function parseJWT(token: string | undefined = ""): Payload | undefined {
	try {
		const [, encodedPayload] = token.split(".");
		return JSON.parse(atob(encodedPayload));
	} catch {
		return undefined;
	}
}
