import type { Address } from "@shared/components/Input/Input";
import type { AddressInput, Address_Types } from "@shared/types/graphql";

import { USStateMap } from "./AddressPicker.consts";

export const isValidUSState = (state: string | undefined): boolean => {
	if (!state) return false;

	return Boolean(USStateMap[state.toUpperCase()]);
};

export const validateAddress = (address: Address) => {
	if (!address.line1) {
		throw new Error("Address line 1 is required");
	}
	if (!address.city) {
		throw new Error("City is required");
	}
	if (!address.state) {
		throw new Error("State is required");
	}
	if (!address.zip) {
		throw new Error("Zip code is required");
	}
};

export const addressToAddressInput = (
	address: Address,
	type: Address_Types
): AddressInput => {
	return {
		line_1: address.line1,
		line_2: address.line2 || undefined,
		city: address.city,
		state: address.state,
		zip: address.zip,
		country: undefined,
		type,
	};
};
