import { useEffect, useRef } from "react";

export function useResizable(resizeable: boolean, value: string | null) {
	const textareaRef = useRef<HTMLTextAreaElement>(null);

	useEffect(() => {
		if (!resizeable || !textareaRef.current) return;

		const minHeight = window
			.getComputedStyle(textareaRef.current)
			.getPropertyValue("min-height");

		textareaRef.current.style.height = minHeight;
		textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
	}, [value, resizeable]);

	return { textareaRef };
}
