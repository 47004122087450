import { useEffect } from "react";

export function useFont(
	family: string | undefined,
	weights: number[] = [300, 400, 500, 600, 700, 800, 900]
) {
	useEffect(() => {
		if (!family) return;
		const link = document.createElement("link");
		link.href = `https://fonts.googleapis.com/css2?family=${family}:wght@${weights.join(
			";"
		)}&amp;display=swap`;
		link.rel = "stylesheet";
		document.head.appendChild(link);
	}, [family, weights]);
}
