import { closeOutline, documentTextOutline } from "ionicons/icons";

import { Button } from "@shared/components/Button";
import { Hero } from "@shared/components/Hero";
import { Icon } from "@shared/components/Icon";
import { ModalBackdrop } from "@shared/components/ModalBackdrop";
import { Stack } from "@shared/components/Stack";
import { Text } from "@shared/components/Text";
import { useIconStyles } from "@shared/hooks/useIconStyles";
import { useTheme } from "@shared/hooks/useTheme";

import styles from "./TermsModal.module.css";

interface Props {
	title: string;
	text: string;
	close(): void;
}

export function TermsModal({ title, text, close }: Props) {
	const { primaryForeground } = useTheme();
	const iconProps = useIconStyles({
		icon: documentTextOutline,
		size: 36,
		strokeWidth: 20,
	});

	return (
		<ModalBackdrop close={close}>
			<div className={styles.modalContent}>
				<Stack direction="vertical" gap={0}>
					<Stack direction="horizontal" justify="end" gap={4}>
						<Button variant="plain" onClick={close}>
							<Icon
								icon={closeOutline}
								size={24}
								stroke={primaryForeground}
								strokeWidth={60}
							/>
						</Button>
					</Stack>
					<Hero title={title} iconProps={iconProps} />
					<Text variant="body" className="whitespace-pre-wrap leading-5">
						{text}
					</Text>
				</Stack>
			</div>
		</ModalBackdrop>
	);
}
