export const getQueryString = (url: string) => url.split("?")[1] ?? "";

export const parse = (
	url: string
): Record<string, string | number | boolean | null | undefined> => {
	const queryString = getQueryString(url);
	if (!queryString) {
		return {};
	}

	const searchParams = Object.fromEntries(new URLSearchParams(queryString));
	return Object.entries(searchParams).reduce((acc, [key, value]) => {
		if (!Number.isNaN(Number(value))) {
			return { ...acc, [key]: parseInt(value, 10) };
		} else if (value === "NaN") {
			return { ...acc, [key]: NaN };
		} else if (value === "Infinity") {
			return { ...acc, [key]: Infinity };
		} else if (value === "-Infinity") {
			return { ...acc, [key]: -Infinity };
		} else if (value === "true") {
			return { ...acc, [key]: true };
		} else if (value === "false") {
			return { ...acc, [key]: false };
		} else if (value === "null") {
			return { ...acc, [key]: null };
		} else if (value === "undefined") {
			return { ...acc, [key]: undefined };
		}

		return { ...acc, [key]: value };
	}, {});
};

export const stringify = (
	params: Record<string, string | number | boolean | null | undefined>
) => {
	const searchParams = new URLSearchParams(params as $FixMe);
	return searchParams.toString();
};
