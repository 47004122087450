import { formatName } from "./formatName";

export function formatProviderName(
	firstName: string | null | undefined,
	lastName: string | null | undefined,
	suffixes: string | null | undefined
) {
	const name = formatName(firstName, lastName);
	return `Dr. ${name}${suffixes ? `, ${suffixes}` : ""}`;
}
