import { Form, FormContent } from "@shared/components/Form";
import { Stack } from "@shared/components/Stack";
import { useTheme } from "@shared/hooks/useTheme";
import type { AddressInput } from "@shared/types/graphql";

import { useAddressPicker } from "./AddressPicker.hooks";
import { Picker } from "./Picker";

interface Props {
	title?: string;
	buttonText?: string;
	prevButtonText?: string;
	buttonDisabled?: boolean;
	showAsBilling?: boolean;
	showShippingAddress?: boolean;
	showSuccessMessage?: boolean;
	onSuccess?: () => void;
	onBack?(): void;
	onChange?(address: AddressInput | null): void;
}

export function AddressPicker({
	title,
	buttonText = "Save",
	prevButtonText = "Go back",
	showAsBilling = false,
	buttonDisabled = false,
	showShippingAddress = false,
	onChange,
	onBack,
	onSuccess,
}: Props) {
	const { spacing } = useTheme();
	const {
		error,
		homeAddress,
		billingAddress,
		shippingAddress,
		shouldUseSameAddress,
		isMissingRequiredInfo,
		setHomeAddress,
		setBillingAddress,
		setShippingAddress,
		setShouldUseSameAddress,
		onSubmitInfo,
	} = useAddressPicker({
		showAsBilling,
		showShippingAddress,
		onChange,
		onSuccess,
	});

	return (
		<Form
			title={title}
			submitButtonProps={{
				text: buttonText,
				disabled: isMissingRequiredInfo || buttonDisabled,
				hidden: showAsBilling,
			}}
			additionalButtonProps={{
				text: prevButtonText,
				variant: "secondary",
				onClick: onBack,
				hidden: showAsBilling,
			}}
			onSubmit={onSubmitInfo}
		>
			{({ isSubmitting }) => (
				<Stack direction="vertical" gap={spacing(3)}>
					{error && (
						<FormContent errorMessage={error} isSubmitting={isSubmitting}>
							<></>
						</FormContent>
					)}
					{showAsBilling ? (
						<Picker
							title="Billing Address"
							value={billingAddress}
							onChange={setBillingAddress}
							isSubmitting={isSubmitting}
							sameAddressLabel="Use home address?"
							shouldUseSameAddress={shouldUseSameAddress}
							onChangeUseSameAddress={setShouldUseSameAddress}
						/>
					) : (
						<Picker
							title="Home Address"
							subtitle="We need your home address for your account information."
							value={homeAddress}
							onChange={setHomeAddress}
							isSubmitting={isSubmitting}
						/>
					)}
					{!showAsBilling && showShippingAddress && (
						<Picker
							title="Shipping Address"
							subtitle="If prescribed, where do you want your medication to be shipped?"
							value={shippingAddress}
							onChange={setShippingAddress}
							isSubmitting={isSubmitting}
							shouldUseSameAddress={shouldUseSameAddress}
							onChangeUseSameAddress={setShouldUseSameAddress}
						/>
					)}
				</Stack>
			)}
		</Form>
	);
}
