import * as Sentry from "@sentry/browser";
import { isDev } from "@shared/constants/environments";

import { debug } from "../../../shared/utilities/debug";

// Environment based on domain
const domainPerEnvironment: Record<string, string> = {
	"app.catchhealth.com": "production",
	"app-dev.catchhealth.com": "development",
	"app-demo.catchhealth.com": "staging",
};

const EVENT_URL = "/api/event_forwarder";
const EVENT_AUTH =
	"ubytaswrve4ub7qsw36t4iq237byt4diyq3tytv4binukqsnti4uk7quydnygszdhjfngkuseyebuk56w3ib76465idtqw376d45tw7346rt58ui7ef6u4trfib76wurz";
let appData: { environment: string; release: string };

export function captureEvent(eventName: string, data: Record<string, unknown>) {
	// Returns if initEventTracker() was not called
	if (appData == null) {
		return;
	}

	Sentry.addBreadcrumb({
		category: eventName,
		data,
	});
	const currentSentryHub = Sentry.getCurrentHub();
	const user = currentSentryHub.getScope().getUser();
	const replay = currentSentryHub.getIntegration(Sentry.Replay);
	const replayId = replay?.getReplayId();
	const browserData = {}; //To be implemented

	sendEvent(eventName, {
		eventData: data,
		appData,
		userData: user,
		sentryData: { replayId },
		browserData,
	}).catch((error) => {
		Sentry.captureException(error);
	});
}

export function initEventTracker() {
	if (!EVENT_URL) {
		return;
	}
	const isProd =
		!isDev && domainPerEnvironment[window.location.hostname] === "production";
	appData = {
		environment: isProd
			? "production"
			: domainPerEnvironment[window.location.hostname],
		release: __APP_VERSION__,
	};
}

async function sendEvent(eventName: string, data: Record<string, unknown>) {
	debug("info", {
		context: "EVENT_TRACKER",
		message: `Sending Event to ${EVENT_URL}`,
		info: { eventName, data },
	});
	let response;
	try {
		response = await fetch(EVENT_URL, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${EVENT_AUTH}`,
			},
			body: JSON.stringify({
				eventName,
				data,
			}),
		});
	} catch (error) {
		debug("error", {
			context: "EVENT_TRACKER",
			message: `Error sending event to ${EVENT_URL}`,
			info: error,
		});
	}

	if (response != null && !response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}
}
