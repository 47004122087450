import { chevronForward } from "ionicons/icons";
import { useCallback, useMemo, useState } from "preact/hooks";
import { useQuery } from "urql";

import { usePatientContext, useWidgetContext } from "@app/components/Contexts";
import { Header } from "@app/components/Header";
import { Main } from "@app/components/Main";
import { RouteMap } from "@app/constants/RouteMap";
import { useActiveServiceIntents } from "@app/hooks/serviceIntent/useActiveServiceIntents";
import { Alert } from "@shared/components/Alert";
import { Badge } from "@shared/components/Badge";
import { List } from "@shared/components/ButtonList";
import { Hero } from "@shared/components/Hero";
import { Icon } from "@shared/components/Icon";
import { OverflowContainer } from "@shared/components/OverflowContainer";
import { Stack } from "@shared/components/Stack";
import { useFirst } from "@shared/hooks/useFirst";
import { useNavigation } from "@shared/hooks/useNavigation";
import { useTheme } from "@shared/hooks/useTheme";
import { useWindowSize } from "@shared/hooks/useWindowSize";
import { AuthStage } from "@shared/types/AuthStages";
import type { IntakeStage, IntakeStageProps } from "@shared/types/IntakeStages";
import type { ServiceIntentStatus } from "@shared/types/ServiceIntentStatus";
import { SERVICE_INTENT_EDITABLE_STATUSES } from "@shared/types/ServiceIntentStatus";
import { WidgetLoadServicesDocument } from "@shared/types/graphql";
import { debug } from "@shared/utilities/debug";

import { captureEvent } from "../../../utilities/eventTracker";
import { ClickableBanner } from "../ClickableBanner/ClickableBanner";
import { ConfirmStartOver } from "./ConfirmStartOver";
import styles from "./Services.module.css";

export function Services({
	onPreviousStage,
}: IntakeStageProps<IntakeStage.Services>) {
	const { spacing, primaryForeground } = useTheme();
	const { width } = useWindowSize();
	const { widgetId } = useWidgetContext();
	const { patientId } = usePatientContext();
	const { push } = useNavigation();
	const [showConfirmStartOver, setShowConfirmStartOver] = useState(false);
	const [selectedServiceId, setSelectedServiceId] = useState<string | null>(
		null
	);
	const [{ data, fetching, error }] = useQuery({
		query: WidgetLoadServicesDocument,
		variables: {
			// FIXME: We shouldn't need to pass this
			widgetId,
		},
		requestPolicy: "cache-and-network",
	});
	const {
		navigateToService,
		serviceIntentsByServiceId,
		fetching: fetchingServiceIntents,
		error: serviceIntentsError,
	} = useActiveServiceIntents();

	const organization = useFirst(data?.organization);
	const handleConfirmation = useCallback(
		(startOver: boolean) => {
			setShowConfirmStartOver(false);

			if (!selectedServiceId) return;

			navigateToService(selectedServiceId, startOver);
		},
		[selectedServiceId, navigateToService]
	);

	const handleClickLogin = () => {
		push(RouteMap.AuthStages, {
			stageId: AuthStage.Start,
			widgetId,
		});
	};

	const items = useMemo(() => {
		const serviceCategories = organization?.service_categories ?? [];

		return serviceCategories.map(({ id, name, services }) => ({
			key: id,
			caption: (
				<div className={styles.badgeWrapper}>
					<Badge shimmer variant="accent" textVariant="smallBold">
						{name}
					</Badge>
				</div>
			),
			accessoryView: (
				<List
					items={services.map(({ id, name, description }) => ({
						key: id,
						title: name,
						description,
						onClick: () => {
							setSelectedServiceId(id);
							const serviceIntent = serviceIntentsByServiceId[id];

							debug("info", {
								context: "Services",
								message: "Service selected",
								info: {
									serviceIntent,
									serviceId: id,
									serviceName: name,
									serviceDescription: description,
									serviceCategoryId: selectedServiceId,
									serviceCategoryName: name,
								},
							});

							const isEditable =
								serviceIntent &&
								SERVICE_INTENT_EDITABLE_STATUSES.has(
									serviceIntent.status as ServiceIntentStatus
								);

							// If it's editable, we want to ask the user if they want to start over.
							if (isEditable) {
								setShowConfirmStartOver(true);
							} else {
								navigateToService(id);
							}
							captureEvent("service_selected", {
								widgetId,
								serviceId: id,
								serviceName: name,
								serviceDescription: description,
								serviceCategoryId: selectedServiceId,
								serviceCategoryName: name,
								serviceIntent,
								isEditable,
							});
						},
						endIcon: <Icon icon={chevronForward} stroke={primaryForeground} />,
					}))}
				/>
			),
		}));
	}, [
		organization?.service_categories,
		navigateToService,
		primaryForeground,
		serviceIntentsByServiceId,
		widgetId,
		selectedServiceId,
	]);

	return (
		<>
			<Header onBack={onPreviousStage} />
			<Main
				loading={fetching || fetchingServiceIntents}
				error={(error || serviceIntentsError)?.message}
			>
				<Stack
					direction="vertical"
					align="center"
					justify="space-between"
					className={styles.root}
					style={{
						height: "100%",
						paddingBottom: !patientId ? "80px" : undefined,
					}}
				>
					<OverflowContainer gap={spacing(2)}>
						<Stack direction="vertical" align="center" gap={spacing(3)}>
							<Hero
								title="How can we help?"
								subtitle="Select from one of our expertly designed services below to begin your healthcare journey."
							/>
							{items.length > 0 && (
								<Stack
									direction="vertical"
									padding={{ start: width > 400 ? spacing(3) : spacing(1) }}
								>
									<List items={items} gap={spacing(2)} compact />
								</Stack>
							)}
							{items.length === 0 && (
								<Alert
									variant="warning"
									caption={
										patientId ? "Come here often?" : "Nothing to see here"
									}
									text={
										patientId
											? "It looks like you've already signed up for all the services we offer at the moment. Go back to the home screen to continue your existing treatment plans."
											: "Sorry, there are no available services at the moment. Try again later."
									}
								/>
							)}
						</Stack>
					</OverflowContainer>
					{!patientId && (
						<ClickableBanner
							show={true}
							data-testid="sign-in-button"
							onClick={handleClickLogin}
						/>
					)}
				</Stack>
				<ConfirmStartOver
					show={showConfirmStartOver}
					onClose={() => setShowConfirmStartOver(false)}
					onConfirmation={handleConfirmation}
				/>
			</Main>
		</>
	);
}
