import type { RouteMap } from "@app/constants/RouteMap";

import type { PathVariables } from "./path";

type Path = ValueOf<typeof RouteMap>;

export enum LocationType {
	Path,
	Url,
}

export type PathHistory = {
	type: LocationType.Path;
	route: Path;
	variables: PathVariables<Path>;
	queryParams: Record<string, string | number | boolean | null | undefined>;
	replaceQueryParams: boolean;
};

export type UrlHistory = {
	type: LocationType.Url;
	url: string;
};

export type LocationHistory = PathHistory | UrlHistory;

export class MemoryLocationHistory {
	private locationHistory: LocationHistory[] = [];

	public init(path: PathHistory) {
		// Already initialized
		if (this.locationHistory.length > 0) {
			return;
		}

		this.locationHistory.push(path);
	}

	public push(path: LocationHistory): number {
		return this.locationHistory.push(path);
	}

	public replace(path: LocationHistory): number {
		this.locationHistory.pop();
		return this.locationHistory.push(path);
	}

	public popPrevious(): LocationHistory | undefined {
		// Having one route in the history means we're on the first page
		if (this.locationHistory.length < 2) {
			return undefined;
		}

		// Remove current route
		this.locationHistory.pop();

		// Get previous route
		return this.locationHistory.pop();
	}

	public getCurrent(): LocationHistory | undefined {
		return this.locationHistory[this.locationHistory.length - 1];
	}

	public clear(): void {
		this.locationHistory = [];
	}

	public get length(): number {
		return this.locationHistory.length;
	}
}
