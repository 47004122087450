import { Form, FormContent } from "@shared/components/Form";
import { Loader } from "@shared/components/Loader";
import { Stack } from "@shared/components/Stack";
import { debug } from "@shared/utilities/debug";

import styles from "./PaytientForm.module.css";
import { usePaytientPurchase } from "./usePaytientPurchase";

interface Props {
	serviceId: string;
	buttonText?: string;
	prevButtonText?: string;
	buttonDisabled?: boolean;
	onBack?(): void;
	onSuccess?: () => void;
}

export function PaytientForm({
	serviceId,
	buttonText,
	prevButtonText,
	buttonDisabled,
	onBack,
	onSuccess,
}: Props) {
	const [purchase, { loading, paymentError }] = usePaytientPurchase(
		serviceId,
		(info) => {
			debug("info", {
				info,
				context: "PurchaseForm",
				message: "Payment intent created",
			});
			onSuccess?.();
		}
	);

	return (
		<Form onSubmit={purchase} plain>
			{({ errorMessage, successMessage, isSubmitting }) => (
				<Stack direction="vertical" padding={{ top: 20 }}>
					<FormContent
						errorMessage={paymentError ?? errorMessage}
						successMessage={successMessage}
						submitButtonProps={{
							text: buttonText,
							disabled: loading || buttonDisabled,
						}}
						additionalButtonProps={{
							text: prevButtonText,
							variant: "secondary",
							onClick: onBack,
						}}
						isSubmitting={loading || isSubmitting}
					>
						<>{(loading || isSubmitting) && <Loader />}</>
					</FormContent>
					<p className={styles.paytientDisclaimer}>
						We&apos;ll only create a transaction on your Paytient account after
						our medical team reviews your order.
					</p>
				</Stack>
			)}
		</Form>
	);
}
