import type { ComponentChildren } from "preact";

import { useOrganizationContext } from "@app/components/Contexts/OrganizationContext";
import { Elements } from "@stripe/react-stripe-js";

import { getStripe } from "./constants";
import { useStripeAppearance } from "./useStripeAppearance";

interface Props {
	clientSecret?: string;
	children: ComponentChildren;
}

export function StripeProvider({ children, clientSecret }: Props) {
	const { organizationId } = useOrganizationContext();
	const appearance = useStripeAppearance();
	const stripe = getStripe(organizationId);

	return (
		<Elements stripe={stripe} options={{ appearance, clientSecret }}>
			{children}
		</Elements>
	);
}
