import classNames from "classnames";

import type { Props as TextProps } from "@shared/components/Text";
import { Text } from "@shared/components/Text";
import { useTheme } from "@shared/hooks/useTheme";

import styles from "./Badge.module.css";

interface Props {
	children?: string;
	shimmer?: boolean;
	circle?: boolean;
	rounded?: boolean;
	color?: string;
	backgroundColor?: string;
	noBorder?: boolean;
	textVariant?: TextProps["variant"];
	variant?: "default" | "accent";
}

export function Badge({
	children,
	shimmer,
	circle,
	rounded,
	color,
	backgroundColor,
	noBorder,
	textVariant = "legend",
	variant = "default",
}: Props) {
	const {
		primaryForeground,
		primaryAccent600,
		primaryAccent50,
		primaryAccent900,
	} = useTheme();
	const accentBackgroundA = primaryAccent50?.color ?? primaryAccent600?.color;
	const accentForegroundA = primaryAccent900?.color ?? primaryAccent50?.color;
	const badgeColor = variant === "accent" ? accentForegroundA : color;
	const badgeBackgroundColor =
		variant === "accent" ? accentBackgroundA : backgroundColor;

	return (
		<div
			className={classNames(styles.badgeBackdrop, {
				[styles.circle]: circle,
			})}
			style={
				!noBorder
					? {
							border: `1px solid ${primaryForeground?.withAlpha(0.4).color}`,
					  }
					: undefined
			}
		>
			<div
				className={classNames(styles.badge, {
					[styles.shimmer]: shimmer,
					[styles.backgroundColor]: !!backgroundColor,
					[styles.rounded]: rounded,
				})}
				style={{ color: badgeColor, backgroundColor: badgeBackgroundColor }}
			>
				<Text variant={textVariant}>{children}</Text>
			</div>
		</div>
	);
}
