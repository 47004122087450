import { useState } from "preact/hooks";

import { useContextualRedirect } from "@app/components/Auth/useContextualRedirect";
import { usePatientContext, useWidgetContext } from "@app/components/Contexts";
import { AcceptTerms } from "@app/components/Forms/EditInfo/AcceptTerms";
import { LogoImage } from "@app/components/Header/components/LogoImage";
import { Main } from "@app/components/Main";
import { IconButton } from "@shared/components/IconButton";
import { Overlay } from "@shared/components/Overlay";
import { Stack } from "@shared/components/Stack";
import { Tray } from "@shared/components/Tray";
import { useIconStyles } from "@shared/hooks/useIconStyles";
import { useTheme } from "@shared/hooks/useTheme";
import { cross } from "@shared/icons/cross";

import styles from "./PaytientTerms.module.css";
import { TermsModal } from "./TermsModal";
import type { ContentInfo } from "./types";

export function PaytientTerms() {
	const { patientFirstName } = usePatientContext();
	const { spacing } = useTheme();
	const [ready, setReady] = useState(false);
	const [contentInfo, showContent] = useState<ContentInfo>();
	const iconProps = useIconStyles({
		icon: cross,
	});
	const { closeWidget } = useWidgetContext();
	const { fetching, error } = useContextualRedirect(ready);

	return (
		<Overlay>
			<Tray>
				<IconButton
					iconProps={{ ...iconProps, size: 20 }}
					buttonProps={{
						onClick: () => closeWidget(),
						variant: "plain",
						className: styles.exitButton,
					}}
				/>
				<Main loading={fetching} error={error?.message}>
					<Stack
						element="form"
						direction="vertical"
						align="center"
						gap={spacing(3)}
						style={{ height: "100%" }}
					>
						<LogoImage asText={false} />
						<span className="font-semibold text-[17px] leading-6">
							Welcome, {patientFirstName}.
						</span>
						<p className="text-[15px] font-normal leading-[22px] text-center">
							Paytient Care is a service made available by Paytient through a
							platform powered by Catch Health. Before you can use this service,
							please read and accept the additional terms below that apply to
							Paytient Care.
						</p>
						<AcceptTerms
							showContent={showContent}
							onSuccess={() => setReady(true)}
						/>
					</Stack>
				</Main>
			</Tray>
			{contentInfo && (
				<TermsModal {...contentInfo} close={() => showContent(undefined)} />
			)}
		</Overlay>
	);
}
