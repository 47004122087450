import { useEffect, useState } from "react";

function getSize() {
	const { width, height } = window.screen;
	return { width, height };
}

export function useWindowSize() {
	const [windowSize, setWindowSize] = useState(getSize);
	useEffect(() => {
		function handleResize() {
			setWindowSize(getSize());
		}

		// Check if resize observer is available
		if (typeof window.ResizeObserver !== "undefined") {
			const resizeObserver = new ResizeObserver(handleResize);
			resizeObserver.observe(window.document.body);
			return;
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return windowSize;
}
