import type { FunctionalComponent } from "preact";

import { useCallback } from "preact/hooks";

import { AuthConfirm } from "@app/components/Auth/AuthConfirm";
import { AuthQuick } from "@app/components/Auth/AuthQuick";
import { AuthStart } from "@app/components/Auth/AuthStart";
import { Consent } from "@app/components/Auth/Consent";
import { Registration } from "@app/components/Auth/Registration";
import { useWidgetContext } from "@app/components/Contexts";
import { RouteMap } from "@app/constants/RouteMap";
import {
	createAnimationTransition,
	useTransitionState,
} from "@shared/components/AnimationTransition";
import { useLocation } from "@shared/hooks/useLocation";
import { useNavigation } from "@shared/hooks/useNavigation";
import type {
	AuthStageProps,
	AuthStageTransitionProps,
} from "@shared/types/AuthStages";
import { AuthStage } from "@shared/types/AuthStages";
import { parse } from "@shared/utilities/queryString";

import { AuthStageContext } from "./AuthStageContext";
import { TermsOfService } from "./TermsOfService";

const AuthTransition = createAnimationTransition<AuthStage>();
type AuthComponent<StageType extends AuthStage> = FunctionalComponent<
	AuthStageProps<StageType>
>;
const AUTH_COMPONENTS: {
	[StageType in AuthStage]: AuthComponent<StageType>;
} = {
	[AuthStage.Start]: AuthStart,
	[AuthStage.QuickLogin]: AuthQuick,
	[AuthStage.LoginCode]: AuthConfirm,
	[AuthStage.Registration]: Registration,
	[AuthStage.Consent]: Consent,
	[AuthStage.TermsOfService]: TermsOfService,
};

interface Props {
	matches: { stageId?: AuthStage };
}

export const Auth = ({
	matches: { stageId: initialStage = AuthStage.Start } = {},
}: Props) => {
	const { url } = useLocation();
	const { push, back } = useNavigation();
	const { widgetId } = useWidgetContext();
	const [
		currentStage,
		{ direction, onNext, onPrevious, goToStage: goTo, stages },
	] = useTransitionState([initialStage]);
	// NOTE: Supress error with `props` not *actually* being the
	// right type, since it's composed of query params.
	const currentStageProps = parse(url) as $FixMe;
	const onPreviousStage = useCallback(async () => {
		onPrevious();
		back();
	}, [onPrevious, back]);
	const onNextStage = useCallback(
		async <StageType extends AuthStage>(
			stage: StageType,
			props: AuthStageTransitionProps[StageType]
		) => {
			const nextStage = await onNext(stage);
			push(
				RouteMap.AuthStages,
				{
					widgetId,
					stageId: nextStage,
				},
				props as Props["matches"]
			);
		},
		[widgetId, onNext, push]
	);

	const goToStage = useCallback(
		async <StageType extends AuthStage>(
			stage: StageType,
			props: AuthStageTransitionProps[StageType]
		) => {
			const nextStage = await goTo(stage);
			push(
				RouteMap.AuthStages,
				{
					widgetId,
					stageId: nextStage,
				},
				props as Props["matches"]
			);
		},
		[widgetId, goTo, push]
	);

	return (
		<AuthStageContext.Provider
			value={{
				currentStage,
				currentStageProps,
				onPreviousStage,
				onNextStage,
				goToStage,
				stages,
			}}
		>
			<AuthTransition
				direction={direction}
				activeStage={currentStage}
				renderStage={(stage) => {
					const AuthComponent = AUTH_COMPONENTS[stage];
					return (
						<AuthComponent
							props={currentStageProps}
							direction={direction}
							onPreviousStage={onPreviousStage}
							onNextStage={onNextStage}
							goToStage={goToStage}
						/>
					);
				}}
			/>
		</AuthStageContext.Provider>
	);
};
