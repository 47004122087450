import { useEffect, useMemo } from "preact/hooks";
import { useMutation } from "urql";

import { GetPractitionerImagesForChannelDocument } from "@shared/types/graphql";

export const usePractitionerImages = ({
	channelId,
	practitionerIds,
}: {
	channelId: string;
	practitionerIds: string[];
}): Record<string, string> => {
	const [{ data }, getPractitionerImages] = useMutation(
		GetPractitionerImagesForChannelDocument
	);
	useEffect(() => {
		if (!channelId || !practitionerIds.length) return;

		// Fetch practionerIds, and refetch when the channel or practitionerIds change.
		// Even though these URLs will expire, we're using useImageCache to cache them.
		getPractitionerImages({ channelId });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [channelId, getPractitionerImages, ...practitionerIds]);

	return useMemo(
		() =>
			data?.getPractitionerImagesForChannel.reduce(
				(acc, practitionerImage) =>
					practitionerImage
						? {
								...acc,
								[practitionerImage.userId]: practitionerImage.url,
						  }
						: acc,
				{}
			) ?? {},
		[data]
	);
};
