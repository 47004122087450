import type { ThemeColor } from "@shared/hooks/useTheme";
import { useTheme } from "@shared/hooks/useTheme";

import styles from "./HorizontalLine.module.css";

interface Props {
	width?: number;
	color?: ThemeColor;
}

export function HorizontalLine({ width = 1, color }: Props) {
	const { primaryPlaceholder } = useTheme();
	return (
		<div
			className={styles.horizontalLine}
			style={{
				borderBottom: `${width}px solid ${(color ?? primaryPlaceholder).rgba}`,
			}}
		/>
	);
}
