import { useCallback } from "preact/hooks";
import { useQuery } from "urql";

import { EditAddress } from "@app/components/Forms/EditAddress";
import { EditInfo } from "@app/components/Forms/EditInfo";
import { PharmacyPicker } from "@app/components/Forms/PharmacyPicker";
import { Header } from "@app/components/Header";
import { Main } from "@app/components/Main";
import { Stack } from "@shared/components/Stack";
import { useFirst } from "@shared/hooks/useFirst";
import { useTheme } from "@shared/hooks/useTheme";
import { WidgetGetPatientInfoDocument } from "@shared/types/graphql";

import type { HubPageProps } from "../HubPage";

export function AccountDetails({ onPreviousPage }: HubPageProps) {
	const { spacing } = useTheme();

	const [{ data }, fetchPatientInfo] = useQuery({
		query: WidgetGetPatientInfoDocument,
		requestPolicy: "network-only",
	});

	const patient = useFirst(data?.patient);
	const encounter = useFirst(patient?.encounters);
	const pharmacy = encounter?.pharmacy;
	const serviceIntentId = encounter?.service_intent?.id;

	const handleSuccess = useCallback(
		(selectedPharmacyId?: string) => {
			if (selectedPharmacyId && selectedPharmacyId !== pharmacy?.id) {
				fetchPatientInfo({ requestPolicy: "network-only" });
			}
		},
		[fetchPatientInfo, pharmacy?.id]
	);

	return (
		<>
			<Header onBack={() => onPreviousPage()} />
			<Main>
				<Stack direction="vertical" gap={spacing(4)}>
					<EditInfo
						title="Your Info"
						hideSubmitButton
						showPersonalInfo
						showPhoneNumber
						showSuccessMessage
						shouldDisableIfUnchanged
						disabled
					/>
					<EditAddress
						title="Your Address"
						showSuccessMessage
						shouldDisableIfUnchanged
					/>
					<PharmacyPicker
						showSuccessMessage
						title="Preferred pharmacy"
						pharmacyId={pharmacy?.id}
						pharmacyZip={pharmacy?.zip}
						serviceIntentId={serviceIntentId}
						onSuccess={handleSuccess}
						isDetail
					/>
				</Stack>
			</Main>
		</>
	);
}
