import { useCallback } from "preact/compat";
import { Survey } from "survey-react-ui";
import { useQuery } from "urql";

import { usePatientContext, useWidgetContext } from "@app/components/Contexts";
import { Header } from "@app/components/Header";
import { Main } from "@app/components/Main";
import { RouteMap } from "@app/constants/RouteMap";
import { Badge } from "@shared/components/Badge";
import { useAuthRequired } from "@shared/hooks/useAuthRequired";
import { useFirst } from "@shared/hooks/useFirst";
import { useNavigation } from "@shared/hooks/useNavigation";
import { AuthStage } from "@shared/types/AuthStages";
import type { IntakeStageProps } from "@shared/types/IntakeStages";
import { IntakeStage } from "@shared/types/IntakeStages";
import { WidgetGetQuestionnaireDocument } from "@shared/types/graphql";
import { debug } from "@shared/utilities/debug";

import { useInferServiceIntent } from "./Questions.hooks";
import { useSurvey } from "./useSurvey";
import { useSurveyCSS } from "./useSurveyCSS";

export function Questions({
	props: { serviceId, serviceIntentId, isSecondaryQuestionStage, startOver },
	onNextStage,
	onPreviousStage,
}: IntakeStageProps<IntakeStage.Questions>) {
	const { widgetId } = useWidgetContext();
	const { patientId } = usePatientContext();
	const { push } = useNavigation();
	const {
		data: { resolvedServiceId, serviceIntent },
		fetching: fetchingServiceIntent,
		error: errorCreatingServiceIntent,
	} = useInferServiceIntent({
		patientId,
		serviceId,
		serviceIntentId,
		startOver,
	});

	debug("info", {
		context: "Questions",
		message: "serviceIntent",
		info: { serviceIntentId, patientId },
	});

	const [
		{
			data: questionnaireData,
			fetching: fetchingSurvey,
			error: errorFetchingSurvey,
		},
	] = useQuery({
		query: WidgetGetQuestionnaireDocument,
		variables: {
			serviceId: resolvedServiceId,
			type: isSecondaryQuestionStage ? "evaluation" : "capture",
		},
		pause: !resolvedServiceId,
	});
	const questionnaire = useFirst(questionnaireData?.questionnaire);
	const questionnaireResponse =
		// TODO: Get the questionnaire response from the service intent (CAT-1357)
		serviceIntent?.encounter?.questionnaire_response || undefined;

	const { survey } = useSurvey({
		questionnaire,
		questionnaireResponse,
		patientId,
		onComplete: useCallback(
			(isDisqualified) => {
				// This should never happen
				if (!serviceIntent) {
					return;
				}

				const serviceIntentId = serviceIntent.id;

				if (!patientId) {
					push(
						RouteMap.AuthStages,
						{
							stageId: AuthStage.Start,
							widgetId,
						},
						{
							serviceIntentId,
							isDisqualified,
						}
					);
				} else if (isDisqualified) {
					onNextStage(IntakeStage.Disqualified, {
						serviceIntentId,
					});
				} else if (isSecondaryQuestionStage) {
					onNextStage(IntakeStage.Checkout, {
						serviceIntentId,
					});
				} else {
					onNextStage(IntakeStage.Treatment, {
						serviceIntentId,
					});
				}
			},
			[
				widgetId,
				patientId,
				serviceIntent,
				isSecondaryQuestionStage,
				onNextStage,
				push,
			]
		),
	});
	const serviceName = questionnaire?.service?.name;
	const content = useFirst(questionnaire?.form_versions)?.content as
		| { showProgressBar: string }
		| undefined;
	const showProgressBar = content?.showProgressBar === "top";

	const surveyCSSData = useSurveyCSS();
	const needsAuth = useAuthRequired(isSecondaryQuestionStage);
	const isLoading = needsAuth || fetchingServiceIntent || fetchingSurvey;

	const hasError =
		(!isLoading && !survey) ||
		!!errorCreatingServiceIntent ||
		!!errorFetchingSurvey;

	// TODO: Redirect if the service intent is not in the right path

	return (
		<>
			<Header
				hideMiddleButton={showProgressBar}
				onBack={() => {
					if (survey && survey.currentPageNo > 0) {
						survey.prevPage();
						return;
					}

					onPreviousStage();
				}}
			/>
			<Main
				className="px-0"
				loading={isLoading}
				error={
					hasError ? "Failed to load questionnaire for service." : undefined
				}
			>
				<div className="inline-block w-fit pl-4">
					<Badge noBorder variant="accent">
						{serviceName}
					</Badge>
				</div>
				{!isLoading && !hasError && (
					<Survey model={survey} css={surveyCSSData} />
				)}
			</Main>
		</>
	);
}
