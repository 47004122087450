import type { ReactNode } from "react";

import classNames from "classnames";
import { warningOutline } from "ionicons/icons";

import { Icon } from "@shared/components/Icon";
import { Loader } from "@shared/components/Loader";
import { Stack } from "@shared/components/Stack";
import { Text } from "@shared/components/Text";
import { useTheme } from "@shared/hooks/useTheme";
import type { MessageFragment } from "@shared/types/graphql";
import { isImageFile } from "@shared/utilities/isImageFile";
import { isVideoFile } from "@shared/utilities/isVideoFile";

import styles from "./FilePreview.module.css";

interface Props {
	file: MessageFragment["file"];
	children: ReactNode;
	isLoaded: boolean;
	isError: boolean;
}

export function FilePreview({ file, isLoaded, isError, children }: Props) {
	const { primaryForeground } = useTheme();

	return (
		<div
			className={classNames(styles.container, {
				[styles.squareContainer]:
					(file && (isImageFile(file) || isVideoFile(file))) ||
					!isLoaded ||
					isError,
				[styles.aspectRatio]:
					(file && isImageFile(file)) || !isLoaded || isError,
			})}
		>
			{!isLoaded && !isError && <Loader color={primaryForeground} compact />}
			{isError && (
				<Stack direction="vertical" align="center" justify="center">
					<Icon
						size={64}
						icon={warningOutline}
						stroke={primaryForeground}
						strokeWidth={20}
					/>
					<Text variant="caption" align="center">
						Failed to load
					</Text>
				</Stack>
			)}
			{!isError && children}
		</div>
	);
}
