import { createContext } from "preact";

import type { CombinedError } from "urql";

export enum PaymentType {
	Stripe = "stripe",
	Paytient = "paytient",
}

export enum LegalFormType {
	TermsOfUse = "terms_of_use",
	PrivacyPolicy = "privacy_policy",
}

export enum ProviderNetworkName {
	Steady = "steady",
	MDI = "mdi",
	Catch = "catch",
}

export const ProviderNetworkNameMapping = {
	steady: "SteadyMD, Inc.",
	mdi: "M&D Integrations, Inc.",
	catch: "Other",
};

interface OrganizationProviderType {
	isPaytient: boolean;
	organizationId: string;
	organizationName: string;
	showShippingAddress: boolean;
	showSignoutButton: boolean;
	bypassPrepayment: boolean;
	termsOfUse?: Maybe<string>;
	privacyPolicy?: Maybe<string>;
	consentToTelehealth?: Maybe<string>;
	noticeToPrivacyPractices?: Maybe<string>;
	logoUrl?: Maybe<string>;
	grayscaleLogoUrl?: Maybe<string>;
	paymentType?: Maybe<PaymentType>;
	defaultMenuId?: Maybe<string>;
	ehrIntegrated?: Maybe<boolean>;
	providerNetworkName?: Maybe<ProviderNetworkName>;
	fetching: boolean;
	error: CombinedError | undefined;
	legalForm: {
		termsOfUse: string | null | undefined;
		privacyPolicy: string | null | undefined;
	};
}

export const OrganizationContext = createContext<OrganizationProviderType>({
	organizationId: "",
	organizationName: "",
	isPaytient: false,
	showShippingAddress: false,
	showSignoutButton: false,
	bypassPrepayment: false,
	ehrIntegrated: false,
	fetching: true,
	error: undefined,
	legalForm: { termsOfUse: null, privacyPolicy: null },
});
