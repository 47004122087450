import { useCallback, useRef, useState } from "react";
import type { ReactNode, FormEvent } from "react";

import classNames from "classnames";
import * as mime from "mime-types";
import { useMutation } from "urql";

import { Icon } from "@shared/components/Icon";
import { Loader } from "@shared/components/Loader";
import { Stack } from "@shared/components/Stack";
import { useId } from "@shared/hooks/useId";
import { useWindowSize } from "@shared/hooks/useWindowSize";
import { attach } from "@shared/icons/attach";
import { WidgetCreateFileUploadUrlDocument } from "@shared/types/graphql";

import styles from "./InputFiles.module.css";

export type ChatFile = {
	id: string;
	type: string;
};
interface Props {
	inputId?: string;
	accept?: string;
	multiple?: boolean;
	fileMap?: Map<string, File>;
	disabled?: boolean;
	icon?: ReactNode;
	label?: string;
	capture?: "user" | "environment";
	onChange: (
		files: ChatFile[] | ((oldValue: ChatFile[]) => ChatFile[])
	) => void;
}

export function InputFiles({
	icon,
	accept,
	multiple,
	fileMap,
	label,
	capture,
	onChange,
	disabled,
}: Props) {
	const id = useId();
	const { width } = useWindowSize();
	const inputRef = useRef<HTMLInputElement>(null);

	const [, createFileUploadUrl] = useMutation(
		WidgetCreateFileUploadUrlDocument
	);
	const [isUploading, setIsUploading] = useState(false);
	const uploadFile = useCallback(
		async (file: File) => {
			try {
				setIsUploading(true);

				// Create a new file upload URL.
				const { data } = await createFileUploadUrl({
					contentType: file.type,
					fileName: file.name,
				});
				const { fileId } = data?.createFileUploadURL ?? {};
				const { signedURL } = data?.createFileUploadURL ?? {};
				if (!file || !fileId || !signedURL) {
					throw new Error("Failed to create file upload URL");
				}

				// Upload file.
				const response = await fetch(signedURL, {
					method: "PUT",
					body: file,
					headers: {
						"Content-Disposition": `attachment; filename=${fileId}.${mime.extension(
							file.type
						)}`,
					},
				});

				if (!response.ok) {
					throw new Error("Failed to upload file");
				}

				// Save to map.
				fileMap?.set(fileId, file);
				setIsUploading(false);
				return { id: fileId, type: file.type } as ChatFile;
			} catch (error) {
				// TODO: Properly display the error
				// eslint-disable-next-line no-console
				console.error(error);
				setIsUploading(false);
				return undefined;
			}
		},
		[createFileUploadUrl, fileMap]
	);
	const uploadFiles = useCallback(
		async (files: File[]) => {
			return (await Promise.all(files.map(uploadFile))).filter(
				(file) => !!file?.id
			) as ChatFile[];
		},
		[uploadFile]
	);
	const onInputChange = async (event: FormEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement;
		const files = Array.from(target.files ?? []);
		const chatFiles: ChatFile[] = await uploadFiles(files);
		onChange((oldValue) => [...oldValue, ...chatFiles]);
	};

	return (
		<div className={styles.uploadContainer}>
			{(() => {
				if (isUploading) {
					return <Loader />;
				}

				return (
					<Stack
						direction={width < 400 ? "vertical" : "horizontal"}
						align={width < 400 ? "stretch" : "center"}
						justify="center"
						gap={3}
					>
						<label className={styles.inputLabel} htmlFor={id}>
							{icon ?? (
								<button
									type="button"
									className={classNames(
										"w-auto flex items-center gap-[4px] h-[28px] text-xs font-medium whitespace-nowrap text-primary-foreground",
										{
											"opacity-50 cursor-not-allowed": disabled,
										}
									)}
									onClick={() => inputRef.current?.click()}
								>
									<Icon
										icon={attach}
										size={14}
										className="min-w-[14px] fill-primary-accent-50"
									/>
									{label}
								</button>
							)}
						</label>
					</Stack>
				);
			})()}
			<input
				id={id}
				ref={inputRef}
				name="files"
				type="file"
				capture={capture}
				data-testid="attach-file-input"
				onInput={onInputChange}
				multiple={multiple}
				accept={accept}
				disabled={disabled}
				style={{ display: "none" }}
			/>
		</div>
	);
}
