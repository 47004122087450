import { useState } from "preact/hooks";

import { faArrowRotateLeft } from "@fortawesome/pro-regular-svg-icons";
import type { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { ConfirmDrawer } from "@shared/components/ConfirmDrawer";
import { IconButton } from "@shared/components/IconButton";

import { useRefreshButton } from "./RefreshButton.hooks";

const HOVER_TITLE = "Start from beginning";

export function RefreshButton() {
	const { hideConfirmation, handleRefresh } = useRefreshButton();
	const [serviceId, setServiceId] = useState<string | undefined>(undefined);

	const handleButtonClick = (event: MouseEvent) => {
		// Set the serviceId here if it's derived from the event or elsewhere
		if (event?.currentTarget) {
			const target = event.currentTarget as HTMLElement;
			setServiceId(target.id);
		}

		handleRefresh(serviceId);
	};

	const iconProps = {
		icon: faArrowRotateLeft,
		size: "sm",
	} as FontAwesomeIconProps;

	if (hideConfirmation) {
		return (
			<IconButton
				faIconProps={iconProps}
				buttonProps={{
					onClick: handleButtonClick,
					variant: "plain",
					title: HOVER_TITLE,
				}}
			/>
		);
	}

	return (
		<ConfirmDrawer
			title="Exit confirmation"
			description="Are you sure you want to exit the Health Survey?"
			cancelText="Go back to Survey"
			confirmText="Exit"
			onAgree={handleRefresh}
		>
			{(toggleOpen) => (
				<IconButton
					faIconProps={iconProps}
					buttonProps={{
						onClick: toggleOpen,
						variant: "plain",
						title: HOVER_TITLE,
					}}
				/>
			)}
		</ConfirmDrawer>
	);
}
