import { useCallback, useEffect, useRef, useState } from "react";

import { useMutation } from "urql";

import { WidgetGetFileDownloadUrlDocument } from "@shared/types/graphql";

export function useMessageFileUrl(
	id: string,
	options: { skip?: boolean } = {}
) {
	const nextFetchInterval = useRef<ReturnType<
		typeof window.setInterval
	> | null>(null);
	const [fileUrl, setFileUrl] = useState<string | null>(null);
	const [state, getFileDownloadUrl] = useMutation(
		WidgetGetFileDownloadUrlDocument
	);
	const updateFileUrl = useCallback(
		async (messageId: string) => {
			const { data } = await getFileDownloadUrl({ messageId });
			const url = data?.getPortalMessageFileDownloadURL?.url;
			setFileUrl(url ?? null);
		},
		[getFileDownloadUrl]
	);
	useEffect(() => {
		if (options.skip) return;

		// Fetch the file URL immediately.
		updateFileUrl(id);

		// Refetch the file URL every 4 hours.
		nextFetchInterval.current = setInterval(
			() => updateFileUrl(id),
			1000 * 60 * 60 * 4
		);
		return () => {
			if (nextFetchInterval.current) {
				clearInterval(nextFetchInterval.current);
			}
		};
	}, [id, updateFileUrl, options.skip]);

	return [fileUrl, state] as const;
}
