export enum CheckoutStep {
	Pharmacy = "CheckoutPharmacy",
	Address = "CheckoutAddress",
	Payment = "CheckoutPurchase",
	Success = "CheckoutSuccess",
}

export type CheckoutStepTransitionProps = {
	[CheckoutStep.Pharmacy]: Record<never, unknown>;
	[CheckoutStep.Address]: Record<never, unknown>;
	[CheckoutStep.Payment]: Record<never, unknown>;
	[CheckoutStep.Success]: Record<never, unknown>;
};

export type CheckoutStepProps = {
	visible?: boolean;
	serviceIntentId: string;
	onPreviousStep: () => void;
	onNextStep: <Page extends CheckoutStep>(
		page: Page,
		props: CheckoutStepTransitionProps[Page]
	) => void;
};
