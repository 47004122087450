import { useState } from "react";

import { useTransition, animated } from "react-spring";

import { debug } from "@shared/utilities/debug";

import styles from "./AnimationTransition.module.css";

export enum TransitionDirection {
	Forwards = "forwards",
	Backwards = "backwards",
}

interface Props<T = string> {
	direction?: TransitionDirection;
	activeStage: T | undefined;
	renderStage: (stage: T) => JSX.Element | null;
	extractKey?: (stage: T) => string;
}

export function useLastExistingValue<T>(value: T | undefined) {
	const [lastExistingValue, setLastExistingValue] = useState<T | undefined>(
		value
	);
	if (value && value !== lastExistingValue) {
		setLastExistingValue(value);
	}

	return lastExistingValue;
}

function AnimationTransition<T = string>({
	direction = TransitionDirection.Forwards,
	activeStage,
	renderStage,
	extractKey = (stage: T) => `${stage}`,
}: Props<T>) {
	debug("info", {
		context: "AnimationTransition",
		message: "activeStage",
		info: {
			activeStage,
			renderStage,
		},
	});
	const lastActiveStage = useLastExistingValue(activeStage);
	const stageToRender = activeStage ?? lastActiveStage ?? [];

	const transitions = useTransition(stageToRender, {
		keys: extractKey,
		from: {
			x: direction === TransitionDirection.Forwards ? 100 : -100,
		},
		enter: { x: 0 },
		leave: {
			x: direction === TransitionDirection.Forwards ? -100 : 100,
		},
	});

	if (activeStage === undefined && lastActiveStage === undefined) return null;

	return transitions((style, item) => (
		<animated.div style={style} className={styles.animationTransitionWrapper}>
			{renderStage(item)}
		</animated.div>
	));
}

export const createAnimationTransition =
	<T,>() =>
	(props: Props<T>) =>
		AnimationTransition(props);
