import { createContext } from "preact";

import { noop } from "@shared/utilities/noop";

interface WidgetProviderType {
	widgetId: string;
	isWidgetOpen: boolean;
	closeWidget: () => void;
	openWidget: () => void;
	onRendered: () => void;
}

export const WidgetContext = createContext<WidgetProviderType>({
	widgetId: "",
	isWidgetOpen: true,
	closeWidget: noop,
	openWidget: noop,
	onRendered: noop,
});
