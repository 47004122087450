import { useCallback, useState } from "react";

import { useConsentContext } from "@app/components/PatientHub/VisitChannel/Context";
import { Button } from "@shared/components/Button";
import { Stack } from "@shared/components/Stack";
import { Text } from "@shared/components/Text";
import { isPlaywright } from "@shared/constants/environments";

import type { ConsentRequestPayload } from "../Message";
import { ApprovedMessage } from "./ApprovedMessage";
import { useConsentFormData } from "./useConsentFormData";

interface Props {
	payload: ConsentRequestPayload;
}

export function Consent({ payload }: Props) {
	const { consentForms, practitionerId } = payload;
	const [consented, setConsented] = useState(false);
	const { approvedAtMap, showConsent } = useConsentContext();
	const {
		fetching,
		consentToForms,
		practitionerIds,
		telehealthConsentFormId,
		privacyPolicyConsentFormId,
	} = useConsentFormData({
		consentForms,
	});

	const approvedAt = approvedAtMap?.[practitionerId];

	const handleButtonClick = useCallback(async () => {
		await consentToForms();
		setConsented(true);
	}, [consentToForms]);

	if (fetching) return null;

	return (
		<Stack direction="vertical" gap={18}>
			<Stack direction="vertical" gap={6}>
				<Text>
					Please review the documents listed below and confirm the following by
					clicking the button below:
					<ul className="list-disc list-inside">
						<li className="list-disc ml-6 pt-1 pb-1">
							{" "}
							I agree to the{" "}
							<button
								type="button"
								onClick={() =>
									showConsent({
										consentFormId: telehealthConsentFormId,
										practitionerIds,
										title: "Consent to Telehealth",
									})
								}
							>
								<Text variant="link">Consent to Telehealth</Text>
							</button>
							.
						</li>
						<li className="list-disc ml-6 pt-1 pb-1">
							{" "}
							I acknowledge receipt of the{" "}
							<button
								type="button"
								onClick={() =>
									showConsent({
										consentFormId: privacyPolicyConsentFormId,
										practitionerIds,
										title: "Notice of Privacy Practices",
									})
								}
							>
								<Text variant="link">Notice of Privacy Practices</Text>
							</button>
							.
						</li>
					</ul>
				</Text>
			</Stack>
			<Stack direction="horizontal" justify="center">
				{approvedAt ? (
					<ApprovedMessage approvedAt={approvedAt} />
				) : (
					<Button
						variant="primary"
						text="I Consent & Acknowledge"
						disabled={consented}
						onClick={handleButtonClick}
						className={
							// If the button jiggles, Playwright can't find it...
							isPlaywright ? undefined : "animate-jiggle hover:animate-none"
						}
					/>
				)}
			</Stack>
		</Stack>
	);
}
