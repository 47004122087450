import type { ReactNode, CSSProperties, AriaRole } from "react";

import classNames from "classnames";

import type { Padding } from "@shared/hooks/usePadding";
import { usePaddingValue } from "@shared/hooks/usePadding";

import styles from "./Stack.module.css";

type StackElement = "div" | "ul" | "ol" | "li" | "label" | "form" | "section";

interface Props {
	element?: StackElement;
	role?: AriaRole;
	style?: CSSProperties;
	children: ReactNode;
	direction: "vertical" | "horizontal";
	align?:
		| "start"
		| "center"
		| "end"
		| "space-between"
		| "space-evenly"
		| "stretch";
	justify?:
		| "start"
		| "center"
		| "end"
		| "space-between"
		| "space-evenly"
		| "stretch";
	padding?: Padding;
	gap?: number;
	template?: string;
	className?: string;
}

export function Stack({
	element: Element = "div",
	role,
	style,
	children,
	direction,
	align = "start",
	justify = "start",
	template,
	padding,
	gap,
	className,
}: Props) {
	const isVertical = direction === "vertical";
	return (
		<Element
			className={classNames(className, styles.stack, styles[direction])}
			style={{
				gap,
				[isVertical ? "gridTemplateRows" : "gridTemplateColumns"]: template,
				[isVertical ? "justifyItems" : "alignItems"]: (() => {
					if (align === "start") return "flex-start";
					if (align === "end") return "flex-end";
					if (align === "center") return "center";
					return align;
				})(),
				[isVertical ? "alignContent" : "justifyContent"]: (() => {
					if (justify === "start") return "flex-start";
					if (justify === "end") return "flex-end";
					if (justify === "center") return "center";
					return justify;
				})(),
				...usePaddingValue(padding),
				...style,
			}}
			role={role}
		>
			{children}
		</Element>
	);
}
