import classNames from "classnames";

import { faArrowRightLong } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@shared/hooks/useTheme";
import { isDarkMode } from "@shared/utilities/isDarkMode";

import styles from "./ClickableBanner.module.css";

type DataAttributes = {
	[K in `data-${string}`]?: string;
};

type ButtonProps = Omit<JSX.HTMLAttributes<HTMLButtonElement>, "ref"> &
	DataAttributes;

export type ClickableBannerProps = ButtonProps & {
	onClick: () => void;
	show: boolean;
};

export function ClickableBanner({
	onClick,
	show,
	...clickableBannerProps
}: ClickableBannerProps) {
	const { primaryAccent800, primaryAccent50 } = useTheme();
	const backgroundColor = isDarkMode()
		? primaryAccent50?.color
		: primaryAccent800?.color;
	return (
		<>
			<div
				hidden={!show}
				className="pointer-events-none z-50 max-w-[520px] absolute inset-x-0 bottom-0 items-center justify-center pb-5"
			>
				<button
					type="button"
					className={classNames(
						"pointer-events-auto w-full flex items-center justify-between gap-x-6 py-2.5 rounded-xl pl-4 pr-3.5"
					)}
					name="Sign in"
					style={{ backgroundColor }}
					{...clickableBannerProps}
					onClick={onClick}
				>
					<p className="flex text-sm leading-6 text-white">
						<div
							className={classNames(
								"flex flex-col text-sm leading-6 text-left text-white sm:flex-row sm:gap-x-2",
								styles.shimmer
							)}
						>
							<strong className="font-semibold">Already Started?</strong>
							Sign in to to pick up where you left off.&nbsp;
						</div>
					</p>
					<div className="-m-1.5 flex-none p-1.5">
						<span className="sr-only">Sign in</span>
						<FontAwesomeIcon
							icon={faArrowRightLong}
							className="h-5 w-5 text-white"
						/>
					</div>
				</button>
			</div>
		</>
	);
}
