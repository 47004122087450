import type { FunctionalComponent } from "preact";

import { useCallback, useState } from "preact/hooks";

import { useAuthContext, useWidgetContext } from "@app/components/Contexts";
import { useFeatureFlag } from "@app/components/Contexts/LaunchDarklyContext";
import { AppointmentScheduling } from "@app/components/Intake/AppointmentScheduling";
import { Checkout } from "@app/components/Intake/Checkout";
import { Disqualified } from "@app/components/Intake/Disqualified";
import { Menu, MenuPreview } from "@app/components/Intake/Menu";
import { Questionnaire } from "@app/components/Intake/Questionnaire";
import { Questions } from "@app/components/Intake/Questions";
import { Services } from "@app/components/Intake/Services";
import { Treatment } from "@app/components/Intake/Treatment";
import { NotFound } from "@app/components/NotFound";
import { RouteMap } from "@app/constants/RouteMap";
import {
	createAnimationTransition,
	useTransitionState,
} from "@shared/components/AnimationTransition";
import { Redirect } from "@shared/components/Redirect";
import { useLocation } from "@shared/hooks/useLocation";
import { useNavigation } from "@shared/hooks/useNavigation";
import { PubSubMessage, useSubscription } from "@shared/hooks/usePubSub";
import { AuthStage } from "@shared/types/AuthStages";
import type {
	IntakeStageProps,
	IntakeStageTransitionProps,
} from "@shared/types/IntakeStages";
import { IntakeStage } from "@shared/types/IntakeStages";
import { debug } from "@shared/utilities/debug";
import type { PubSubPayload } from "@shared/utilities/pubSub";
import { parse } from "@shared/utilities/queryString";

import { IntakeStageContext } from "./IntakeStageContext";

const IntakeTransition = createAnimationTransition<IntakeStage>();
type IntakeComponent<StageType extends IntakeStage> = FunctionalComponent<
	IntakeStageProps<StageType>
>;
const INTAKE_COMPONENTS: {
	[StageType in IntakeStage]: IntakeComponent<StageType>;
} = {
	[IntakeStage.Menu]: Menu,
	[IntakeStage.MenuPreview]: MenuPreview,
	[IntakeStage.Services]: Services,
	[IntakeStage.Questions]: Questions,
	[IntakeStage.Scheduling]: AppointmentScheduling,
	[IntakeStage.Treatment]: Treatment,
	[IntakeStage.Checkout]: Checkout,
	[IntakeStage.Disqualified]: Disqualified,
	[IntakeStage.Questionnaire]: Questionnaire,
};

const INTAKE_PUBSUBMESSAGE: {
	[StageType: string]: PubSubMessage;
} = {
	[IntakeStage.MenuPreview]: PubSubMessage.MenuData,
};

const AUTHED_STAGES = [
	IntakeStage.Treatment,
	IntakeStage.Checkout,
	IntakeStage.Disqualified,
];

interface Props {
	matches: { stageId?: IntakeStage };
}

export const Intake = ({
	matches: { stageId: initialStage = IntakeStage.Services } = {},
}: Props) => {
	const { url } = useLocation();
	const [, search] = url.split("?");
	const queryParams = new URLSearchParams(search);

	const { push, back, replace } = useNavigation();
	const { widgetId } = useWidgetContext();
	const { patientId } = useAuthContext();

	const [
		currentStage,
		{ direction, onNext, onPrevious, goToStage: goTo, stages },
	] = useTransitionState([initialStage]);

	const [customStageProps, setCustomStageProps] = useState<
		Partial<{
			[key in keyof PubSubPayload as `raw${string & key}`]: PubSubPayload[key];
		}>
	>();
	const pubSubMessage = INTAKE_PUBSUBMESSAGE[currentStage] || null;

	useSubscription(pubSubMessage, (event) => {
		setCustomStageProps({
			[`raw${pubSubMessage}`]: JSON.stringify(event.payload),
		});
	});

	const currentStageProps = customStageProps?.[`raw${pubSubMessage}`]
		? customStageProps
		: (parse(url) as $FixMe);

	const onPreviousStage = useCallback(async () => {
		if (currentStage === IntakeStage.Services && stages.length === 1) {
			replace(RouteMap.Visits, { widgetId });

			return;
		}

		onPrevious();
		back();
	}, [currentStage, onPrevious, back, replace, widgetId, stages]);

	const onNextStage = useCallback(
		async <StageType extends IntakeStage>(
			stageId: StageType,
			props: IntakeStageTransitionProps[StageType]
		) => {
			push(
				RouteMap.IntakeStages,
				{
					widgetId,
					stageId,
				},
				props
			);

			onNext(stageId);
		},
		[widgetId, onNext, push]
	);

	const goToStage = useCallback(
		async <StageType extends IntakeStage>(
			stage: StageType,
			props: IntakeStageTransitionProps[StageType]
		) => {
			const nextStage = await goTo(stage);
			replace(
				RouteMap.IntakeStages,
				{
					widgetId,
					stageId: nextStage,
				},
				props
			);
		},
		[widgetId, goTo, replace]
	);

	const isMenusStage = currentStage === IntakeStage.Menu;
	const isLeadStage = currentStage === IntakeStage.Questionnaire;
	const hasMenus = useFeatureFlag("customizableMenus");
	const hasQuestionnaires = useFeatureFlag("leadQuestionnaires");
	if (isMenusStage && !hasMenus) {
		return <NotFound />;
	}

	if (isLeadStage && !hasQuestionnaires) {
		return <NotFound />;
	}

	/* Build redirect link post-login. */

	// Check if there is a direct link to evaluation questionnaire.
	const isDirectLinkToEvaluationQuestionnaire =
		queryParams &&
		currentStage === IntakeStage.Questions &&
		queryParams.get("isSecondaryQuestionStage") === "true";

	debug("info", {
		context: "Intake",
		message: "Rendering Intake component",
		info: {
			currentStage,
			currentStageProps,
			stages,
			queryParams,
		},
	});

	if (
		!patientId &&
		(AUTHED_STAGES.includes(currentStage) ||
			isDirectLinkToEvaluationQuestionnaire)
	) {
		let redirectQueryParams, replaceQueryParams;
		if (queryParams && queryParams.size > 0) {
			redirectQueryParams = Object.fromEntries(queryParams.entries());
			replaceQueryParams = false;
		}
		return (
			<Redirect
				to={RouteMap.AuthStages}
				variables={{ widgetId, stageId: AuthStage.Start }}
				queryParams={redirectQueryParams}
				replaceQueryParams={replaceQueryParams}
			/>
		);
	}

	return (
		<IntakeStageContext.Provider
			value={{
				currentStage,
				currentStageProps,
				onPreviousStage,
				onNextStage,
				goToStage,
				stages,
			}}
		>
			<IntakeTransition
				direction={direction}
				activeStage={currentStage}
				renderStage={(stage) => {
					const IntakeComponent = INTAKE_COMPONENTS[stage];
					return (
						<IntakeComponent
							props={currentStageProps}
							direction={direction}
							goToStage={goToStage}
							onPreviousStage={onPreviousStage}
							onNextStage={onNextStage}
						/>
					);
				}}
			/>
		</IntakeStageContext.Provider>
	);
};
