import { useCallback, useMemo } from "react";

import classNames from "classnames";

import { useFeatureFlag } from "@app/components/Contexts/LaunchDarklyContext";
import { Button } from "@shared/components/Button";
import { VideoModal } from "@shared/components/VideoModal";
import { useMessageFileUrl } from "@shared/hooks/useMessageFileUrl";
import type { MessageFragment } from "@shared/types/graphql";
import { downloadFile } from "@shared/utilities/downloadFile";
import { isAudioFile } from "@shared/utilities/isAudioFIle";
import { isEventType } from "@shared/utilities/isEventType";
import { isImageFile } from "@shared/utilities/isImageFile";
import { isTemporaryMessageId } from "@shared/utilities/isTemporaryMessageId";
import { isVideoFile } from "@shared/utilities/isVideoFile";

import { InputFiles } from "../InputFiles";
import type { ChatFile } from "../InputFiles/InputFiles";
import { Audio } from "./Audio";
import { Consent } from "./Consent";
import { Image } from "./Image";
import styles from "./Message.module.css";
import { processContent } from "./Message.utils";
import { MessageBubble, MessageBubbleStyle } from "./MessageBubble";
import { Video } from "./Video";

const isMobile =
	/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
		navigator.userAgent
	);

interface Props {
	message: MessageFragment;
	senderName: string;
	isIncoming: boolean;
	messageDate: string | null;
	showImagePreview(url: string): void;
	showBeforeArrow: boolean;
	onUploadFile(file: ChatFile): void;
}

export enum PortalMessagePayloadType {
	CONSENT_FORM_AGREEMENT = "consent_form_agreement",
	CONSENT_FORM_REQUEST = "consent_form_request",
	REQUIRES_AV_FILE_UPLOAD = "requires_av_file_upload",
}

export type ConsentFormPayload = {
	practitionerId: string;
	consentFormIds: string[];
};

export type ConsentRequestPayload = {
	type: PortalMessagePayloadType.CONSENT_FORM_REQUEST;
	consentForms: ConsentFormPayload[];
	practitionerId: string;
};

export type ConsentAgreementPayload = {
	type: PortalMessagePayloadType.CONSENT_FORM_AGREEMENT;
	consentForms: {
		practitionerId: string;
		consentForms: {
			consentFormId: string;
			consentFormTemplateId: number;
		}[];
	}[];
	date: number;
};

export type RequiresAVFileUploadPayload = {
	type: PortalMessagePayloadType.REQUIRES_AV_FILE_UPLOAD;
	file?: string;
};

export type PortalMessagePayload =
	| ConsentRequestPayload
	| ConsentAgreementPayload
	| RequiresAVFileUploadPayload;

export function Message({
	message,
	senderName,
	isIncoming,
	messageDate,
	showImagePreview,
	showBeforeArrow,
	onUploadFile,
}: Props) {
	const { id, text, file, created_at } = message;
	const [fileUrl, { fetching, error }] = useMessageFileUrl(id, {
		skip: !file || !id || isTemporaryMessageId(id),
	});
	const download = useCallback(async () => {
		if (!fileUrl) return;

		// Check if iOS, because our usual download method doesn't work on iOS.:
		const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
		if (isIOS) {
			// Moreover: `window.open` on iOS needs to be wrapped in a setTimeout,
			// because reasons.
			// https://stackoverflow.com/a/70463940/2085743
			setTimeout(() => window.open(fileUrl, "_blank"));
			return;
		}

		downloadFile(fileUrl);
	}, [fileUrl]);
	const payload = message.payload as PortalMessagePayload | undefined;

	const hasConsentForms = useFeatureFlag("consentForms");
	const isConsentRequest =
		payload?.type === PortalMessagePayloadType.CONSENT_FORM_REQUEST;
	const isAVFileUploadRequest =
		payload?.type === PortalMessagePayloadType.REQUIRES_AV_FILE_UPLOAD;
	const isImage = file && isImageFile(file);
	const isVideo = file && isVideoFile(file);
	const isAudio = file && isAudioFile(file);
	const isOtherFile = file && !isImage && !isVideo && !isAudio;
	const timestamp = new Date(created_at).toLocaleString();
	const bubbleStyle = useMemo(() => {
		if (isIncoming || isConsentRequest) {
			return MessageBubbleStyle.Incoming;
		} else if (isEventType(message)) {
			return MessageBubbleStyle.Event;
		}

		return MessageBubbleStyle.Outgoing;
	}, [message, isIncoming, isConsentRequest]);

	if (!text && !file && !isConsentRequest) return null;

	const parsedText = processContent(text);

	return (
		<div
			className={classNames(styles.messageContainer, {
				[styles.messageContainerIncoming]: isIncoming,
			})}
		>
			{parsedText && (
				<MessageBubble
					type={bubbleStyle}
					senderName={senderName}
					messageDate={messageDate}
					timestamp={timestamp}
					showBeforeArrow={isIncoming && showBeforeArrow}
				>
					{parsedText}
				</MessageBubble>
			)}
			{hasConsentForms && isConsentRequest && (
				<MessageBubble
					type={bubbleStyle}
					messageDate={messageDate}
					senderName={senderName}
					timestamp={timestamp}
				>
					<Consent payload={payload} />
				</MessageBubble>
			)}
			{isImage && (
				<Image
					file={file}
					fileUrl={fileUrl}
					fetching={fetching}
					error={error}
					showImagePreview={showImagePreview}
				/>
			)}
			{isVideo && (
				<Video
					file={file}
					fileUrl={fileUrl}
					fetching={fetching}
					error={error}
				/>
			)}
			{isAudio && (
				<Audio
					file={file}
					fileUrl={fileUrl}
					fetching={fetching}
					error={error}
				/>
			)}
			{isOtherFile && (
				<MessageBubble type={bubbleStyle} timestamp={timestamp}>
					<Button
						variant="plain"
						text={file.display_name ?? "Download File"}
						textClassName={styles.downloadButtonText}
						onClick={download}
						underline
					/>
				</MessageBubble>
			)}
			{isAVFileUploadRequest && (
				<MessageBubble type={bubbleStyle} timestamp={timestamp}>
					{isMobile ? (
						<InputFiles
							onChange={(files) => {
								const file =
									typeof files === "function" ? files([])[0] : files[0];

								onUploadFile(file);
							}}
							label="Record Video"
							accept="video/*"
						/>
					) : (
						<VideoModal onChange={onUploadFile} />
					)}
				</MessageBubble>
			)}
		</div>
	);
}
