import type { ReactNode, AriaRole } from "react";

import classNames from "classnames";

import type { Props as ButtonProps } from "@shared/components/Button";
import { Button } from "@shared/components/Button";
import type { Props as IconProps } from "@shared/components/Icon";
import { Icon } from "@shared/components/Icon";
import { Stack } from "@shared/components/Stack";
import { Text } from "@shared/components/Text";
import type { Padding } from "@shared/hooks/usePadding";
import { usePaddingValue } from "@shared/hooks/usePadding";
import { useTheme } from "@shared/hooks/useTheme";

import styles from "./Highlight.module.css";

interface CommonProps {
	role?: AriaRole;
	className?: string;
	variant?: "primary" | "success" | "outline" | "danger" | "warning";
	iconProps?: IconProps;
	iconPosition?: "before" | "after";
	buttonProps?: ButtonProps;
	additionalButtonProps?: ButtonProps;
	secondaryButtonProps?: ButtonProps;
	padding?: Padding;
}

interface PropsWithText extends CommonProps {
	caption?: string;
	title?: ReactNode;
	text?: ReactNode;
}

interface PropsWithChildren extends CommonProps {
	children: ReactNode;
}

export type Props = PropsWithText | PropsWithChildren;

const isPropsWithChildren = (props: Props): props is PropsWithChildren =>
	"children" in props;

export function Highlight(props: Props) {
	const {
		variant = "primary",
		iconProps,
		iconPosition,
		buttonProps,
		additionalButtonProps,
		secondaryButtonProps,
		padding,
	} = props;
	const { primaryForeground, spacing } = useTheme();
	return (
		<section
			role={props.role}
			className={classNames(
				styles.highlightWrapper,
				{
					[styles.highlightBackgroundPrimary]: variant === "primary",
					[styles.highlightBackgroundOutline]: variant === "outline",
					[styles.highlightBackgroundDanger]: variant === "danger",
					[styles.highlightBackgroundWarning]: variant === "warning",
					[styles.highlightBackgroundSuccess]: variant === "success",
				},
				props.className
			)}
			style={usePaddingValue(padding)}
		>
			<div
				className={styles.highlightBackground}
				style={{
					borderColor:
						variant === "outline"
							? primaryForeground.withAlpha(0.1).color
							: "none",
				}}
			/>
			<div className={styles.highlightContent}>
				<Stack
					direction="horizontal"
					align="center"
					justify={(() => {
						if (iconProps) {
							if (iconPosition === "after") {
								return "space-between";
							}

							return "start";
						}

						return "stretch";
					})()}
					gap={spacing(2)}
				>
					{!!iconProps && iconPosition === "before" && <Icon {...iconProps} />}
					<Stack
						direction="vertical"
						align="stretch"
						justify="start"
						gap={spacing(5)}
					>
						{isPropsWithChildren(props) ? (
							props.children
						) : (
							<Stack
								direction="vertical"
								align="stretch"
								justify="center"
								gap={spacing(1)}
							>
								{(props.title || props.caption) && (
									<Stack direction="vertical" align="stretch" gap={spacing(1)}>
										{typeof props.caption === "string" ? (
											<Text variant="caption">{props.caption}</Text>
										) : (
											props.caption
										)}
										{typeof props.title === "string" ? (
											<Text variant="h3">{props.title}</Text>
										) : (
											props.title
										)}
									</Stack>
								)}
								{typeof props.text === "string" ? (
									<Text>{props.text}</Text>
								) : (
									props.text
								)}
							</Stack>
						)}
						{buttonProps || secondaryButtonProps ? (
							<Stack
								direction={additionalButtonProps ? "horizontal" : "vertical"}
								align="stretch"
								justify="stretch"
								gap={spacing(2)}
							>
								{additionalButtonProps && <Button {...additionalButtonProps} />}
								{buttonProps && (
									<Button
										{...buttonProps}
										className={buttonProps.className}
										textClassName={buttonProps.textClassName}
									/>
								)}
								{secondaryButtonProps && <Button {...secondaryButtonProps} />}
							</Stack>
						) : null}
					</Stack>
					{!!iconProps && iconPosition === "after" && <Icon {...iconProps} />}
				</Stack>
			</div>
		</section>
	);
}
