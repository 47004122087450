import type { FunctionalComponent } from "preact";

import { useCallback } from "preact/compat";
import { matchPath } from "react-router-dom";

import { useAuthContext, useWidgetContext } from "@app/components/Contexts";
import { AccountDetails } from "@app/components/PatientHub/AccountDetails";
import { VisitChannel } from "@app/components/PatientHub/VisitChannel";
import { VisitChannelDetails } from "@app/components/PatientHub/VisitChannelDetails";
import { Visits } from "@app/components/PatientHub/Visits";
import { RouteMap } from "@app/constants/RouteMap";
import {
	createAnimationTransition,
	useTransitionState,
} from "@shared/components/AnimationTransition";
import { Redirect } from "@shared/components/Redirect";
import { useLocation } from "@shared/hooks/useLocation";
import { useNavigation } from "@shared/hooks/useNavigation";
import { AuthStage } from "@shared/types/AuthStages";
import { debug } from "@shared/utilities/debug";

import type { HubPageTransitionProps, HubPageProps, Options } from "./HubPage";
import { HubPage } from "./HubPage";

const HubPageTransition = createAnimationTransition<HubPage>();
export type HubPageComponent = FunctionalComponent<HubPageProps>;
const PAGE_COMPONENTS: { [Page in HubPage]: HubPageComponent } = {
	[HubPage.Visits]: Visits,
	[HubPage.VisitChannel]: VisitChannel,
	[HubPage.VisitChannelDetails]: VisitChannelDetails,
	[HubPage.AccountDetails]: AccountDetails,
};

export function PatientHub() {
	const { path } = useLocation();
	debug("info", {
		context: "PatientHub",
		message: "path",
		info: {
			path,
		},
	});
	const { push, replace, back } = useNavigation();
	const { widgetId } = useWidgetContext();
	const { patientId } = useAuthContext();
	const [currentPage, { direction, onPrevious, onNext }] = useTransitionState(
		(() => {
			debug("info", {
				context: "PatientHub",
				message: "usetransitionstate",
				info: {
					path,
					matchPath: matchPath(RouteMap.VisitChannel, path),
				},
			});
			if (matchPath(RouteMap.AccountDetails, path)) {
				return [HubPage.AccountDetails, HubPage.Visits];
			} else if (matchPath(RouteMap.VisitChannel, path)) {
				return [HubPage.VisitChannel, HubPage.Visits];
			} else if (matchPath(RouteMap.VisitChannelDetails, path)) {
				return [
					HubPage.VisitChannelDetails,
					HubPage.VisitChannel,
					HubPage.Visits,
				];
			}

			return [HubPage.Visits];
		})()
	);
	debug("info", {
		context: "PatientHub",
		message: "usetransitionstate",
		info: {
			path,
			currentPage,
			direction,
		},
	});
	const onPreviousPage = useCallback(async () => {
		if (currentPage === HubPage.VisitChannel) {
			onPrevious();
			replace(RouteMap.Visits, {
				widgetId,
			});
			return;
		}

		onPrevious();
		back();
	}, [currentPage, onPrevious, back, replace, widgetId]);
	const onNextPage = useCallback(
		async <Page extends HubPage>(
			page: Page,
			props: HubPageTransitionProps[Page],
			options?: Options
		) => {
			const nextPage = await onNext(page, options);
			debug("info", {
				context: "PatientHub",
				message: "onNextPage",
				info: {
					page,
					props,
					options,
					nextPage,
				},
			});
			if (nextPage === HubPage.VisitChannel) {
				const { channelId } =
					props as HubPageTransitionProps[HubPage.VisitChannel];
				push(RouteMap.VisitChannel, {
					widgetId,
					channelId,
				});
			} else if (nextPage === HubPage.VisitChannelDetails) {
				const { channelId } =
					props as HubPageTransitionProps[HubPage.VisitChannelDetails];
				push(RouteMap.VisitChannelDetails, {
					widgetId,
					channelId,
				});
			} else if (nextPage === HubPage.AccountDetails) {
				push(RouteMap.AccountDetails, {
					widgetId,
				});
			}
		},
		[onNext, push, widgetId]
	);

	if (!patientId) {
		debug("info", {
			context: "PatientHub",
			message: "patientId",
			info: {
				patientId,
			},
		});
		return (
			<Redirect
				to={RouteMap.AuthStages}
				variables={{ widgetId, stageId: AuthStage.Start }}
				replaceHistory={true}
			/>
		);
	}

	return (
		<HubPageTransition
			activeStage={currentPage}
			direction={direction}
			renderStage={(page) => {
				const Page = PAGE_COMPONENTS[page];
				return <Page onPreviousPage={onPreviousPage} onNextPage={onNextPage} />;
			}}
		/>
	);
}
