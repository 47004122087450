import { render } from "preact";

import "catchhealth-survey-widgets/dist/main.css";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import "preact/debug";

import { App } from "@app/components/App";
import { LD_CLIENT_KEY } from "@shared/constants/environments";
import { Theme } from "@shared/hooks/useTheme";
import { publish, PubSubMessage, subscribe } from "@shared/utilities/pubSub";
import { trpc, trpcClient } from "@shared/utilities/trpc/trpc";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

import { ClientProvider } from "./components/Contexts/ClientContext";
import { isIframed } from "./utilities/isIframed";

declare global {
	interface Window {
		isPlaywright?: boolean;
	}
}

(async () => {
	const LDProvider = await asyncWithLDProvider({
		clientSideID: LD_CLIENT_KEY,
		options: {
			// Treat all user attributes as private by default.
			// This is so we don't accidentally send PII to LaunchDarkly.
			allAttributesPrivate: true,
		},
	});
	const queryClient = new QueryClient();
	if (isIframed()) {
		subscribe(
			PubSubMessage.Intitialize,
			({ payload: { lightTheme, darkTheme } }) => {
				render(
					<LDProvider>
						<ClientProvider>
							<trpc.Provider client={trpcClient} queryClient={queryClient}>
								<QueryClientProvider client={queryClient}>
									<App
										lightTheme={Theme.fromString(lightTheme)}
										darkTheme={Theme.fromString(darkTheme)}
									/>
								</QueryClientProvider>
							</trpc.Provider>
						</ClientProvider>
					</LDProvider>,
					document.body
				);
			}
		);
		publish(window.parent, PubSubMessage.Ready);
	} else {
		render(
			<LDProvider>
				<ClientProvider>
					<trpc.Provider client={trpcClient} queryClient={queryClient}>
						<QueryClientProvider client={queryClient}>
							<App />
						</QueryClientProvider>
					</trpc.Provider>
				</ClientProvider>
			</LDProvider>,
			document.body
		);
	}
})();
