import type { Props as HighlightProps } from "@shared/components/Highlight";
import { Highlight } from "@shared/components/Highlight";

type Props = HighlightProps & {
	variant: "success" | "danger" | "warning";
};

export function Alert(props: Props) {
	return <Highlight role="alert" {...props} />;
}
