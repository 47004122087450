import { useCallback, useMemo } from "preact/hooks";
import { useQuery } from "urql";

import {
	useAuthContext,
	usePatientContext,
	useWidgetContext,
} from "@app/components/Contexts";
import { getServiceIntentStepRoute } from "@app/utilities/getServiceIntentStepRoute";
import { useNavigation } from "@shared/hooks/useNavigation";
import type { ServiceIntentStatus } from "@shared/types/ServiceIntentStatus";
import {
	SERVICE_INTENT_EDITABLE_STATUSES,
	SERVICE_INTENT_INPROGRESS_STATUSES,
	SERVICE_INTENT_TERMINAL_STATUSES,
} from "@shared/types/ServiceIntentStatus";
import type { ServiceIntentFieldsForServiceFragment } from "@shared/types/graphql";
import { WidgetGetActiveServiceIntentsDocument } from "@shared/types/graphql";
import { debug } from "@shared/utilities/debug";

export function useActiveServiceIntents() {
	const { widgetId } = useWidgetContext();
	const { navigateTo } = useNavigation();
	const { temporaryPatientId } = useAuthContext();
	const { patientId } = usePatientContext();
	debug("info", {
		context: "useActiveServiceIntents",
		message: "useActiveServiceIntents",
		info: { patientId: patientId, temporaryPatientId, widgetId: widgetId },
	});
	const isPatientPresent = !!patientId || !!temporaryPatientId;
	const [{ data, fetching, error }] = useQuery({
		query: WidgetGetActiveServiceIntentsDocument,
		variables: {
			// TODO: Stop relying on statuses for this (CAT-1287)
			terminalStatuses: Array.from(SERVICE_INTENT_TERMINAL_STATUSES),
		},
		pause: !isPatientPresent,
		requestPolicy: "cache-and-network",
	});

	// TODO: We should revisit if we can remove this
	const shouldUpdateServiceIntent = useCallback(
		(
			currentServiceIntent: ServiceIntentFieldsForServiceFragment | undefined,
			newServiceIntent: ServiceIntentFieldsForServiceFragment
		) => {
			// Check if the status is in progress
			const isInProgressStatus = (status: ServiceIntentStatus) =>
				Array.from(SERVICE_INTENT_INPROGRESS_STATUSES).includes(status);

			// Check if the status is editable
			const isEditableStatus = (status: ServiceIntentStatus) =>
				Array.from(SERVICE_INTENT_EDITABLE_STATUSES).includes(status);

			// If there's no current service intent, update it
			if (!currentServiceIntent) return true;
			// If the new service intent is in progress, update it
			if (isInProgressStatus(newServiceIntent.status as ServiceIntentStatus))
				return true;
			// If the new service intent is editable and the current one is not in progress, update it
			if (
				isEditableStatus(newServiceIntent.status as ServiceIntentStatus) &&
				!isInProgressStatus(currentServiceIntent.status as ServiceIntentStatus)
			)
				return true;
			// If the current service intent is neither in progress nor editable, update it
			return (
				!isInProgressStatus(
					currentServiceIntent.status as ServiceIntentStatus
				) &&
				!isEditableStatus(currentServiceIntent.status as ServiceIntentStatus)
			);
		},
		[]
	);

	// Main function to create a map of service intents by service id
	const serviceIntentsByServiceId = useMemo(() => {
		// Get the service intents from the data
		const serviceIntents = data?.service_intent;
		// If there are no service intents, return an empty object
		if (!serviceIntents) {
			return {};
		}

		// Sort the service intents by updated_at in descending order
		const sortedServiceIntents = serviceIntents.sort((a, b) => {
			return (
				new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
			);
		});

		// Reduce the sorted service intents into a map by service id
		return sortedServiceIntents.reduce((acc, serviceIntent) => {
			// If the service intent should be updated, update it in the accumulator
			if (
				shouldUpdateServiceIntent(acc[serviceIntent.service_id], serviceIntent)
			) {
				acc[serviceIntent.service_id] = serviceIntent;
			}
			// Return the accumulator for the next iteration
			return acc;
		}, {} as Record<string, ServiceIntentFieldsForServiceFragment>);
	}, [data, shouldUpdateServiceIntent]);
	const navigateToService = useCallback(
		(serviceId: string, startOver = false) => {
			const serviceIntent = serviceIntentsByServiceId[serviceId];
			const path = getServiceIntentStepRoute({
				widgetId,
				serviceId,
				serviceIntent: startOver ? undefined : serviceIntent,
			});

			debug("info", {
				context: "useActiveServiceIntents",
				message: "path",
				info: { path },
			});

			navigateTo(path);
		},
		[serviceIntentsByServiceId, widgetId, navigateTo]
	);

	return {
		navigateToService,
		serviceIntentsByServiceId,
		fetching,
		error,
	};
}
