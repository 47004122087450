import { Header } from "@app/components/Header";
import { Main } from "@app/components/Main";
import { Stack } from "@shared/components/Stack";
import { useAuthRequired } from "@shared/hooks/useAuthRequired";
import { useTheme } from "@shared/hooks/useTheme";
import type { IntakeStageProps } from "@shared/types/IntakeStages";
import { IntakeStage } from "@shared/types/IntakeStages";

import styles from "./AppointmentScheduling.module.css";
import { Scheduler } from "./Scheduler";

export function AppointmentScheduling({
	props: { serviceIntentId, serviceId },
	onNextStage,
}: IntakeStageProps<IntakeStage.Scheduling>) {
	const { spacing } = useTheme();

	const needsAuth = useAuthRequired(true);

	function handleBooking() {
		onNextStage(IntakeStage.Questions, {
			serviceId,
			serviceIntentId,
			isSecondaryQuestionStage: true,
		});
	}
	return (
		<>
			<Header />
			<Main loading={needsAuth}>
				<Stack direction="vertical" gap={spacing(6)} className={styles.root}>
					<Scheduler
						serviceIntentId={serviceIntentId}
						onBooking={handleBooking}
					/>
				</Stack>
			</Main>
		</>
	);
}
