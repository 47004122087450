import classNames from "classnames";
import * as _ from "lodash-es";
import { defaultV2Css } from "survey-core";
// Order is important.
import "survey-core/defaultV2.min.css";

import buttonStyles from "@shared/components/Button/Button.module.css";
import inputStyles from "@shared/components/Input/Input.module.css";
import textStyles from "@shared/components/Text/Text.module.css";
import textareaStyles from "@shared/components/TextArea/TextArea.module.css";

import styles from "./Questions.module.css";

export function useSurveyCSS(): typeof defaultV2Css {
	const css: typeof defaultV2Css = _.cloneDeep(defaultV2Css);

	css.rootMobile = classNames(css.rootMobile, "overflow-y-hidden min-w-full");
	css.rootFitToContainer = classNames(css.rootFitToContainer);
	css.rootWrapper = classNames(css.rootWrapper, styles.questions);
	css.rootWrapperFixed = classNames(
		css.rootWrapperFixed,
		styles.questions,
		"h-full"
	);
	css.container = classNames(css.container, "h-full");
	css.bodyContainer = classNames(css.bodyContainer, "h-full");
	css.body = classNames(
		css.body,
		"h-full scroll-smooth overflow-y-auto flex flex-col"
	);

	css.title = classNames(css.title);

	css.bodyNavigationButton = buttonStyles.button;

	css.page.root = classNames(
		css.page.root,
		"grow overflow-x-hidden mt-0 sm:mt-2 mb-4 !pl-4 !pr-2 min-w-full flex flex-nowrap"
	);

	css.page.emptyHeaderRoot = classNames(
		css.page.emptyHeaderRoot,
		"grow overflow-x-hidden mt-0 sm:mt-2 mt-2 mb-4 !pl-4 !pr-2 min-w-full flex flex-nowrap"
	);

	css.page.title = classNames(css.page.title, styles.pageTitle);

	css.pageRow = classNames(css.pageRow, "border-transparent shadow-none");

	css.navigation.complete = classNames(buttonStyles.primary, textStyles.button);

	css.navigation.next = classNames(buttonStyles.primary, textStyles.button);

	css.navigation.prev = classNames(buttonStyles.outline, textStyles.button);

	css.question.mainRoot = classNames(
		css.question.mainRoot,
		"space-y-4 p-0 shadow-none"
	);

	css.question.withFrame = classNames(
		css.question.withFrame,
		"space-y-4 p-0 shadow-none"
	);

	css.question.content = classNames(
		css.question.content,
		styles.questionContent
	);

	css.question.header = classNames(css.question.header, styles.questionHeader);

	css.question.description = classNames(
		css.question.description,
		textStyles.text,
		textStyles.body,
		textStyles.faded,
		styles.questionDescription
	);

	css.question.comment = classNames(
		css.question.comment,
		styles.questionComment
	);

	css.question.formGroup = classNames("h-full");

	css.question.other = classNames(css.question.other, styles.questionComment);

	css.question.title = classNames(css.question.title, "w-fit");

	css.question.titleBar = classNames(css.question.titleBar, "w-fit");

	css.actionBar.root = classNames(
		css.actionBar.root,
		styles.questionsPageFooter,
		"p-2",
		textStyles.text,
		textStyles.button
	);

	css.error.root = classNames(
		css.error.root,
		"bg-transparent absolute",
		styles.questionError
	);

	css.text.root = inputStyles.input;

	css.checkbox.root = classNames(css.checkbox.root, styles.questionOptions);

	css.checkbox.item = classNames(css.checkbox.item, styles.questionOption);

	css.checkbox.itemControl = styles.hidden;

	css.checkbox.itemChecked = classNames(
		css.checkbox.itemChecked,
		styles.questionOptionIsSelected
	);

	css.checkbox.label = classNames(
		css.checkbox.label,
		styles.questionOptionLabelCheckbox
	);

	css.checkbox.controlLabel = classNames(
		styles.questionOptionItemControlLabel,
		textStyles.text,
		textStyles.body
	);

	css.checkbox.materialDecorator = classNames(
		css.checkbox.materialDecorator,
		styles.questionCheckbox
	);

	css.checkbox.other = textareaStyles.textarea;

	css.radiogroup.root = classNames(css.radiogroup.root, styles.questionOptions);

	css.radiogroup.item = classNames(css.radiogroup.item, styles.questionOption);

	css.radiogroup.itemChecked = classNames(
		css.radiogroup.itemChecked,
		styles.questionOptionIsSelected
	);

	css.radiogroup.label = classNames(
		css.radiogroup.label,
		styles.questionOptionLabel
	);

	css.radiogroup.controlLabel = classNames(
		styles.questionOptionItemControlLabel,
		textStyles.text,
		textStyles.body
	);

	css.radiogroup.materialDecorator = classNames(styles.questionRadio);

	css.radiogroup.other = textareaStyles.textarea;

	css.list.item = classNames(css.list.item);

	css.list.itemSelected = classNames(css.list.itemSelected);

	css.dropdown.controlLabel = classNames(
		css.dropdown.controlLabel,
		textStyles.text,
		textStyles.body
	);

	css.dropdown.materialDecorator = classNames(
		css.dropdown.materialDecorator,
		styles.questionRadio
	);

	css.dropdown.popup = classNames(css.dropdown.popup, styles.dropdownPopup);

	css.dropdown.control = classNames(
		css.dropdown.control,
		"bg-transparent",
		styles.questionDropdown
	);

	css.dropdown.other = textareaStyles.textarea;

	css.tagbox.controlLabel = classNames(
		css.tagbox.controlLabel,
		textStyles.text,
		textStyles.body
	);

	css.tagbox.materialDecorator = classNames(
		css.tagbox.materialDecorator,
		styles.questionRadio
	);

	css.tagbox.other = textareaStyles.textarea;

	css.boolean.item = classNames(css.boolean.item, styles.questionBooleanItem);

	css.boolean.radioControlLabel = classNames(
		css.boolean.radioControlLabel,
		textStyles.text,
		textStyles.body
	);

	css.boolean.slider = classNames(
		css.boolean.slider,
		"bg-primary-accent-50 text-primary-foreground font-bold"
	);
	css.boolean.sliderGhost = classNames(
		css.boolean.sliderGhost,
		"bg-transparent text-primary-foreground"
	);
	css.boolean.controlCheckbox = classNames(
		css.boolean.controlCheckbox,
		"invisible important"
	);

	css.comment.root = textareaStyles.textarea;

	return css;
}
