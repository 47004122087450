import type { ComponentChildren } from "preact";

import { useEffect, useState } from "preact/hooks";

import {
	useOrganizationContext,
	usePatientContext,
	useWidgetContext,
} from "@app/components/Contexts";
import { RouteMap } from "@app/constants/RouteMap";
import { Button } from "@shared/components/Button";
import { Icon } from "@shared/components/Icon";
import { useNavigation } from "@shared/hooks/useNavigation";
import { useTheme } from "@shared/hooks/useTheme";
import { logo } from "@shared/icons/logo";
import { paytientCare } from "@shared/icons/paytientCare";

export type LogoImageProps = {
	title?: string;
	asText?: boolean;
};

export function LogoImage({ title, asText = true }: LogoImageProps) {
	const organization = useOrganizationContext();
	const { primaryAccent600 } = useTheme();
	const [logoUrl, setLogoUrl] = useState(organization.logoUrl);
	useEffect(() => setLogoUrl(organization.logoUrl), [organization.logoUrl]);

	if (organization.isPaytient) {
		return asText ? (
			<div className="text-sm sm:text-lg font-semibold">Paytient Care</div>
		) : (
			<ButtonWrapper>
				<div dangerouslySetInnerHTML={{ __html: paytientCare }} />
			</ButtonWrapper>
		);
	}

	if (!logoUrl) {
		return (
			<ButtonWrapper>
				<Icon
					icon={logo}
					fill={primaryAccent600}
					strokeWidth={40}
					size={36}
					title={title}
				/>
			</ButtonWrapper>
		);
	}

	return (
		<ButtonWrapper>
			<img
				src={logoUrl}
				onError={() => setLogoUrl(null)}
				alt="Widget Logo"
				width={36}
				height={36}
				title={title}
			/>
		</ButtonWrapper>
	);
}

function ButtonWrapper({ children }: { children: ComponentChildren }) {
	const { push } = useNavigation();
	const { widgetId } = useWidgetContext();
	const { patientId } = usePatientContext();

	return (
		<Button
			variant="plain"
			data-testid="logo-button"
			onClick={() =>
				push(patientId ? RouteMap.Visits : RouteMap.Default, { widgetId })
			}
		>
			{children}
		</Button>
	);
}
