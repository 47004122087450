import {
	disableFileDataAsText,
	registerCameraWidget,
} from "catchhealth-survey-widgets/dist/index";
import classNames from "classnames";
import DOMPurify from "dompurify";
import { useMemo, useRef } from "preact/hooks";
import Showdown from "showdown";
import * as Survey from "survey-core";
import type { Model, Question, UrlConditionItem } from "survey-core";
import { useMutation } from "urql";

import { useWidgetContext } from "@app/components/Contexts";
import { RouteMap } from "@app/constants/RouteMap";
import buttonStyles from "@shared/components/Button/Button.module.css";
import textStyles from "@shared/components/Text/Text.module.css";
import { useFirst } from "@shared/hooks/useFirst";
import { useNavigation } from "@shared/hooks/useNavigation";
import { useUploadFile } from "@shared/hooks/useUploadFile";
import type {
	WidgetGetLeadQuestionnaireQuery,
	EncounterForServiceFragment,
} from "@shared/types/graphql";
import {
	WidgetSubmitQuestionnaireAnswersDocument,
	WidgetSetQuestionnaireQualificationStatusDocument,
} from "@shared/types/graphql";
import { debug } from "@shared/utilities/debug";
import install from "@twind/with-web-components";

import defaultConfig from "../../../../twind.config";
import { validateSurveyResponse } from "./validateSurveyResponse";

type CameraQuestion = Question & {
	hasBeenValidated?: boolean;
	hasUploadedPhotos?: boolean;
};

disableFileDataAsText(Survey as $FixMe);

const engLocale = Survey.surveyLocalization.locales["en"];

engLocale.pageNextText = "Continue";
engLocale.completeText = "Continue";
engLocale.pagePrevText = "Go back";

const DISQUALIFIED_ID = "disqualified";

const converter = new Showdown.Converter();

enum QuestionnaireTypes {
	Capture = "capture",
	Evaluation = "evaluation",
	Lead = "lead",
}

const EncounterResponseTypes = [
	QuestionnaireTypes.Capture,
	QuestionnaireTypes.Evaluation,
];

function isEncounterResponseType(
	type: QuestionnaireTypes | undefined
): type is QuestionnaireTypes.Capture | QuestionnaireTypes.Evaluation {
	return type !== undefined && EncounterResponseTypes.includes(type);
}

type UseSurveyOptions = {
	questionnaire:
		| WidgetGetLeadQuestionnaireQuery["questionnaire_by_pk"]
		| undefined;
	questionnaireResponse?: EncounterForServiceFragment["questionnaire_response"];
	patientId: string | null;
	onComplete: (
		isDisqualified: boolean,
		showDataSavingSuccess?: (text?: string) => void
	) => void;
	onNavigate?: (url: string) => void;
};

const withTwind = install(defaultConfig);

class TwindElement extends withTwind(HTMLElement) {
	constructor() {
		super();
		this.attachShadow({ mode: "open" });

		const htmlContent = this.getAttribute("html");

		if (this.shadowRoot && htmlContent) {
			// Sanitize the htmlContent here before inserting it into the shadow DOM
			const sanitizedContent = this.sanitizeHTML(htmlContent);
			this.shadowRoot.innerHTML = sanitizedContent || "";
		}
	}

	sanitizeHTML(html: string) {
		// Implement or use a library to sanitize the HTML content
		// For example, you could use DOMPurify or a similar library
		return DOMPurify.sanitize(html);
	}
}

customElements.define("twind-element", TwindElement);

export function useSurvey({
	questionnaire,
	questionnaireResponse,
	patientId,
	onComplete,
	onNavigate,
}: UseSurveyOptions) {
	const questionsMetadata = useRef<Record<string, unknown>>({});
	const surveyJSON = useFirst(questionnaire?.form_versions);
	const __type = questionnaire?.type as QuestionnaireTypes | undefined;
	const canHaveEncounterResponses =
		questionnaire?.service_id &&
		questionnaireResponse &&
		__type &&
		isEncounterResponseType(__type);
	const surveyResponse = canHaveEncounterResponses
		? validateSurveyResponse(questionnaireResponse?.[__type]?.state)
		: null;

	const [{ fetching: submitting }, submitQuestionnaireAnswers] = useMutation(
		WidgetSubmitQuestionnaireAnswersDocument
	);
	const [{ fetching: completing }, setQuestionnaireQualification] = useMutation(
		WidgetSetQuestionnaireQualificationStatusDocument
	);
	const { uploadFile } = useUploadFile();

	const { push } = useNavigation();
	const { widgetId } = useWidgetContext();
	const isSubmittingRef = useRef(false);

	const survey = useMemo(() => {
		if (!questionnaire || !surveyJSON) return null;

		// Clear any existing widgets so we can override them.
		Survey.CustomWidgetCollection.Instance.clear();
		registerCameraWidget(
			Survey as $FixMe,
			async (file: File, questionId?: string): Promise<string> => {
				try {
					const uploadResult = await uploadFile(file, questionId, patientId);

					return uploadResult.fileId;
				} catch (err) {
					return "";
				}
			}
		);

		/* TODO: RMC CAT-1553 - Eventually, we will need to refactor lines 113-273 to come up with a better system for 
			managing disqualified logic. This is a temporary fix. */

		// surveyJSONContentForModel.triggers will be mutated if there are triggers which update "disqualified" flag to true.
		const surveyJSONContentForModel = JSON.parse(
			JSON.stringify(surveyJSON.content)
		);

		// surveyJSONContentForTriggers is a temp variable used to determine the triggers used in SurveyModel
		const surveyJSONContentForTriggers = JSON.parse(
			JSON.stringify(surveyJSON.content)
		);

		/* triggers is a temp variable mapped from `surveyJSON.content.triggers`. `surveyJSON.content.triggers` array can't be used directly.
			Example:
				[
					{
						"type": "complete",
						"expression": "{DoYouHaveAnyOfTheFollowing?noneImportant=true} anyof ['item1', 'item2', 'item3', 'item4', 'item5', 'item6']"
					},
					{
						"type": "setvalue",
						"expression": "{DoYouHaveAnyOfTheFollowing?noneImportant=true} anyof ['item1', 'item2', 'item3', 'item4', 'item5', 'item6']",
						"setToName": "disqualified",
						"setValue": "true"
					},
					{
						"type": "runexpression",
						"expression": "{question1} anyof ['item1', 'item3']",
						"setToName": "disqualified",
						"runExpression": "iif(true,'true','false')"
					}
				]
		*/
		const triggers: Array<{ [key: string]: string }> | undefined = (
			surveyJSONContentForTriggers?.triggers as
				| Array<{ [key: string]: string }>
				| undefined
		)?.map((trigger) => {
			return { ...trigger };
		});

		/* If this survey has triggers, then check if there are any relevant triggers which update "disqualified" flag. If there are,
			then invert the logic for the relevant triggers, and insert them at the beginning indices of a new array of triggers 
			(triggersJSON), and reset surveyContentForModel.triggers to triggersJSON.
		*/
		if (triggers && Array.isArray(triggers) && triggers.length > 0) {
			// filteredTriggersDetails filters the triggers to only triggers that update the disqualified flag
			const filteredTriggersDetails: Array<{ [key: string]: string }> =
				triggers.filter((detail) => {
					/* Only include trigger in filteredTriggersDetails if the trigger is type "runexpression" or "setvalue" 
					AND the trigger updates "disqualified"
				*/

					if (
						!(
							(detail.type === "runexpression" || detail.type === "setvalue") &&
							detail.setToName === "disqualified"
						)
					) {
						return false;
					}

					/* Only include trigger in filteredTriggersDetails if the trigger has either 
					a runExpression or setValue field.
				 */
					if (!(detail.runExpression || detail.setValue)) return false;

					// If the trigger contains a runExpression field...
					if (detail.runExpression) {
						const match = detail.runExpression
							.replace(/\s/g, "")
							.match(/iif\(([^,]+),([^,]+)(?:,([^)]+))?\)/);

						// Only include runExpression trigger if it's formatted in iif
						if (!match) return false;

						/* Only include runExpression trigger in filteredTriggersDetails if the trigger's runExpression 
							sets "disqualified" to true if runExpression evaluates to true. 
						*/
						if (!(match[2] === "'true'" || match[2] === "true")) return false;
					}

					// If the trigger contains a setValue field...
					if (detail.setValue) {
						/* Only include setValue trigger in filteredTriggersDetails if the trigger's setValue 
							sets "disqualified" to true.
						*/
						if (!(detail.setValue === "'true'" || detail.setValue === "true")) {
							return false;
						}
					}
					return true;
				});

			// If there are relevant triggers of type "runexpression" or "setvalue" which update "disqualified" to true...
			if (filteredTriggersDetails && filteredTriggersDetails.length > 0) {
				/* updatedTriggersDetails creates a new array of triggers from filterTriggersDetails with the inverse logic applied.
					For example:
						{
							"type": "setvalue",
							"expression": "{DoYouHaveAnyOfTheFollowing?noneImportant=true} anyof ['item1', 'item2', 'item3', 'item4', 'item5', 'item6']",
							"setToName": "disqualified",
							"setValue": "true"
						}
						... maps to =>
						{
							"type": "setvalue",
							"expression": "!({DoYouHaveAnyOfTheFollowing?noneImportant=true} anyof ['item1', 'item2', 'item3', 'item4', 'item5', 'item6'])",
							"setToName": "disqualified",
							"setValue": "false"
						}
				*/
				const updatedTriggersDetails = filteredTriggersDetails.map((detail) => {
					// Change the expression field to !(expression)
					if (detail.expression) {
						detail.expression = `!(${detail.expression})`;
					}

					/* Change the value in the first parameter of the iif(param1, param2, param3) or iif(param1, param2) statement to !(param1),
						so that the inverse of the user-defined logic is also evaluated. This will ensure that if a user unselects the option which
						triggers disqualified to be set to true, the inverse runExpression logic evaluates the inverted runExpression conditional as
						well. This will effectively reverse the disqualified flag from being set to true to being set to false.
					*/
					if (detail.runExpression) {
						const match = detail.runExpression
							.replace(/\s/g, "")
							.match(/iif\(([^,]+),([^,]+)(?:,([^)]+))?\)/);
						if (match) {
							detail.runExpression = match[3]
								? `iif(!(${match[1]}),${match[2]},${match[3]})`
								: `iif(!(${match[1]}),${match[2]},'false')`;
						}
					}

					/* Set the setValue field to 'false' if the current value is set to true. On line 200, we escape if 
						setValue is set to true, so this will only reset the setValue to false if the current value is true.
					*/
					if (detail.setValue) {
						detail.setValue = "false";
					}

					return detail;
				});

				/* triggersJSON contains a new array which includes the original SurveyModel triggers list with the
					updatedTriggersDetails prepended to the array. 
					
					It is important that the new updated triggers which evaluate "disqualified"="false" are positioned 
					before the triggers which update "disqualified"="true".
				*/
				const triggersJSON = updatedTriggersDetails.concat(
					surveyJSONContentForTriggers.triggers
				);

				// This line updates the initial surveyJSON.content.triggers to the new triggersJSON.
				surveyJSONContentForModel.triggers = triggersJSON;
			}
		}

		/* This creates the new Survey model which will be used in calculating the FE widget business logic. If there
			were relevant triggers which update "disqualified" value in the original SurveyJSON, then this will include
			the inverse logic as well.
		*/
		const model = new Survey.SurveyModel(surveyJSONContentForModel);

		// This events occurs just before a new page is loaded.
		model.onCurrentPageChanging.add(async (sender: Survey.SurveyModel) => {
			// If the survey is already submitting, don't run triggers
			if (isSubmittingRef.current) return;

			// This will re-evaluate the triggers before moving to the next page.
			sender.runTriggers();
		});

		// This events occurs when a new page is loaded.
		model.onCurrentPageChanged.add(async () => {
			// This prevents the page from preserving scroll position on the newly loaded page.
			const sjsBodyDiv = document.body.getElementsByClassName("sd-body");
			const sjsPageDiv =
				sjsBodyDiv.length > 0
					? sjsBodyDiv[0].getElementsByClassName("sd-page")
					: ([] as unknown as HTMLCollectionOf<HTMLDivElement>);
			if (sjsPageDiv.length > 0) sjsPageDiv[0].scrollTo(0, 0);
		});

		model.onTextMarkdown.add((_, options) => {
			let str = converter.makeHtml(options.text);
			// Remove <p> and </p> only if they are the only tags present (surveyjs requires this)
			if (str.startsWith("<p>") && str.endsWith("</p>")) {
				str = str.substring(3, str.length - 4);
			}
			options.html = str;
		});

		model.addNavigationItem({
			id: "sv-nav-prev",
			title: "Go back",
			visibleIndex: 19,
			action: () => {
				push(RouteMap.Default, { widgetId });
			},
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			visible: new Survey.ComputedUpdater<boolean>(() => {
				return model.isFirstPage;
			}),
			innerCss: classNames(
				buttonStyles.button,
				buttonStyles.outline,
				textStyles.button
			),
		});

		model.onServerValidateQuestions.add(
			async (
				sender: Model,
				options: {
					data: Partial<Model["data"]>;
					errors: Record<string, string>;
					complete(): void;
				}
			) => {
				isSubmittingRef.current = true;
				const cameraQuestions: CameraQuestion[] = [];
				let questionIdToUseForErrors = "";
				const formattedAnswers: { questionId: string; answer: string }[] = [];

				const questionIds = Object.keys(options.data).filter(
					(qid) => qid !== DISQUALIFIED_ID
				);
				const questionIdsWithComment = questionIds.filter(
					(qid) => sender.getValue(`${qid}-Comment`) !== undefined
				);

				questionIds.forEach((questionIdWithQueryString) => {
					questionIdToUseForErrors = questionIdWithQueryString;
					const [questionId] = questionIdWithQueryString.split("?");
					const commentAnswer = sender.getValue(
						`${questionIdWithQueryString}-Comment`
					);
					let answer =
						questionsMetadata.current[questionIdWithQueryString] ??
						options.data[questionIdWithQueryString] ??
						"";
					const question = sender.getQuestionByName(
						questionIdWithQueryString
					) as CameraQuestion;

					// Reset validation for camera type questions
					if (question.getType() === "camera") {
						question.hasBeenValidated = false;
						cameraQuestions.push(question);
					}

					// Simplify format of answer for file uploads
					if (Array.isArray(answer) && answer[0]?.fileId) {
						answer = answer.map(({ fileId }) => fileId);
					}

					if (typeof answer === "boolean") {
						answer = answer.toString();
					}

					if (
						question.getType() === "camera" &&
						(question.hasBeenValidated || !question.hasUploadedPhotos)
					) {
						return;
					}

					formattedAnswers.push({
						questionId,
						answer,
					});

					if (commentAnswer !== undefined) {
						formattedAnswers.push({
							questionId: `${questionId}-Comment`,
							answer: commentAnswer,
						});
					}
				});

				const questionnaireId = questionnaire?.id ?? "";

				if (
					formattedAnswers.length !==
					questionIds.length + questionIdsWithComment.length
				) {
					options.complete();
					return;
				}

				const response = await submitQuestionnaireAnswers({
					questionnaireId,
					answers: formattedAnswers,
					state: {
						currentPageNo: sender.currentPageNo,
						data: {
							...sender.data,
							...options.data,
						},
					},
				});

				// Show error if any. Will be shown on the last question on the page. Can improve that in the future
				if (response.error) {
					options.errors[questionIdToUseForErrors] =
						response.error.message ?? "Error submitting answers.";
				} else {
					cameraQuestions.forEach((question) => {
						question.hasBeenValidated = true;
					});
				}

				options.complete();
				isSubmittingRef.current = false;
			}
		);

		model.onNavigateToUrl.add(
			async (
				sender: Model,
				options: {
					allow: boolean;
					url: string;
				}
			) => {
				// This is being triggered even when there's no navigation, so we need to check for that
				if (!options.url) {
					return;
				}

				options.allow = true;
				const results = sender.data;
				const questionnaireId = questionnaire?.id ?? "";
				const disqualified = results.disqualified;
				const qualified = disqualified !== "true" && disqualified !== true;
				const response = await setQuestionnaireQualification({
					questionnaireId,
					// TODO: Handle tentative-disqualified
					qualifiedStatus: qualified ? "qualified" : "disqualified",
				});

				if (!response.error && onNavigate) {
					onNavigate(options.url);
				}
			}
		);

		model.onCompleting.add(
			(sender: Model, options: { allow: boolean; allowComplete: boolean }) => {
				/* TODO: Should be removed when we refactor lines 113-273. This ensures that runTriggers is run 
					again before completing the survey to ensure that the disqualified flag is updated to the most 
					recent computed value before completing the survey.
				*/
				sender.runTriggers();

				if (!onNavigate) {
					options.allowComplete = true;
					return;
				}
				if (sender.navigateToUrlOnCondition?.length > 0) {
					options.allow = false;
					options.allowComplete = false;
					const urlOnCondition = sender.navigateToUrlOnCondition.find(
						(condition: UrlConditionItem) => {
							const runner = new Survey.ConditionRunner(condition.expression);
							const isExpression = runner.run(sender.data);
							return isExpression;
						}
					);
					if (urlOnCondition) {
						const url = urlOnCondition.url;
						onNavigate(url);
						return;
					}
				}
				if (sender.navigateToUrl) {
					onNavigate(sender.navigateToUrl);
					return;
				}
			}
		);

		model.onComplete.add(
			async (
				sender: Model,
				options: {
					isCompleteOnTrigger: boolean;
					showDataSaving(text?: string): void;
					showDataSavingError(text?: string): void;
					showDataSavingSuccess(text?: string): void;
					showDataSavingClear(): void;
				}
			) => {
				const results = sender.data;
				const questionnaireId = questionnaire?.id ?? "";
				const disqualified = results.disqualified;
				const qualified = disqualified !== "true" && disqualified !== true;

				const response = await setQuestionnaireQualification({
					questionnaireId,
					// TODO: Handle tentative-disqualified
					qualifiedStatus: qualified ? "qualified" : "disqualified",
				});

				if (response.error) {
					options.showDataSavingError(
						response.error.message ?? "Error completing questionnaire."
					);
				} else {
					const isDisqualified =
						!qualified ||
						response.data?.setQuestionnaireQualificationStatus?.status !==
							"qualified";
					onComplete(isDisqualified, options.showDataSavingSuccess);
				}
			}
		);
		model.onUploadFiles.add(
			async (
				sender: Model,
				options: {
					question: Question;
					files: File[];
					name: string;
					callback: (
						status: string,
						files: { file: File; content: string }[]
					) => void;
				}
			) => {
				const { question, files, name } = options;
				const questionId = question.name;

				debug("info", {
					context: "useSurvey",
					message: "Uploading files",
					info: { question: options.question, files, name },
				});

				const uploadedFiles: {
					file: File;
					fileId: string;
					content: string;
				}[] = await Promise.all(
					files.map((file) => uploadFile(file, questionId, patientId))
				);

				questionsMetadata.current[name] = uploadedFiles.map(
					({ file, fileId }: { file: File; fileId: string }) => ({
						name: file.name,
						type: file.type,
						fileId,
					})
				);

				options.callback("success", uploadedFiles);
			}
		);

		model.onProcessHtml.add(
			async (
				sender: Model,
				options: {
					html: string;
					reason: string;
				}
			) => {
				const { html, reason } = options;
				if (reason === "html-question") {
					// Sanitize the HTML first to prevent XSS attacks
					const sanitizedHtml = DOMPurify.sanitize(html);

					// Parse the sanitized HTML into a temporary DOM element to inspect it
					const tempDiv = document.createElement("div");
					tempDiv.innerHTML = sanitizedHtml;

					// Check if the first child is a 'twind-element'
					const isAlreadyWrapped =
						tempDiv.firstChild &&
						tempDiv.firstChild.nodeName.toLowerCase() === "twind-element";

					if (!isAlreadyWrapped) {
						// Proceed to wrap the sanitized HTML if it's not already within a 'twind-element'
						const htmlString = sanitizedHtml
							.replace(/&lt;/g, "<")
							.replace(/&gt;/g, ">")
							.replace(/&quot;/g, '"')
							.replace(/&#039;/g, "'")
							.replace(/&amp;/g, "&");
						const el = document.createElement("body");
						const twindElement = document.createElement("twind-element");
						twindElement.setAttribute("html", htmlString);
						el.appendChild(twindElement);

						// Use the outerHTML of the twindElement for options.html
						options.html = el.innerHTML;
					} else {
						// If the content is already properly wrapped, use the sanitized HTML as is
						options.html = sanitizedHtml;
					}
				}
			}
		);

		model.showPageNumbers = false;
		model.showQuestionNumbers = false;
		if (surveyResponse) {
			model.currentPageNo = surveyResponse.currentPageNo;
			model.data = surveyResponse.data;
		}

		return model;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		questionnaire,
		surveyJSON,
		surveyResponse,
		uploadFile,
		submitQuestionnaireAnswers,
		setQuestionnaireQualification,
		onComplete,
		onNavigate,
		patientId,
	]);

	return {
		survey,
		submitting: submitting || completing,
	};
}
