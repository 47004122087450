import { useState } from "preact/hooks";

import { useAuthContext } from "@app/components/Contexts";
import { Form } from "@shared/components/Form";
import { InputBirthdate } from "@shared/components/InputBirthdate";
import { formatDate } from "@shared/utilities/formatDate";
import { formatPhoneNumber } from "@shared/utilities/formatPhoneNumber";
import { isValidDate } from "@shared/utilities/isValidDate";

interface Props {
	title?: string;
	buttonText?: string;
	onSuccess?: () => void;
	phoneNumber: string;
	code: string;
}

export function BirthdayLogin({
	title,
	buttonText = "Continue",
	onSuccess,
	phoneNumber,
	code,
}: Props) {
	const { loginWithCode } = useAuthContext();
	const [birthDate, setBirthDate] = useState<Date | null>(null);
	const onStartLogin = async () => {
		if (!phoneNumber || !birthDate || !isValidDate(birthDate)) {
			throw new Error("Missing required fields");
		}

		const formattedPhoneNumber = formatPhoneNumber(
			phoneNumber,
			"numeric-no-country-code"
		);

		const formattedBirthDate = formatDate(birthDate, "YYYY-MM-DD");

		const loginWithCodeResponse = await loginWithCode(
			code,
			formattedPhoneNumber,
			formattedBirthDate
		);

		if (!loginWithCodeResponse?.data?.loginWithCode?.jwt) {
			throw new Error(
				loginWithCodeResponse?.error?.message ?? "No JWT returned"
			);
		}

		onSuccess?.();
	};

	return (
		<Form
			title={title}
			submitButtonProps={{
				text: buttonText,
				disabled: !phoneNumber || !birthDate,
			}}
			onSubmit={onStartLogin}
		>
			{({ isSubmitting }) => (
				<InputBirthdate
					label="Date of Birth"
					onInput={setBirthDate}
					autocomplete="bday"
					disabled={isSubmitting}
					required
				/>
			)}
		</Form>
	);
}
